import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Thesis submission correction - Candidate submitting corrections state.
 *
 * Chair view.
 */
const CandidateSubmittingChanges = ({ submission, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).chairMessage
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, 'REQUEST_CHANGES')

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      outcomeMessage={submission?.previousIteration?.lastStatus?.outcomeMessage}
    />
  )
}

export default CandidateSubmittingChanges
