import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material'

function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <MuiTooltip describeChild={true} arrow {...props}>
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
