import { useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import StyledDoNotDisturbIcon from 'src/components/Icons/DoNotDisturbIcon'

import ThesisExaminerSubmissionNoteDialog from '../ThesisExaminerSubmissionNoteDialog'
import ThesisExaminerUserNoteDialog from '../ThesisExaminerUserNoteDialog'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0] | null
}
/**
 * Rejected examiner section
 */
const RejectedExaminer = ({ examiner }: Props) => {
  const { hasRole } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const [onOpenExaminerSubmissionNoteDialog, setOnOpenExaminerSubmissionNoteDialog] = useState<boolean>(false)
  const [onOpenExaminerUserNoteDialog, setOnOpenExaminerUserNoteDialog] = useState<boolean>(false)

  const onCloseDialog = () => {
    handleMenuClose()

    setOnOpenExaminerSubmissionNoteDialog(false)
    setOnOpenExaminerUserNoteDialog(false)
  }

  const isGS = hasRole('grad-school-user')

  return (
    <>
      <Grid
        container
        alignContent={'flex-start'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        sx={{ mb: 1 }}
      >
        <Grid xs="auto">
          <StyledDoNotDisturbIcon />
        </Grid>
        <Grid xs sx={{ ml: 1 }}>
          <Grid direction={'column'} container>
            <Grid xs={12}>
              <Typography variant="subtitle1" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {examiner?.user ? examiner?.user?.displayName : examiner?.fullName || 'To be confirmed'}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography fontSize="small" color={'#eeeeee'} sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{`${
                examiner?.institution || ''
              }`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* @TODO CHECK if there's any action if none do not show this */}
        {isGS && (
          <Grid xs={'auto'}>
            <Tooltip title={'View actions'}>
              <IconButton sx={{ color: 'rgba(255,255,255,0.75)', mr: -2 }} onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      {/* GS action only */}
      {onOpenExaminerSubmissionNoteDialog && isGS && (
        <ThesisExaminerSubmissionNoteDialog
          onOpen={onOpenExaminerSubmissionNoteDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
        />
      )}

      {/* Only available once user confirms their email */}
      {onOpenExaminerUserNoteDialog && examiner?.user && isGS && (
        <ThesisExaminerUserNoteDialog
          onOpen={onOpenExaminerUserNoteDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
        />
      )}

      {/* Menu */}
      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleMenuClose}>
        {isGS && (
          <Box>
            <MenuItem onClick={() => setOnOpenExaminerSubmissionNoteDialog(true)}>{'View submission note'}</MenuItem>
            {examiner?.user && (
              <MenuItem onClick={() => setOnOpenExaminerUserNoteDialog(true)}>{'View user note'}</MenuItem>
            )}
          </Box>
        )}
      </Menu>
    </>
  )
}

export default RejectedExaminer
