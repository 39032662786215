const THESIS_DOWNLOAD_DETAILS_QUERY = gql`
  query ThesisDownloadDetailsQuery($uuid: String!) {
    thesisDownloadDetails(uuid: $uuid) {
      title
      author
      files {
        id
        type
        filename
        submissionDate
      }
    }
  }
`

export default THESIS_DOWNLOAD_DETAILS_QUERY
