import ExternalLink from 'src/components/ExternalLink/ExternalLink'

export default {
  title: 'Thesis Intellectual Property',
  text: (
    <>
      For additional information on intellectual property see the{` `}
      <ExternalLink href="https://study.uq.edu.au/admissions/phd-mphil-professional-doctorate/intellectual-property">
        Graduate School website
      </ExternalLink>
      .
    </>
  ),
}
