import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
} from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

import { useController } from '@redwoodjs/forms'

type Props = {
  label: string
  helperText?: string
  error?: boolean
  required?: boolean
  readOnly?: boolean
  value: string
  onChange: (value: string) => void
  options: { value: unknown; label: string }[]
  row?: boolean
  size?: 'small' | 'medium'
  labelSx?: SxProps<Theme>
  colShading?: boolean
}

export const RadioGroup = (props: Props) => {
  const onLocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.readOnly) {
      props.onChange(event.target.value)
    }
  }

  const row = props.row === undefined ? true : props.row

  const randomID = Math.floor(Math.random() * 3)
  return (
    <FormControl required={props.required} error={props.error} fullWidth>
      <FormLabel id={`YesNo_${randomID}`} required={props.required} sx={props.labelSx}>
        {props.label}
      </FormLabel>
      {props.helperText && <FormHelperText sx={{ ml: 0 }}>{props.helperText}</FormHelperText>}
      <MuiRadioGroup row={row} value={props.value} onChange={onLocalChange} aria-labelledby={`YesNo_${randomID}`}>
        {props.options.map((option, index) => (
          <FormControlLabel
            sx={
              props.colShading &&
              index % 2 !== 0 && { bgcolor: 'rgba(81, 36, 122,0.03)', pr: 2, ml: -1.5, borderRadius: 10 }
            }
            key={option.value}
            value={option.value}
            control={<Radio size={props.size} />}
            label={option.label}
            disabled={props.readOnly}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

type CProps = Omit<Props, 'value' | 'onChange'> & {
  name: string
}

export const CRadioGroup = (props: CProps) => {
  const { field, fieldState } = useController({ name: props.name })
  const onChange = (value: string) => {
    let parsedValue
    if (value === 'true') {
      parsedValue = true
    } else if (value === 'false') {
      parsedValue = false
    } else {
      parsedValue = value // Keep original value for non-boolean options
    }

    field.onChange(parsedValue)
    field.onBlur()
  }

  return (
    <RadioGroup
      {...props}
      value={field.value}
      onChange={onChange}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : props.helperText}
    />
  )
}
