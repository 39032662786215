import { getIterationState } from '@common/thesis/iterationState'
import { Alert } from '@mui/material'
import { GetThesisSubmissionQuery, MyRecordsQuery } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import ThesisIterationFormViewer from 'src/components/Thesis/ThesisIterationFormViewer/ThesisIterationFormViewer'
import MY_RECORDS_QUERY from 'src/lib/graphql/queries/MyRecordsQuery'
import {
  FinalThesisSubmissionForm,
  ThesisSubmissionForm,
} from 'src/pages/ThesisSubmissionDetailsPage/components/ThesisSubmissionForm'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
  onClose: () => void
}

/**
 * Thesis submission form (initial or final or read-only)
 */
const SubmissionForm = ({ submission, onReload, onClose }: Props) => {
  const { data, loading, error } = useQuery<MyRecordsQuery>(MY_RECORDS_QUERY)

  if (loading) return <InlineLoader message="Loading your thesis details..." />

  if (error) {
    return <Alert severity="error">Could not load your thesis details</Alert>
  }

  const iterationState = getIterationState(submission?.currentIteration?.currentStatus)
  const canCreateIteration = iterationState.canSubmitIteration

  const canCreateInitialSubmission =
    canCreateIteration && (iterationState.thesisState === 'INITIAL' || iterationState.thesisState === 'REQUEST_CHANGES')
  const canCreateFinalSubmission = canCreateIteration && iterationState.thesisState === 'FINAL'

  return (
    <>
      {canCreateFinalSubmission && (
        <FinalThesisSubmissionForm
          userRecordsSummary={data?.userRecordsSummary}
          submission={submission}
          onReload={onReload}
          onClose={onClose}
        />
      )}
      {canCreateInitialSubmission && (
        <ThesisSubmissionForm
          userRecordsSummary={data?.userRecordsSummary}
          submission={submission}
          onReload={onReload}
          onClose={onClose}
        />
      )}

      {canCreateIteration === false && <ThesisIterationFormViewer iteration={submission.currentIteration} />}
    </>
  )
}

export default SubmissionForm
