import { useAuth } from 'src/auth'
import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import { getItsHelpLink } from 'src/lib/variables'

const SupportLink = () => {
  const { currentUser } = useAuth()

  return <ExternalLink href={getItsHelpLink(currentUser)}>the service desk</ExternalLink>
}

export default SupportLink
