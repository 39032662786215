import Grid from '@mui/material/Unstable_Grid2/Grid2'

import HelpButton from 'src/components/HelpButton/HelpButton'
import keywordsGuidance from 'src/lib/guidance/thesis/keywordsGuidance'

import { CAutocomplete } from '../Base/Autocomplete'

type Props = {
  name: string
  readOnly: boolean
}

const ThesisKeywords = ({ name, readOnly }: Props) => {
  return (
    <Grid container alignItems="normal">
      <Grid xs>
        <CAutocomplete
          freeSolo={true}
          multiple={true}
          name={name}
          textFieldProps={{
            label: 'Keywords',
            required: true,
            placeholder: 'Type each keyword and press enter to add',
          }}
          getOptionLabel={(option) => option}
          readOnly={readOnly}
          options={[]}
        />
      </Grid>
      <Grid xs="auto" sx={{ pt: 1.3 }}>
        <HelpButton {...keywordsGuidance} />
      </Grid>
    </Grid>
  )
}

export default ThesisKeywords
