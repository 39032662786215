import { LoadingButton } from '@mui/lab'

import Button from '../Button/Button'

import { Dialog, DialogActions, DialogContent, DialogTitle } from './Dialog'

type Props = {
  cancelLabel?: string
  confirmLabel?: string
  children: React.ReactNode
  disableClickAway?: boolean
  loading?: boolean
  open: boolean
  title?: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmDialog = ({
  cancelLabel = 'Cancel',
  confirmLabel = 'Submit',
  children,
  loading,
  open,
  title,
  onClose,
  onConfirm,
  disableClickAway = false,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={disableClickAway ? undefined : onClose}
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      {title && (
        <DialogTitle variant="h2" component="h2" color="primary" id="dialogtitle">
          {title}
        </DialogTitle>
      )}
      <DialogContent id="dialogcontent">{children}</DialogContent>

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose} disabled={!!loading}>
          {cancelLabel}
        </Button>
        <LoadingButton variant="contained" color="primary" onClick={onConfirm} loading={!!loading}>
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
