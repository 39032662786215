import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import GRADUATE_SCHOOL_UPDATES_EXAMINER_DETAILS from 'src/lib/graphql/mutations/GraduateSchoolUpdatesExaminerDetails'
import GRADUATE_SCHOOL_UPDATES_PARTIAL_EXAMINER_DETAILS from 'src/lib/graphql/mutations/GraduateSchoolUpdatesPartialExaminerDetails'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ConflictOfInterestDisclosureDetails from './ConflictOfInterestDisclosureDetails'
import GraduateSchoolExaminerDetailsUpdateForm from './GraduateSchoolExaminerDetailsUpdateForm'
import {
  createGSExaminerBasicDetailsUpdateDefaultValues,
  graduateSchoolUpdateAllExaminerDetailsValidation,
  graduateSchoolUpdatePartialExaminerDetailsValidation,
  GSExaminerBasicDetailUpdateType,
  gsUpdatesExaminerDetailsTransformer,
  gsUpdatesPartialExaminerDetailsTransformer,
} from './schema'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
}

/**
 * Graduate School Examiner details update dialog
 */
const GraduateSchoolExaminerDetailsUpdateDialog = ({ examiner, submission, onOpen, onClose }: Props) => {
  const { hasRole } = useAuth()
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState<boolean>(false)

  const examinerHasUserEntry = !!examiner?.user
  const hasAdvisorCOIStatements =
    !!examiner?.advisorCOIStatements && Object.keys(examiner.advisorCOIStatements).length > 0
  const hasCandidateCOIStatements =
    !!examiner?.candidateCOIStatements && Object.keys(examiner?.candidateCOIStatements).length > 0
  const hasExaminerCOIStatements =
    !!examiner?.examinerCOIStatements && Object.keys(examiner?.examinerCOIStatements).length > 0
  const hasAtLeastOneCOIStatements = hasAdvisorCOIStatements || hasCandidateCOIStatements || hasExaminerCOIStatements

  const [gsUpdatesExaminerDetailsMutation] = useMutation(GRADUATE_SCHOOL_UPDATES_EXAMINER_DETAILS, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [gsUpdatesPartialExaminerDetailsMutation] = useMutation(GRADUATE_SCHOOL_UPDATES_PARTIAL_EXAMINER_DETAILS, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm<GSExaminerBasicDetailUpdateType>({
    defaultValues: { ...createGSExaminerBasicDetailsUpdateDefaultValues(examiner, examinerHasUserEntry) },
    resolver: yupResolver(
      examinerHasUserEntry
        ? graduateSchoolUpdatePartialExaminerDetailsValidation
        : graduateSchoolUpdateAllExaminerDetailsValidation
    ), //@TODO fix this error
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async ({ ...data }: GSExaminerBasicDetailUpdateType) => {
    setSaving(true)

    try {
      if (examinerHasUserEntry) {
        await gsUpdatesPartialExaminerDetailsMutation({
          variables: {
            id: examiner?.id,
            submissionId: submission.id,
            examinerData: gsUpdatesPartialExaminerDetailsTransformer(data),
          },
        })
      } else {
        await gsUpdatesExaminerDetailsMutation({
          variables: {
            id: examiner?.id,
            submissionId: submission.id,
            examinerData: gsUpdatesExaminerDetailsTransformer(data),
          },
        })
      }

      showSnackbar('You have successfully updated the examiner’s information.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update Thesis Examiner information. Please try again later.', 'error')
    }

    formMethods.reset()
    setSaving(false)
  }

  const isGS = hasRole('grad-school-user')

  const hasValidationError = !formMethods.formState.isValid
  return (
    <Dialog
      open={onOpen}
      onClose={() => onClose()}
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle variant="h2" component="h2" id="dialogTitle" color="primary">
        Examiner details
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogContent">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <GraduateSchoolExaminerDetailsUpdateForm
                examiner={examiner}
                examinerHasUQAccount={examinerHasUserEntry}
              />
            </Grid>
            {hasAtLeastOneCOIStatements && (
              <ConflictOfInterestDisclosureDetails examiner={examiner} submission={submission} />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          {isGS && (
            <Button variant="contained" color="primary" type="submit" disabled={saving || hasValidationError}>
              Update
            </Button>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default GraduateSchoolExaminerDetailsUpdateDialog
