import { Box, Grid, InputLabel, Typography } from '@mui/material'

import { Autocomplete, TextField, ThesisEmbargoType } from 'src/components/Form/Inputs'

import { ThesisSubmissionIterationType } from '../ThesisSubmissionType'

import ThesisFilesViewer from './components/ThesisFilesViewer'

type Props = {
  iteration: ThesisSubmissionIterationType
}

/**
 * Shows information for a single iteration of a _final_ thesis submission. This is purposefully a read-only view
 * in order to keep the logic as simple as possible. Separation of concerns is important here.
 */
const FinalThesisIterationFormViewer = ({ iteration }: Props) => {
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <InputLabel shrink={true} id="titleid" required>
          Thesis title
        </InputLabel>
        <Box tabIndex={0}>
          <Box sx={{ position: 'absolute', left: -1000, top: -1000 }}>
            Thesis Title - edit read only - {iteration.title}
          </Box>
          <Box aria-hidden="true" dangerouslySetInnerHTML={{ __html: iteration.title }} sx={{ mt: -2.5, pb: 0 }} />
        </Box>
      </Grid>
      <Grid item={true} xs={12}>
        <InputLabel shrink={true} id="abstractid" required>
          Thesis abstract
        </InputLabel>
        <Box tabIndex={0}>
          <Box sx={{ position: 'absolute', left: -1000, top: -1000 }}>
            Thesis abstract - edit read only - {iteration.abstract}
          </Box>
          <Box aria-hidden="true" dangerouslySetInnerHTML={{ __html: iteration.abstract }} sx={{ mt: -2.5, pb: 0 }} />
        </Box>
      </Grid>
      <Grid item={true} sm={12}>
        <TextField
          label="Additional supervisors"
          value={iteration.additionalSupervisors.map((s) => s.displayName).join(', ') || 'None'}
          InputLabelProps={{ shrink: true }}
          readOnly
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Autocomplete
          textFieldProps={{ label: 'Field of research codes', required: true }}
          multiple={true}
          value={iteration.forCodes}
          readOnly={true}
          options={iteration.forCodes}
          getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.code} - ${option.name}`)}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <Autocomplete
          textFieldProps={{ label: 'Thesis keywords', required: true }}
          multiple={true}
          getOptionLabel={(o) => o}
          value={iteration.keywords}
          readOnly={true}
          options={iteration.keywords}
        />
      </Grid>
      <Grid item={true} xs={12} md={6} sx={{ mt: 0.8 }}>
        <TextField label="Total pages" value={iteration.totalPages} required readOnly={true} />
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          required
          label="Have you assigned your intellectual property to UQ?"
          value={iteration.isUqIntellectualProperty ? 'Yes' : 'No'}
          readOnly
        />
      </Grid>
      <Grid item={true} xs={12}>
        <ThesisEmbargoType label="Embargo status" value={iteration.embargoType} readOnly required />
      </Grid>
      <Grid item={true} xs={12} sx={{ mt: 2 }}>
        <Typography variant="h2" component="h2" color="primary">
          Thesis files
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <ThesisFilesViewer files={iteration.files} showIthenticateFile={false} />
      </Grid>
    </Grid>
  )
}

export default FinalThesisIterationFormViewer
