export default {
  title: 'Criteria for thesis examination',
  text: (
    <>
      <ul>
        <li>Does the candidate demonstrate a significant and original contribution to knowledge (PhD candidates)?</li>
        <li>Does the candidate show originality in the application of knowledge (MPhil candidates)?</li>
        <li>Does the candidate engage with the literature and the work of others?</li>
        <li>
          Does the candidate show an advanced knowledge of research principles and methods relating to the applicable
          discipline?
        </li>
        <li>Is there a clear and discernible coherence in the presented research, its arguments and conclusions?</li>
        <li>Is the thesis clearly, accurately and cogently written?</li>
      </ul>
      <p>Please be sure to include any comments or changes you recommend the candidate make.</p>
      <p>
        In addition, some examiners choose to provide comments via an annotated PDF copy of the thesis but this is not
        expected.
      </p>
    </>
  ),
}
