import { thesisTypes } from '@common/thesis'
import { getIterationState } from '@common/thesis/iterationState'
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { GetProvostActionListQuery } from 'types/graphql'

import ContentCard from 'src/components/ContentCard/ContentCard'
import ConferAllThesesButton from 'src/components/Thesis/ProvostActions/ConferAllThesesButton/ConferAllThesesButton'
import ConferThesisButton from 'src/components/Thesis/ProvostActions/ConferThesisButton/ConferThesisButton'
import { toFullTimezone } from 'src/lib/toTimezone'

type Props = {
  data: GetProvostActionListQuery['getProvostActionList']
  onRefetch: () => void
}

const PendingConferralCard = ({ data, onRefetch }: Props) => {
  const styles = {
    dataCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }

  return (
    <ContentCard title="Pending conferral by Provost" action={<ConferAllThesesButton onReload={onRefetch} />}>
      <Table
        aria-label="Theses conferral table. PLease note that each thesis record is made of 2 rows in the table, the first being the title and the second row being the extranous data for that thesis"
        size="small"
        sx={{ minWidth: 100, maxWidth: '100%', width: '100%', tableLayout: 'fixed' }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <i>Title</i>/Candidate
            </TableCell>
            <TableCell>Principal Advisor</TableCell>
            <TableCell width={70}>Degree</TableCell>
            <TableCell>UQ organisational unit</TableCell>
            <TableCell width={180}>Reviewed by date</TableCell>
            <TableCell align="right" width={160}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pendingConferralSubmissions.length == 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No theses available to confer
              </TableCell>
            </TableRow>
          ) : (
            data.pendingConferralSubmissions.map((action, index) => {
              const iterationState = getIterationState(action.currentStatus)
              return (
                <>
                  <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }} colSpan={6}>
                      <Typography
                        variant="body1"
                        sx={{ pb: 0, pt: 1, mb: -1, fontStyle: 'italic' }}
                        dangerouslySetInnerHTML={{ __html: action.currentIteration.title }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip title={action.candidate.displayName} arrow>
                        <Typography variant="body2" sx={styles.dataCell}>
                          {action.candidate.displayName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip title={action.principalAdvisor.displayName} arrow>
                        <Typography variant="body2" sx={styles.dataCell}>
                          {action.principalAdvisor.displayName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip
                        title={thesisTypes.find((type) => type.code === action?.currentIteration?.type).name}
                        arrow
                      >
                        <span>{thesisTypes.find((type) => type.code === action?.currentIteration?.type).name}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip title={action.currentIteration.organisation.name} arrow>
                        <Typography variant="body2" sx={styles.dataCell}>
                          {action.currentIteration.organisation.name}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>{toFullTimezone(action?.currentStatus.createdAt)}</TableCell>
                    <TableCell align="right" sx={styles.dataCell}>
                      <ButtonGroup
                        size="small"
                        variant="contained"
                        color="secondary"
                        aria-label="View or confer this thesis"
                        sx={{ mr: -1, mb: 1 }}
                      >
                        {iterationState.code === 'AWAITING_PROVOST_CONFERRAL' && (
                          <Button
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(
                                `${process.env.WEB_BASE_URL}/graduate-school/thesis/details/${action.submission.id}`,
                                '_blank'
                              )
                            }}
                          >
                            View
                          </Button>
                        )}
                        {iterationState.code === 'AWAITING_PROVOST_CONFERRAL' && (
                          <ConferThesisButton iterationId={action.currentIteration.id} onReload={onRefetch} />
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                </>
              )
            })
          )}
        </TableBody>
      </Table>
    </ContentCard>
  )
}

export default PendingConferralCard
