import { useCallback, useContext } from 'react'

import IconHelp from '@mui/icons-material/HelpOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/system'

import Button from 'src/components/Button/Button'
import UiContext from 'src/lib/context/Ui/Context'

type HelpButtonProps = {
  buttonLabel?: string
  buttonSx?: object
  style?: unknown
  title: string
  text: React.ReactNode
  version?: string
  dense?: boolean
  isInfo?: boolean
}

const HelpButton = ({ title, style, buttonLabel, text, version, dense, buttonSx, isInfo }: HelpButtonProps) => {
  const { showHelpDrawer } = useContext(UiContext)

  const onClick = useCallback(() => {
    showHelpDrawer(title, text, buttonLabel)
  }, [showHelpDrawer, title, text, buttonLabel])
  if (version === 'text') {
    return (
      <Box
        tabIndex={0}
        onClick={onClick}
        sx={{
          display: 'inline-block',
          '&:focus': { cursor: 'pointer', textDecoration: 'underline' },
          '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
        }}
      >
        Click for more information ({title})
      </Box>
    )
  }
  if (version === 'button') {
    return (
      <Button
        variant="contained"
        color="inherit"
        title={title}
        onClick={onClick}
        sx={{ pl: 1, pr: 1, minWidth: 20 }}
        aria-label={`Click for more information (${title})`}
      >
        {isInfo ? <InfoOutlinedIcon /> : <IconHelp />}
      </Button>
    )
  }

  const iconButtonSx = { ...buttonSx, ...(dense ? { mt: -1, mb: -0.7, pb: '8px' } : {}) }

  return (
    <IconButton
      title={title}
      onClick={onClick}
      style={style || {}}
      aria-label={`Click for more information (${title})`}
      sx={iconButtonSx}
    >
      {isInfo ? <InfoOutlinedIcon /> : <IconHelp />}
    </IconButton>
  )
}

export default HelpButton
