import { Box, Grid } from '@mui/material'

const styles = {
  arrow1: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid #740001',
  },
  arrow2: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid #7b2a00',
  },
  arrow3: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid #134522',
  },
  arrow4: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid #0f2233',
  },
  arrow5: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid rgba(0,0,0,0.1)',
  },
  arrow6: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid #51247a',
  },
  cells: {
    '&:last-child': {
      width: '40px !important',
      display: 'block !important',
      minWidth: 30,
      maxWidth: 30,
    },
  },
}

const getArrowStyle = (priority) =>
  priority == 1
    ? styles.arrow1
    : priority == 2
    ? styles.arrow2
    : priority == 3
    ? styles.arrow3
    : priority == 4
    ? styles.arrow4
    : priority == 5
    ? styles.arrow5
    : styles.arrow6

type Props = {
  activeStep: number
  maxStep: number
  steps: { tooltip: string; group?: number; priority?: number; error?: boolean }[]
}

/**
 * Thesis submission stepper pointer
 */
const StepperPointer = ({ steps, activeStep, maxStep }: Props) => {
  return (
    <Grid container sx={{ pl: 1 }}>
      {steps.map((step, index) => {
        // If the step is the current step, show the arrow
        if (index + 1 == activeStep) {
          return (
            <Grid item xs key={index} sx={styles.cells}>
              <Box sx={getArrowStyle(step.priority || 4)} />
            </Grid>
          )
        }
        // If the max step has been completed, leave the arrow on the last step
        else if (index + 1 === maxStep && activeStep > maxStep) {
          return (
            <Grid item xs key={index} sx={styles.cells}>
              <Box sx={getArrowStyle(step.priority || 4)} />
            </Grid>
          )
        } else if (index + 1 != steps.length) {
          return <Grid item xs key={index} sx={styles.cells} />
        } else {
          return <Grid item xs key={index} sx={styles.cells} />
        }
      })}
    </Grid>
  )
}

export default StepperPointer
