import { ThesisSubmissionIterationStatus } from 'types/graphql'

import { getThesisNotificationState } from 'src/lib/helpers/thesis'

import { getThesisSubmissionStepperInfo } from './helpers'
import Stepper from './Stepper/Stepper'

type Props = {
  iteration: {
    currentStatus: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>
  }
}

/**
 * Thesis submission initial (without oral) process stepper
 */
const ThesisSubmissionStepperInitialNoOral = ({ iteration }: Props) => {
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration }, 'NO_ORAL_INITIAL')

  const notificationState = getThesisNotificationState(iteration?.currentStatus)

  return (
    stepperInfo && (
      <Stepper
        steps={[
          { tooltip: 'Candidate submits thesis', priority: notificationState },
          { tooltip: 'Graduate School reviews thesis', priority: notificationState },
          { tooltip: 'Principal Advisor reviews thesis', priority: notificationState },
          { tooltip: 'Graduate School initiates examination', priority: notificationState },
          { tooltip: 'Examiners review thesis', priority: notificationState },
          { tooltip: 'Chair reviews examination', priority: notificationState },
          { tooltip: 'Pending Dean outcome', priority: notificationState },
          // @TODO Final step description might need to be changed... RnR and approval and request changes should be different
          { tooltip: 'Approved for thesis finalisation', priority: notificationState },
        ]}
        {...stepperInfo}
      />
    )
  )
}

export default ThesisSubmissionStepperInitialNoOral
