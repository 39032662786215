import { useState } from 'react'

import { ProvostConferAllTheses, ProvostConferAllThesesVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import PROVOST_CONFER_ALL_THESES from 'src/lib/graphql/mutations/ProvostConferAllTheses'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

type Props = {
  disabled?: boolean
  onReload: () => unknown
  fullWidth?: boolean
}

const ConferAllThesesButton = ({ onReload, disabled, fullWidth }: Props) => {
  const [saving, setSaving] = useState(false)
  const [conferAll] = useMutation<ProvostConferAllTheses, ProvostConferAllThesesVariables>(PROVOST_CONFER_ALL_THESES)
  const showSnackbar = useSnackbar()

  const onConfirm = async () => {
    setSaving(true)
    try {
      await conferAll()

      showSnackbar("You've successfully conferred all theses.", 'success')
      onReload()
    } catch (error) {
      const errorMessage = error?.graphQLErrors?.[0]?.message || 'Could not confer all theses. Please try again later.'

      Sentry.captureException(error)
      showSnackbar(errorMessage, 'error')
    }
    setSaving(false)
  }

  return (
    <>
      <Button
        size={'small'}
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        onClick={onConfirm}
        disabled={disabled || saving}
        sx={{ mt: 1, mr: 2 }}
      >
        Confer all
      </Button>
    </>
  )
}

export default ConferAllThesesButton
