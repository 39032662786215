import { forwardRef } from 'react'

import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField'

import { useController, useFormContext } from '@redwoodjs/forms'

export type TextFieldProps = MUITextFieldProps & {
  readOnly?: boolean
  showReadOnlyTitle?: boolean
  multiline?: boolean
  rows?: number
  minRows?: number
  maxRows?: number
}
export const TextField = forwardRef((props: TextFieldProps, ref) => {
  const { readOnly, showReadOnlyTitle, multiline, rows, minRows, maxRows, InputProps, ...textFieldProps } = props
  const IProps = {
    ...InputProps,
    readOnly,
    multiline,
    rows,
    minRows,
    maxRows,
  }

  const fullWidth = props.fullWidth === undefined ? true : props.fullWidth
  return (
    <MUITextField
      {...textFieldProps}
      fullWidth={fullWidth}
      InputProps={IProps}
      label={readOnly && showReadOnlyTitle ? props.label + ' (read only)' : props.label}
      ref={ref}
      inputRef={props.inputRef}
    />
  )
})

type ControlledTextFieldProps = TextFieldProps & {
  name: string
  maxLength?: number
  minLength?: number
}

export const CTextField = forwardRef((props: ControlledTextFieldProps, ref) => {
  const { name, maxLength, minLength, ...textFieldProps } = props
  const { field, fieldState } = useController({ name, defaultValue: '' })
  const { trigger } = useFormContext()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const newValue = event.target.value.replace(/\s+/g, ' ')
    const newValue = event.target.value.replace(/(?<!\n) {2,}(?!\n)/g, ' ')
    field.onChange(newValue)
  }

  const fieldLength = (field.value || '').length

  textFieldProps.inputProps = textFieldProps.inputProps || {}

  let lengthHelperText = ''

  // Max length given, but no min length
  if (!!maxLength && !minLength && fieldLength >= maxLength) {
    textFieldProps.inputProps.maxLength = maxLength
    lengthHelperText = `${fieldLength} of ${maxLength} required maximum characters`
  }

  // Min length given, no no max length
  if (!!minLength && !maxLength && fieldLength <= minLength) {
    textFieldProps.inputProps.minLength = minLength
    lengthHelperText = `${fieldLength} of ${minLength} required minimum characters`
  }

  // Both min and max length given
  if (!!minLength && !!maxLength && (fieldLength >= maxLength || fieldLength <= minLength)) {
    textFieldProps.inputProps.minLength = minLength
    lengthHelperText = `${fieldLength} of ${minLength}-${maxLength} required characters`
  }

  // Figure out helper text
  let helperText = fieldState.error?.message || props.helperText
  if (lengthHelperText !== '') {
    helperText = `${lengthHelperText} ${helperText ? ' - ' + helperText : ''}`
  }

  const { ...fieldProps } = field
  return (
    <TextField
      inputRef={ref}
      ref={ref}
      {...textFieldProps}
      {...fieldProps}
      error={!!fieldState.error}
      helperText={helperText}
      onChange={handleInputChange}
      onBlur={() => trigger(name)}
    />
  )
})
