import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import UqUserLookupInput from 'src/components/Form/Inputs/UqUserLookupInput/UqUserLookupInput'
import GRADUATE_SCHOOL_UPDATES_CHAIR from 'src/lib/graphql/mutations/GraduateSchoolUpdatesChair'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

export type ThesisSubmissionChairFormData = {
  displayName: string
  eduPersonPrincipalName: string
}

const createDefaultValuesForChair = {
  eduPersonPrincipalName: '',
}

const validationSchemaForChair = yup.object().shape({
  eduPersonPrincipalName: yup.string().required('You must provide a Chair.'),
})

type Props = {
  onOpen: boolean
  onClose: () => void
  submissionId: number
  currentChairId: number | null
  currentChairEppn: string | null
}

/**
 * Update Chair dialog
 */
const UpdateChairDialog = ({ onOpen, onClose, submissionId, currentChairId, currentChairEppn }: Props) => {
  const showSnackbar = useSnackbar()

  const [isPending, setIsPending] = useState<boolean>(false)
  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false)

  const [updateChairMutation] = useMutation(GRADUATE_SCHOOL_UPDATES_CHAIR, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm<ThesisSubmissionChairFormData>({
    defaultValues: createDefaultValuesForChair,
    resolver: yupResolver(validationSchemaForChair), //@TODO fix this error
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const hasValidationError = !formMethods.formState.isValid

  const onSubmit = async (data: ThesisSubmissionChairFormData) => {
    setIsPending(true)

    try {
      await updateChairMutation({
        variables: {
          submissionId: submissionId,
          currentChairId,
          newChairEppn: data.eduPersonPrincipalName,
        },
      })
      showSnackbar('You have successfully updated the Chair of Examiners', 'success')
      onClose()
    } catch (error) {
      const errorMessage =
        error?.graphQLErrors?.[0]?.message || 'Could not update committee Chair. Please try again later.'

      Sentry.captureException(error)

      showSnackbar(errorMessage, 'error')

      onClose()
    }
    formMethods.reset()
    onClose()
    setIsPending(false)
  }

  return (
    <Dialog
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          formMethods.reset()
          onClose()
        }
      }}
      open={onOpen}
    >
      <DialogTitle id="dialogTitle" variant="h2" component="h2" color="primary">
        Update the Chair of Examiners
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit} onReset={() => formMethods.reset()}>
        <DialogContent id="dialogContent">
          <Typography variant="body2" component="div">
            Begin typing the user's name or email address, and select the user from the list.
            <br />
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid xs={12}>
                <UqUserLookupInput
                  name="eduPersonPrincipalName"
                  setOpenAutoComplete={setOpenAutoComplete}
                  disabled={isPending}
                  openAutoComplete={openAutoComplete}
                  label="Search for a Chair of Examiners"
                  excludeUserEppn={currentChairEppn}
                />
              </Grid>
            </Grid>
            <br />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} direction="row" sx={{ pr: 2, pb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onClose()
                formMethods.reset()
              }}
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={openAutoComplete || isPending || hasValidationError}
            >
              Update
            </Button>
          </Stack>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default UpdateChairDialog
