import { useState } from 'react'

import { DeanRequestsReviseAndResubmit, DeanRequestsReviseAndResubmitVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import DEAN_REQUESTS_REVISE_AND_RESUBMIT from 'src/lib/graphql/mutations/DeanRequestsReviseAndResubmit'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ReviseResubmitButtonDialog from './ReviseResubmitButtonDialog'

type Props = {
  iterationId: number
  disabled?: boolean
  onReload: () => unknown
  onClose: () => void
  fullWidth?: boolean
}

const ReviseResubmitButton = ({ iterationId, onReload, disabled, fullWidth, onClose }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [requestReviseAndResubmit] = useMutation<DeanRequestsReviseAndResubmit, DeanRequestsReviseAndResubmitVariables>(
    DEAN_REQUESTS_REVISE_AND_RESUBMIT
  )
  const showSnackbar = useSnackbar()

  const onConfirmRequestReviseAndResubmit = async (outcomeMessage: string) => {
    setSaving(true)
    try {
      const result = await requestReviseAndResubmit({ variables: { iterationId, outcomeMessage, hasOral: true } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully requested a revise and resubmit", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not request a revise and resubmit from the candidate. Please try again later.', 'error')
      setSaving(false)
    }

    onClose()
  }

  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color="warning"
        onClick={() => setOpen(true)}
        disabled={disabled}
        sx={{ mb: 1 }}
      >
        Request revise and resubmit
      </Button>
      <ReviseResubmitButtonDialog
        open={open}
        saving={saving}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirmRequestReviseAndResubmit}
      />
    </>
  )
}

export default ReviseResubmitButton
