const EXAMINER_SUBMITS_FEEDBACK_RNR = gql`
  mutation ExaminerSubmitsFeedbackRnR(
    $id: Int!
    $submissionId: Int!
    $iterationId: Int!
    $input: ExaminationSummaryRnRInput!
  ) {
    examinerSubmitsFeedbackRnR(id: $id, submissionId: $submissionId, iterationId: $iterationId, input: $input)
  }
`

export default EXAMINER_SUBMITS_FEEDBACK_RNR
