import { useState } from 'react'

import { useQuery } from '@apollo/client'
import { Alert } from '@mui/material'
import { GetOrganisationsQuery } from 'types/graphql'

import { useWatch } from '@redwoodjs/forms'

import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'
import QUERY from 'src/lib/graphql/queries/GetOrganisationsQuery'
import organisationGuidance from 'src/lib/guidance/organisationGuidance'
import useDebounce from 'src/lib/hooks/useDebounce'

import { CAutocomplete } from '../Base/Autocomplete'

import renderOption from './renderOption'

type Props = {
  helpDisabled?: boolean
  name?: string
  label?: string
  readOnly?: boolean
  inactiveMessageDisabled?: boolean
  required?: boolean
  guidance?: {
    title: string
    text: React.JSX.Element
  }
}

const Organisation = ({
  helpDisabled,
  readOnly,
  inactiveMessageDisabled,
  label = 'UQ organisational unit',
  name = 'organisation',
  required,
  guidance,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 200)
  const value = useWatch({ name })

  const { loading, data } = useQuery<GetOrganisationsQuery>(QUERY, {
    variables: { searchString: debouncedInputValue },
    fetchPolicy: 'cache-first',
    skip: debouncedInputValue.length < 3,
  })

  // Check if selected organisation is inactive
  const selectedOrganisation = value && data && data.organisations.find((o) => o.id === value.id)
  const hasInactiveOrganisation = !!selectedOrganisation && !!selectedOrganisation.id && !selectedOrganisation.isActive

  const options = data ? [...data.organisations] : [value]
  if (!!value && !options.find((o) => o.id === value.id)) options.push(value)

  const filteredOptions = options.filter((o) => o !== null)

  const guidanceText = guidance || organisationGuidance

  return (
    <>
      <HelpWrapper
        disabled={helpDisabled || readOnly}
        helpProps={{ ...guidanceText, buttonSx: { mt: 2 } }}
        sx={{ p: 0 }}
      >
        <CAutocomplete
          name={name}
          textFieldProps={{
            label,
            placeholder: 'Begin typing a UQ organisational unit to search',
            required: required === undefined ? false : required,
          }}
          readOnly={readOnly}
          getOptionLabel={(option) => (option && (typeof option === 'string' ? option : option.name)) || ''}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onInputChange={(_e, value: string) => {
            if (selectedOrganisation && value === selectedOrganisation.name) return
            if (value !== inputValue) {
              setInputValue(value)
            }
          }}
          renderOption={renderOption}
          options={filteredOptions}
          loading={loading}
        />
      </HelpWrapper>
      {!inactiveMessageDisabled && hasInactiveOrganisation && (
        <Alert severity="warning">
          The Organisational Unit associated with this record can no longer be associated with a UQRDM project. Please
          consider updating your record.
        </Alert>
      )}
    </>
  )
}

export default Organisation
