import { useState } from 'react'

import { ChairRequestsAdditionalCorrections, ChairRequestsAdditionalCorrectionsVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import CHAIR_REQUESTS_ADDITIONAL_CORRECTIONS from 'src/lib/graphql/mutations/ChairRequestsAdditionalCorrections'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ChairRequestsAdditionalCorrectionsDialog from './ChairRequestsAdditionalCorrectionsDialog'

type Props = {
  iterationId: number
  onReload: () => unknown
}

/**
 * Chair requests additional corrections from candidate button
 */
const ChairRequestsAdditionalCorrectionsButton = ({ iterationId, onReload }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [chairRequestsAdditionalCorrections] = useMutation<
    ChairRequestsAdditionalCorrections,
    ChairRequestsAdditionalCorrectionsVariables
  >(CHAIR_REQUESTS_ADDITIONAL_CORRECTIONS)
  const showSnackbar = useSnackbar()

  const onConfirmRequestAdditionalCorrections = async (outcomeMessage: string) => {
    setSaving(true)
    try {
      const result = await chairRequestsAdditionalCorrections({ variables: { iterationId, outcomeMessage } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully requested additional corrections.", 'success')
      onReload()
      setOpen(false)
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not request additional corrections from the candidate. Please try again later.', 'error')
      setSaving(false)
    }
  }

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)} fullWidth>
        Request additional corrections from candidate
      </Button>
      <ChairRequestsAdditionalCorrectionsDialog
        open={open}
        saving={saving}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirmRequestAdditionalCorrections}
      />
    </>
  )
}

export default ChairRequestsAdditionalCorrectionsButton
