import { Typography } from '@mui/material'
import {
  GetThesisSubmissionExaminerInvitationQuery,
  GetThesisSubmissionExaminerInvitationQueryVariables,
} from 'types/graphql'

import { Metadata, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import GET_THESIS_SUBMISSION_EXAMINER_INVITATION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionExaminerInvitationQuery'

import TokenAcceptance from './components/TokenAcceptance'
import TokenError from './components/TokenError'

type Props = {
  joinToken: string
}

const ThesisExaminerJoinPage = ({ joinToken }: Props) => {
  const { currentUser } = useAuth()
  const { data, loading } = useQuery<
    GetThesisSubmissionExaminerInvitationQuery,
    GetThesisSubmissionExaminerInvitationQueryVariables
  >(GET_THESIS_SUBMISSION_EXAMINER_INVITATION_QUERY, { variables: { joinToken } })

  return (
    <>
      <Metadata title="UQRDM - Join Thesis" description="UQRDM - Join Thesis" />
      <ContentCard>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Hi {currentUser.displayName}!
        </Typography>
        {loading ? (
          <InlineLoader message="Please wait whilst we load your details..." />
        ) : (
          <div>
            {data.thesisSubmissionExaminerInvitation.statusCode === 'INVITATION_OK' ? (
              <TokenAcceptance
                thesisSubmissionExaminerInvitation={data.thesisSubmissionExaminerInvitation}
                joinToken={joinToken}
              />
            ) : (
              <TokenError statusCode={data.thesisSubmissionExaminerInvitation.statusCode} />
            )}
          </div>
        )}
      </ContentCard>
    </>
  )
}
export default ThesisExaminerJoinPage
