import { IterationStateDetails } from '@common/thesis/iterationState'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import thesisTypes from '@common/thesis/thesisTypes'
import {
  FinalThesisSubmissionInput,
  MyRecordsQuery,
  Organisation,
  ThesisEmbargoType,
  ThesisSubmissionInput,
  ThesisType,
} from 'types/graphql'
import * as yup from 'yup'

import { UploadedFile } from 'src/components/Form/Inputs'
import { RedwoodUser } from 'src/lib/auth'

import { ThesisSubmission } from '../../ThesisSubmissionDetailsPage'

export type ThesisSubmissionFormData = {
  certify: boolean
  title: string
  abstract: string
  record: '' | Pick<MyRecordsQuery['userRecordsSummary'][0], 'id' | 'uuid' | 'name' | 'collaborators'>
  principalAdvisor: { id: number; displayName: string } | ''
  organisation: '' | { id: number; name: string }
  type: ThesisType
  additionalSupervisors: { id: number; displayName: string }[]
  thesisDocument: UploadedFile
  ithenticateFile: UploadedFile
  correctionsFile: UploadedFile | null
  supplementaryFiles: UploadedFile[]
  examinerReviewFiles: UploadedFile[]
  examinerAnnotatedThesisFiles: UploadedFile[]
  chairReviewFile: UploadedFile | null
  isUqIntellectualProperty: '1' | '0' | true
  embargoType: ThesisEmbargoType | ''
  keywords: string[]
  totalPages: string | ''
  forCodes: { id: number; name: string }[]
}

export const createDefaultValues = (
  submission: ThesisSubmission,
  currentUser: RedwoodUser
): ThesisSubmissionFormData => {
  const iteration = submission.currentIteration

  return {
    certify: false,
    title: iteration ? iteration.title : '',
    abstract: iteration ? iteration.abstract : '',
    record: iteration?.record ? iteration.record : '',
    principalAdvisor: iteration
      ? { id: iteration.principalAdvisor.id, displayName: iteration.principalAdvisor.displayName }
      : { id: submission.principalAdvisor.id, displayName: submission.principalAdvisor.displayName },
    type: submission.isDowngradedToMPhil ? 'MPHIL_IN_LIEU_OF_PHD' : iteration ? iteration.type : 'PHD',
    additionalSupervisors: iteration
      ? iteration.additionalSupervisors.map((s) => ({ id: s.id, displayName: s.displayName }))
      : [],
    organisation: iteration ? iteration.organisation : currentUser.organisation ? currentUser.organisation : '',
    thesisDocument: iteration ? iteration.files.find((f) => f.type === ThesisFileType.THESIS_FILE) || null : null,
    ithenticateFile: iteration ? iteration.files.find((f) => f.type === ThesisFileType.ITHENTICATE_FILE) || null : null,
    supplementaryFiles: iteration ? iteration.files.filter((f) => f.type === ThesisFileType.SUPPLEMENTARY_FILE) : [],
    correctionsFile: iteration ? iteration.files.find((f) => f.type === ThesisFileType.CORRECTIONS_FILE) || null : null,
    keywords: iteration ? iteration.keywords : [],
    forCodes: iteration ? iteration.forCodes : [],
    isUqIntellectualProperty:
      iteration?.isUqIntellectualProperty !== null ? (iteration?.isUqIntellectualProperty === false ? '0' : '1') : null,
    embargoType: iteration && iteration.embargoType ? iteration.embargoType : '',
    totalPages: iteration && iteration.totalPages !== null ? `${iteration.totalPages}` : '',
  }
}

// @TODO: Add validation for file names
const baseSchema = {
  title: yup
    .string()
    .required('Please add your thesis title')
    .max(1500, 'Your thesis title should be no more than 1,500 characters'),
  type: yup
    .string()
    .required()
    .oneOf(thesisTypes.map((t) => t.code)),
  record: yup
    .mixed()
    .test(
      'valid_record',
      'Please select a record with your Principal Advisor as the lead investigator',
      (value: string | MyRecordsQuery['userRecordsSummary'][0]) => {
        if (typeof value !== 'object') return false

        const lead = value.collaborators.find((c) => c.isLead)
        const owner = value.collaborators.find((c) => c.isOwner)

        if (!lead || !owner || !value.id || lead.user.id === owner.user.id) return false
        return true
      }
    ),
  organisation: yup
    .object({ id: yup.number() })
    .typeError('Please select an organisation')
    .required('Please select an organisation'),
  thesisDocument: yup
    .object({ key: yup.string(), filename: yup.string() })
    .test('has-file', 'Please upload your thesis document', (value) => {
      return !!(value && value.key && value.filename)
    })
    .required('Please upload your thesis document'),
  supplementaryFiles: yup
    .array()
    .max(10, 'You can only save up to 10 additional files')
    .of(yup.object({ key: yup.string(), filename: yup.string() })),
  certify: yup.boolean().isTrue('You must agree to the statements above.'),
}

export const createValidationSchema = (iterationState: IterationStateDetails) => {
  return yup.object({
    ...baseSchema,
    ithenticateFile: yup
      .object({ key: yup.string(), filename: yup.string() })
      .test('has-file', 'Please upload your thesis iThenticate Similarity Report', (value) => {
        return !!(value && value.key && value.filename)
      })
      .required('Please upload your thesis iThenticate Similarity Report'),
    ...(iterationState.thesisState === 'REQUEST_CHANGES'
      ? {
          correctionsFile: yup
            .object({ key: yup.string(), filename: yup.string() })
            .test('has-file', 'Please upload your corrections file', (value) => {
              return !!(value && value.key && value.filename)
            })
            .required('Please upload your corrections file'),
        }
      : {}),
  })
}

export const finalValidationSchema = yup.object({
  ...baseSchema,
  abstract: yup
    .string()
    .required('Please enter your abstract')
    .max(10000, 'Please reduce the abstract size')
    .test('min-characters', 'Your abstract must be at least 50 characters long', (value: string) => {
      return value.trim().length >= 50
    }),
  keywords: yup
    .array()
    .of(yup.string().max(255, 'Keywords cannot be longer than 255 characters. Please add multiple words separately.'))
    .min(1, 'Please type at least 1 keyword and click enter to add it')
    .max(10, 'You can enter at most 10 keywords'),
  forCodes: yup.array().min(1, 'Please enter at least 1 field of research code'),
  isUqIntellectualProperty: yup.boolean().required('Please select an option'),
  embargoType: yup.string().required('Please select the embargo type'),
  totalPages: yup
    .string()
    .required('Please enter a valid number of pages')
    .matches(/^\d+$/, 'Please enter a valid number of pages'),
})

const createSharedSubmissionInput = (formData: ThesisSubmissionFormData) => {
  const recordLead = (formData.record as MyRecordsQuery['userRecordsSummary'][0]).collaborators.find((c) => c.isLead)

  return {
    title: formData.title,
    type: formData.type,
    recordId: (formData.record as MyRecordsQuery['userRecordsSummary'][0]).id,
    organisationId: (formData.organisation as Organisation).id,
    principalAdvisorId: formData.principalAdvisor !== '' ? formData.principalAdvisor.id : recordLead.id,
    additionalSupervisorIds: formData.additionalSupervisors.map((s) => s.id),
    files: [
      {
        id: typeof formData.thesisDocument.id === 'number' ? formData.thesisDocument.id : undefined,
        key: formData.thesisDocument.key,
        filename: formData.thesisDocument.filename,
        fileType: ThesisFileType.THESIS_FILE,
      },
      ...formData.supplementaryFiles.map((f) => ({
        id: typeof f.id === 'number' ? f.id : undefined,
        key: f.key,
        filename: f.filename,
        fileType: ThesisFileType.SUPPLEMENTARY_FILE,
      })),
    ],
  }
}

export const createThesisSubmissionInput = (formData: ThesisSubmissionFormData): ThesisSubmissionInput => {
  const sharedData = createSharedSubmissionInput(formData)

  const correctionsFile = formData.correctionsFile
    ? [
        {
          id: typeof formData.correctionsFile.id === 'number' ? formData.correctionsFile.id : undefined,
          key: formData.correctionsFile.key,
          filename: formData.correctionsFile.filename,
          fileType: ThesisFileType.CORRECTIONS_FILE,
        },
      ]
    : []

  return {
    ...sharedData,
    files: [
      ...sharedData.files,
      {
        id: typeof formData.ithenticateFile.id === 'number' ? formData.ithenticateFile.id : undefined,
        key: formData.ithenticateFile.key,
        filename: formData.ithenticateFile.filename,
        fileType: 'ITHENTICATE_FILE',
      },
      ...correctionsFile,
    ],
  }
}

export const createFinalThesisSubmissionInput = (formData: ThesisSubmissionFormData): FinalThesisSubmissionInput => {
  const sharedData = createSharedSubmissionInput(formData)

  return {
    ...sharedData,
    abstract: formData.abstract,
    isUqIntellectualProperty: formData.isUqIntellectualProperty === '1' || formData.isUqIntellectualProperty === true,
    embargoType: formData.embargoType === '' ? undefined : (formData.embargoType as ThesisEmbargoType),
    keywords: formData.keywords,
    totalPages: parseInt(`${formData.totalPages}`),
    fieldOfResearchCodeIds: formData.forCodes.map((c) => c.id),
  }
}
