import ExternalLink from 'src/components/ExternalLink/ExternalLink'

export default {
  title: 'Thesis Examiner Confidentiality Agreement',
  text: (
    <div>
      <div>
        <p>
          1. In consideration for the University disclosing the Confidential Information to me the Examiner, I
          acknowledge, warrant and covenant that during the continuation of this Agreement and after termination of this
          Agreement I shall: (a) keep secret and confidential the Confidential Information contained in the Thesis; (b)
          not use, copy, reproduce, publish, circulate, communicate, observe or disclose (or authorise or permit anyone
          else to use, copy, reproduce, publish, circulate, communicate, observe or disclose) any of the Confidential
          Information for any purpose other than for the purpose of examining the Thesis; (c) not use the Confidential
          Information disclosed in carrying on any business activity or to reproduce, redesign, reverse engineer or
          manufacture any equipment or products contained in or related to the Thesis.
        </p>
        <p>
          2. I agree to preserve the confidential status of any and all Confidential Information received until the
          Confidential Information becomes public knowledge through no breach of this agreement by me.
        </p>
        <p>
          3. I agree to keep the Thesis in a safe and secure place during the examination process and whilst in my
          possession and shall destroy or return all copies to the University immediately upon completion of the
          examination process.
        </p>
        <p>
          4. I agree to:- a. comply with all reasonable requests from the University with respect to maintaining the
          confidentiality of the Thesis; b. to promptly notify the University if I learn of an unauthorised release of
          the Confidential Information.
        </p>
        <p>
          5. I acknowledge and agree that I obtain no proprietary rights of any kind to the Confidential Information as
          a result of disclosure to me under this Agreement.
        </p>
        <p>
          6. This Agreement imposes no obligation on me with respect to any portion of the Confidential Information
          contained in the Thesis which:- a. is lawfully obtained by me, from a third party under no obligation of
          confidentiality; b. is or becomes generally known or publicly available other than by unauthorised disclosure;
        </p>
        <p>
          7. In the event disclosure of the Confidential Information is required of me under provisions of any judicial
          or administrative proceeding, I will a. promptly notify the University of the obligation to make such
          disclosure, and b. assert the confidentiality of such Confidential Information.
        </p>
        <p>
          8. Confidential Information in any form shall remain confidential unless specifically agreed in writing by the
          University that the information is not confidential.
        </p>
        <p>
          9. In the event of a breach or threatened breach or intended breach of this Agreement by me, in addition to
          any other legal remedies, the University shall be entitled to seek interim and final injunctive relief.
        </p>
        <p>
          10. This Agreement shall be governed by the laws of the State of Queensland and I submit to the jurisdiction
          of the courts of that State and the subsequent courts of appeal.
        </p>
        <p>
          11. The rights and obligations of the parties under this Agreement may not be sold, assigned or otherwise
          transferred.
        </p>
        <p>12. This Agreement does not create any agency or partnership in relationship.</p>
        <p>
          13. All additions or modifications to this Agreement must be made in writing and must be signed by both me and
          the University.
        </p>
        <p>
          14. Transmission by facsimile, email or other form of electronic transmission of an executed version of this
          Agreement shall be deemed to constitute due and sufficient delivery of such executed Agreement.
        </p>
        For further enquiries:
        <br />
        Telephone: +61 7 3346 0531
        <br />
        Facsimile: +61 7 3346 0512
        <br />
        Email:&nbsp;
        <ExternalLink href="mailto:GraduateSchool@uq.edu.au">GraduateSchool@uq.edu.au</ExternalLink>
      </div>
    </div>
  ),
}
