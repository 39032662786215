import { THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE } from '@common/thesis/thesisExaminers/examinerNominationData'
import { Circle, Error } from '@mui/icons-material'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ThesisSubmission, ThesisSubmissionExaminer } from 'types/graphql'

interface Props {
  examiner: Pick<
    ThesisSubmissionExaminer,
    | 'status'
    | 'fullName'
    | 'mail'
    | 'position'
    | 'institution'
    | 'rationaleForNomination'
    | 'candidateCOIStatements'
    | 'candidateCOIDetails'
    | 'advisorCOIStatements'
    | 'advisorCOIDetails'
    | 'candidateCOIStatements'
    | 'candidateCOIDetails'
  > & {
    user?: {
      displayName: string
      mail: string
    }
  }
  submission: Pick<ThesisSubmission, 'id'> & {
    principalAdvisor?: {
      displayName: string
      mail: string
    }
    creator?: {
      displayName: string
      mail: string
    }
  }
}
const CandidateConflictOfInterestDisclosureExaminerDetails = ({ examiner, submission }: Props) => {
  const isFinalGSApproval = examiner?.status === 'GRADUATE_SCHOOL_FINAL_REVIEW'
  const styles = {
    table: {
      width: '100%',
      mb: 2,
    },
    tableCell: {
      verticalAlign: 'top !important',
      width: isFinalGSApproval ? '33%' : '50%',
      borderBottomWidth: 0,
      position: 'relative',
    },
    tableBorderBottom: {
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
    tableShadedCell: {
      // bgcolor: 'rgba(0,0,0,0.02)',
    },
    tableQuestionCell: {
      display: 'inline',
      position: 'absolute',
      fontWeight: 'bold',
      overflowX: 'visible',
      textOverflow: 'visible',
      whiteSpace: 'nowrap',
    },
  }
  return (
    <Grid container spacing={1} sx={{ width: '100%' }}>
      <Grid xs={12}>
        <Table
          sx={styles.table}
          size="small"
          aria-label="Conflict of interest disclosure table. Please note this table is non-standard. Each question is 2 rows, wth the first row being the question spanning the columns, and the second row being the answers."
        >
          {/* Header */}
          <TableHead></TableHead>
          {/* Body */}
          <TableBody>
            {/* Row 1 */}
            <TableRow aria-describedby="question1">
              {/* Question 1 */}
              <TableCell sx={styles.tableCell} id="question1">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      {THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('publishedWithCandidate')}
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* 1 */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.candidateCOIStatements?.['publishedWithCandidate'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.candidateCOIStatements?.['publishedWithCandidate'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            {/* Row 2 */}
            <TableRow aria-describedby="question2">
              {/* Question 2 */}
              <TableCell sx={styles.tableCell} id="question2">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      {THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('grantWithAdvisor')}
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.candidateCOIStatements?.['grantWithAdvisor'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.candidateCOIStatements?.['grantWithAdvisor'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            {/* Row 3 */}
            <TableRow aria-describedby="question3">
              {/* Question 3 */}
              <TableCell sx={styles.tableCell} id="question3">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      {THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('personalOrProfessionalRelationship')}
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.candidateCOIStatements?.['personalOrProfessionalRelationship'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.candidateCOIStatements?.['personalOrProfessionalRelationship'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            {/* Row 4 */}
            <TableRow aria-describedby="question4">
              {/* Question 4 */}
              <TableCell sx={styles.tableCell} id="question4">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      {THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('consultedOnThesis')}
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* Examiner Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.candidateCOIStatements?.['consultedOnThesis'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.candidateCOIStatements?.['consultedOnThesis'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            {/* Row 5 */}
            <TableRow aria-describedby="question5">
              {/* Question 5 */}
              <TableCell sx={styles.tableCell} id="question5">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      {THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('otherCOI')}
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* Examiner Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.candidateCOIStatements?.['otherCOI'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>{examiner?.candidateCOIStatements?.['otherCOI'] === true ? 'Yes' : 'No'}</Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            {/* Row 6 */}
            {!!examiner.candidateCOIDetails && (
              <>
                <TableRow aria-describedby="question5">
                  {/* Question 5 */}
                  <TableCell sx={styles.tableCell} id="question5">
                    <Grid container spacing={2}>
                      <Grid>
                        &nbsp;
                        <Box sx={{ ...styles.tableQuestionCell, whiteSpace: 'wrap' }}>Details of the conflict</Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
                </TableRow>
                <TableRow sx={styles.tableBorderBottom}>
                  {/* Examiner Answer */}
                  <TableCell sx={styles.tableCell}>
                    <Typography fontStyle={'italic'}>
                      {`${examiner?.candidateCOIDetails ? '"' + examiner?.candidateCOIDetails + '"' : ''}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default CandidateConflictOfInterestDisclosureExaminerDetails
