import { useApolloClient } from '@apollo/client'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import { CheckCircle } from '@mui/icons-material'
import { Card, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisFileDownloadUrlQuery, GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import StyledHourglassIcon from 'src/components/Icons/HourGlassIcon'
import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import {
  isAssignedThesisCandidate,
  isAssignedThesisChair,
  isAssignedThesisExaminer,
  isAssignedThesisPrincipalAdvisor,
} from 'src/lib/userAccessChecker'

import ChairFeedbackSummaryButton from '../../ChairActions/ChairFeedbackSummaryButton/ChairFeedbackSummaryButton'
import ThesisFilesViewerSection from '../../ThesisIterationFormViewer/components/ThesisFilesViewerSection'

type Props = {
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair'] | null
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

/**
 * Thesis chair report summary card user accordion
 */
const UserAccordion = ({ chair, submission, onReload }: Props) => {
  const { hasRole, currentUser } = useAuth()
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()

  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const chairAccess = isAssignedThesisChair(currentUser, chair?.user.id)
  const advisorAccess = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const candidateAccess = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const gsAccess = hasRole(['grad-school-user'])
  // @TODO need to rethink examiner access logic here and refactor
  const examinerAccess1 = isAssignedThesisExaminer(
    currentUser,
    submission?.thesisSubmissionExaminers[0]?.user?.id,
    submission?.thesisSubmissionExaminers[0]?.status
  )
  const examinerAccess2 = isAssignedThesisExaminer(
    currentUser,
    submission?.thesisSubmissionExaminers[1]?.user?.id,
    submission?.thesisSubmissionExaminers[1]?.status
  )

  const hasAccess =
    chairAccess ||
    ((advisorAccess || candidateAccess || examinerAccess1 || examinerAccess2 || gsAccess) &&
      chair?.summaryReportCompleted &&
      submission.showChairFeedback)

  // Thesis status NOT in AWAITING_CHAIR_EXAMINATION_OUTCOME AND summaryReportCompleted is false - Awaiting examiner feedback
  // Thesis status AWAITING_CHAIR_EXAMINATION_OUTCOME AND summaryReportCompleted is false - Awaiting Chair summary
  // Thesis status NOT in AWAITING_CHAIR_EXAMINATION_OUTCOME AND summaryReportCompleted is true - Summary completed
  let status
  if (!chair?.summaryReportCompleted) {
    if (submission?.currentIteration?.currentStatus.status == 'AWAITING_CHAIR_EXAMINATION_OUTCOME') {
      status = 'Awaiting Chair summary'
    } else {
      status = 'Awaiting oral examination'
    }
  } else {
    status = 'Summary completed'
  }

  const hasAccessToForm =
    chairAccess ||
    (gsAccess && submission.chair?.summaryReportCompleted) ||
    ((advisorAccess || candidateAccess || examinerAccess1 || examinerAccess2) &&
      submission.chair?.summaryReportCompleted &&
      submission.showChairFeedback)

  const hasFile =
    submission.currentIteration.files.filter(
      (f) => f.id === chair?.fileId && f.type === ThesisFileType.CHAIR_REVIEW_FILE && f.isActive
    ).length > 0
  const chairHasSubmittedFeedback = !!submission.chair?.summaryReportCompleted
  return (
    <>
      <Card sx={{ background: '#eeeeee' }}>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid alignItems="center" display="flex" xs="auto">
            {chair?.summaryReportCompleted ? <CheckCircle sx={{ color: 'success.main' }} /> : <StyledHourglassIcon />}
          </Grid>
          <Grid alignContent={'center'}>
            <Grid container spacing={1}>
              <Grid xs={'auto'}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} alignSelf={'center'}>
                  {chair ? chair.user.displayName : 'To be confirmed'}
                </Typography>
              </Grid>
              <Grid xs={'auto'}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid xs={'auto'} alignSelf={'center'}>
                <Typography variant="body2" fontSize={'small'}>
                  {status.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {hasAccessToForm && !chairHasSubmittedFeedback && (
            <>
              <Grid xs />
              <Grid xs="auto">
                <ChairFeedbackSummaryButton
                  chair={submission?.chair}
                  submission={submission}
                  onReload={onReload}
                  hasAccess={hasAccess}
                  // isForThesisNotification={!submission.chair.summaryReportCompleted}
                  isForThesisNotification={false}
                />
              </Grid>
            </>
          )}
          {hasAccess && hasFile && (
            <Grid xs={12}>
              <ThesisFilesViewerSection
                documentLabel=""
                fileType={ThesisFileType.CHAIR_REVIEW_FILE}
                files={submission.currentIteration.files.filter(
                  (f) => f.id === chair?.fileId && f.type === ThesisFileType.CHAIR_REVIEW_FILE && f.isActive
                )}
                onDownload={onDownload}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  )
}

export default UserAccordion
