import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Thesis submission correction - Principal Advisor review corrections state.
 *
 * Examiner view.
 */
const PrincipalAdvisorReviewsChanges = ({ submission, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).examinerMessage
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, 'REQUEST_CHANGES')

  return <ThesisSubmissionMessage {...cm} {...stepperInfo} />
}

export default PrincipalAdvisorReviewsChanges
