export const THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER = (value: string) => {
  switch (value) {
    case 'publishedWithCandidate':
      return "I have published with the candidate or a member of the candidate's advisory team in the last 5 years."
    case 'grantWithAdvisor':
      return 'I hold a current grant or have applied for a grant with the candidate or a member of the candidate’s advisory team. '
    case 'personalOrProfessionalRelationship':
      return 'I have or have had a close personal or professional relationship with the candidate or a member of the candidate’s advisory team. '
    case 'consultedOnThesis':
      return "I have consulted with the candidate or a member of the candidate's advisory team regarding the thesis."
    case 'otherCOI':
      return 'Other conflict not listed above.'
    default:
      return ''
  }
}

export const THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE = (value: string) => {
  switch (value) {
    case 'publishedWithCandidate':
      return 'I have co-authored an academic publication with the nominated examiner in the last 5 years.'
    case 'grantWithAdvisor':
      return 'I hold a current grant or have previously applied for a grant with the nominated examiner.'
    case 'personalOrProfessionalRelationship':
      return 'I have or have had a close personal or professional relationship with the nominated examiner.'
    case 'consultedOnThesis':
      return 'I have consulted with the nominated examiner regarding my thesis.'
    case 'otherCOI':
      return 'Other conflict not listed above.'
    default:
      return ''
  }
}
