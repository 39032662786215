import MUIAutocomplete, { AutocompleteProps as MUIAutocompleteProps } from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'

import { useController } from '@redwoodjs/forms'

import { TextField, TextFieldProps } from './TextField'

const defaultGetOptionLabel = (option) => option.label

export type AutocompleteProps<T> = Omit<
  MUIAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
  'renderInput'
> & {
  textFieldProps?: TextFieldProps
  getOptionStyle?: (option: T) => React.CSSProperties | undefined
  readonly?: boolean
}
export function Autocomplete<T>(props: AutocompleteProps<T>) {
  const { textFieldProps, getOptionStyle, readOnly = false, ...otherProps } = props

  const getOptionLabel = props.getOptionLabel || defaultGetOptionLabel

  return (
    <MUIAutocomplete
      readOnly={readOnly}
      {...otherProps}
      renderInput={(params) => (
        <TextField readOnly={readOnly} ref={textFieldProps.ref} {...params} {...textFieldProps} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={index}
            variant="outlined"
            label={getOptionLabel(option)}
            style={getOptionStyle ? getOptionStyle(option as T) : undefined}
          />
        ))
      }
    />
  )
}

type ControlledAutocompleteProps<T> = AutocompleteProps<T> & {
  name: string
  helperText?: string
}
export function CAutocomplete<T>(props: ControlledAutocompleteProps<T>) {
  const { name, readOnly = false, ...autocompleteProps } = props
  const { field, fieldState } = useController({ name })
  const { ref, ...fieldProps } = field

  const textFieldProps = autocompleteProps.textFieldProps || {}

  if (fieldState.error && Array.isArray(fieldState.error)) {
    textFieldProps.helperText = fieldState.error[0]?.message
  } else if (fieldState.error && typeof fieldState.error === 'object') {
    textFieldProps.helperText = fieldState.error.message
  } else {
    textFieldProps.helperText = undefined
  }

  textFieldProps.error = !!fieldState.error
  textFieldProps.inputRef = ref
  textFieldProps.onBlur = field.onBlur
  textFieldProps.readOnly = readOnly

  const onChange = (_, options) => {
    field.onChange(options)
    field.onBlur()
  }

  return (
    <Autocomplete<T>
      readOnly={readOnly}
      {...autocompleteProps}
      {...fieldProps}
      onChange={onChange}
      textFieldProps={{
        ...textFieldProps,
        helperText: textFieldProps.helperText,
        error: !!fieldState.error,
        inputRef: ref,
        onBlur: field.onBlur,
        readOnly: readOnly,
      }}
    />
  )
}
