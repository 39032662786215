import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'
import { Button } from '@mui/material'

import { routes } from '@redwoodjs/router'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * A submission has been marked as revise and resubmit (with or without oral) by the Dean.
 *
 * Examiner view.
 */
const MarkedAsReviseAndResubmit = ({ submission, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).examinerMessage
  const stepperState = getInitialType(submission.currentIteration.hasOral) // Need to check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      outcomeMessage={submission?.currentIteration?.currentStatus?.outcomeMessage}
      actions={[
        <Button
          key={0}
          variant="contained"
          href={routes.thesisExaminerDetails({ id: submission.resubmittedSubmissionId })}
        >
          View new submission
        </Button>,
      ]}
    />
  )
}

export default MarkedAsReviseAndResubmit
