import { useEffect } from 'react'

import { titles } from '@common/inputFormData'
import { YES_NO_INPUTS } from '@common/thesis/thesisExaminers/examinationFormData'
import { Link, MenuItem, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFormContext } from '@redwoodjs/forms'

import { CCheckbox, CRadioGroup, CSelect, CTextField } from 'src/components/Form/Inputs'
import HelpButton from 'src/components/HelpButton/HelpButton'

/**
 * Graduate School add examiner form.
 */
const GraduateSchoolAddExaminerForm = () => {
  const formMethods = useFormContext()

  const needsPayment = formMethods.watch('requirePayment')

  useEffect(() => {
    if (!needsPayment) {
      formMethods.setValue('bankAccountType', null)
    }
  }, [needsPayment])
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="body2">
          To add a previously approved examiner, enter their details below and submit. The Principal Advisor and will
          not complete the conflict of interest disclosures in UQRDM, nor will the Dean be able to review the examiner.
          Upon submission, the examiner will be invited to join the thesis candidature.
          <br />
          <br />
          This method of examiner invitation is only to be used for examiners submitted and approved before the HDR
          dashboard included thesis examination.
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="h2" component="h2" color="primary">
          Examiner details
        </Typography>
      </Grid>
      <Grid xs={2}>
        <CSelect required label={'Title'} name="title">
          {titles.map((o) => (
            <MenuItem key={o.label} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </CSelect>
      </Grid>
      <Grid xs={10}>
        <CTextField name="fullName" label="Full name" required fullWidth />
      </Grid>
      <Grid xs={6}>
        <CTextField name="contactNumber" label="Contact phone number" required fullWidth />
      </Grid>
      <Grid xs={6}>
        <CTextField name="mail" label="Email address" type="email" required fullWidth autoComplete="off" />
      </Grid>
      <Grid xs={12}>
        <CTextField name="contactAddress" label="Contact postal address" required fullWidth multiline />
      </Grid>
      <Grid xs={6}>
        <CTextField name="position" label="Position" required fullWidth />
      </Grid>
      <Grid xs={6}>
        <CTextField name="institution" label="Institution" required fullWidth />
      </Grid>
      <Grid xs={'auto'}>
        <CRadioGroup
          label="Do you require payment for your examination?"
          name="requirePayment"
          required
          aria-label="Do you require payment for your examination?"
          options={YES_NO_INPUTS}
        />
      </Grid>
      <Grid xs>
        <HelpButton
          buttonSx={{ mt: -1, ml: -2 }}
          isInfo
          title="Payment of examiners"
          text={
            <div>
              We’re grateful for the significant contribution you make to the quality of our research and offer the
              payment of an honorarium.
              <br />
              <br />
              Honorarium amounts are recommended by{' '}
              <Link
                target="_blank"
                href="https://www.universitiesaustralia.edu.au/policy-submissions/research-innovations/fees-for-external-examiners/#.XA8JE7EzZhG"
              >
                Universities Australia
              </Link>
              . You’ll be paid into the bank account you nominate when accepting our invitation.
            </div>
          }
        />
      </Grid>
      {needsPayment && (
        <Grid xs={12}>
          <CRadioGroup
            label="Please indicate your bank account type"
            name="bankAccountType"
            required
            aria-label="Please indicate your bank account type"
            options={[
              { label: 'Australian Bank Account', value: 'AUS' },
              { label: 'International Bank Account', value: 'INTERNATIONAL' },
            ]}
          />
        </Grid>
      )}

      <Grid xs={12}>
        <CCheckbox
          color={'secondary'}
          name="termsAndConditions"
          label="I confirm that the Principal Advisor, candidate, and examiner have submitted conflict of interest disclosures, which have been reviewed and approved by the Graduate School, and that any confidentiality agreements have been made."
        />
      </Grid>
    </Grid>
  )
}

export default GraduateSchoolAddExaminerForm
