import { STATUSES_BEFORE_SET_ORAL_EXAM_DATE } from '@common/thesis/getThesisStatuses'
import { Alert, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'
import { toTimezone } from 'src/lib/toTimezone'
import {
  isAssignedThesisCandidate,
  isAssignedThesisChair,
  isAssignedThesisExaminer,
  isAssignedThesisPrincipalAdvisor,
} from 'src/lib/userAccessChecker'

import ChairFeedbackSummaryButton from '../../ChairActions/ChairFeedbackSummaryButton/ChairFeedbackSummaryButton'

import UserAccordion from './UserAccordion'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

/**
 * Thesis chair summary report form card
 */
const ChairSummaryReportCard = ({ submission, onReload }: Props) => {
  const { currentUser, hasRole } = useAuth()

  const hasChairRole = hasRole('user-thesis-chair')

  // Chair msg logic
  const thesisStatusIsBeforeAwaitOralExam = STATUSES_BEFORE_SET_ORAL_EXAM_DATE.has(
    submission.currentIteration?.currentStatus.status
  )
  const thesisStatusIsAwaitOralExam = submission.currentIteration?.currentStatus.status === 'AWAITING_ORAL_EXAMINATION'
  const thesisStatusIsChairReviewExam =
    submission.currentIteration?.currentStatus.status === 'AWAITING_CHAIR_EXAMINATION_OUTCOME'

  const chairAccess = isAssignedThesisChair(currentUser, submission.chair?.user.id)
  const advisorAccess = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const candidateAccess = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const gsAccess = hasRole(['grad-school-user'])
  // @TODO need to rethink examiner access logic here and refactor
  const examinerAccess1 = isAssignedThesisExaminer(
    currentUser,
    submission?.thesisSubmissionExaminers[0]?.user?.id,
    submission?.thesisSubmissionExaminers[0]?.status
  )
  const examinerAccess2 = isAssignedThesisExaminer(
    currentUser,
    submission?.thesisSubmissionExaminers[1]?.user?.id,
    submission?.thesisSubmissionExaminers[1]?.status
  )

  const hasAccessToForm =
    chairAccess ||
    (gsAccess && submission.chair?.summaryReportCompleted) ||
    ((advisorAccess || candidateAccess || examinerAccess1 || examinerAccess2) &&
      submission.chair?.summaryReportCompleted &&
      submission.showChairFeedback)
  return (
    <ContentCard title="Chair summary report">
      <Grid container spacing={2} direction={'column'}>
        {submission?.currentIteration?.currentStatus?.status === 'AWAITING_CHAIR_EXAMINATION_OUTCOME' &&
          submission?.currentIteration?.chairSummaryReportDueDate && (
            <Grid xs={12}>
              <Typography variant="body2">{`Summary report due date: ${toTimezone(
                submission?.currentIteration?.chairSummaryReportDueDate
              )}`}</Typography>
            </Grid>
          )}

        {thesisStatusIsBeforeAwaitOralExam ? (
          // Before oral exam date set
          <Grid xs={12}>
            <Alert severity="info">
              {`Once the thesis examination has been completed, the Chair summary report will be submitted and viewed here.`}
            </Alert>
          </Grid>
        ) : thesisStatusIsAwaitOralExam ? (
          <>
            {hasChairRole ? (
              <Grid xs={12}>
                <Typography variant="body2">{`Please view the summary report here in preparation for the oral examination.`}</Typography>
              </Grid>
            ) : (
              <>
                <Grid xs={12}>
                  <Alert severity="info">
                    {`Once the thesis examination has been completed, the Chair summary report will be submitted and viewed here.`}
                  </Alert>
                </Grid>
              </>
            )}
            <Grid xs={'auto'}>
              <UserAccordion chair={submission?.chair} submission={submission} onReload={onReload} />
            </Grid>
          </>
        ) : thesisStatusIsChairReviewExam ? (
          <>
            <Grid xs={12}>
              {hasChairRole ? (
                <Typography variant="body2">{`Please complete the summary report below. Your report, including your recommended outcome, will be reviewed by the Dean when endorsing the examination outcome.`}</Typography>
              ) : (
                <Typography variant="body2">{`The Chair is completing the summary report. Once submitted and reviewed by the Dean, an outcome for the examination will be decided. The summary report will be made available to you once the outcome has been confirmed.`}</Typography>
              )}
            </Grid>
            <Grid xs={'auto'}>
              <UserAccordion chair={submission?.chair} submission={submission} onReload={onReload} />
            </Grid>
            {hasAccessToForm && submission.chair.summaryReportCompleted && (
              <Grid xs={12} sm display="flex" alignSelf={'center'} justifyContent={'flex-end'}>
                <ChairFeedbackSummaryButton
                  chair={submission?.chair}
                  submission={submission}
                  onReload={onReload}
                  hasAccess={hasAccessToForm}
                />
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid xs={'auto'}>
              <UserAccordion chair={submission?.chair} submission={submission} onReload={onReload} />
            </Grid>
            {hasAccessToForm && (
              <Grid xs={12} sm display="flex" alignSelf={'center'} justifyContent={'flex-end'}>
                <ChairFeedbackSummaryButton
                  chair={submission?.chair}
                  submission={submission}
                  onReload={onReload}
                  hasAccess={hasAccessToForm}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </ContentCard>
  )
}

export default ChairSummaryReportCard
