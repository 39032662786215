import { ThesisSubmissionExaminerStatus } from 'types/graphql'

const getThesisExaminerOutcomeLabel = (status: ThesisSubmissionExaminerStatus): string => {
  if (status === 'CANDIDATE_COI_REVIEW') return 'Pending candidate COI review'
  if (status === 'DEAN_INITIAL_COI_REVIEW') return 'Pending Dean COI review'
  if (status === 'GRADUATE_SCHOOL_INITIAL_REVIEW') return "Pending Graduate School's initial approval"
  if (status === 'PENDING_INVITATION_ACCEPTANCE') return 'Pending examiner invitation acceptance'
  if (status === 'EXAMINER_COI_REVIEW') return 'Pending examiner to provide additional information'
  if (status === 'GRADUATE_SCHOOL_FINAL_REVIEW') return "Pending Graduate School's final approval"
  if (status === 'DEAN_FINAL_COI_REVIEW') return 'Pending Dean final COI review'
  if (status === 'INITIAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL') return 'Initial change requested by Graduate School'
  if (status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL') return 'Additional information requested from the examiner'
  if (status === 'APPROVED') return 'Thesis examiner approved'
  if (status === 'REJECTED') return 'Thesis examiner rejected'
  if (status === 'REMOVED') return 'Thesis examiner removed'

  return 'Unknown'
}

export default getThesisExaminerOutcomeLabel
