const EXAMINER_SUBMITS_FEEDBACK = gql`
  mutation ExaminerSubmitsFeedback(
    $id: Int!
    $submissionId: Int!
    $iterationId: Int!
    $input: ExaminationSummaryInput!
  ) {
    examinerSubmitsFeedback(id: $id, submissionId: $submissionId, iterationId: $iterationId, input: $input)
  }
`

export default EXAMINER_SUBMITS_FEEDBACK
