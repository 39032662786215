import { RecordServiceStatus } from '@common/recordServiceStatus'
import { RecordStatus } from '@common/recordStatus'
import { DatasetStatus } from '@prisma/client'

export const datasetStatusCodes = {
  [DatasetStatus.PENDING]: 'Pending',
  [DatasetStatus.PUBLISHED]: 'Published',
  [DatasetStatus.REMOVED]: 'Removed',
}

export const recordStatusCodes = {
  All: 'All',
  [RecordStatus.ACTIVE]: 'Active',
  [RecordStatus.DRAFT]: 'Draft',
  [RecordStatus.PENDING_LEAD_APPROVAL]: 'Pending lead approval',
  [RecordStatus.PENDING_REMOVAL]: 'Pending removal',
  [RecordStatus.REMOVED]: 'Removed',
}

export const storageTypes = {
  STANDARD:
    'This storage allocation will not be used to store identifiable human data, nor does it require HPC facilities',
  HUMAN_IDENTIFIABLE: 'This storage allocation will be used to store human identifiable data',
  HPC: 'The storage allocation will be used to store data that needs to be accessed by UQ HPC facilities',
}

export const recordServiceStatusCodes = {
  All: 'All',
  [RecordServiceStatus.ACTIVE]: 'Active',
  [RecordServiceStatus.PENDING_CREATION]: 'Pending creation',
  [RecordServiceStatus.PENDING_SERVICE_RESPONSE]: 'Pending service response',
  [RecordServiceStatus.PENDING_RECORD_SUBMISSION]: 'Pending service submission',
  [RecordServiceStatus.PENDING_SERVICE_REMOVAL]: 'Pending service removal',
}
