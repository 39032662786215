const EXAMINER_SAVES_FEEDBACK = gql`
  mutation ExaminerSavesFeedback(
    $id: Int!
    $submissionId: Int!
    $iterationId: Int!
    $input: SaveExaminationSummaryInput!
  ) {
    examinerSavesFeedback(id: $id, submissionId: $submissionId, iterationId: $iterationId, input: $input)
  }
`

export default EXAMINER_SAVES_FEEDBACK
