import { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import { CheckCircle } from '@mui/icons-material'
import { Button, Card, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisFileDownloadUrlQuery, GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import {
  isAssignedThesisCandidate,
  isAssignedThesisChair,
  isAssignedThesisExaminer,
  isAssignedThesisPrincipalAdvisor,
} from 'src/lib/userAccessChecker'

import StyledHourglassIcon from '../../../Icons/HourGlassIcon'
import ThesisFilesViewerSection from '../../ThesisIterationFormViewer/components/ThesisFilesViewerSection'

import ExaminerReviewDialog from './ExaminerReviewDialog'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0] | null
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

/**
 * Thesis written examination card user accordion
 */
const UserAccordion = ({ examiner, submission, onReload }: Props) => {
  const { currentUser, hasRole } = useAuth()
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()

  const [openReviewForm, setOpenReviewForm] = useState(false)

  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const hasExaminerRole = hasRole('user-thesis-examiner')
  const hasCompletedExaminationFeedback = examiner?.examinationFeedbackCompleted
  const showExaminerReports = submission.showExaminerReports
  const examinerReviewingStatus = submission.currentIteration?.currentStatus.status === 'EXAMINERS_REVIEWING'

  // user access logics
  const candidateAccess = isAssignedThesisCandidate(currentUser, submission.creator.id) && showExaminerReports
  const isFileOwner = isAssignedThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)
  const advisorAccess =
    isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id) && showExaminerReports
  const examinerFileAccess = (isFileOwner && hasCompletedExaminationFeedback) || showExaminerReports
  const examinerFormAccess = examiner
    ? isAssignedThesisExaminer(currentUser, examiner?.user?.id, examiner?.status) &&
      (examinerReviewingStatus || hasCompletedExaminationFeedback)
    : false
  const chairAccess = isAssignedThesisChair(currentUser, submission?.chair?.user.id) && hasCompletedExaminationFeedback
  const gsAccess = hasRole(['grad-school-user']) && hasCompletedExaminationFeedback

  const hasAccessToFeedbackFiles = candidateAccess || advisorAccess || examinerFileAccess || chairAccess || gsAccess
  const hasAccessToViewForm = examinerFormAccess // only examiner, chair and GS can view the form

  // Thesis status NOT in EXAMINERS_REVIEWING AND examinationFeedbackCompleted is false - awaiting thesis
  // Thesis status EXAMINERS_REVIEWING AND examinationFeedbackCompleted is false - reviewing thesis
  // Thesis status NOT in EXAMINERS_REVIEWING AND examinationFeedbackCompleted is true - review completed
  let status
  if (!hasCompletedExaminationFeedback) {
    if (!examinerReviewingStatus) {
      status = 'Awaiting examination initiation'
    } else {
      status = 'Reviewing thesis'
    }
  } else {
    status = 'Review completed'
  }

  return (
    <>
      <Card
        sx={{
          background: 'rgba(0,0,0,0.05)',
        }}
      >
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid alignItems="center" display="flex" xs="auto">
            {hasCompletedExaminationFeedback ? <CheckCircle sx={{ color: 'success.main' }} /> : <StyledHourglassIcon />}
          </Grid>
          <Grid alignContent={'center'}>
            <Grid container spacing={1}>
              <Grid xs={'auto'}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} alignSelf={'center'}>
                  {examiner ? examiner?.user?.displayName || examiner?.fullName : 'Examiner nomination to be confirmed'}
                </Typography>
              </Grid>
              <Grid xs={'auto'}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid xs={'auto'} alignSelf={'center'}>
                <Typography variant="body2" fontSize={'small'}>
                  {status.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {hasAccessToViewForm && (
            <Grid xs display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                color={hasCompletedExaminationFeedback ? 'secondary' : hasExaminerRole ? 'primary' : 'secondary'}
                onClick={() => setOpenReviewForm(true)}
                disabled={!hasAccessToViewForm}
                size="small"
              >
                {hasCompletedExaminationFeedback ? 'View feedback' : hasExaminerRole ? 'Submit' : 'View'}
              </Button>
            </Grid>
          )}

          {/* @TODO can use loop */}
          {hasAccessToFeedbackFiles && hasCompletedExaminationFeedback && (
            <>
              <Grid xs={12}>
                <ThesisFilesViewerSection
                  documentLabel=""
                  fileType={ThesisFileType.EXAMINER_REVIEW_FILE}
                  files={submission.currentIteration.files.filter(
                    (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_REVIEW_FILE && f.isActive
                  )}
                  onDownload={onDownload}
                />
              </Grid>
              <Grid xs={12}>
                <ThesisFilesViewerSection
                  documentLabel=""
                  fileType={ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE}
                  files={submission.currentIteration.files.filter(
                    (f) =>
                      f.examinerId === examiner?.id &&
                      f.type === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE &&
                      f.isActive
                  )}
                  onDownload={onDownload}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Card>
      <ExaminerReviewDialog
        open={openReviewForm}
        onCancel={() => setOpenReviewForm(false)}
        examiner={examiner}
        submission={submission}
        onReload={onReload}
      />
    </>
  )
}

export default UserAccordion
