import { useState } from 'react'

import { CHAIR_CAN_BE_CHANGED_THESIS_STATUSES } from '@common/thesis/getThesisStatuses'
import { CheckCircle, WarningAmber } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import StyledHourglassIcon from 'src/components/Icons/HourGlassIcon'
import { isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

import ThesisChairNoteDialog from './ThesisChairNoteDialog'
import ThesisChairSubmissionNoteDialog from './ThesisChairSubmissionNoteDialog'
import UpdateChairDialog from './UpdateChairDialog'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

/**
 * Editable Chair view for Graduate School user and Principal Advisor
 *
 * The Graduate School user can add/edit the Chair
 * The Principal Advisor can only add the Chair
 */
const ChairEdit = ({ submission }: Props) => {
  const { hasRole, currentUser } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const isGS = hasRole('grad-school-user')
  const isAssignedPA = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)

  // Graduate School
  const [onOpenThesisChairUpdateDialog, setOnOpenThesisChairUpdateDialog] = useState<boolean>(false)
  const [onOpenThesisChairNoteDialog, setOnOpenThesisChairNoteDialog] = useState(false)
  const [onOpenThesisChairSubmissionNoteDialog, setOnOpenThesisChairSubmissionNoteDialog] = useState(false)

  const chair = submission?.chair

  const canUpdateChair =
    isGS && CHAIR_CAN_BE_CHANGED_THESIS_STATUSES.has(submission.currentIteration.currentStatus.status)

  return (
    <>
      <MenuList color="white" sx={{ p: 0 }}>
        {chair ? (
          <Grid container>
            <Grid xs={11}>
              <MenuItem
                disableRipple
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  p: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    cursor: 'default',
                  },
                }}
              >
                <ListItemIcon color="white">
                  <CheckCircle sx={{ color: '#00b3a6', height: '1.1em', width: '1.1em' }} />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white' }}>
                  <Grid container direction={'column'}>
                    <Grid xs={'auto'}>
                      <Typography variant="subtitle1" sx={{ color: 'white' }}>
                        {chair.user.displayName}
                      </Typography>
                    </Grid>
                    <Grid xs={'auto'}>
                      <Typography
                        fontSize="small"
                        color={'#eeeeee'}
                        sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                      >{`${submission?.chair?.user.organisation?.name}`}</Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </MenuItem>
            </Grid>

            {isGS && (
              <Grid xs={1} display="flex" justifyContent={'end'} alignSelf={'flex-start'}>
                <Tooltip title={'View actions'}>
                  <IconButton sx={{ color: 'grey' }} onClick={handleMenuOpen}>
                    <MoreVertIcon sx={{ color: 'rgba(255,255,255,0.75)', mr: -2 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ) : (
          <>
            {isAssignedPA && (
              <Grid container spacing={1}>
                <Grid xs={'auto'}>
                  <WarningAmber sx={{ color: 'warning.main' }} fontSize="inherit" />
                </Grid>
                <Grid xs>
                  <Typography
                    variant="body2"
                    sx={{ color: 'warning.main', whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    You need to assign a Chair
                  </Typography>
                </Grid>
              </Grid>
            )}
            {isGS && (
              <Grid container alignContent={'flex-start'}>
                <Grid>
                  <StyledHourglassIcon />
                </Grid>
                <Grid xs sx={{ ml: 1 }}>
                  <Grid direction={'column'} container>
                    <Grid xs={12}>
                      <Typography variant="subtitle1" sx={{ color: 'white' }}>
                        {'Principal Advisor to assign'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </MenuList>

      {/* Dialogs */}
      {isGS && (
        <>
          {chair && onOpenThesisChairUpdateDialog && (
            <UpdateChairDialog
              onOpen={onOpenThesisChairUpdateDialog}
              onClose={() => {
                setOnOpenThesisChairUpdateDialog(false)
                handleMenuClose()
              }}
              submissionId={submission.id}
              currentChairId={chair?.id || null}
              currentChairEppn={chair?.user?.eduPersonPrincipalName || null}
            />
          )}
          {chair && onOpenThesisChairNoteDialog && (
            <ThesisChairNoteDialog
              onOpen={onOpenThesisChairNoteDialog}
              onClose={() => {
                setOnOpenThesisChairNoteDialog(false)
                handleMenuClose()
              }}
              submission={submission}
            />
          )}
          {chair && onOpenThesisChairSubmissionNoteDialog && (
            <ThesisChairSubmissionNoteDialog
              onOpen={onOpenThesisChairSubmissionNoteDialog}
              onClose={() => {
                setOnOpenThesisChairSubmissionNoteDialog(false)
                handleMenuClose()
              }}
              submission={submission}
            />
          )}
        </>
      )}

      {isGS && (
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleMenuClose}>
          {canUpdateChair && (
            <MenuItem onClick={() => setOnOpenThesisChairUpdateDialog(true)}>Update the Chair of Examiners</MenuItem>
          )}
          <MenuItem onClick={() => setOnOpenThesisChairNoteDialog(true)}>
            {submission.chair?.user.graduateSchoolNote ? 'View user note' : 'Add user note'}
          </MenuItem>
          <MenuItem onClick={() => setOnOpenThesisChairSubmissionNoteDialog(true)}>
            {submission.chair?.graduateSchoolNote ? 'View submission note' : 'Add submission note'}
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

export default ChairEdit
