import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Metadata } from '@redwoodjs/web'

import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import PageTitle from 'src/components/Page/PageTitle'
import { ThesisSubmissionStepper } from 'src/components/Thesis'
import ThesisDetails from 'src/components/Thesis/ThesisDetails/ThesisDetails'
import ThesisExaminerNominationNotifications from 'src/components/Thesis/ThesisExaminerNominationNotification/ThesisExaminerNominationNotifications'
import ThesisSubmissionMessageExaminer from 'src/components/Thesis/ThesisSubmissionMessageExaminer/ThesisSubmissionMessageExaminer'
import { getExaminerActionNotifications } from 'src/lib/helpers/thesis'
import { stripHtmlTags } from 'src/lib/stringHelpers'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
  query?: URLSearchParams
}

const Content = ({ submission, onReload, query }: Props) => {
  const pageTitle =
    (submission?.currentIteration?.title && stripHtmlTags(submission?.currentIteration?.title)) ||
    'HDR examination details'

  return (
    <>
      <Metadata title={pageTitle} description={pageTitle} />
      <PageTitle dangerouslySetInnerHTML={{ __html: pageTitle }} />
      <Typography variant="subtitle1" marginBottom={2}>
        {`${submission.creator.displayName} - `}
        <ExternalLink href={`mailto:${submission.creator.mail}`}>{submission.creator.mail}</ExternalLink>
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <ThesisSubmissionStepper
            iteration={submission.currentIteration}
            hasOral={submission.currentIteration.hasOral}
          />
        </Grid>
        <Grid xs={12}>
          <ThesisSubmissionMessageExaminer submission={submission} onReload={onReload} />
        </Grid>
        {getExaminerActionNotifications(submission).map((action, index) => {
          return (
            <Grid xs={12} sx={{ pl: 1, pr: 1 }} key={index}>
              <ThesisExaminerNominationNotifications key={index} action={action} submission={submission} />
            </Grid>
          )
        })}
        <Grid xs={12}>
          <ThesisDetails submission={submission} onReload={onReload} query={query} />
        </Grid>
      </Grid>
    </>
  )
}

export default Content
