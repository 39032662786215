import { useCallback, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Alert, Box, Link } from '@mui/material'
import AwsS3 from '@uppy/aws-s3'
import Uppy, { UppyFile } from '@uppy/core'

import { useController, useWatch } from '@redwoodjs/forms'

import { useAuth } from 'src/auth'
import GET_PRESIGNED_URL_THESIS_UPLOAD from 'src/lib/graphql/queries/GetPresignedUrlThesisUpload'

import { getThesisFileTableValues } from '../helpers'

import ThesisFilesTable from './ThesisFilesTable'
import ThesisFilesUploadButton from './ThesisFilesUploadButton'
import ThesisFilesUploadModal from './ThesisFilesUploadModal'

const uppy = new Uppy({
  id: 'uppy-upload-thesis-document',
  restrictions: {
    maxFileSize: 5000000000,
    allowedFileTypes: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.doc',
      '.docx',
    ],
    maxNumberOfFiles: 1,
  },
  autoProceed: true,
  allowMultipleUploadBatches: false,
}).use(AwsS3)

type Props = {
  readOnly: boolean
}

const ThesisCorrectionsFile = ({ readOnly }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { field, fieldState } = useController({ name: 'correctionsFile' })
  const apolloClient = useApolloClient()
  const thesisType = useWatch({ name: 'type' })

  const { currentUser } = useAuth()

  const onUploadSuccess = useCallback(
    (file: UppyFile) => {
      field.onChange({
        key: file.meta.key as string,
        filename: file.name,
      })
      field.onBlur()
    },
    [field]
  )

  useEffect(() => {
    uppy.on('upload-success', onUploadSuccess)

    uppy.getPlugin('AwsS3').setOptions({
      getUploadParameters(file) {
        // Fetch presigned URL from the API for this file
        return apolloClient
          .query({ query: GET_PRESIGNED_URL_THESIS_UPLOAD, variables: { filename: file.name, contentType: file.type } })
          .then((response) => {
            return {
              method: 'put',
              url: response.data.getPresignedUrlThesisUpload.presignedUrl,
              fields: { key: response.data.getPresignedUrlThesisUpload.key },
              headers: { 'Content-Type': file.type },
            }
          })
      },
    })
  }, [apolloClient, onUploadSuccess])

  const onRemoveUploadedFile = () => {
    field.onChange(null)
  }

  const fieldValue = field.value
    ? getThesisFileTableValues([field.value], 'CORRECTIONS_FILE', currentUser.eduPersonPrincipalName, thesisType)
    : []

  return (
    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)', br: 5 }}>
      <Box sx={{ p: 1 }}>
        <ThesisFilesTable
          help={{
            title: 'Thesis corrections file',
            text: (
              <span>
                Your degree was awarded with changes to your thesis. Please attach your{' '}
                <Link target="_blank" href="https://my.uq.edu.au/files/19630/Guide - UQRDM Corrections August 2021.pdf">
                  corrections document
                </Link>{' '}
                as required by your{' '}
                <Link
                  target="_blank"
                  href="https://my.uq.edu.au/information-and-services/higher-degree-research/my-thesis/3-thesis-examination?p=2#2"
                >
                  examination outcome
                </Link>
              </span>
            ),
          }}
          uploadedFiles={fieldValue}
          onRemove={onRemoveUploadedFile}
          fileTitle="Corrections file *"
          noFilesMessage="No corrections file uploaded"
          readOnly={readOnly}
        />
        {fieldState.error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {fieldState.error.message}
          </Alert>
        )}
        {field.value === null && !readOnly && (
          <ThesisFilesUploadButton label="Upload corrections file" onClick={() => setDialogOpen(true)} />
        )}
        <ThesisFilesUploadModal
          uppy={uppy}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false)
            uppy.cancelAll()
          }}
        />
      </Box>
    </Box>
  )
}
export default ThesisCorrectionsFile
