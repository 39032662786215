import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { ThesisSubmissionHistoryEntry } from './ThesisHistoryDialog'
import ThesisHistoryStatusTable from './ThesisHistoryStatusTable'

type Props = {
  iteration: ThesisSubmissionHistoryEntry
  hasFiles: boolean
  expanded: boolean
  onToggleExpand: (expanded: boolean) => void
  onViewIteration: (iterationId: number) => void
}

const ThesisHistoryIterationAccordion = ({ iteration, hasFiles, expanded, onToggleExpand, onViewIteration }: Props) => {
  const onExpand = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    onToggleExpand(isExpanded)
  }

  return (
    <Accordion key={iteration.id} sx={{ mb: 1 }} expanded={expanded} onChange={onExpand}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>Iteration {iteration.number}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          {iteration.entries[iteration.entries.length - 1].event}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ThesisHistoryStatusTable entries={iteration.entries} />

        {hasFiles && (
          <Grid container direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onViewIteration(iteration.id)}
              endIcon={<ChevronRight />}
            >
              View iteration
            </Button>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default ThesisHistoryIterationAccordion
