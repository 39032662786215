const GET_THESIS_SUBMISSION_QUERY = gql`
  query GetThesisSubmissionQuery($id: Int!) {
    getThesisSubmission(id: $id) {
      id
      uuid
      showExaminerReports
      showChairFeedback
      resubmittedSubmissionId
      reviseAndResubmitOfId
      isDowngradedToMPhil
      updatedAt
      createdAt
      creator {
        id
        displayName
        mail
        eduPersonPrincipalName
      }
      principalAdvisor {
        id
        displayName
        mail
        eduPersonPrincipalName
        organisation {
          name
        }
      }
      chair {
        id
        fileId
        createdAt
        detailedKnowledgeOfThesis
        understandingResearchInBroaderContext
        defendMethodologyAndConclusions
        communicatedResultsAndImpact
        independentContributionToDiscipline
        amendments
        otherAmendmentDetails
        examinationSummary
        chairOutcomeRecommendation
        isActive
        canViewSubmission
        summaryReportCompleted
        graduateSchoolNote
        user {
          id
          displayName
          mail
          eduPersonPrincipalName
          graduateSchoolNote
          organisation {
            name
          }
        }
        file {
          id
          type
          key
          filename
          isNew
          isActive
          submissionDate
        }
      }
      thesis {
        id
        espacePid
      }
      thesisSubmissionExaminers {
        id
        submissionId
        mail
        title
        fullName
        createdAt
        status
        advisorCOIStatements
        advisorCOIDetails
        candidateCOIStatements
        candidateCOIDetails
        examinerCOIStatements
        examinerCOIDetails
        position
        institution
        rationaleForNomination
        contactNumber
        contactAddress
        requirePayment
        bankAccountType
        canViewSubmission
        outcomeMessage
        graduateSchoolNote
        examinationFeedbackCompleted
        oralAttendanceType
        otherOralAttendanceArrangements
        deanCOIDecision
        poorThesisQuality
        user {
          id
          displayName
          mail
          eduPersonPrincipalName
          graduateSchoolNote
        }
        files {
          id
          type
          key
          filename
          isNew
          isActive
          submissionDate
        }
        contributionKnowledge
        engagementWithLiterature
        advancedKnowledge
        researchCoherence
        convincingWriting
        examinerRecommendation
        thesesAssessed
        candidatesSupervised
        thesisRating
      }
      currentIteration {
        id
        title
        abstract
        submittedDate
        intendedSubmissionDate
        oralDate
        hasOral
        examinerFeedbackDueDate
        chairSummaryReportDueDate
        organisation {
          id
        }
        record {
          id
          uuid
          name
          collaborators {
            isOwner
            isLead
            user {
              id
              displayName
              eduPersonPrincipalName
            }
          }
        }
        files {
          id
          examinerId
          type
          key
          filename
          isNew
          isActive
          submissionDate
        }
        principalAdvisor {
          id
          displayName
          mail
        }
        totalPages
        type
        additionalSupervisors {
          id
          displayName
          mail
        }
        embargoType
        forCodes {
          id
          code
          name
        }
        isUqIntellectualProperty
        keywords
        organisation {
          id
          name
          isActive
          organisationTypeId
        }
        currentStatus {
          id
          previousStatusId
          status
          outcome
          outcomeMessage
          createdAt
          updatedAt
          outcomeByUser {
            displayName
          }
          nextStatus {
            id
          }
        }
        previousStatus {
          id
          previousStatusId
          status
          outcome
          outcomeMessage
          createdAt
          updatedAt
          outcomeByUser {
            displayName
          }
        }
      }
      previousIteration {
        lastStatus {
          outcomeMessage
        }
      }
    }
  }
`

export default GET_THESIS_SUBMISSION_QUERY
