import { thesisIterationStatusTypes } from '@common/thesis'

import { ThesisSubmissionIterationType } from '../ThesisSubmissionType'

import FinalThesisIterationFormViewer from './FinalThesisIterationFormViewer'
import InitialThesisIterationFormViewer from './InitialThesisIterationFormViewer'

type Props = {
  iteration: ThesisSubmissionIterationType
}

const ThesisIterationFormViewer = ({ iteration }: Props) => {
  const status = thesisIterationStatusTypes.find((s) => s.code === iteration.currentStatus.status)

  return status.isFinal ? (
    <FinalThesisIterationFormViewer iteration={iteration} />
  ) : (
    <InitialThesisIterationFormViewer iteration={iteration} />
  )
}

export default ThesisIterationFormViewer
