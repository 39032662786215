import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: () => unknown
}

const PassSubmissionButtonDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="md"
      fullWidth
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      <DialogTitle color="primary" variant="h2" component="h2" id="dialogtitle">
        Pass submission
      </DialogTitle>
      <DialogContent id="dialogcontent">
        <Typography variant="body1" sx={{ mb: 1 }}>
          By passing the submission, you confirm the candidate has met the requirements of the examination successfully
          and can now submit their final thesis for review, conferral, and publication.
        </Typography>
      </DialogContent>
      <DialogActions>
        {!saving && (
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}

        <LoadingButton variant="contained" color="primary" loading={saving} onClick={onConfirm}>
          Pass submission
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default PassSubmissionButtonDialog
