import { ReactNode } from 'react'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'

import Link from '../Link/Link'

type LinkType = {
  text: string
  url: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export type LinkCardProps = {
  links: LinkType[]
  image?: { src: string; title?: string; alt: string }
  title?: string
  body?: string | ReactNode
  imgHeight?: number
}

const LinkCard = (props: LinkCardProps) => {
  const { links, image, title = undefined, body = undefined, imgHeight = undefined } = props
  return (
    <Card
      sx={{
        height: '100%',
        maxWidth: 345,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Link href={links[0].url} notStyled>
        {image && (
          <CardMedia component="img" alt={image.alt} height={imgHeight || 200} image={image.src} title={image.title} />
        )}
        {title && (
          <CardContent>
            <Typography gutterBottom variant="h4" color="black" fontWeight={500}>
              {title}
            </Typography>
            {body && <Typography variant="body2">{body}</Typography>}
          </CardContent>
        )}
      </Link>
      {/* </CardActionArea> */}
      <CardActions sx={{ justifyContent: 'center', flexDirection: 'column' }}>
        {links.map((link, index) =>
          link.url ? (
            <Link href={link.url} key={link.url} notStyled>
              <Button tabIndex={-1} size="small" color="primary" endIcon={<NavigateNextIcon />} fullWidth>
                {link.text}
              </Button>
            </Link>
          ) : (
            <Button
              key={index}
              size="small"
              color="primary"
              endIcon={<NavigateNextIcon />}
              fullWidth
              onClick={link.onClick}
            >
              {link.text}
            </Button>
          )
        )}
      </CardActions>
    </Card>
  )
}

export default LinkCard
