const GRADUATE_SCHOOL_UPDATES_CHAIR = gql`
  mutation GraduateSchoolUpdatesChair($submissionId: Int!, $currentChairId: Int, $newChairEppn: String!) {
    graduateSchoolUpdatesChair(
      submissionId: $submissionId
      currentChairId: $currentChairId
      newChairEppn: $newChairEppn
    )
  }
`

export default GRADUATE_SCHOOL_UPDATES_CHAIR
