import { useState } from 'react'

import Button from 'src/components/Button/Button'

import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

import ThesisSubmissionButtonDialog from './ThesisSubmissionButtonDialog'

type Props = {
  submission: ThesisSubmissionDetails
  onReload: () => unknown
  buttonText?: string
}

const ThesisSubmissionButton = ({ submission, onReload, buttonText = 'Submit your thesis' }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} fullWidth>
        {buttonText}
      </Button>
      <ThesisSubmissionButtonDialog
        submission={submission}
        onReload={onReload}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default ThesisSubmissionButton
