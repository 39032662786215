import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import CandidatureCard from './Candidature/CandidatureCard'
import ChairSummaryReportCard from './ChairSummaryReport/ChairSummaryReportCard'
import CommitteeCard from './Committee/CommitteeCard'
import ThesisSubmissionCard from './SubmissionForm/ThesisSubmissionCard'
import WrittenExaminationCard from './WrittenExamination/WrittenExaminationCard'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
  query?: URLSearchParams
}

/**
 * Submission details content
 */
const ThesisDetails = ({ submission, onReload, query }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={7}>
        <Grid container spacing={2}>
          <Grid xs={12} sx={{ width: '100%' }}>
            <ThesisSubmissionCard submission={submission} onReload={onReload} />
          </Grid>
          <Grid xs={12} sx={{ width: '100%' }}>
            <WrittenExaminationCard submission={submission} onReload={onReload} />
          </Grid>
          <Grid xs={12} sx={{ width: '100%' }}>
            <ChairSummaryReportCard submission={submission} onReload={onReload} />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} sm={5}>
        <Grid container spacing={2}>
          <Grid xs={12} sx={{ width: '100%' }}>
            <CandidatureCard submission={submission} />
          </Grid>
          <Grid xs={12} sx={{ width: '100%' }}>
            <CommitteeCard submission={submission} query={query} onReload={onReload} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ThesisDetails
