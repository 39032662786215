import { Upload } from '@mui/icons-material'
import { Button } from '@mui/material'

type Props = {
  label: string
  onClick: () => void
}

const ThesisFilesUploadButton = ({ label, onClick }: Props) => {
  return (
    <Button
      color="secondary"
      size="small"
      variant="contained"
      onClick={onClick}
      fullWidth={true}
      startIcon={<Upload />}
      sx={{ mt: 1 }}
    >
      {label}
    </Button>
  )
}

export default ThesisFilesUploadButton
