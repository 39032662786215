import { Stack } from '@mui/material'

import PageTitle from 'src/components/Page/PageTitle'
import PageWrapper from 'src/components/Page/PageWrapper'

import GraduateSchoolDashboardGreetings from './components/GraduateSchoolDashboardGreetings'

/**
 * Graduate School dashboard page
 */
const GraduateSchoolDashboardPage = () => {
  return (
    <PageWrapper>
      <Stack direction="column" spacing={2}>
        <PageTitle>Dashboard</PageTitle>
        <GraduateSchoolDashboardGreetings />
      </Stack>
    </PageWrapper>
  )
}

export default GraduateSchoolDashboardPage
