import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Check if the time is within 24 hours of the current time
 */
export const isWithin24Hours = (time: string) => {
  const givenTime = dayjs(time, 'YYYY-MM-DD HH:mm:ss.SSS')
  const currentDateTime = dayjs()

  const isWithin24Hours = givenTime.isAfter(currentDateTime.subtract(24, 'hour'))

  if (isWithin24Hours) {
    return true
  } else {
    return false
  }
}

/**
 * Convert UTC time to Australia/Brisbane time
 */
export const toBrisbaneTime = (time: string | Date) => {
  return dayjs(time).tz('Australia/Brisbane')
}
