import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { Alert } from '@mui/material'
import { FieldOfResearchCode } from 'types/graphql'

import { useWatch } from '@redwoodjs/forms'

import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import { CAutocomplete } from 'src/components/Form/Inputs'
import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'
import forCodesGuidance from 'src/lib/guidance/forCodesGuidance'
import useDebounce from 'src/lib/hooks/useDebounce'

import ListboxComponent from './ListboxComponent'

const isCurrentVersion = (v?: string) => v === '2020-06-30' || v === '2020-06-29' || v === undefined

const GET_CODES = gql`
  query FindFieldOfResearchCodesQuery($searchString: String) {
    fieldOfResearchCodes(searchString: $searchString) {
      id
      code
      name
      version
      parentId
    }
  }
`
type QueryResult = {
  fieldOfResearchCodes: FieldOfResearchCode[]
}

export type FieldOfResearchCodesProps = {
  helpDisabled?: boolean
  disabled?: boolean
  readOnly?: boolean
  name: string
}

function FieldOfResearchCodes({ helpDisabled, disabled, readOnly = false, name }: FieldOfResearchCodesProps) {
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 200)
  const value = useWatch({ name })

  const { loading, data } = useQuery<QueryResult>(GET_CODES, {
    variables: { searchString: debouncedInputValue },
    fetchPolicy: 'cache-first',
    skip: debouncedInputValue.length < 2,
  })

  let fieldOfResearchCodes: FieldOfResearchCode[] = []
  if (!!data && data.fieldOfResearchCodes) {
    fieldOfResearchCodes = [...data.fieldOfResearchCodes].filter((f) => isCurrentVersion(f.version))
  }

  // Add current value to options if not already present
  value.forEach((v) => {
    if (!fieldOfResearchCodes.find((f) => f.id === v.id)) fieldOfResearchCodes.push(v)
  })

  return (
    <>
      <HelpWrapper disabled={helpDisabled} helpProps={forCodesGuidance} sx={{ p: 0 }}>
        <CAutocomplete<FieldOfResearchCode>
          name={name}
          multiple={true}
          getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.code} - ${option.name}`)}
          options={fieldOfResearchCodes}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onInputChange={(_event: unknown, newInputValue: string) => {
            setInputValue(newInputValue)
          }}
          filterOptions={(x) => x}
          loading={loading}
          disabled={disabled}
          readOnly={readOnly}
          disableClearable={readOnly}
          ListboxComponent={React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(ListboxComponent)}
          textFieldProps={{
            label: 'Field of research codes *',
            placeholder: readOnly ? '' : 'Begin typing a field of research code to search',
          }}
          getOptionStyle={(o) =>
            isCurrentVersion(o.version)
              ? undefined
              : { backgroundColor: 'rgb(248, 237, 229)', border: '1px solid #bf5000' }
          }
        />
      </HelpWrapper>
      {!!value?.find((fc: FieldOfResearchCode) => !isCurrentVersion(fc.version)) && (
        <Alert severity="warning">
          The highlighted field of research codes belong to the Australian and New Zealand Standard Research
          Classification (ANZSRC) from 2008. UQRDM is now using the updated ANZSRC from 2020. Please replace the
          highlighted fields of research codes with equivalents from ANZSRC from 2020. For more information, visit the{' '}
          <ExternalLink href="https://www.arc.gov.au/grants/grant-application/classification-codes-rfcd-seo-and-anzsic-codes">
            Australian Research Council website
          </ExternalLink>
          .
        </Alert>
      )}
    </>
  )
}
export default FieldOfResearchCodes
