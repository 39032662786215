import { Box } from '@mui/material'

import ContentCard from '../ContentCard/ContentCard'
import InlineLoader from '../InlineLoader/InlineLoader'

type ContentLoaderProps = {
  message?: string
}
const ContentLoader = (props: ContentLoaderProps) => {
  const message = props.message || 'Loading...'

  return (
    <ContentCard>
      <InlineLoader />
      <Box style={{ textAlign: 'center' }}>{message}</Box>
    </ContentCard>
  )
}
export default ContentLoader
