import { getRenamedThesisFilename } from '@common/thesis'
import { ThesisFileType, ThesisType } from 'types/graphql'

export type UploadedFile = {
  id?: number
  key: string
  filename: string
  renamedTo?: string
  submissionDate?: string
}

export const getThesisFileTableValues = (
  values: UploadedFile[],
  fileType: ThesisFileType,
  eduPersonPrincipalName: string,
  thesisType: ThesisType
): UploadedFile[] => {
  return values.map((file) => getThesisFileTableValue(file, fileType, eduPersonPrincipalName, thesisType))
}

const getThesisFileTableValue = (
  file: UploadedFile,
  fileType: ThesisFileType,
  eduPersonPrincipalName: string,
  thesisType: ThesisType
): UploadedFile => {
  const willBeRenamedTo = getRenamedThesisFilename(file.filename, fileType, eduPersonPrincipalName, thesisType)
  return {
    ...file,
    renamedTo: willBeRenamedTo !== file.filename ? willBeRenamedTo : undefined,
  }
}
