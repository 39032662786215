import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { CTextField } from 'src/components/Form/Inputs'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

const schema = yup.object().shape({
  chairNote: yup.string(),
})

type FormValues = {
  chairNote: string
}

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
}

const ADD_SUBMISSION_CHAIR_NOTE = gql`
  mutation GraduateSchoolAddsSubmissionChairNote($submissionId: Int!, $chairId: Int!, $note: String) {
    graduateSchoolAddsSubmissionChairNote(submissionId: $submissionId, chairId: $chairId, note: $note)
  }
`

const ThesisChairSubmissionNoteDialog = ({ submission, onOpen, onClose }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)

  const [addSubmissionChairNoteMutation] = useMutation(ADD_SUBMISSION_CHAIR_NOTE, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm({
    defaultValues: {
      chairNote: submission.chair?.graduateSchoolNote || '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  const onConfirmUpdate = async (data: FormValues) => {
    setSaving(true)

    try {
      const result = await addSubmissionChairNoteMutation({
        variables: {
          submissionId: submission.id,
          chairId: submission.chair.id,
          note: data.chairNote,
        },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully updated the note for this committee Chair.", 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update note for this committee Chair. Please try again later.', 'error')
    }

    setSaving(false)
    onClose()
  }

  return (
    <>
      <Dialog
        open={onOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
        aria-describedby="dialogContent"
        aria-labelledby="dialogTitle"
        fullWidth
      >
        <DialogTitle id="dialogTitle" color="primary" variant="h2" component="h2">
          Chair of Examiners note (submission level)
        </DialogTitle>
        <Form formMethods={formMethods} onSubmit={onConfirmUpdate}>
          <DialogContent id="dialogContent">
            <Grid container>
              <Grid xs={12}>
                <Typography variant="body2">This is a private note visible to the Graduate School and Dean.</Typography>
              </Grid>
              <Grid xs={12}>
                <CTextField name="chairNote" minRows={5} multiline={true} fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => onClose()} disabled={saving}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={saving}>
              Update
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default ThesisChairSubmissionNoteDialog
