import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import PRINCIPAL_ADVISOR_UPDATES_EXAMINER_DETAILS from 'src/lib/graphql/mutations/PrincipalAdvisorUpdatesExaminerDetails'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import {
  ThesisSubmissionExaminerFormData,
  advisorAddExaminerValidationSchema,
  createAdvisorUpdateExaminerDetailsDefaultValues,
} from '../../helpers'

import AdvisorUpdateExaminerCOIForm from './AdvisorUpdateExaminerCOIForm'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
  readOnly?: boolean
}

/**
 * Principal Advisor conflict of interest form dialog
 */
const AdvisorUpdateExaminerCOIFormDialog = ({ examiner, submission, onOpen, onClose, readOnly }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState<boolean>(false)

  const [paUpdatesExaminerDetailsMutation] = useMutation(PRINCIPAL_ADVISOR_UPDATES_EXAMINER_DETAILS, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm<ThesisSubmissionExaminerFormData>({
    defaultValues: createAdvisorUpdateExaminerDetailsDefaultValues(examiner),
    resolver: yupResolver(advisorAddExaminerValidationSchema), //@TODO fix this error
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const hasValidationError = !formMethods.formState.isValid

  const onSubmit = async ({
    _checkboxGroup,
    ...rest
  }: ThesisSubmissionExaminerFormData & { _checkboxGroup: never }) => {
    setSaving(true)

    try {
      await paUpdatesExaminerDetailsMutation({
        variables: { id: examiner?.id, submissionId: submission.id, examinerData: rest },
      })

      showSnackbar(
        'Successfully updated examiner information. The Candidate will be notified and will submit to the Graduate School for review.',
        'success'
      )
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update examiner information. Please try again later.', 'error')
    }

    formMethods.reset()
    setSaving(false)
  }

  return (
    <Dialog
      open={onOpen}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' || readOnly) {
          onClose()
        }
      }}
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle color="primary" variant="h2" component="h2" id="dialogTitle" sx={{ mb: 1, display: 'none' }}>
        Examiner nomination
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogContent">
          <AdvisorUpdateExaminerCOIForm examiner={examiner} submission={submission} readOnly={readOnly} />
        </DialogContent>
        <DialogActions>
          <Grid xs={12}>
            <Grid container spacing={1} justifyContent={'flex-end'} flexDirection={'row'}>
              <Grid>
                <Button variant="contained" color="secondary" onClick={() => onClose()} disabled={saving}>
                  Cancel
                </Button>
              </Grid>
              {!readOnly && (
                <Grid>
                  <Button variant="contained" color="primary" type="submit" disabled={saving || hasValidationError}>
                    Update
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default AdvisorUpdateExaminerCOIFormDialog
