import {
  customIterationStateMessages,
  getIterationStateByCode,
  THESIS_ITERATION_STATE_CODE,
} from '@common/thesis/iterationState'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Initial submission - examiners reviewing thesis submission state.
 *
 * Chair view.
 */
const ExaminersReviewing = ({ submission, iterationStateCode }: Props) => {
  const cm = submission.currentIteration.hasOral
    ? getIterationStateByCode(iterationStateCode).chairMessage
    : {
        ...getIterationStateByCode(iterationStateCode).chairMessage,
        message: customIterationStateMessages.noOralExaminersReviewingChairMessage,
      }

  const stepperState = getInitialType(submission.currentIteration.hasOral) // Need to check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return <ThesisSubmissionMessage {...cm} {...stepperInfo} />
}

export default ExaminersReviewing
