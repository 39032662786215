import { Dialog, DialogContent } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetDeanActionListQuery } from 'types/graphql'

import OralExaminationChairSummaryForm from './OralExaminationChairSummaryForm'

type Props = {
  onOpen: boolean
  onClose: () => void
  input: GetDeanActionListQuery['getDeanActionList']['submissionsToBeApproved'][0]
  onRefetch: () => void
}

const OralExaminationChairSummaryDialog = ({ onOpen, onClose, input, onRefetch }: Props) => {
  return (
    <Dialog open={onOpen} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <OralExaminationChairSummaryForm
              onClose={onClose}
              input={input}
              submissionUuid={input?.submission?.uuid}
              onRefetch={onRefetch}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default OralExaminationChairSummaryDialog
