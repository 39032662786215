import { getIterationStateByCode, NotificationState, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'
import { Button } from '@mui/material'

import { routes } from '@redwoodjs/router'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Candidate submitting - revise and resubmit state.
 *
 * Chair view.
 */
const CandidateSubmittingReviseAndResubmit = ({ submission, iterationStateCode }: Props) => {
  const hasOral = submission.currentIteration.hasOral
  let cm: { title: string; message: string; action: NotificationState }

  if (hasOral) {
    cm = getIterationStateByCode(iterationStateCode).chairMessage
  } else {
    cm = {
      title: 'Candidate is revising their thesis',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis, but is not requiring the oral examination to be repeated. The candidate will have 12 months to complete the revisions and resubmit the thesis for examination. You will be notified via email when your action is required.',
      action: NotificationState.NO_ACTION,
    }
  }

  const stepperState = getInitialType(hasOral) // Need to check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      outcomeMessage={submission?.currentIteration?.currentStatus?.outcomeMessage}
      actions={[
        <Button key={0} variant="contained" href={routes.thesisChairDetails({ id: submission.reviseAndResubmitOfId })}>
          View previous submission
        </Button>,
      ]}
    />
  )
}

export default CandidateSubmittingReviseAndResubmit
