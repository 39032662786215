import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import ChairFeedbackSummaryButton from '../../ChairActions/ChairFeedbackSummaryButton/ChairFeedbackSummaryButton'
import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
  onReload: () => void
}

/**
 * Initial submission - Awaiting chair summary outcome state.
 *
 * Chair view.
 */
const AwaitingChairSummaryOutcome = ({ submission, iterationStateCode, onReload }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).chairMessage
  const stepperState = getInitialType(submission.currentIteration.hasOral) // Need chairMessage check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      actions={[
        <ChairFeedbackSummaryButton
          key={0}
          chair={submission.chair}
          submission={submission}
          onReload={onReload}
          hasAccess={true}
          isForThesisNotification={true}
        />,
      ]}
    />
  )
}

export default AwaitingChairSummaryOutcome
