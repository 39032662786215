import { DataGridProps, DataGrid as MuiDataGrid } from '@mui/x-data-grid'

const dataGridStyles = {
  '&.MuiDataGrid-root': { borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderRadius: 0 },
  '& .MuiDataGrid-columnHeadersInner': {
    '& .MuiDataGrid-columnHeader:last-child': {
      '& .MuiDataGrid-columnSeparator': { visibility: 'hidden' },
    },
  },
  '& .MuiDataGrid-cell': {
    alignItems: 'center',
    display: 'flex',
    minHeight: 36,
  },
}

interface CustomDataGridProps extends DataGridProps {
  pageSize?: number
}

const DataGrid = (props: CustomDataGridProps) => {
  const sx = {
    ...dataGridStyles,
    ...(props.onRowClick && {
      gridRow: { '&:hover': { cursor: 'pointer' } },
    }),
    ...(props.sx && {
      ...props.sx,
    }),
  }
  const density = props.density || 'compact'
  const disableRowSelectionOnClick =
    props.disableRowSelectionOnClick !== undefined ? props.disableRowSelectionOnClick : true
  const disableColumnMenu = props.disableColumnMenu !== undefined ? props.disableColumnMenu : true
  return (
    <MuiDataGrid
      {...props}
      sx={sx}
      density={density}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      disableColumnMenu={disableColumnMenu}
      autoHeight={true}
    />
  )
}

export default DataGrid
