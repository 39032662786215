import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Unstable_Grid2'

import MobileNotice from 'src/components/MobileNotice/MobileNotice'
import Notifications from 'src/components/Notifications/Notifications'
import RdmLogoWhite from 'src/images/rdm_logo_white'
import UqLogoWhite from 'src/images/uq_logo_white'

const PageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  background: 'linear-gradient(100deg, rgba(81,36,122,1) 70%, rgba(150,42,139,1) 100%)',
  color: 'white',
  minHeight: '100vh',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}))

const ContentWrapper = styled(Box)({
  width: '100%',
  maxWidth: 1200,
})

type SecondaryLayoutProps = {
  children?: React.ReactNode
  noNotifications?: boolean
}

const SecondaryLayout = ({ children, noNotifications }: SecondaryLayoutProps) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <RdmLogoWhite height={100} />
          <UqLogoWhite height={80} />
        </Grid>
        {noNotifications !== true && <Notifications />}
        {children}
      </ContentWrapper>
      <MobileNotice />
    </PageWrapper>
  )
}

export default SecondaryLayout
