import { ListItemButton, ListItemText, Typography } from '@mui/material'
import Highlighter from 'react-highlight-words'
import { GetOrganisationsQuery } from 'types/graphql'

type HighlightProps = {
  children: React.ElementType
}
const Highlight = ({ children }: HighlightProps) => {
  return (
    <Typography component="strong" sx={{ color: 'primary.main', fontWeight: '800' }}>
      {children}
    </Typography>
  )
}

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  organisation: GetOrganisationsQuery['organisations'][0],
  { inputValue }: { inputValue: string }
) => {
  if (organisation) {
    const ariaLabel = organisation.parent ? `${organisation.name} | ${organisation.parent.name}` : organisation.name
    return (
      <ListItemButton {...props} component="li" key={organisation.id} aria-label={ariaLabel}>
        <ListItemText
          primary={
            <Highlighter searchWords={[inputValue]} textToHighlight={organisation.name} highlightTag={Highlight} />
          }
          secondary={organisation.parent?.name}
        />
      </ListItemButton>
    )
  }
  return ''
}

export default renderOption
