import { useState } from 'react'

import { EXAMINER_REMOVED_STATUSES } from '@common/thesis/thesisExaminers/getThesisExaminerStatuses'
import { AddCircle, WarningAmber } from '@mui/icons-material'
import { Divider, IconButton, Tooltip, Typography } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import StyledHourglassIcon from 'src/components/Icons/HourGlassIcon'
import { isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

import AdvisorAddExaminerDialog from './components/AdvisorAddExaminer/AdvisorAddExaminerDialog'
import ExaminerActionMenu from './components/ExaminerActionMenu'
import GraduateSchoolAddExaminerDialog from './components/GraduateSchoolAddExaminer/GraduateSchoolAddExaminerDialog'
import RejectedExaminer from './components/RejectedExaminerSection/RejectedExaminer'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  query?: URLSearchParams
}

/**
 * Examiners section
 */
const ExaminersSection = ({ submission, query }: Props) => {
  const { currentUser, hasRole } = useAuth()
  const [openPAAddExaminerDialog, setOpenPAAddExaminerDialog] = useState<boolean>(false)
  const [openGSAddExaminerDialog, setOpenGSAddExaminerDialog] = useState<boolean>(false)

  const isAdvisor = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const isExaminer = currentUser.roles.includes('user-thesis-examiner')

  const examiners = submission?.thesisSubmissionExaminers.filter(
    (examiner) => !EXAMINER_REMOVED_STATUSES.has(examiner.status)
  )
  const unSuccessfulExaminers = submission?.thesisSubmissionExaminers.filter((examiner) =>
    EXAMINER_REMOVED_STATUSES.has(examiner.status)
  )
  const nrRequiredExaminers = examiners.length === 0 ? 2 : examiners.length === 1 ? 1 : 0
  const paCanAddExaminer = isAdvisor && examiners.length < 2
  const gsCanAddExaminer = hasRole('grad-school-user') && examiners.length < 2
  return (
    <>
      <Grid container>
        <Grid xs={6} display={'flex'} alignSelf={'center'}>
          <Typography variant="h4" sx={{ color: 'white', mb: 1 }}>
            Examiners:
          </Typography>
        </Grid>
        {paCanAddExaminer && (
          <Grid xs={6} display={'flex'} justifyContent={'end'}>
            <Tooltip title={'Nominate examiner'} placement="left">
              <IconButton onClick={() => setOpenPAAddExaminerDialog(true)} sx={{ mr: -1 }}>
                <AddCircle sx={{ color: '#00a2c7' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {gsCanAddExaminer && (
          <Grid xs={6} display={'flex'} justifyContent={'end'}>
            <Tooltip title={'Add examiner'} placement="left">
              <IconButton onClick={() => setOpenGSAddExaminerDialog(true)} sx={{ mr: -1 }}>
                <AddCircle sx={{ color: '#00a2c7' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      {/* Add examiners */}
      {paCanAddExaminer && (
        <Grid container spacing={1}>
          <Grid xs={'auto'}>
            <WarningAmber sx={{ color: 'warning.main' }} fontSize="inherit" />
          </Grid>
          <Grid xs>
            <Typography variant="body2" sx={{ color: 'warning.main', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              {`You must nominate ${nrRequiredExaminers} ${nrRequiredExaminers === 1 ? 'examiner' : 'examiners'}`}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Edit examiners */}
      {examiners.map((examiner, index) => {
        return <ExaminerActionMenu key={index} submission={submission} examiner={examiner} query={query} />
      })}
      {!isAdvisor &&
        Array.from({ length: nrRequiredExaminers }).map((_, index) => {
          return (
            <Grid
              container
              alignContent={'flex-start'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              sx={{ mb: 1 }}
              key={index}
            >
              <Grid xs="auto">
                <StyledHourglassIcon />
              </Grid>
              <Grid xs sx={{ ml: 1 }}>
                <Grid direction={'column'} container>
                  <Grid xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {'Principal Advisor to nominate'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      {/* UnsuccessfulExaminers */}
      {unSuccessfulExaminers.length > 0 && (
        <>
          <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.25)', my: 2 }} />
          <Typography variant="h4" sx={{ color: 'white', mb: 1 }}>
            Unsuccessful examiners:
          </Typography>
          <MenuList color="white" sx={{ p: 0 }}>
            {unSuccessfulExaminers.map((examiner, index) => {
              return <RejectedExaminer key={index} submission={submission} examiner={examiner} />
            })}
          </MenuList>
        </>
      )}

      {openPAAddExaminerDialog && (
        <AdvisorAddExaminerDialog
          onClose={() => setOpenPAAddExaminerDialog(false)}
          onOpen={openPAAddExaminerDialog}
          submission={submission}
        />
      )}
      {openGSAddExaminerDialog && (
        <GraduateSchoolAddExaminerDialog
          onClose={() => setOpenGSAddExaminerDialog(false)}
          onOpen={openGSAddExaminerDialog}
          submission={submission}
        />
      )}
    </>
  )
}

export default ExaminersSection
