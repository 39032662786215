import { Typography } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import PrincipalAdvisor from './PrincipalAdvisor'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

/**
 * Principal Advisor section
 */
const PrincipalAdvisorSection = ({ submission, onReload }: Props) => {
  return (
    <>
      <Typography variant="h4" sx={{ color: 'white', mb: 1 }}>
        Principal Advisor:
      </Typography>
      <PrincipalAdvisor submission={submission} onReload={onReload} />
    </>
  )
}

export default PrincipalAdvisorSection
