import { MenuItem } from '@mui/material'
import { MyRecordsQuery } from 'types/graphql'

import { useController, useFormContext } from '@redwoodjs/forms'
import { routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'

import { Select } from '../Base/Select'

type Props = {
  userRecordsSummary: MyRecordsQuery['userRecordsSummary']
  readOnly?: boolean
  onlyOwnedRecords?: boolean
  onlyLeadRecords?: boolean
}

const RecordSelectorInput = ({ userRecordsSummary, readOnly, onlyLeadRecords, onlyOwnedRecords }: Props) => {
  const { currentUser } = useAuth()
  const { field, fieldState } = useController({ name: 'record' })

  const { setError } = useFormContext()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(userRecordsSummary.find((r) => r.id === parseInt(event.target.value)))
    field.onBlur()
  }

  const records = userRecordsSummary.filter((r) => {
    const collab = r.collaborators.find((c) => c.user && c.user.id === currentUser.id)
    if (onlyLeadRecords && !collab.isLead) return false
    if (onlyOwnedRecords && !collab.isOwner) return false

    return true
  })

  return (
    <HelpWrapper
      disabled={readOnly}
      helpProps={{
        buttonSx: { mt: 2 },
        title: 'UQRDM Records',
        text: (
          <>
            Choose your UQRDM record that is connected to your thesis. This will prefill some of the information on this
            form.
            <br />
            <br />
            Your data in that record will not be accessed during the examination process.
            <br />
            <br />
            Your UQRDM record needs to have your student account as the owner, your Principal Advisor as the lead
            investigator, and needs to be marked as a HDR project. Collaborator roles and project metadata can be edited
            or updated from the record page by the owner or lead investigator of the project.
            <br />
            <br />
            If you do not have an active UQRDM record, create a new record by using the navigation menu on the left
            side.
            <br />
            <br />
            You can find information on how to create a record{' '}
            <ExternalLink href="https://guides.library.uq.edu.au/for-researchers/uq-research-data-manager/hdr-support">
              here
            </ExternalLink>
            . You can view your existing records <ExternalLink href={routes.dashboard()}>here</ExternalLink>.
          </>
        ),
      }}
    >
      <Select
        name="record"
        required
        label="Select the UQRDM record that is connected to your thesis"
        readOnly={readOnly}
        value={field.value ? field.value.id : ''}
        onChange={onChange}
        error={!!fieldState.error}
        helperText={fieldState.error ? fieldState.error.message : ''}
        onFocus={() =>
          records.length === 0 &&
          setError('record', {
            type: 'manual',
            message: 'You do not have an appropriate HDR record. Click the help icon to the right for more details.',
          })
        }
      >
        {records.map((r) => (
          <MenuItem key={r.id} value={r.id}>
            {r.uuid} - {r.name}
          </MenuItem>
        ))}
      </Select>
    </HelpWrapper>
  )
}

export default RecordSelectorInput
