import * as React from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import HelpButton from '../HelpButton/HelpButton'

interface Props {
  children?: React.ReactNode
  helpProps: { buttonSx?: object; title: string; text: React.ReactNode }
  disabled?: boolean
  version?: string
  sx?: object
}

export default function HelpWrapper({ helpProps, children, disabled, sx, version }: Props) {
  return disabled ? (
    <>{children}</>
  ) : (
    <Grid container alignItems="center" sx={sx} columnSpacing={0} justifyContent="space-between">
      <Grid xs>{children}</Grid>
      <HelpButton {...helpProps} version={version} />
    </Grid>
  )
}
