import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: () => unknown
}

/**
 * Chair approves a thesis corrections dialog
 */
const ChairApprovesCorrectionsDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  return (
    <Dialog open={open} onClose={onCancel} aria-describedby="dialogcontent" aria-labelledby="dialogtitle">
      <DialogTitle id="dialogtitle" variant="h2" component="h3" color="primary">
        Approve thesis submission
      </DialogTitle>
      <DialogContent id="dialogcontent">
        Approving the corrected thesis will send the submission to the Dean to review and approve or request further
        corrections.
      </DialogContent>
      <DialogActions>
        {!saving && (
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}

        <LoadingButton variant="contained" color="primary" onClick={onConfirm} loading={saving}>
          Approve submission
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ChairApprovesCorrectionsDialog
