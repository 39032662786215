import { useCallback, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Alert, Box } from '@mui/material'
import { ThesisFileType } from '@prisma/client'
import AwsS3 from '@uppy/aws-s3'
import Uppy, { UppyFile } from '@uppy/core'

import { useController, useFormContext, useWatch } from '@redwoodjs/forms'

import { useAuth } from 'src/auth'
import GET_PRESIGNED_URL_EXAMINER_FILE_UPLOAD from 'src/lib/graphql/queries/GetPresignedUrlExaminerFileUpload'

import { getThesisFileTableValues } from '../helpers'

import ThesisFilesTable from './ThesisFilesTable'
import ThesisFilesUploadButton from './ThesisFilesUploadButton'
import ThesisFilesUploadModal from './ThesisFilesUploadModal'

const uppy = new Uppy({
  id: 'uppy-upload-thesis-examiner-review-file',
  locale: {
    strings: {
      youCanOnlyUploadFileTypes: 'Please upload your document in a PDF format.',
    },
  },
  restrictions: {
    maxFileSize: 5_000_000_000,
    allowedFileTypes: ['application/pdf'],
    maxNumberOfFiles: 1,
  },
  autoProceed: true,
  allowMultipleUploadBatches: false,
}).use(AwsS3)

type Props = {
  readOnly: boolean
  examinerId: number
}

const ThesisExaminerReviewFile = ({ readOnly, examinerId }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { currentUser } = useAuth()
  const { trigger } = useFormContext()
  const { field, fieldState } = useController({ name: 'examinerReviewFile' })
  const apolloClient = useApolloClient()
  const thesisType = useWatch({ name: 'type' })

  const onUploadSuccess = useCallback(
    (file: UppyFile) => {
      field.onChange({
        key: file.meta.key as string,
        filename: file.name,
      })
      trigger('examinerReviewFile')
      field.onBlur()
    },
    [field]
  )

  useEffect(() => {
    uppy.on('upload-success', onUploadSuccess)

    uppy.getPlugin('AwsS3').setOptions({
      getUploadParameters(file) {
        // Fetch presigned URL from the API for this file
        return apolloClient
          .query({
            query: GET_PRESIGNED_URL_EXAMINER_FILE_UPLOAD,
            variables: { filename: file.name, contentType: file.type, examinerId },
          })
          .then((response) => {
            return {
              method: 'put',
              url: response.data.getPresignedUrlExaminerFileUpload.presignedUrl,
              fields: { key: response.data.getPresignedUrlExaminerFileUpload.key },
              headers: { 'Content-Type': file.type },
            }
          })
      },
    })
  }, [apolloClient, onUploadSuccess])

  const onRemoveUploadedFile = () => {
    field.onChange(null)
  }

  const fieldValue = field.value
    ? getThesisFileTableValues(
        [field.value],
        ThesisFileType.EXAMINER_REVIEW_FILE,
        currentUser.eduPersonPrincipalName,
        thesisType
      )
    : []

  return (
    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)', br: 5 }}>
      <Box sx={{ p: 1 }}>
        <ThesisFilesTable
          uploadedFiles={fieldValue}
          onRemove={onRemoveUploadedFile}
          fileTitle="Thesis examiner review file *"
          noFilesMessage="No thesis examiner review document uploaded"
          readOnly={readOnly}
          fileType={ThesisFileType.EXAMINER_REVIEW_FILE}
          examinerId={examinerId}
        />
        {fieldState.error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {fieldState.error.message}
          </Alert>
        )}
        {!field.value && !readOnly && (
          <ThesisFilesUploadButton label="Upload thesis examiner review document" onClick={() => setDialogOpen(true)} />
        )}
        <ThesisFilesUploadModal
          uppy={uppy}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false)
            uppy.cancelAll()
          }}
        />
      </Box>
    </Box>
  )
}

export default ThesisExaminerReviewFile
