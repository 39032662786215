import embargoTypes from '@common/thesis/embargoTypes'
import { MenuItem } from '@mui/material'

import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'
import embargoTypeGuidance from 'src/lib/guidance/thesis/embargoTypeGuidance'

import { CSelect, Select, SelectProps } from '../Base/Select'

type ControlledProps = {
  name: string
  readOnly: boolean
}

export const ControlledThesisEmbargoType = ({ name, readOnly }: ControlledProps) => {
  return (
    <HelpWrapper helpProps={embargoTypeGuidance}>
      <CSelect name={name} label="Embargo status" readOnly={readOnly}>
        {embargoTypes.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </CSelect>
    </HelpWrapper>
  )
}

export const ThesisEmbargoType = (props: SelectProps) => {
  return (
    <HelpWrapper helpProps={embargoTypeGuidance}>
      <Select label="Embargo status" {...props} readOnly={props.readOnly}>
        {embargoTypes.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </HelpWrapper>
  )
}
