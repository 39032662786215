const GET_ORGANISATIONS_QUERY = gql`
  query GetOrganisationsQuery($searchString: String!) {
    organisations(searchString: $searchString) {
      id
      name
      isActive
      parent {
        name
      }
    }
  }
`

export default GET_ORGANISATIONS_QUERY
