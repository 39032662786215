import { InfoOutlined } from '@mui/icons-material'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { useAuth } from 'src/auth'
import Tooltip from 'src/components/Tooltip/Tooltip'
import { toFullTimezone } from 'src/lib/toTimezone'

import { ThesisSubmissionHistoryEntry } from './ThesisHistoryDialog'

type Props = {
  entries: ThesisSubmissionHistoryEntry['entries']
}

const ThesisHistoryStatusTable = ({ entries }: Props) => {
  const { currentUser } = useAuth()

  const reversedEntries = [...entries].reverse()

  // Dean Graduate School and Chair
  const isDean = currentUser.roles.includes('dean-user')
  const isChair = currentUser.roles.includes('user-thesis-chair') // @TODO On the hdr details page we already do access check for the right chair. Future improvement: check if the user is chair of this thesis
  const isGradSchool = currentUser.roles.includes('grad-school-user')

  const cannotSeeDeanRequestsChairCorrectionsMsg = !(isDean || isChair || isGradSchool)

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Event</TableCell>
          <TableCell></TableCell>
          <TableCell>Event by</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reversedEntries.map((entry, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{toFullTimezone(entry.createdAt)}</TableCell>
              <TableCell>{entry.event}</TableCell>
              <TableCell sx={{ pt: 0, pb: 0 }}>
                {entry.eventDetails && (
                  <>
                    {/* @TODO very dirty way of doing it. Will have to be updated. During additional corrections requested by dean for the chair, only GS, Dean and Chair can see the msg. */}
                    {entry.event === 'Dean has requested additional corrections' &&
                    cannotSeeDeanRequestsChairCorrectionsMsg ? null : (
                      <Tooltip title={<span>{entry.eventDetails}</span>}>
                        <InfoOutlined color="primary" sx={{ fontSize: 22, mt: 0.5 }} />
                      </Tooltip>
                    )}
                  </>
                )}
              </TableCell>
              <TableCell>
                {entry.eventByName}
                {entry.mimickedByName && isGradSchool && (
                  <Tooltip title={`Mimicked by ${entry.mimickedByName}`}>
                    <InfoOutlined color="primary" fontSize="small" sx={{ verticalAlign: 'middle', ml: 1 }} />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default ThesisHistoryStatusTable
