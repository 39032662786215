const GRADUATE_SCHOOL_UPDATES_EXAMINER_DETAILS = gql`
  mutation GraduateSchoolUpdatesExaminerDetails(
    $id: Int!
    $submissionId: Int!
    $examinerData: GraduateSchoolUpdatesExaminerDetailsInput!
  ) {
    graduateSchoolUpdatesExaminerDetails(id: $id, submissionId: $submissionId, examinerData: $examinerData)
  }
`

export default GRADUATE_SCHOOL_UPDATES_EXAMINER_DETAILS
