import { ThesisSubmissionExaminer } from 'types/graphql'

import { EXAMINER_ASSIGNED_STATUSES, EXAMINER_REMOVED_STATUSES } from './getThesisExaminerStatuses'
import { isExaminerAddedByGraduateSchool } from './helper'

export const getApprovedExaminerCount = (examiners: Pick<ThesisSubmissionExaminer, 'status'>[]) => {
  return examiners.reduce((acc, examiner) => {
    if (EXAMINER_ASSIGNED_STATUSES.has(examiner.status)) {
      return acc + 1
    }

    return acc
  }, 0)
}

export const getNominatedExaminerCount = (examiners: Pick<ThesisSubmissionExaminer, 'status'>[]) => {
  return examiners.reduce((acc, examiner) => {
    if (!EXAMINER_REMOVED_STATUSES.has(examiner.status)) {
      return acc + 1
    }

    return acc
  }, 0)
}

export const getApprovedAndPendingInvitationAcceptanceExaminerCount = (
  examiners: Pick<ThesisSubmissionExaminer, 'status' | 'advisorCOIStatements' | 'candidateCOIStatements'>[]
) => {
  return examiners.reduce((acc, examiner) => {
    if (EXAMINER_ASSIGNED_STATUSES.has(examiner.status) || isExaminerAddedByGraduateSchool(examiner)) {
      return acc + 1
    }

    return acc
  }, 0)
}
