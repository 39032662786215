import { Box } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'

import Tooltip from 'src/components/Tooltip/Tooltip'

const renderTooltipCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value} placement="right">
      <Box component={'span'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {params.value}
      </Box>
    </Tooltip>
  )
}

export const renderLineWrappedTooltipCell = (lines: number) => (params: GridRenderCellParams) => {
  return (
    <>
      <Tooltip title={params.value} placement="right">
        <Box
          component={'div'}
          sx={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            whiteSpace: 'break-spaces',
            WebkitLineClamp: lines,
            WebkitBoxOrient: 'vertical',
            lineHeight: 1.25,
          }}
        >
          {params.value}
        </Box>
      </Tooltip>
    </>
  )
}

export default renderTooltipCell
