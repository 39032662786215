import {
  EXAMINER_ASSIGNED_STATUSES,
  EXAMINER_REMOVED_STATUSES,
} from '@common/thesis/thesisExaminers/getThesisExaminerStatuses'
import { ThesisSubmissionExaminerStatus } from 'types/graphql'

import { CurrentUser } from '@redwoodjs/auth'

// @TODO here we uses userId but it's not clear what userId it is so need to just be a variable of examiner, advisor, chair, candidate

/**
 * Checks if the current user is the assigned Principal Advisor of the thesis
 */
export const isAssignedThesisPrincipalAdvisor = (currentUser: CurrentUser, userId: number) => {
  return currentUser.roles.includes('user-thesis-principal-advisor') && currentUser.id === userId
}

/**
 * Checks if the current user is the candidate of the thesis
 */
export const isAssignedThesisCandidate = (currentUser: CurrentUser, userId: number) => {
  return currentUser.roles.includes('user-thesis-submission') && currentUser.id === userId
}

/**
 * Checks if the current user is the assigned chair of the thesis
 */
export const isAssignedThesisChair = (currentUser: CurrentUser, userId: number) => {
  return currentUser.roles.includes('user-thesis-chair') && currentUser.id === userId
}

/**
 * Checks if the current user is an examiner that is in pending approval
 */
export const isPendingThesisExaminer = (
  currentUser: CurrentUser,
  userId: number,
  status: ThesisSubmissionExaminerStatus
) => {
  return (
    currentUser.roles.includes('user-thesis-examiner') &&
    currentUser.id === userId &&
    !EXAMINER_ASSIGNED_STATUSES.has(status) &&
    !EXAMINER_REMOVED_STATUSES.has(status)
  )
}

/**
 * Checks if the current user is an non-rejected or removed examiner of the thesis
 */
export const isActiveThesisExaminer = (
  currentUser: CurrentUser,
  userId: number,
  status: ThesisSubmissionExaminerStatus
) => {
  return (
    currentUser.roles.includes('user-thesis-examiner') &&
    currentUser.id === userId &&
    !EXAMINER_REMOVED_STATUSES.has(status)
  )
}

/**
 * Checks if the current user is an approved examiner of the thesis
 */
export const isAssignedThesisExaminer = (
  currentUser: CurrentUser,
  userId: number,
  status: ThesisSubmissionExaminerStatus
) => {
  return (
    currentUser.roles.includes('user-thesis-examiner') &&
    currentUser.id === userId &&
    EXAMINER_ASSIGNED_STATUSES.has(status)
  )
}

/**
 * Checks if the current user is a rejected examiner of the thesis
 */
export const isRejectedThesisExaminer = (
  currentUser: CurrentUser,
  userId: number,
  status: ThesisSubmissionExaminerStatus
) => {
  return (
    currentUser.roles.includes('user-thesis-examiner') &&
    currentUser.id === userId &&
    EXAMINER_REMOVED_STATUSES.has(status)
  )
}
