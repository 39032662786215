import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'

import { CTextField } from 'src/components/Form/Inputs'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: (outcomeMessage: string) => unknown
}

/**
 * Dean requests corrections from the candidate dialog.
 */
const DeanRequestsCorrectionsFromChairDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  const formMethods = useForm({ defaultValues: { message: '' } })

  const onSubmit = (data) => {
    onConfirm(data.message)
    formMethods.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
    >
      <DialogTitle color="primary" component="h2" variant="h2" id="dialogTitle">
        Request additional corrections
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogContent">
          <Typography variant="body1" sx={{ mb: 1 }}>
            If you require additional corrections to the thesis or have any concerns for the Chair of Examiners, please
            detail them below. Your message will only be seen by the Chair, where they can request further corrections
            from the candidate.
          </Typography>
          <CTextField name="message" label="Message" multiline={true} rows={10} maxLength={2000} />
        </DialogContent>
        <DialogActions>
          {!saving && (
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}

          <LoadingButton variant="contained" color="primary" loading={saving} type="submit">
            Request corrections
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default DeanRequestsCorrectionsFromChairDialog
