import { Typography } from '@mui/material'
import { ThesisSubmission, ThesisSubmissionExaminer } from 'types/graphql'

import BasicExaminerDetails from './BasicExaminerDetails'
import ConflictOfInterestDisclosureDetails from './ConflictOfInterestDisclosureDetails'

interface Props {
  readOnly?: boolean
  examiner: Pick<
    ThesisSubmissionExaminer,
    | 'status'
    | 'fullName'
    | 'mail'
    | 'position'
    | 'institution'
    | 'rationaleForNomination'
    | 'examinerCOIStatements'
    | 'examinerCOIDetails'
    | 'advisorCOIStatements'
    | 'advisorCOIDetails'
    | 'candidateCOIStatements'
    | 'candidateCOIDetails'
  > & {
    user?: {
      displayName: string
      mail: string
    }
  }
  submission: Pick<ThesisSubmission, 'id'> & {
    principalAdvisor?: {
      displayName: string
      mail: string
    }
    creator?: {
      displayName: string
      mail: string
    }
  }
}

/**
 * Examiner COI details view only component.
 */
const ExaminerCOIOverallDetailsViewer = ({ examiner, submission, readOnly = false }: Props) => {
  return (
    <>
      <BasicExaminerDetails examiner={examiner} readOnly={readOnly} />
      <Typography variant="h2" component="h2" color="primary" sx={{ mt: 2, ml: 1 }}>
        Conflict of interest disclosures
      </Typography>
      <ConflictOfInterestDisclosureDetails examiner={examiner} submission={submission} />
    </>
  )
}

export default ExaminerCOIOverallDetailsViewer
