import { useApolloClient } from '@apollo/client'
import { Delete, Download } from '@mui/icons-material'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ThesisFileType } from '@prisma/client'

import HelpButton from 'src/components/HelpButton/HelpButton'
import Tooltip from 'src/components/Tooltip/Tooltip'
import EXISTING_FILE_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { toFullTimezone } from 'src/lib/toTimezone'

import { UploadedFile } from '../helpers'

const QUERY = gql`
  query GetPresignedUrlTemporaryThesisFileDownload($key: String!, $fileCreatorUserId: Int) {
    getPresignedUrlTemporaryThesisFileDownload(key: $key, fileCreatorUserId: $fileCreatorUserId)
  }
`

const GET_PRESIGNED_URL_TEMP_EXAMINER_FILE_DOWNLOAD = gql`
  query GetPresignedUrlTemporaryExaminerFileDownload($key: String!, $examinerId: Int!) {
    getPresignedUrlTemporaryExaminerFileDownload(key: $key, examinerId: $examinerId)
  }
`

type Props = {
  fileTitle: string
  noFilesMessage: string
  uploadedFiles: UploadedFile[]
  onRemove: (key: string) => void
  readOnly: boolean
  fileType?: ThesisFileType
  help?: any
  fileCreatorUserId?: number
  examinerId?: number
}

const ThesisFilesTable = ({
  fileTitle,
  noFilesMessage,
  uploadedFiles,
  onRemove,
  readOnly,
  fileType,
  help,
  fileCreatorUserId,
  examinerId,
}: Props) => {
  const apolloClient = useApolloClient()
  const showSnackbar = useSnackbar()
  const onDownloadFile = async (uploadedFile: UploadedFile) => {
    const hasBeenSubmitted = 'submissionDate' in uploadedFile

    try {
      // Temp Thesis and iThenticate files do not have id.
      // Temp Supplementary files have id (RHF useFieldArray automatically generated uuid as id) but no submissionDate.
      if (!uploadedFile.id || !hasBeenSubmitted) {
        // Require to have a get api for examiners due to the role restrictions
        if (
          fileType === ThesisFileType.EXAMINER_REVIEW_FILE ||
          fileType === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE
        ) {
          const response = await apolloClient.query({
            query: GET_PRESIGNED_URL_TEMP_EXAMINER_FILE_DOWNLOAD,
            variables: { key: uploadedFile.key, examinerId },
          })
          window.open(response.data.getPresignedUrlTemporaryExaminerFileDownload, '_blank')
        } else {
          const response = await apolloClient.query({
            query: QUERY,
            variables: { key: uploadedFile.key, fileCreatorUserId: fileCreatorUserId },
          })
          window.open(response.data.getPresignedUrlTemporaryThesisFileDownload, '_blank')
        }
      } else {
        const response = await apolloClient.query({
          query: EXISTING_FILE_QUERY,
          variables: { fileId: uploadedFile.id },
        })
        window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
      }
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  return (
    <TableContainer component={Paper} sx={{ p: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{fileTitle}</TableCell>
            <TableCell width={200}>Uploaded on</TableCell>
            <TableCell width={125}>
              Actions {help && <HelpButton dense title={help.title} text={help.text} />}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uploadedFiles.map((uploadedFile) => (
            <TableRow key={uploadedFile.key}>
              <TableCell sx={{ maxWidth: 10, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Tooltip title={uploadedFile?.filename} arrow>
                  <Box
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      maxHeight: '3em',
                    }}
                  >
                    {uploadedFile.filename}
                  </Box>
                </Tooltip>
                {uploadedFile.renamedTo && (
                  <span
                    style={{
                      color: '#333',
                      fontSize: 12,
                      maxWidth: 10,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    This file will be renamed to {uploadedFile.renamedTo}
                  </span>
                )}
              </TableCell>
              <TableCell>
                {uploadedFile.submissionDate ? toFullTimezone(uploadedFile.submissionDate) : 'Not yet submitted'}
              </TableCell>
              <TableCell>
                <Tooltip title="Download file">
                  <IconButton onClick={() => onDownloadFile(uploadedFile)} color="primary">
                    <Download />
                  </IconButton>
                </Tooltip>
                {!readOnly && (
                  <Tooltip title="Remove file">
                    <IconButton onClick={() => onRemove(uploadedFile.key)} color="secondary">
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
          {uploadedFiles.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>{noFilesMessage}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ThesisFilesTable
