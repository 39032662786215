const THESIS_SUBMISSION_HISTORY_QUERY = gql`
  query ThesisSubmissionHistoryQuery($id: Int!) {
    thesisSubmissionHistory(id: $id) {
      id
      number
      hasFiles
      entries {
        type
        event
        eventDetails
        eventByName
        mimickedByName
        createdAt
      }
    }
  }
`

export default THESIS_SUBMISSION_HISTORY_QUERY
