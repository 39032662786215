import { useRef, useState } from 'react'

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ExaminerCOIOverallDetailsViewer from './components/ExaminerCOIOverallDetailsViewer/ExaminerCOIOverallDetailsViewer'

const options = ['Approve', 'Request more information', 'Request Dean review', 'Reject']

const REQUEST_EXAMINER_COI_REVIEW = gql`
  mutation GraduateSchoolRequestsExaminerForCOIReview($id: Int!) {
    graduateSchoolRequestsExaminerForCOIReview(id: $id)
  }
`

const EXAMINER_FINAL_APPROVAL = gql`
  mutation graduateSchoolExaminerFinalApproval($id: Int!) {
    graduateSchoolExaminerFinalApproval(id: $id)
  }
`

const REJECT_EXAMINER = gql`
  mutation GraduateSchoolRejectsExaminer($id: Int!, $submissionId: Int!, $outcomeMessage: String) {
    graduateSchoolRejectsExaminer(id: $id, submissionId: $submissionId, outcomeMessage: $outcomeMessage)
  }
`

const REQUEST_PRINCIPAL_ADVISOR_MORE_INFO = gql`
  mutation graduateSchoolRequestsPrincipalAdvisorMoreInfo($id: Int!, $submissionId: Int!, $outcomeMessage: String) {
    graduateSchoolRequestsPrincipalAdvisorMoreInfo(
      id: $id
      submissionId: $submissionId
      outcomeMessage: $outcomeMessage
    )
  }
`

const REQUEST_DEAN_COI_REVIEW = gql`
  mutation GraduateSchoolRequestsDeanCOIReview($id: Int!, $submissionId: Int!) {
    graduateSchoolRequestsDeanCOIReview(id: $id, submissionId: $submissionId)
  }
`

const REQUEST_EXAMINER_MORE_INFO = gql`
  mutation GraduateSchoolRequestsExaminerMoreInfo($id: Int!, $submissionId: Int!, $outcomeMessage: String) {
    graduateSchoolRequestsExaminerMoreInfo(id: $id, submissionId: $submissionId, outcomeMessage: $outcomeMessage)
  }
`

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
}

/**
 * Examiner approval dialog.
 */
const ThesisExaminerApprovalDialog = ({ examinerData, submission, onOpen, onClose }: Props) => {
  const showSnackbar = useSnackbar()
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [saving, setSaving] = useState(false)
  const [confirmReject, setConfirmReject] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [changeRequestReason, setChangeRequestReason] = useState('')
  const [confirmRequestChanges, setConfirmRequestChanges] = useState(false)
  const [confirmRequestDeanReview, setConfirmRequestDeanReview] = useState(false)
  const [confirmApprove, setConfirmApprove] = useState(false)

  const isPendingGSInitialApproval = examinerData.status === 'GRADUATE_SCHOOL_INITIAL_REVIEW'

  const [requestExaminerCOIReviewMutation] = useMutation(REQUEST_EXAMINER_COI_REVIEW, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [examinerFinalApprovalMutation] = useMutation(EXAMINER_FINAL_APPROVAL, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [rejectExaminerMutation] = useMutation(REJECT_EXAMINER, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [requestPrincipalAdvisorMoreInfo] = useMutation(REQUEST_PRINCIPAL_ADVISOR_MORE_INFO, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [requestDeanCOIReviewMutation] = useMutation(REQUEST_DEAN_COI_REVIEW, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [requestExaminerMoreInfoMutation] = useMutation(REQUEST_EXAMINER_MORE_INFO, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const onConfirmApprove = async () => {
    setSaving(true)
    try {
      const result = await requestExaminerCOIReviewMutation({ variables: { id: examinerData.id } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully approved this examiner.", 'success')
      setConfirmApprove(false)
      onClose()
      setSaving(false)
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not approve examiner. Please try again later.', 'error')
    }
  }

  const onConfirmFinalApprove = async () => {
    setSaving(true)
    try {
      const result = await examinerFinalApprovalMutation({ variables: { id: examinerData.id } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully approved this examiner.", 'success')
      setConfirmApprove(false)
      onClose()
      setSaving(false)
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not approve examiner. Please try again later.', 'error')
    }
  }

  const onConfirmReject = async () => {
    setSaving(true)
    try {
      const result = await rejectExaminerMutation({
        variables: {
          id: examinerData.id,
          submissionId: examinerData.submissionId,
          outcomeMessage: rejectionReason.trim(),
        },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully rejected this examiner.", 'success')
      setConfirmApprove(false)
      onClose()
      setSaving(false)
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not reject examiner. Please try again later.', 'error')
    }
  }

  const onConfirmRequestChanges = async () => {
    setSaving(true)

    try {
      let result = undefined
      if (isPendingGSInitialApproval) {
        result = await requestPrincipalAdvisorMoreInfo({
          variables: {
            id: examinerData.id,
            submissionId: examinerData.submissionId,
            outcomeMessage: changeRequestReason.trim(),
          },
        })
      } else if (examinerData.status === 'GRADUATE_SCHOOL_FINAL_REVIEW') {
        result = await requestExaminerMoreInfoMutation({
          variables: {
            id: examinerData.id,
            submissionId: examinerData.submissionId,
            outcomeMessage: changeRequestReason.trim(),
          },
        })
      }

      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully requested changes.", 'success')
      setConfirmApprove(false)
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not request changes. Please try again later.', 'error')
    }

    setSaving(false)
  }

  const onConfirmRequestDeanReview = async () => {
    setSaving(true)

    try {
      let result = undefined
      if (isPendingGSInitialApproval) {
        result = await requestDeanCOIReviewMutation({
          variables: { id: examinerData.id, submissionId: examinerData.submissionId },
        })
      }

      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully requested review from Dean.", 'success')
      setConfirmApprove(false)
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not request review from Dean. Please try again later.', 'error')
    }

    setSaving(false)
  }

  const handleClick = () => {
    if (options[selectedIndex] === 'Approve') {
      setConfirmApprove(true)
    }
    if (options[selectedIndex] === 'Request more information') {
      setConfirmRequestChanges(true)
    }
    if (options[selectedIndex] === 'Request Dean review' && isPendingGSInitialApproval) {
      setConfirmRequestDeanReview(true)
    }
    if (options[selectedIndex] === 'Reject') {
      setConfirmReject(true)
    }
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }
  return (
    <>
      <Dialog
        open={onOpen}
        onClose={() => onClose()}
        aria-describedby="dialogContent"
        aria-labelledby="dialogTitle"
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle color="primary" component="h2" variant="h2" id="dialogTitle">
          Review conflict of interest disclosures
        </DialogTitle>
        <DialogContent id="dialogContent">
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid xs={12} sx={{ pb: 2 }}>
                <Typography variant="body1" component="p">
                  {`Review the provided details for the examiner nomination and select an action below. Additional
                information can be requested from the ${
                  isPendingGSInitialApproval
                    ? 'candidate or Principal Advisor or you can request review by the Dean of the Graduate School.'
                    : 'examiner.'
                }
              `}
                </Typography>
              </Grid>
              <ExaminerCOIOverallDetailsViewer examiner={examinerData} submission={submission} />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: 2, pb: 2 }}>
          <Button variant="contained" color="secondary" onClick={() => onClose()} /* disabled={!!loading} */>
            Cancel
          </Button>

          <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu">
            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select decision action"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => {
                        if (option === 'Request Dean review' && !isPendingGSInitialApproval) {
                          return null
                        }

                        return (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                          >
                            {option}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        confirmLabel="Approve"
        open={confirmApprove}
        loading={saving}
        onClose={() => setConfirmApprove(false)}
        onConfirm={isPendingGSInitialApproval ? onConfirmApprove : onConfirmFinalApprove}
        title="Approve examiner"
      >
        {`${
          isPendingGSInitialApproval
            ? 'You are providing initial approval. The examiner will be notified and supply COI disclosure.'
            : 'This is the final examiner nomination approval. By approving the examiner you confirm that all details and COI disclosures are acceptable for thesis examination. The examiner will be notified of their acceptance and will await the initiation of the thesis examination.'
        }`}
      </ConfirmDialog>
      <ConfirmDialog
        open={confirmReject}
        loading={saving}
        onClose={() => {
          setConfirmReject(false)
          setRejectionReason('')
        }}
        onConfirm={onConfirmReject}
        title="Reject examiner"
      >
        <Grid container spacing={2}>
          <Grid xs={12}>Please provide reasoning on the outcome, Principal Advisor will be notified.</Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Reason for rejection"
              multiline
              rows={4}
              variant="outlined"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      <ConfirmDialog
        open={confirmRequestChanges}
        confirmLabel="Request"
        loading={saving}
        onClose={() => {
          setConfirmRequestChanges(false)
          setChangeRequestReason('')
        }}
        onConfirm={onConfirmRequestChanges}
        title="Request more information"
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            You can request more information about the examiner or COI disclosures.{' '}
            {examinerData?.status === 'GRADUATE_SCHOOL_INITIAL_REVIEW' &&
              'This request will go to Principal Advisor first and then will be sent to the candidate.'}{' '}
            Please explain below what needs to be changed or amended.
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Please explain what needs to be changed or amended here"
              multiline
              rows={4}
              variant="outlined"
              value={changeRequestReason}
              onChange={(e) => setChangeRequestReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      {isPendingGSInitialApproval && (
        <ConfirmDialog
          confirmLabel="Request"
          open={confirmRequestDeanReview}
          loading={saving}
          onClose={() => {
            setConfirmRequestDeanReview(false)
          }}
          onConfirm={onConfirmRequestDeanReview}
          title="Request conflict of interest review by Dean"
        >
          The <abbr>COI</abbr> will be assigned to the Dean for review. If urgent review is required, please contact
          them directly.
        </ConfirmDialog>
      )}
    </>
  )
}

export default ThesisExaminerApprovalDialog
