import { useEffect, useState } from 'react'

import { YES_NO_INPUTS } from '@common/thesis/thesisExaminers/examinationFormData'
import { THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER } from '@common/thesis/thesisExaminers/examinerNominationData'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { CCheckbox, CRadioGroup, CTextField } from 'src/components/Form/Inputs'
import HelpButton from 'src/components/HelpButton/HelpButton'
import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'
import Link from 'src/components/Link/Link'
import EXAMINER_SUBMITS_COI from 'src/lib/graphql/mutations/ExaminerSubmitsCOI'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import examinerConfidentialityAgreement from 'src/lib/guidance/thesis/examinerConfidentialityAgreement'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import { ExaminerCOIDefaultValues, examinerCOIFormValidationSchema } from './schema'

export interface ExaminerFormData {
  publishedWithCandidate: boolean
  grantWithAdvisor: boolean
  personalOrProfessionalRelationship: boolean
  consultedOnThesis: boolean
  otherCOI: boolean
  examinerCOIDetails: string
}

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
  readOnly?: boolean
}

/**
 * Examiner COI details form dialog
 */
const ThesisExaminerDetailsFormDialog = ({ examinerData, submission, onOpen, onClose, readOnly }: Props) => {
  const showSnackbar = useSnackbar()
  const [isPending, setIsPending] = useState<boolean>(false)

  const [updateDetailMutation] = useMutation(EXAMINER_SUBMITS_COI, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm({
    defaultValues: {
      ...ExaminerCOIDefaultValues(examinerData),
      _checkboxGroup: [false],
    },
    resolver: yupResolver(examinerCOIFormValidationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const needsPayment = formMethods.watch('requirePayment')
  const publishedWithCandidate = formMethods.watch('publishedWithCandidate')
  const grantWithAdvisor = formMethods.watch('grantWithAdvisor')
  const personalOrProfessionalRelationship = formMethods.watch('personalOrProfessionalRelationship')
  const consultedOnThesis = formMethods.watch('consultedOnThesis')
  const otherCOI = formMethods.watch('otherCOI')
  const showExaminerCOIDetailsInput =
    publishedWithCandidate || grantWithAdvisor || personalOrProfessionalRelationship || consultedOnThesis || otherCOI

  useEffect(() => {
    if (!needsPayment) {
      formMethods.setValue('bankAccountType', null)
    }

    if (!showExaminerCOIDetailsInput) {
      formMethods.setValue('examinerCOIDetails', null)
    } else {
      formMethods.setValue('examinerCOIDetails', examinerData.examinerCOIDetails)
    }
  }, [needsPayment, showExaminerCOIDetailsInput])

  const onSubmit = async ({ _checkboxGroup, ...inputData }: ExaminerFormData & { _checkboxGroup: never }) => {
    setIsPending(true)

    try {
      await updateDetailMutation({
        variables: { id: examinerData.id, submissionId: submission.id, input: inputData },
      })
      showSnackbar('Successfully updated your information. Graduate School has been notified for review.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update your information. Please try again later.', 'error')
    }

    setIsPending(false)
    onCloseDialog()
    navigate(routes.thesisExaminerDetails({ id: submission.id }))
  }

  const onCloseDialog = () => {
    formMethods.reset()
    onClose()
  }

  const hasValidationError = !formMethods.formState.isValid
  const hasExaminerCOIStatements =
    examinerData?.examinerCOIStatements && Object.keys(examinerData?.examinerCOIStatements).length !== 0

  return (
    <>
      <Dialog
        open={onOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick' || readOnly) {
            onCloseDialog()
          }
        }}
        aria-describedby="dialogContent"
        aria-labelledby="dialogTitle"
        maxWidth="lg"
      >
        <DialogTitle variant="h2" component="h2" color="primary" id="dialogTitle">
          Contact details and COI disclosure
        </DialogTitle>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <DialogContent id="dialogContent" sx={{ pt: 0 }}>
            <Grid container spacing={2}>
              {!readOnly && (
                <Grid xs={12}>
                  {examinerData.status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL' ? (
                    <>
                      <Typography variant="body2" mb={2}>
                        The Graduate School has reviewed your details and conflict of interest disclosure and requests
                        you update or amend the information with the following message:
                      </Typography>
                      {!!examinerData.outcomeMessage && (
                        <Alert severity="warning">
                          <Typography variant="body2">{`${examinerData?.outcomeMessage || 'No message'}`}</Typography>
                        </Alert>
                      )}
                    </>
                  ) : (
                    <Typography variant="body2">
                      As a nominated examiner for {submission?.creator?.displayName}'s HDR thesis, the Graduate School
                      has requested that you submit additional personal contact details and a conflict of interest
                      disclosure to be considered for the examination.
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid xs={12}>
                <Typography variant="h2" component="h2" color="primary">
                  Your details
                </Typography>
              </Grid>
              <Grid xs={4}>
                <CTextField name="institution" label="Institution" required fullWidth readOnly={readOnly} />
              </Grid>
              <Grid xs={4}>
                <CTextField name="position" label="Position" required fullWidth readOnly={readOnly} />
              </Grid>
              <Grid xs={4}>
                <CTextField name="contactNumber" label="Contact phone number" required fullWidth readOnly={readOnly} />
              </Grid>
              <Grid xs={12}>
                <CTextField
                  name="contactAddress"
                  label="Contact postal address"
                  required
                  fullWidth
                  multiline
                  readOnly={readOnly}
                />
              </Grid>
              <Grid xs="auto">
                <CRadioGroup
                  label="Do you require payment for your examination?"
                  name="requirePayment"
                  required
                  aria-label="Do you require payment for your examination?"
                  options={YES_NO_INPUTS}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid xs="auto">
                <HelpButton
                  buttonSx={{ mt: -1, ml: -2 }}
                  isInfo
                  title="Payment of examiners"
                  text={
                    <div>
                      We’re grateful for the significant contribution you make to the quality of our research and offer
                      the payment of an honorarium.
                      <br />
                      <br />
                      Honorarium amounts are recommended by{' '}
                      <Link
                        target="_blank"
                        href="https://www.universitiesaustralia.edu.au/policy-submissions/research-innovations/fees-for-external-examiners/#.XA8JE7EzZhG"
                      >
                        Universities Australia
                      </Link>
                      . You’ll be paid into the bank account you nominate when accepting our invitation.
                    </div>
                  }
                />
              </Grid>
              {needsPayment && (
                <Grid xs={12}>
                  <CRadioGroup
                    size="small"
                    label="Please indicate your bank account type"
                    name="bankAccountType"
                    required
                    aria-label="Please indicate your bank account type"
                    options={[
                      { label: 'Australian Bank Account', value: 'AUS' },
                      { label: 'International Bank Account', value: 'INTERNATIONAL' },
                    ]}
                    readOnly={readOnly}
                  />
                </Grid>
              )}
              {(!readOnly || hasExaminerCOIStatements) && (
                <>
                  <Grid xs={12}>
                    <Typography variant="h2" component="h2" color="primary">
                      Conflict of interest disclosure
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="body2">
                      Our conflict of interest (COI) guidelines ensure that all theses are examined independently and
                      free from any perception of bias or preferential treatment. Some of the more common COIs are
                      listed below. Selecting any of the boxes below may not necessarily preclude you from examining the
                      thesis; however, it must be declared so that an assessment can be made. Please note that the
                      conflicts listed below are not exhaustive, and further information on UQ's COI guidelines is{' '}
                      <Link href="https://policies.uq.edu.au/document/view-current.php?id=452#section4">
                        available here
                      </Link>
                      . If you select any of these categories, please provide further details below.
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <CRadioGroup
                      size="small"
                      label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('publishedWithCandidate')}
                      name="publishedWithCandidate"
                      required
                      aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('publishedWithCandidate')}
                      options={YES_NO_INPUTS}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <CRadioGroup
                      size="small"
                      label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('grantWithAdvisor')}
                      name="grantWithAdvisor"
                      required
                      aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('publishedWithCandidate')}
                      options={YES_NO_INPUTS}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <CRadioGroup
                      size="small"
                      label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER(
                        'personalOrProfessionalRelationship'
                      )}
                      name="personalOrProfessionalRelationship"
                      required
                      aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER(
                        'personalOrProfessionalRelationship'
                      )}
                      options={YES_NO_INPUTS}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <CRadioGroup
                      size="small"
                      label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('consultedOnThesis')}
                      name="consultedOnThesis"
                      required
                      aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('consultedOnThesis')}
                      options={YES_NO_INPUTS}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <CRadioGroup
                      size="small"
                      label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('otherCOI')}
                      name="otherCOI"
                      required
                      aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_EXAMINER('otherCOI')}
                      options={YES_NO_INPUTS}
                      readOnly={readOnly}
                    />
                  </Grid>
                  {showExaminerCOIDetailsInput && (
                    <Grid xs={12}>
                      <CTextField
                        name="examinerCOIDetails"
                        label="Please provide specific details about the conflict and your relationship with the Candidate and/or Principal Advisor"
                        placeholder="If you have published together, include the year and citation for the publication. If you have collaborated in other ways (e.g. thesis consultation), specify when it occurred and describe the nature of the collaboration. Providing clear details will help us assess the potential conflict appropriately."
                        multiline
                        minRows={3}
                        required
                        minLength={200}
                        fullWidth
                        readOnly={readOnly}
                      />
                    </Grid>
                  )}
                </>
              )}

              {!readOnly && (
                <Grid xs={12}>
                  <HelpWrapper helpProps={examinerConfidentialityAgreement}>
                    <CCheckbox
                      color={'secondary'}
                      name="_checkboxGroup[0]"
                      label="I confirm I will maintain the confidentiality of this examination."
                    />
                  </HelpWrapper>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => onCloseDialog()} /* disabled={!!loading} */>
              Cancel
            </Button>
            {!readOnly && (
              <Button variant="contained" color="primary" type="submit" disabled={isPending || hasValidationError}>
                Submit
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default ThesisExaminerDetailsFormDialog
