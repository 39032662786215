import { Alert } from '@mui/material'
import { ThesisSubmissionExaminerInvitationResult } from 'types/graphql'

import { useAuth } from 'src/auth'

import SupportLink from './SupportLink'

type Props = {
  statusCode: ThesisSubmissionExaminerInvitationResult['statusCode']
}

const TokenError = ({ statusCode }: Props) => {
  const { currentUser } = useAuth()

  if (statusCode === 'INVITATION_INVALID') {
    return (
      <Alert severity="error">
        The invitation link that you used is invalid. Please make sure you click on the right link. Contact{' '}
        <SupportLink /> if you require any assistance.
      </Alert>
    )
  } else if (statusCode === 'INVITATION_EXPIRED') {
    return (
      <Alert severity="error">
        The invitation link that you used has <b>expired</b>. Please contact The Principal Advisor for the thesis to
        re-invite you.
      </Alert>
    )
  } else if (statusCode === 'INVITATION_ALREADY_USED_BY_OTHER') {
    return (
      <Alert severity="error">
        This invitation link has already been used. Please contact the Principal Advisor for the thesis or contact{' '}
        <SupportLink />.
      </Alert>
    )
  } else if (statusCode === 'INVITATION_ALREADY_USED_BY_USER') {
    return (
      <Alert severity="info">
        You have already signed into this invitation link. This means that you are part of the UQRDM and should have
        received access instructions. Pleas contact <SupportLink /> if you require support.
      </Alert>
    )
  } else if (statusCode === 'USER_ALREADY_ON_THESIS_SUBMISSION') {
    return (
      <Alert severity="warning">
        Your username "{currentUser.eduPersonPrincipalName.split('@')[0]}" has already been added to this thesis
        submission as an examiner.
      </Alert>
    )
  } else {
    return (
      <Alert severity="error">
        Something went wrong. Please contact <SupportLink /> if you require assistance.
      </Alert>
    )
  }
}

export default TokenError
