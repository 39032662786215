const THESIS_ITERATION_DETAILS_QUERY = gql`
  query ThesisIterationDetailsQuery($iterationId: Int!) {
    thesisIterationDetails(iterationId: $iterationId) {
      id
      title
      abstract
      submittedDate
      intendedSubmissionDate
      oralDate
      hasOral
      examinerFeedbackDueDate
      chairSummaryReportDueDate
      organisation {
        id
      }
      record {
        id
        uuid
        name
        collaborators {
          isOwner
          isLead
          user {
            id
            displayName
            eduPersonPrincipalName
          }
        }
      }
      record {
        id
        name
        collaborators {
          isOwner
          isLead
          user {
            id
            displayName
            eduPersonPrincipalName
          }
        }
      }
      files {
        id
        examinerId
        type
        key
        filename
        isNew
        isActive
        submissionDate
      }
      principalAdvisor {
        id
        displayName
        mail
      }
      totalPages
      type
      additionalSupervisors {
        id
        displayName
        mail
      }
      embargoType
      forCodes {
        id
        code
        name
      }
      isUqIntellectualProperty
      keywords
      organisation {
        id
        name
        isActive
        organisationTypeId
      }
      currentStatus {
        id
        previousStatusId
        status
        outcome
        outcomeMessage
        createdAt
        updatedAt
        outcomeByUser {
          displayName
        }
        nextStatus {
          id
        }
      }
      previousStatus {
        id
        previousStatusId
        status
        outcome
        outcomeMessage
        createdAt
        updatedAt
        outcomeByUser {
          displayName
        }
      }
    }
  }
`

export default THESIS_ITERATION_DETAILS_QUERY
