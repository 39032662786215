import { Alert } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import ContentLoader from 'src/components/ContentLoader/ContentLoader'
import QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'

import Content from './components/Content'

type Props = {
  id: number
}

const ThesisChairDetailsPage = ({ id }: Props) => {
  const { data, error, loading, refetch } = useQuery<GetThesisSubmissionQuery>(QUERY, {
    variables: { id },
  })

  return (
    <>
      {error && <Alert severity="error">Error loading thesis submission details</Alert>}
      {loading && <ContentLoader message="Loading thesis submission details..." />}
      {!loading && data && <Content submission={data.getThesisSubmission} onReload={refetch} />}
    </>
  )
}

export default ThesisChairDetailsPage
