import { ThesisSubmissionExaminer } from 'types/graphql'

export const hasCOIStatements = (
  examiner: Pick<ThesisSubmissionExaminer, 'advisorCOIStatements' | 'candidateCOIStatements'>
) => {
  const hasAdvisorCOIStatements =
    !!examiner?.advisorCOIStatements && Object.keys(examiner.advisorCOIStatements).length > 0
  const hasCandidateCOIStatements =
    !!examiner?.candidateCOIStatements && Object.keys(examiner?.candidateCOIStatements).length > 0

  return hasAdvisorCOIStatements && hasCandidateCOIStatements
}

/**
 * Check if the examiner was added by the Graduate School via bypass method which does not require COI statements
 */
export const isExaminerAddedByGraduateSchool = (
  examiner: Pick<ThesisSubmissionExaminer, 'status' | 'advisorCOIStatements' | 'candidateCOIStatements'>
) => {
  return examiner?.status === 'PENDING_INVITATION_ACCEPTANCE' && !hasCOIStatements(examiner)
}

interface COIRequiredProps {
  publishedWithCandidate?: boolean
  grantWithAdvisor?: boolean
  personalOrProfessionalRelationship?: boolean
  consultedOnThesis?: boolean
  otherCOI?: boolean
}

/**
 * Candidate Conflict of interest Statements with Nominated examiner
 */
export const COIStatements = <T extends COIRequiredProps>(COIInput: T) => {
  return {
    publishedWithCandidate: COIInput.publishedWithCandidate,
    grantWithAdvisor: COIInput.grantWithAdvisor,
    personalOrProfessionalRelationship: COIInput.personalOrProfessionalRelationship,
    consultedOnThesis: COIInput.consultedOnThesis,
    otherCOI: COIInput.otherCOI,
  }
}
