import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'
import { Button } from '@mui/material'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Final submission - Thesis complete state.
 *
 * Chair view.
 */
const ThesisComplete = ({ submission, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).chairMessage
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, 'FINAL')

  const actions = []

  if (submission.thesis?.espacePid) {
    actions.push(
      <Button
        key="1"
        variant="contained"
        href={`${process.env.ESPACE_WEB_BASE_URI}/${submission.thesis?.espacePid}`}
        target="_blank"
        color="primary"
      >
        View thesis in UQ eSpace
      </Button>
    )
  }
  return <ThesisSubmissionMessage {...cm} {...stepperInfo} actions={actions} />
}

export default ThesisComplete
