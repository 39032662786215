import { isExaminerAddedByGraduateSchool } from '@common/thesis/thesisExaminers/helper'
import { ThesisFileType } from '@prisma/client'
import {
  GetThesisSubmissionQuery,
  NonOralSummaryReportInput,
  OralSummaryReportInput,
  SaveSummaryReportInput,
} from 'types/graphql'
import * as yup from 'yup'

import { UploadedFile } from 'src/components/Form/Inputs'

export type BaseChairSummaryFormDefaultInputType = {
  examinationSummary: string
  detailedKnowledgeOfThesis: string
  understandingResearchInBroaderContext: string
  defendMethodologyAndConclusions: string
  communicatedResultsAndImpact: string
  independentContributionToDiscipline: string
  amendments: string
  otherAmendmentDetails?: string
  chairOutcomeRecommendation: string
  chairReviewFile: UploadedFile | null
}

export type OralChairSummaryFormDefaultInputType = BaseChairSummaryFormDefaultInputType & {
  examiner0AttendanceType: { examinerId: number | null; type: string; otherArrangements: string }
  examiner1AttendanceType: { examinerId: number | null; type: string; otherArrangements: string }
}

// Chair summary form default values (non-oral)
export const createNonOralChairSummaryFormDefaultValues = (
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair'],
  submission: GetThesisSubmissionQuery['getThesisSubmission']
): BaseChairSummaryFormDefaultInputType => {
  const iteration = submission.currentIteration

  return {
    examinationSummary: chair?.examinationSummary || '',
    detailedKnowledgeOfThesis: chair?.detailedKnowledgeOfThesis || '',
    understandingResearchInBroaderContext: chair?.understandingResearchInBroaderContext || '',
    defendMethodologyAndConclusions: chair?.defendMethodologyAndConclusions || '',
    communicatedResultsAndImpact: chair?.communicatedResultsAndImpact || '',
    independentContributionToDiscipline: chair?.independentContributionToDiscipline || '',
    amendments: chair?.amendments || '',
    otherAmendmentDetails: chair?.otherAmendmentDetails || '',
    chairOutcomeRecommendation: chair?.chairOutcomeRecommendation || '',
    chairReviewFile: iteration
      ? iteration.files.find((f) => f.id === chair?.fileId && f.type === ThesisFileType.CHAIR_REVIEW_FILE && f.isActive)
      : null,
  }
}

// Chair summary form default values (oral)
export const createOralChairSummaryFormDefaultValues = (
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair'],
  submission: GetThesisSubmissionQuery['getThesisSubmission']
): OralChairSummaryFormDefaultInputType => {
  const examiners = submission.thesisSubmissionExaminers

  const defaultExaminersData = examiners
    .filter((e) => e.status === 'APPROVED' || isExaminerAddedByGraduateSchool(e))
    .reduce((acc, curr, index) => {
      return {
        ...acc,
        [`examiner${index}AttendanceType`]: {
          examinerId: curr?.id,
          type: curr?.oralAttendanceType || '',
          otherArrangements: curr?.otherOralAttendanceArrangements || '',
        },
      }
    }, {})

  return {
    ...createNonOralChairSummaryFormDefaultValues(chair, submission),
    examiner0AttendanceType: { examinerId: null, type: '', otherArrangements: '' },
    examiner1AttendanceType: { examinerId: null, type: '', otherArrangements: '' },
    ...defaultExaminersData,
  }
}

// Chair summary form base validation schema
const createBaseSchema = () => {
  return yup.object({
    examinationSummary: yup.string().required('You must provide more information.'),
    detailedKnowledgeOfThesis: yup.string().required('You must pick an answer.'),
    understandingResearchInBroaderContext: yup.string().required('You must pick an answer.'),
    defendMethodologyAndConclusions: yup.string().required('You must pick an answer.'),
    communicatedResultsAndImpact: yup.string().required('You must pick an answer.'),
    independentContributionToDiscipline: yup.string().required('You must pick an answer.'),
    amendments: yup.string().required('You must pick an amendments/corrections.'),
    otherAmendmentDetails: yup.string().when('amendments', {
      is: (val) => val == 'additional',
      then: (schema) => schema.required('You must provide required amendments.'),
      otherwise: (schema) => schema.notRequired(),
    }),
    chairOutcomeRecommendation: yup.string().required('You must recommend an outcome.'),
    chairReviewFile: yup.object({ key: yup.string(), filename: yup.string() }).nullable(),
  })
}

// Chair summary form validation schema (non-oral)
export const createNonOralChairSummaryFormValidationSchema = () => {
  return createBaseSchema()
}

// Chair summary form validation schema (oral)
export const createOralChairSummaryFormValidationSchema = () => {
  const baseSchema = createBaseSchema()

  return baseSchema.shape({
    examiner0AttendanceType: yup
      .object({
        examinerId: yup.number().required('Error. Cannot find examiner.'),
        type: yup.string().required('You must pick a mode of attendance.'),
        otherArrangements: yup.string().when('type', {
          is: (type) => type === 'other',
          then: (schema) => schema.required('You must provide details of other arrangements.'),
          otherwise: (schema) => schema.notRequired(),
        }),
      })
      .required(),
    examiner1AttendanceType: yup
      .object({
        examinerId: yup.number().required('Error. Cannot find examiner.'),
        type: yup.string().required('You must pick a mode of attendance.'),
        otherArrangements: yup.string().when('type', {
          is: (type) => type === 'other',
          then: (schema) => schema.required('You must provide details of other arrangements.'),
          otherwise: (schema) => schema.notRequired(),
        }),
      })
      .required(),
  })
}

const baseChairSummaryFormInput = (
  formData: OralChairSummaryFormDefaultInputType | BaseChairSummaryFormDefaultInputType
) => {
  return {
    detailedKnowledgeOfThesis: formData.detailedKnowledgeOfThesis,
    understandingResearchInBroaderContext: formData.understandingResearchInBroaderContext,
    defendMethodologyAndConclusions: formData.defendMethodologyAndConclusions,
    communicatedResultsAndImpact: formData.communicatedResultsAndImpact,
    independentContributionToDiscipline: formData.independentContributionToDiscipline,
    examinationSummary: formData.examinationSummary,
    amendments: formData.amendments,
    otherAmendmentDetails: formData.otherAmendmentDetails,
    chairOutcomeRecommendation: formData.chairOutcomeRecommendation,
  }
}

// Create oral chair summary form submission input
export const createOralChairSummaryFormSubmissionInput = (
  formData: OralChairSummaryFormDefaultInputType
): OralSummaryReportInput => {
  const chairReviewFile =
    formData?.chairReviewFile && Object.keys(formData?.chairReviewFile).length !== 0
      ? [
          {
            id: typeof formData?.chairReviewFile.id === 'number' ? formData?.chairReviewFile.id : undefined,
            key: formData?.chairReviewFile.key,
            filename: formData?.chairReviewFile.filename,
            fileType: ThesisFileType.CHAIR_REVIEW_FILE,
          },
        ]
      : []

  return {
    ...baseChairSummaryFormInput(formData),
    examiner0AttendanceType: {
      examinerId: Number(formData.examiner0AttendanceType.examinerId),
      type: formData.examiner0AttendanceType.type,
      otherArrangements: formData.examiner0AttendanceType.otherArrangements,
    },
    examiner1AttendanceType: {
      examinerId: Number(formData.examiner1AttendanceType.examinerId),
      type: formData.examiner1AttendanceType.type,
      otherArrangements: formData.examiner1AttendanceType.otherArrangements,
    },
    files: [...chairReviewFile],
  }
}

// Create non-oral chair summary form submission input
export const createNonOralChairSummaryFormSubmissionInput = (
  formData: BaseChairSummaryFormDefaultInputType
): NonOralSummaryReportInput => {
  const chairReviewFile =
    formData?.chairReviewFile && Object.keys(formData?.chairReviewFile).length !== 0
      ? [
          {
            id: typeof formData?.chairReviewFile.id === 'number' ? formData?.chairReviewFile.id : undefined,
            key: formData?.chairReviewFile.key,
            filename: formData?.chairReviewFile.filename,
            fileType: ThesisFileType.CHAIR_REVIEW_FILE,
          },
        ]
      : []

  return {
    ...baseChairSummaryFormInput(formData),
    files: [...chairReviewFile],
  }
}

// Create chair summary form save input
export const createChairSummaryFormSaveInput = (formData: any): SaveSummaryReportInput => {
  const chairReviewFile = formData?.chairReviewFile
    ? [
        {
          id: typeof formData?.chairReviewFile.id === 'number' ? formData?.chairReviewFile.id : undefined,
          key: formData?.chairReviewFile.key,
          filename: formData?.chairReviewFile.filename,
          fileType: ThesisFileType.CHAIR_REVIEW_FILE,
        },
      ]
    : []

  return {
    ...baseChairSummaryFormInput(formData),
    examiner0AttendanceType: {
      examinerId: Number(formData.examiner0AttendanceType.examinerId),
      type: formData.examiner0AttendanceType.type,
      otherArrangements: formData.examiner0AttendanceType.otherArrangements,
    },
    examiner1AttendanceType: {
      examinerId: Number(formData.examiner1AttendanceType.examinerId),
      type: formData.examiner1AttendanceType.type,
      otherArrangements: formData.examiner1AttendanceType.otherArrangements,
    },
    files: [...chairReviewFile],
  }
}

// Create chair summary form save input
export const createNonOralChairSummaryFormSaveInput = (formData: any): SaveSummaryReportInput => {
  const chairReviewFile = formData?.chairReviewFile
    ? [
        {
          id: typeof formData?.chairReviewFile.id === 'number' ? formData?.chairReviewFile.id : undefined,
          key: formData?.chairReviewFile.key,
          filename: formData?.chairReviewFile.filename,
          fileType: ThesisFileType.CHAIR_REVIEW_FILE,
        },
      ]
    : []

  return {
    ...baseChairSummaryFormInput(formData),
    files: [...chairReviewFile],
  }
}
