import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

import { Controller, useFormContext } from '@redwoodjs/forms'

type DateProps = {
  label: string
  disablePast?: boolean
  helperText?: string
  readOnly?: boolean
  required?: boolean
  maxDate?: string
  minDate?: string
  value: Date
  onChange: (value: Date) => unknown
  onBlur?: () => unknown
  error?: boolean
}
export const Date = (props: DateProps) => {
  const readOnly = props.readOnly === undefined ? false : props.readOnly
  const required = props.required === undefined ? false : props.required
  const maxDate = props.maxDate === undefined ? null : props.maxDate
  const minDate = props.minDate === undefined ? null : props.minDate

  const onLocalChange = (value: dayjs.Dayjs) => {
    props.onChange(value && value.isValid() ? value.toDate() : null)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="DD-MM-YYYY"
        value={dayjs(props.value)}
        readOnly={readOnly}
        label={props.label}
        maxDate={dayjs(maxDate)}
        minDate={dayjs(minDate)}
        onChange={onLocalChange}
        disablePast={props.disablePast}
        slotProps={{
          textField: {
            helperText: props.helperText,
            error: !!props.error,
            fullWidth: true,
            required: required,
            onBlur: props.onBlur,
          },
          inputAdornment: {
            onBlur: props.onBlur,
          },
        }}
      />
    </LocalizationProvider>
  )
}

type CDateProps = Omit<DateProps, 'value' | 'onChange' | 'onBlur' | 'error'> & {
  name: string
}

export const CDate = (props: CDateProps) => {
  const { name, ...otherProps } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Date
          {...otherProps}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : props.helperText}
        />
      )}
    />
  )
}
