import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import PRINCIPAL_ADVISOR_ADDS_EXAMINER from 'src/lib/graphql/mutations/PrincipalAdvisorAddsExaminer'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import {
  AdvisorExaminerFormDataInput,
  ThesisSubmissionExaminerFormData,
  advisorAddExaminerValidationSchema,
  createAdvisorAddExaminerDefaultValues,
} from '../../helpers'

import AdvisorAddExaminerForm from './AdvisorAddExaminerForm'

interface Props {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onClose: () => void
  onOpen: boolean
}

/**
 * Principal Advisor add examiner dialog
 */
const AdvisorAddExaminerDialog = ({ onClose, onOpen, submission }: Props) => {
  const showSnackbar = useSnackbar()
  const [isPending, setIsPending] = useState<boolean>(false)

  const [advisorAddExaminerMutation] = useMutation(PRINCIPAL_ADVISOR_ADDS_EXAMINER, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm<AdvisorExaminerFormDataInput>({
    defaultValues: createAdvisorAddExaminerDefaultValues(),
    resolver: yupResolver(advisorAddExaminerValidationSchema), //@TODO fix this error
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async ({
    _checkboxGroup,
    ...rest
  }: ThesisSubmissionExaminerFormData & { _checkboxGroup: never }) => {
    setIsPending(true)

    try {
      await advisorAddExaminerMutation({
        variables: { submissionId: submission.id, examinerData: rest },
      })
      showSnackbar('Successfully nominated examiner. Candidate will review COI.', 'success')
      formMethods.reset()
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not nominate examiner. Please try again later.', 'error')
    }

    setIsPending(false)
  }

  const hasValidationError = !formMethods.formState.isValid

  return (
    <Dialog
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          formMethods.reset()
          onClose()
        }
      }}
      open={onOpen}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle variant="h2" component="h2" color="primary" id="dialogTitle">
        Examiner nomination
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent sx={{ pt: 0 }} id="dialogContent">
          <AdvisorAddExaminerForm />
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} direction="row" sx={{ pr: 2, pb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                formMethods.reset()
                onClose()
              }}
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={isPending || hasValidationError}>
              Nominate
            </Button>
          </Stack>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default AdvisorAddExaminerDialog
