import { getIterationState } from '@common/thesis/iterationState'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Link, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { MyRecordsQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import { useAuth } from 'src/auth'
import {
  CCheckbox,
  CWysiwyg,
  Organisation,
  RecordSelectorInput,
  ThesisFiles,
  ThesisTypeSelectorInput,
} from 'src/components/Form/Inputs'
import { ThesisSubmissionDetails } from 'src/components/Thesis'
import organisationThesisGuidance from 'src/lib/guidance/organisationThesisGuidance'

import { ThesisSubmissionFormData, createDefaultValues, createValidationSchema } from '../helpers'

type Props = {
  userRecordsSummary: MyRecordsQuery['userRecordsSummary']
  disabled: boolean
  saving?: boolean
  submission: ThesisSubmissionDetails
  onSubmit?: (data: ThesisSubmissionFormData) => unknown
  onClose: () => void
}

const ThesisIterationForm = ({ userRecordsSummary, disabled, saving, submission, onSubmit, onClose }: Props) => {
  const { currentUser } = useAuth()
  const iterationState = getIterationState(submission.currentIteration?.currentStatus)
  const formMethods = useForm({
    defaultValues: createDefaultValues(submission, currentUser),
    resolver: yupResolver(createValidationSchema(iterationState)),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })
  const { watch } = formMethods

  const userRecordsWithAssignedPaAsLead = userRecordsSummary.filter((r) =>
    r.collaborators.find(
      (c) => c.isLead && c.user.eduPersonPrincipalName === submission.principalAdvisor.eduPersonPrincipalName
    )
  )

  const isValid = formMethods.formState.isValid
  return (
    <Form formMethods={formMethods} encType="form" onSubmit={onSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography component="h2" variant="h2" color="primary" sx={{ mb: 1 }}>
            Thesis submission form
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography component="h3" variant="h4" color="primary" sx={{ mb: 1 }}>
            Thesis details
          </Typography>
          <RecordSelectorInput
            userRecordsSummary={userRecordsWithAssignedPaAsLead}
            onlyOwnedRecords={true}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <CWysiwyg
            height={280}
            name="title"
            label="Thesis title"
            readOnly={disabled}
            required={true}
            helperText="Enter your thesis title here"
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Organisation
            required={true}
            inactiveMessageDisabled={true}
            readOnly={disabled}
            guidance={organisationThesisGuidance}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <ThesisTypeSelectorInput label="Degree" name="type" required={true} readOnly={disabled} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ mt: 2 }}>
          <ThesisFiles
            isFinal={false}
            readOnly={disabled}
            isRequestChanges={iterationState.thesisState === 'REQUEST_CHANGES'}
            submission={submission}
          />
        </Grid>
      </Grid>
      {!disabled && (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Grid container spacing={1}>
                <Grid xs={12} id="statements">
                  <Typography variant="h4" color="primary" component="h3">
                    Submission declarations
                  </Typography>
                  <ul>
                    <li>
                      I certify that I have run my thesis through iThenticate and have provided a copy of the
                      originality report and that this report is based on the thesis submitted.
                    </li>
                    <li>
                      I certify that the thesis I have submitted is composed of my original work and that the assertions
                      I have made in the thesis are truthful and complete.
                    </li>
                    <li>
                      I understand that work submitted may be subjected to a plagiarism detection process. I have
                      reviewed{' '}
                      <Link href="https://policies.uq.edu.au/document/view-current.php?id=118" target="_blank">
                        UQ's open access policy
                      </Link>{' '}
                      and have discussed access options with my advisors.
                    </li>
                  </ul>
                </Grid>
                <Grid xs={12}>
                  <CCheckbox
                    name="certify"
                    label="I agree to the above statements as listed"
                    describedBy="statements"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs />
            <Grid xs={'auto'}>
              <Button color="secondary" variant="contained" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid xs={'auto'}>
              <LoadingButton disabled={!isValid} variant="contained" color="primary" loading={saving} type="submit">
                Submit your thesis
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  )
}

export default ThesisIterationForm
