import { useState } from 'react'

import { DeanPassSubmission, DeanPassSubmissionVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import DEAN_PASS_SUBMISSION from 'src/lib/graphql/mutations/DeanPassSubmission'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import PassSubmissionButtonDialog from './PassSubmissionButtonDialog'

type Props = {
  iterationId: number
  disabled?: boolean
  onReload: () => unknown
  onClose: () => void
  fullWidth?: boolean
}

const PassSubmissionButton = ({ iterationId, onReload, disabled, fullWidth, onClose }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [passSubmission] = useMutation<DeanPassSubmission, DeanPassSubmissionVariables>(DEAN_PASS_SUBMISSION)
  const showSnackbar = useSnackbar()

  const onConfirm = async () => {
    setSaving(true)
    try {
      const result = await passSubmission({ variables: { iterationId } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully passed the submission.", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not pass this submission. Please try again later.', 'error')
      setSaving(false)
    }

    onClose()
  }

  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true)
        }}
        disabled={disabled}
        sx={{ mb: 1 }}
      >
        Pass submission
      </Button>
      <PassSubmissionButtonDialog open={open} saving={saving} onCancel={() => setOpen(false)} onConfirm={onConfirm} />
    </>
  )
}

export default PassSubmissionButton
