import { NotificationState } from '@common/thesis/iterationState'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'

import { BackgroundContainer } from 'src/components/UserNotifications/Base/BackgroundContainer'

type Props = {
  title: string
  message: string
  action: NotificationState.CANDIDATE_SUBMITTING | NotificationState.ACTION_REQUIRED | NotificationState.NO_ACTION
  outcomeMessage?: string
  instructions?: React.ReactNode | string
  activeStep?: number
  maxStep?: number
  actions?: React.ReactNode[]
}

const ThesisSubmissionMessage = ({
  message,
  title,
  action,
  instructions,
  outcomeMessage,
  activeStep,
  maxStep,
  actions,
}: Props) => {
  activeStep = activeStep > maxStep ? maxStep : activeStep

  return (
    <BackgroundContainer priority={action}>
      <Grid container sx={{ margin: 2, marginLeft: 3 }}>
        <Grid item xs={12}>
          {activeStep && maxStep && (
            <Grid container>
              <Grid item xs={'auto'} sx={{ fontSize: '0.8rem', marginRight: 1 }}>
                <TimerOutlinedIcon />
              </Grid>
              <Grid item xs={'auto'} alignSelf="center" sx={{ fontSize: '0.8rem' }}>
                Step {activeStep} of {maxStep}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ fontSize: '1.8rem', fontWeight: 300, lineHeight: 1, mt: 1, mb: 1 }}>
          {title}
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant="body2">{message}</Typography>
        </Grid>

        {outcomeMessage && (
          <Grid item xs={12} sx={{ mb: 2, p: 1.5, backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              <i>{`"${outcomeMessage}"`}</i>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm sx={{ mb: 2 }}>
          <Typography variant="body2">{instructions}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ position: 'relative', mt: -1, pl: 3, pr: 2, pb: 2 }}
        alignContent={'flex-end'}
        justifyContent={'flex-end'}
        spacing={2}
      >
        {actions &&
          actions.length > 0 &&
          actions?.map((action, index) =>
            action === undefined ? null : (
              <Grid item key={index}>
                {action}
              </Grid>
            )
          )}
      </Grid>
    </BackgroundContainer>
  )
}

export default ThesisSubmissionMessage
