import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import UqUserLookupInput from 'src/components/Form/Inputs/UqUserLookupInput/UqUserLookupInput'
import PRINCIPAL_ADVISOR_ADDS_CHAIR from 'src/lib/graphql/mutations/PrincipalAdvisorAddsChair'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import { createDefaultValuesForChair, ThesisSubmissionChairFormData, validationSchemaForChair } from './helpers'

interface Props {
  submissionId: number
  onClose: () => void
  onOpen: boolean
}

/**
 *  PA adds thesis submission chair dialog
 */
const PrincipalAdvisorAddChairDialog = ({ onClose, onOpen, submissionId }: Props) => {
  const showSnackbar = useSnackbar()

  const [isPending, setIsPending] = useState<boolean>(false)
  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false)
  const [checked, setChecked] = React.useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const [addChairMutation] = useMutation(PRINCIPAL_ADVISOR_ADDS_CHAIR, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm<ThesisSubmissionChairFormData>({
    defaultValues: createDefaultValuesForChair,
    resolver: yupResolver(validationSchemaForChair),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const hasValidationError = !formMethods.formState.isValid

  const onSubmit = async (data: ThesisSubmissionChairFormData) => {
    setIsPending(true)

    try {
      await addChairMutation({
        variables: {
          submissionId,
          chairEppn: data.eduPersonPrincipalName,
        },
      })
      showSnackbar('Successfully added Chair.', 'success')
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not add Chair. Please try again later.', 'error')
      onClose()
    }
    formMethods.reset()
    onClose()
    setIsPending(false)
  }

  return (
    <Dialog
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          formMethods.reset()
          onClose()
        }
      }}
      open={onOpen}
    >
      <DialogTitle component="h2" variant="h2" id="dialogtitle" color="primary">
        Assign Chair of Examiners
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit} onReset={() => formMethods.reset()}>
        <DialogContent id="dialogContent" sx={{ pt: 0 }}>
          <Typography variant="body2" component="div">
            Select the Chair here. Begin typing the user's name or email address, then, select the user from the list.
            <br />
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid xs={12}>
                <UqUserLookupInput
                  name="eduPersonPrincipalName"
                  setOpenAutoComplete={setOpenAutoComplete}
                  disabled={isPending}
                  openAutoComplete={openAutoComplete}
                  label="Search for a chair"
                />
              </Grid>
            </Grid>
            <br />
          </Typography>
          <FormControlLabel
            label={
              <Typography variant="body2" component="span">
                I confirm that I have followed the chair selection process for my unit and the Chair has agreed to
                undertake this examination
              </Typography>
            }
            control={
              <Checkbox
                sx={{ mt: -3 }}
                checked={checked}
                onChange={handleChange}
                inputProps={{
                  'aria-label':
                    'I confirm that I have followed the chair selection process for my unit and the Chair has agreed to undertake this examination',
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} direction="row" sx={{ pr: 2, pb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onClose()
                formMethods.reset()
              }}
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={openAutoComplete || isPending || hasValidationError || !checked}
            >
              Assign
            </Button>
          </Stack>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default PrincipalAdvisorAddChairDialog
