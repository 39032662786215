import { useApolloClient } from '@apollo/client'
import {
  AMENDMENTS_INPUTS,
  MODE_OF_ATTENDANCE_INPUTS,
  RECOMMENDATION_INPUTS,
  RNR_RECOMMENDATION_INPUTS,
  THESIS_QUALITY_INPUTS,
} from '@common/thesis/chair/examinationFormData'
import { isExaminerAddedByGraduateSchool } from '@common/thesis/thesisExaminers/helper'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisFileDownloadUrlQuery, GetThesisSubmissionQuery } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'

import { useAuth } from 'src/auth'
import { CRadioGroup, CTextField, CWysiwyg } from 'src/components/Form/Inputs'
import ThesisChairReviewFile from 'src/components/Form/Inputs/Thesis/ThesisFiles/components/ThesisChairReviewFile'
import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { isAssignedThesisChair } from 'src/lib/userAccessChecker'

import ThesisFilesViewerSection from '../../../ThesisIterationFormViewer/components/ThesisFilesViewerSection'

type Props = {
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair']
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  hasOral: boolean
}

const ChairFeedbackFormInputs = ({ chair, submission, hasOral }: Props) => {
  const { currentUser, hasRole } = useAuth()
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()
  const formMethods = useFormContext()

  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const isAssignedChair = isAssignedThesisChair(currentUser, chair?.user?.id)
  const completedFeedback = chair?.summaryReportCompleted
  const canChairUploadFile = (isAssignedChair && !completedFeedback) || hasRole('grad-school-user')

  const hasAdditionalAmendments = formMethods.watch('amendments') === 'additional'
  const recommendationInputs =
    submission.reviseAndResubmitOfId === null ? RECOMMENDATION_INPUTS : RNR_RECOMMENDATION_INPUTS
  const chairFile = submission.currentIteration.files.filter(
    (f) => f.id === chair?.fileId && f.type === ThesisFileType.CHAIR_REVIEW_FILE && f.isActive
  )

  return (
    <>
      {isAssignedChair && (
        <Grid xs={12}>
          {completedFeedback ? (
            <Typography variant="body2">You have already submitted your summary.</Typography>
          ) : (
            <Typography variant="body2">
              {hasOral
                ? 'Please complete the summary report and submit when finished. The form can be edited and saved for future submission.'
                : 'This form contains the previous summary of the oral examination. Please add any required amendments or corrections required of the candidate to the form and submit your recommended outcome for the Dean to review. The form can be edited and saved for future submission.'}
            </Typography>
          )}
        </Grid>
      )}

      {hasOral && (
        <>
          <Grid xs={12} marginTop={1}>
            <Typography variant="h4" color="primary">
              Attendance
            </Typography>
          </Grid>
          {submission.thesisSubmissionExaminers
            .filter((e) => e.status === 'APPROVED' || isExaminerAddedByGraduateSchool(e))
            .map((examiner, index) => {
              return (
                <>
                  <Grid xs={12} sm={6}>
                    {/* @TODO need to rethink how i am going to store this */}
                    <Box sx={{ bgcolor: 'rgba(81, 36, 122,0.03)', borderRadius: 3, padding: 2 }}>
                      <CRadioGroup
                        labelSx={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.8)' }}
                        size="small"
                        label={`${examiner?.user?.displayName || `${examiner?.title} ${examiner?.fullName}`}`}
                        name={`examiner${index}AttendanceType.type`}
                        aria-label={`examiner${index}AttendanceType`}
                        options={MODE_OF_ATTENDANCE_INPUTS}
                      />
                      {formMethods.watch(`examiner${index}AttendanceType.type`) === 'other' && (
                        <>
                          <Typography variant="body2" sx={{ mb: 2, mt: 1 }}>
                            If one examiner is not able to be present either in person or via video conference, please
                            provide details below of other arrangements made by committee to enable the candidate to
                            address this examiner's questions or comments.
                          </Typography>
                          <CTextField
                            name={`examiner${index}AttendanceType.otherArrangements`}
                            label="Details of other arrangements"
                            multiline
                            minRows={3}
                            InputLabelProps={{ shrink: true }}
                            required={formMethods.watch(`examiner${index}AttendanceType.type`) === 'other'}
                          />
                        </>
                      )}
                    </Box>
                  </Grid>{' '}
                </>
              )
            })}
        </>
      )}

      <Grid xs={12} marginTop={1} sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <Typography variant="h4" color="primary">
          Summary assessment
        </Typography>
        <Typography sx={{ mt: 1 }}>
          {hasOral
            ? `Please rate the candidate's presentation and their responses to questions raised by the examination committee during the oral examination.`
            : `Please rate the candidate's knowledge and understanding demonstrated in their examination.`}
        </Typography>
      </Grid>
      <Grid xs={12} sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <CRadioGroup
          colShading={true}
          size="small"
          label="The candidate demonstrated detailed knowledge of the thesis."
          name="detailedKnowledgeOfThesis"
          aria-label="detailedKnowledgeOfThesis"
          options={THESIS_QUALITY_INPUTS}
        />
      </Grid>
      <Grid xs={12} sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <CRadioGroup
          colShading={true}
          size="small"
          label="The candidate demonstrated an understanding of their research in the broader context of their discipline."
          name="understandingResearchInBroaderContext"
          aria-label="understandingResearchInBroaderContext"
          options={THESIS_QUALITY_INPUTS}
        />
      </Grid>
      <Grid xs={12} sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <CRadioGroup
          colShading={true}
          size="small"
          label="The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of the limitations of the thesis."
          name="defendMethodologyAndConclusions"
          aria-label="defendMethodologyAndConclusions"
          options={THESIS_QUALITY_INPUTS}
        />
      </Grid>
      <Grid xs={12} sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <CRadioGroup
          colShading={true}
          size="small"
          label="The candidate effectively communicated the results of the research in terms of impact and application of new knowledge."
          name="communicatedResultsAndImpact"
          aria-label="communicatedResultsAndImpact"
          options={THESIS_QUALITY_INPUTS}
        />
      </Grid>
      <Grid xs={12} sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <CRadioGroup
          colShading={true}
          size="small"
          label="The candidate demonstrated a substantive and independent contribution to the discipline."
          name="independentContributionToDiscipline"
          aria-label="independentContributionToDiscipline"
          options={THESIS_QUALITY_INPUTS}
        />
      </Grid>

      <Grid xs={12} marginTop={1}>
        <Typography variant="h4" color="primary">
          Examination committee's report
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography>
          Please provide a brief report on the {hasOral ? 'oral ' : ''}examination, detailing the structure of the
          examination and providing a summary of the candidate's performance in responding to the questions raised by
          the examiners.
        </Typography>
        <CWysiwyg height={100} name="examinationSummary" label="" helperText="Type your summary here" />
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          size="small"
          label="Please identify amendments/corrections required to be completed by the candidate."
          name="amendments"
          aria-label="amendments"
          options={AMENDMENTS_INPUTS}
          row={false}
        />
        {hasAdditionalAmendments && (
          <CWysiwyg height={100} name="otherAmendmentDetails" label="List required amendments" required={true} />
        )}
      </Grid>
      <Grid xs={12} marginTop={1}>
        <Typography variant="h4" color="primary">
          Summary recommendation
        </Typography>
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          colShading
          size="small"
          label="Please provide your recommended outcome to the Dean, based on examiner feedback and the oral examination"
          name="chairOutcomeRecommendation"
          aria-label="chairOutcomeRecommendation"
          options={recommendationInputs}
          row={false}
        />
      </Grid>

      {/* Chair support doc uploader */}
      {canChairUploadFile && (
        <>
          <Grid xs={12}>
            <Typography variant="h4" color="primary">
              Review document
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="body2">Upload your support document (optional)</Typography>
          </Grid>
          <Grid xs={12}>
            <ThesisChairReviewFile readOnly={completedFeedback} fileCreatorUserId={chair.user.id} />
          </Grid>
        </>
      )}
      {completedFeedback && chairFile.length !== 0 && (
        <>
          <Grid xs={12}>
            <Typography variant="h2">Review document</Typography>
          </Grid>
          <Grid xs={12}>
            <ThesisFilesViewerSection
              documentLabel=""
              fileType={ThesisFileType.CHAIR_REVIEW_FILE}
              files={chairFile}
              onDownload={onDownload}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default ChairFeedbackFormInputs
