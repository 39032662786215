import { useState } from 'react'

import { ThesisExaminerActionNotificationDataType } from '@common/thesis/thesisExaminers/actionNotificationData'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Button, darken, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { BackgroundContainer } from 'src/components/UserNotifications/Base/BackgroundContainer'

import PrincipalAdvisorAddChairDialog from '../ThesisDetails/Committee/Chair/PrincipalAdvisorAddChairDialog'
import AdvisorAddExaminerDialog from '../ThesisDetails/Committee/Examiners/components/AdvisorAddExaminer/AdvisorAddExaminerDialog'
import AdvisorUpdateExaminerCOIFormDialog from '../ThesisDetails/Committee/Examiners/components/AdvisorUpdateExaminerCOI/AdvisorUpdateExaminerCOIFormDialog'
import CandidateCOIFormDialog from '../ThesisDetails/Committee/Examiners/components/CandidateUpdateExaminerCOI/CandidateUpdateExaminerCOIDialog'
import ThesisExaminerDetailsFormDialog from '../ThesisDetails/Committee/Examiners/components/ExaminerUpdateExaminerCOI/ThesisExaminerDetailsFormDialog'
import ThesisExaminerApprovalDialog from '../ThesisDetails/Committee/Examiners/ThesisExaminerApprovalDialog'

const dialogComponents = {
  addExaminerDialog: AdvisorAddExaminerDialog,
  paAddChairDialog: PrincipalAdvisorAddChairDialog,
  candidateCOIFormDialog: CandidateCOIFormDialog,
  gsApprovalDialog: ThesisExaminerApprovalDialog,
  paCOIFormDialog: AdvisorUpdateExaminerCOIFormDialog,
  examinerCOIFormDialog: ThesisExaminerDetailsFormDialog,
}

type Props = {
  action: ThesisExaminerActionNotificationDataType
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

/**
 * Thesis examiner nomination action notification banner
 */
const ThesisExaminerNominationNotifications = ({ action, submission }: Props) => {
  const [openDialogType, setOpenDialogType] = useState(null)
  const [dialogProps, setDialogProps] = useState(null)

  const examiner = submission?.thesisSubmissionExaminers?.find((examiner) => examiner.id === action?.relatedExaminerId)

  const handleOpenDialog = (dialogType) => {
    let params = {}

    // Dynamically set the parameters based on the dialog type or notification
    switch (dialogType) {
      case 'addExaminerDialog':
        params = { submission: submission }
        break
      case 'paAddChairDialog':
        params = { submissionId: submission.id }
        break
      case 'candidateCOIFormDialog':
        params = { examiner: examiner, submission: submission }
        break
      case 'gsApprovalDialog':
        params = { examinerData: examiner, submission: submission }
        break
      case 'paCOIFormDialog':
        params = { examiner: examiner, submission: submission }
        break
      case 'examinerCOIFormDialog':
        params = { examinerData: examiner, submission: submission }
        break
      default:
        params = {}
    }

    setOpenDialogType(dialogType) // Set dialog type
    setDialogProps(params) // Set the dialog parameters
  }

  const handleCloseDialog = () => {
    setOpenDialogType(null)
    setDialogProps(null)
  }

  const ActiveDialog = openDialogType ? dialogComponents[openDialogType] : null

  return (
    <BackgroundContainer priority={2}>
      <Grid container sx={{ margin: 2, marginLeft: 3 }} alignItems={'center'}>
        <Grid item xs={'auto'} alignSelf={'center'} marginRight={1} fontSize={0.8}>
          <WarningAmberIcon sx={{ color: '#FF7700' }} />
        </Grid>

        <Grid item xs={7} sm={8} md={9}>
          <Typography
            variant="body2"
            sx={{
              width: '100%',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          >
            {action.message}
          </Typography>
        </Grid>

        <Grid item xs={'auto'} sx={{ ml: 'auto' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF7700',
              '&:hover': {
                backgroundColor: darken('#FF7700', 0.15),
              },
            }}
            onClick={() => handleOpenDialog(action.dialog)}
          >
            {action.buttonText}
          </Button>
        </Grid>
      </Grid>

      {ActiveDialog && <ActiveDialog onOpen={Boolean(openDialogType)} onClose={handleCloseDialog} {...dialogProps} />}
    </BackgroundContainer>
  )
}

export default ThesisExaminerNominationNotifications
