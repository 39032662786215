import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'

import { useController } from '@redwoodjs/forms'

type CheckboxProps = Omit<FormControlLabelProps, 'control'> & {
  checkboxProps?: MUICheckboxProps
  readOnly?: boolean
  error?: boolean
  helperText?: string
  describedBy?: string
  size?: 'small' | 'medium' | 'large'
}
export const Checkbox = (props: CheckboxProps) => {
  const { checkboxProps, readOnly, error, helperText, describedBy, size = 'medium', ...otherProps } = props

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!readOnly && !!props.onChange) props.onChange(e, checked)
  }

  return (
    <>
      <FormControlLabel
        {...otherProps}
        onChange={onChange}
        control={
          <MUICheckbox
            size={size}
            disableRipple={props.readOnly}
            aria-disabled={props.readOnly}
            {...checkboxProps}
            sx={{ mr: 1 }}
            inputProps={{
              'aria-describedby': describedBy,
            }}
          />
        }
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  )
}

type ControlledCheckboxProps = CheckboxProps & {
  name: string
  describedBy?: string
}
export const CCheckbox = (props: ControlledCheckboxProps) => {
  const { name, ...checkboxProps } = props
  const { field, fieldState } = useController({ name })

  const { ref, ...fieldProps } = field
  return (
    <Checkbox
      aria-disabled={props.readOnly}
      aria-describedby={props.describedBy}
      {...checkboxProps}
      {...fieldProps}
      inputRef={ref}
      checked={!!field.value}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : checkboxProps.helperText}
    />
  )
}
