import { GetThesisSubmissionQuery } from 'types/graphql'
import * as yup from 'yup'

export interface ExaminerUpdateSelfDetailsType {
  position: string
  institution: string

  contactNumber?: string
  contactAddress?: string
}

export const createExaminerUpdateSelfDetailsDefaultValues = (
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
) => {
  return {
    position: examiner?.position,
    institution: examiner?.institution,
    contactNumber: examiner?.contactNumber,
    contactAddress: examiner?.contactAddress,
    requirePayment: examiner?.requirePayment,
    bankAccountType: examiner?.bankAccountType,

    publishedWithCandidate: examiner?.examinerCOIStatements?.['publishedWithCandidate'],
    grantWithAdvisor: examiner?.examinerCOIStatements?.['grantWithAdvisor'],
    personalOrProfessionalRelationship: examiner?.examinerCOIStatements?.['personalOrProfessionalRelationship'],
    consultedOnThesis: examiner?.examinerCOIStatements?.['consultedOnThesis'],
    otherCOI: examiner?.examinerCOIStatements?.['otherCOI'],
    examinerCOIDetails: examiner?.examinerCOIDetails,
  }
}

export const examinerUpdateSelfDetailsSchema = yup.object().shape({
  position: yup.string().required('You must provide a position.'),
  institution: yup.string().required('You must provide an institution.'),
  contactNumber: yup
    .string()
    .required('You must provide your contact phone number.')
    .matches(/^(\+)?[0-9]*$/, 'Contact number can only contain digits and at most one "+" at the start.')
    .test(
      'plus-sign',
      'Contact phone number can only have one "+" at the start.',
      (value) => !value || value.indexOf('+') <= 0
    ),
  contactAddress: yup.string().required('You must provide your contact postal address.'),
})

export const examinerDetailsTransformer = (data: ExaminerUpdateSelfDetailsType) => {
  return {
    position: data.position,
    institution: data.institution,
    contactNumber: data?.contactNumber,
    contactAddress: data?.contactAddress,
  }
}
