import { GetThesisSubmissionQuery } from 'types/graphql'
import * as yup from 'yup'

/**
 * Examiner conflict of interest form schema
 */
export const examinerCOIFormValidationSchema = yup.object().shape({
  institution: yup.string().required('You must provide an institution.'),
  position: yup.string().required('You must provide a position.'),
  contactNumber: yup
    .string()
    .required('You must provide your contact phone number.')
    .matches(/^(\+)?[0-9]*$/, 'Contact number can only contain digits and at most one "+" at the start.')
    .test(
      'plus-sign',
      'Contact phone number can only have one "+" at the start.',
      (value) => !value || value.indexOf('+') <= 0
    ),
  contactAddress: yup.string().required('You must provide your contact postal address.'),
  requirePayment: yup.boolean().required('You must select Yes or No.'),
  bankAccountType: yup.string().when('requirePayment', {
    is: true,
    then: (schema) => schema.required('You must provide your bank account type.'),

    otherwise: (schema) => schema.notRequired(),
  }),

  publishedWithCandidate: yup.boolean().required('You must select Yes or No.'),
  grantWithAdvisor: yup.boolean().required('You must select Yes or No.'),
  personalOrProfessionalRelationship: yup.boolean().required('You must select Yes or No.'),
  consultedOnThesis: yup.boolean().required('You must select Yes or No.'),
  otherCOI: yup.boolean().required('You must select Yes or No.'),
  examinerCOIDetails: yup
    .string()
    .when(
      [
        'publishedWithCandidate',
        'grantWithAdvisor',
        'personalOrProfessionalRelationship',
        'consultedOnThesis',
        'otherCOI',
      ],
      {
        is: (
          publishedWithCandidate,
          grantWithAdvisor,
          personalOrProfessionalRelationship,
          consultedOnThesis,
          otherCOI
        ) =>
          publishedWithCandidate ||
          grantWithAdvisor ||
          personalOrProfessionalRelationship ||
          consultedOnThesis ||
          otherCOI,
        then: (schema) =>
          schema.required('You must provide details.').min(200, 'You must provide at least 200 characters of details.'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  _checkboxGroup: yup
    .array()
    .of(yup.boolean())
    .test('allConditions', 'All checkboxes must be checked.', (conditions) => {
      const checkForFalsy = conditions.some((condition) => condition === false)

      if (checkForFalsy) {
        return false
      }

      return true
    }),
})

export const ExaminerCOIDefaultValues = (
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
) => {
  return {
    institution: examiner?.institution,
    position: examiner?.position,

    contactNumber: examiner?.contactNumber,
    contactAddress: examiner?.contactAddress,
    requirePayment: examiner?.requirePayment,
    bankAccountType: examiner?.bankAccountType,
    publishedWithCandidate: examiner?.examinerCOIStatements?.['publishedWithCandidate'],
    grantWithAdvisor: examiner?.examinerCOIStatements?.['grantWithAdvisor'],
    personalOrProfessionalRelationship: examiner?.examinerCOIStatements?.['personalOrProfessionalRelationship'],
    consultedOnThesis: examiner?.examinerCOIStatements?.['consultedOnThesis'],
    otherCOI: examiner?.examinerCOIStatements?.['otherCOI'],
    examinerCOIDetails: examiner?.examinerCOIDetails,
  }
}
