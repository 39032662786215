import { useState } from 'react'

import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { GraduateSchoolUpdatePrincipalAdvisor, GraduateSchoolUpdatePrincipalAdvisorVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import { UserSelectorInput, UserSelectorUser } from 'src/components/Form/Inputs'
import QUERY from 'src/lib/graphql/mutations/GraduateSchoolUpdatePrincipalAdvisor'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

type Props = {
  open: boolean
  submissionId: number
  onCancel: () => void
  onReload: () => void
}

const UpdatePrincipalAdvisorDialog = ({ open, submissionId, onCancel, onReload }: Props) => {
  const [loading, setLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserSelectorUser>(undefined)
  const showSnackbar = useSnackbar()
  const [updatePrincipalAdvisor] = useMutation<
    GraduateSchoolUpdatePrincipalAdvisor,
    GraduateSchoolUpdatePrincipalAdvisorVariables
  >(QUERY)

  const onChangePrincipalAdvisor = async () => {
    setLoading(true)
    try {
      await updatePrincipalAdvisor({
        variables: { submissionId, eduPersonPrincipalName: selectedUser.eduPersonPrincipalName },
      })

      showSnackbar('Successfully updated Principal Advisor.', 'success')
      setLoading(false)
      onReload()
      onCancel()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update Principal Advisor. Please try again or contact support.', 'error')
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} aria-describedby="dialogcontent" aria-labelledby="dialogtitle">
      <DialogContent id="dialogcontent">
        <Typography id="dialogtitle" variant='h2' component="h2" color="primary" sx={{ mb: 2}}>
        Select new Principal Advisor
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          By changing the Principal Advisor, they will be responsible for assigning the Committee Chair, Examiners, and
          the thesis will be sent to the new advisor for review. They will be notified via email. <br />
          <br />
          The previous advisor will play no further role in the thesis review or approval. <br />
          <br />
          Note: The student will still be responsible for changing the lead investigator of the UQRDM record associated
          with the thesis.
        </Typography>

        <UserSelectorInput onSelect={setSelectedUser} disabled={loading} />
        {selectedUser && (
          <ConfirmDialog open={true} onClose={() => setSelectedUser(undefined)} onConfirm={onChangePrincipalAdvisor}>
            Are you sure you want to make {selectedUser.displayName} ({selectedUser.mail}) the Principal Advisor of this
            thesis submission?
          </ConfirmDialog>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdatePrincipalAdvisorDialog
