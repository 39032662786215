import { Dialog, DialogContent } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import SubmissionForm from '../../ThesisDetails/SubmissionForm/SubmissionForm'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  open: boolean
  onClose: () => void
  onReload: () => void
}

/**
 * Thesis submission dialog
 */
const ThesisSubmissionButtonDialog = ({ submission, open, onClose, onReload }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      fullWidth
      maxWidth="lg"
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
    >
      <DialogContent id="dialogContent">
        <SubmissionForm submission={submission} onReload={onReload} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}

export default ThesisSubmissionButtonDialog
