const GET_DEAN_ACTION_LIST = gql`
  query GetDeanActionListQuery {
    getDeanActionList {
      submissionsToBeApproved {
        principalAdvisor {
          displayName
          mail
        }
        candidate {
          id
          mail
          displayName
        }
        chair {
          id
          fileId
          user {
            id
            displayName
            mail
          }
          detailedKnowledgeOfThesis
          understandingResearchInBroaderContext
          defendMethodologyAndConclusions
          communicatedResultsAndImpact
          independentContributionToDiscipline
          amendments
          otherAmendmentDetails
          examinationSummary
          chairOutcomeRecommendation
          summaryReportCompleted
        }
        examiners {
          institution
          position
          poorThesisQuality
          fullName
          user {
            displayName
          }
          contributionKnowledge
          engagementWithLiterature
          advancedKnowledge
          researchCoherence
          convincingWriting
        }
        submission {
          id
          uuid
          reviseAndResubmitOfId
          isDowngradedToMPhil
          updatedAt
        }
        currentIteration {
          id
          type
          submittedDate
          oralDate
          files {
            id
            type
            key
            filename
            isNew
            isActive
            submissionDate
          }
        }
        currentStatus {
          id
          previousStatusId
          status
          outcome
          outcomeMessage
          createdAt
          updatedAt
          outcomeByUser {
            displayName
          }
          nextStatus {
            id
          }
        }
      }
      submissionToBeReviewed {
        candidate {
          id
          displayName
          mail
        }
        principalAdvisor {
          displayName
          mail
        }
        chair {
          user {
            id
            displayName
            mail
          }
          detailedKnowledgeOfThesis
          understandingResearchInBroaderContext
          defendMethodologyAndConclusions
          communicatedResultsAndImpact
          independentContributionToDiscipline
          amendments
          otherAmendmentDetails
          examinationSummary
          chairOutcomeRecommendation
        }
        examiners {
          oralAttendanceType
          otherOralAttendanceArrangements
          user {
            displayName
          }
        }
        submission {
          id
          uuid
          reviseAndResubmitOfId
          isDowngradedToMPhil
          updatedAt
        }
        currentIteration {
          id
          type
          submittedDate
          oralDate
          title
        }
        currentStatus {
          id
          previousStatusId
          status
          outcome
          outcomeMessage
          createdAt
          updatedAt
          outcomeByUser {
            displayName
          }
          nextStatus {
            id
          }
        }
      }
      pendingFinalSubmissionApproval {
        candidate {
          id
          displayName
          mail
        }
        principalAdvisor {
          id
          displayName
          mail
        }
        chair {
          user {
            id
            displayName
            mail
          }
          detailedKnowledgeOfThesis
          understandingResearchInBroaderContext
          defendMethodologyAndConclusions
          communicatedResultsAndImpact
          independentContributionToDiscipline
          amendments
          otherAmendmentDetails
          examinationSummary
          chairOutcomeRecommendation
        }
        examiners {
          oralAttendanceType
          otherOralAttendanceArrangements
          user {
            displayName
            mail
          }
        }
        submission {
          id
          uuid
          reviseAndResubmitOfId
          isDowngradedToMPhil
          updatedAt
        }
        currentIteration {
          id
          title
          type
          submittedDate
          oralDate
          organisation {
            name
          }
        }
        currentStatus {
          id
          previousStatusId
          status
          outcome
          outcomeMessage
          createdAt
          updatedAt
          outcomeByUser {
            displayName
          }
          nextStatus {
            id
          }
        }
      }
      examinersToBeApproved {
        examiner {
          id
          status
          title
          fullName
          mail
          position
          institution
          rationaleForNomination
          advisorCOIDetails
          advisorCOIStatements
          candidateCOIDetails
          candidateCOIStatements
          examinerCOIDetails
          examinerCOIStatements
          updatedAt
          user {
            displayName
            mail
          }
        }
        submission {
          id
          creator {
            displayName
            mail
          }
          principalAdvisor {
            displayName
            mail
          }
        }
      }
    }
  }
`

export default GET_DEAN_ACTION_LIST
