import { ThesisSubmissionIterationStatus } from 'types/graphql'

import { getThesisNotificationState } from 'src/lib/helpers/thesis'

import { getThesisSubmissionStepperInfo } from './helpers'
import Stepper from './Stepper/Stepper'

type Props = {
  iteration: {
    currentStatus: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>
  }
}

/**
 * Thesis submission change request process stepper
 */
const ThesisSubmissionStepperChangesRequested = ({ iteration }: Props) => {
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration }, 'REQUEST_CHANGES')
  const notificationState = getThesisNotificationState(iteration?.currentStatus)

  return (
    <Stepper
      steps={[
        { tooltip: 'Candidate submits corrections', priority: notificationState },
        { tooltip: 'Principal Advisor reviews corrections', priority: notificationState },
        { tooltip: 'Chair reviews corrections', priority: notificationState },
        { tooltip: 'Pending Dean outcome', priority: notificationState },
        { tooltip: 'Approved for thesis finalisation', priority: notificationState },
      ]}
      {...stepperInfo}
    />
  )
}

export default ThesisSubmissionStepperChangesRequested
