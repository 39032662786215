import { useState } from 'react'

import {
  STATUSES_TO_SHOW_GS_REJECTION_MESSAGE,
  STATUSES_TO_SHOW_INITIAL_GS_CHANGE_REQUEST_MESSAGE,
} from '@common/thesis/thesisExaminers/getThesisExaminerStatuses'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import BasicExaminerDetails from 'src/components/Thesis/ThesisDetails/Committee/Examiners/components/ExaminerCOIOverallDetailsViewer/BasicExaminerDetails'
import CANDIDATE_SUBMITS_EXAMINER_COI from 'src/lib/graphql/mutations/CandidateSubmitsExaminerCOI'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { isAssignedThesisCandidate, isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

// src\components\Thesis\ThesisDetails\Committee\Examiners\components\ExaminerCOIOverallDetailsViewer\ExaminerCOIOverallDetailsViewer.tsx

import CandidateUpdateExaminerCOIForm from './CandidateUpdateExaminerCOIForm'
import { CandidateCOIDefaultFormValues, candidateCOIFormSchema } from './schema'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
  readOnly?: boolean
}

/**
 * Candidate Examiner COI form dialog
 */
const CandidateUpdateExaminerCOIDialog = ({ submission, examiner, onOpen, onClose, readOnly }: Props) => {
  const { hasRole, currentUser } = useAuth()

  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)

  const [candidateSubmitsExaminerCOIMutation] = useMutation(CANDIDATE_SUBMITS_EXAMINER_COI, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm({
    defaultValues: CandidateCOIDefaultFormValues(examiner),
    resolver: yupResolver(candidateCOIFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data) => {
    setSaving(true)

    try {
      const result = await candidateSubmitsExaminerCOIMutation({
        variables: { submissionId: examiner.submissionId, examinerId: examiner.id, candidateCOIInputs: data },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully submitted your COI disclosure.", 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not submit COI disclosure. Please try again later.', 'error')
    }

    setSaving(false)
    onClose()
  }

  const hasValidationError = !formMethods.formState.isValid

  const isGS = hasRole('grad-school-user')
  const isAdvisor = isAssignedThesisPrincipalAdvisor(currentUser, submission?.principalAdvisor.id)
  const isCandidate = isAssignedThesisCandidate(currentUser, submission?.creator?.id)

  const initialGSChangeRequestStatuses = STATUSES_TO_SHOW_INITIAL_GS_CHANGE_REQUEST_MESSAGE.has(examiner?.status)

  const showGSOutcomeMessageForPA =
    !!examiner && !!examiner?.outcomeMessage && initialGSChangeRequestStatuses && (isGS || isAdvisor || isCandidate)

  return (
    <Dialog
      open={onOpen}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' || readOnly) {
          onClose()
        }
      }}
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      maxWidth="lg"
    >
      <DialogTitle color="primary" variant="h2" component="h2" id="dialogTitle">
        Examiner nomination
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogContent" sx={{ pt: 0 }}>
          <Grid container spacing={2}>
            {showGSOutcomeMessageForPA && (
              <Grid xs={12}>
                <Alert severity="warning" role="banner">
                  <AlertTitle>{`Graduate School has ${
                    STATUSES_TO_SHOW_GS_REJECTION_MESSAGE.has(examiner.status)
                      ? 'rejected this examiner'
                      : 'requested changes'
                  }`}</AlertTitle>
                  <Box sx={{ width: '99%' }}>
                    {examiner?.outcomeMessage && (
                      <Typography variant="body1">
                        <i>{`"${examiner.outcomeMessage}"`}</i>
                      </Typography>
                    )}
                  </Box>
                </Alert>
              </Grid>
            )}
            <Grid xs={12}>
              <BasicExaminerDetails examiner={examiner} readOnly={readOnly} />
            </Grid>
            <Grid xs={12}>
              <CandidateUpdateExaminerCOIForm examiner={examiner} submission={submission} readOnly={readOnly} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid xs={12}>
            <Grid container spacing={1} justifyContent={'flex-end'} flexDirection={'row'}>
              <Grid>
                <Button variant="contained" color="secondary" onClick={() => onClose()} disabled={saving}>
                  Cancel
                </Button>
              </Grid>
              {!readOnly && (
                <Grid>
                  <Button variant="contained" color="primary" type="submit" disabled={hasValidationError}>
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CandidateUpdateExaminerCOIDialog
