import { useEffect } from 'react'

import { titles } from '@common/inputFormData'
import { YES_NO_INPUTS } from '@common/thesis/thesisExaminers/examinationFormData'
import { MenuItem } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'

import { CRadioGroup, CSelect, CTextField } from 'src/components/Form/Inputs'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  examinerHasUQAccount?: boolean
}

/**
 * Examiner update form.
 */
const GraduateSchoolExaminerDetailsUpdateForm = ({ examiner, examinerHasUQAccount }: Props) => {
  const formMethods = useFormContext()
  const needsPayment = formMethods.watch('requirePayment')

  useEffect(() => {
    if (!needsPayment) {
      formMethods.setValue('bankAccountType', null)
    }
  }, [needsPayment])
  return (
    <Grid container spacing={2}>
      {!examinerHasUQAccount && (
        <Grid xs={2}>
          <CSelect required={true} label={'Title'} name="title" readOnly={examinerHasUQAccount}>
            {titles.map((o) => (
              <MenuItem key={o.label} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </CSelect>
        </Grid>
      )}
      <Grid xs={12} sm={5}>
        <CTextField name="fullName" label="Full name" required fullWidth readOnly={examinerHasUQAccount} />
      </Grid>
      <Grid xs={12} sm={5}>
        <CTextField
          name="mail"
          label="Email"
          type="email"
          required
          fullWidth
          autoComplete="off"
          readOnly={examinerHasUQAccount}
        />
      </Grid>
      <Grid xs={6}>
        <CTextField name="position" label="Position" required fullWidth />
      </Grid>
      <Grid xs={6}>
        <CTextField name="institution" label="Institution" required fullWidth />
      </Grid>

      {examiner?.contactNumber && (
        <Grid xs={12} sm={6}>
          <CTextField name="contactNumber" label="Contact phone number" fullWidth />
        </Grid>
      )}
      {examiner?.contactAddress && (
        <Grid xs={12}>
          <CTextField name="contactAddress" label="Contact postal address" fullWidth multiline />
        </Grid>
      )}
      <Grid xs={12}>
        <CTextField name="rationaleForNomination" label="Rationale for nomination" multiline fullWidth />
      </Grid>
      {typeof examiner?.requirePayment === 'boolean' && (
        <>
          <Grid xs={12} sm={examiner?.bankAccountType ? 'auto' : 12}>
            <CRadioGroup size="small" label="Payment required" name="requirePayment" required options={YES_NO_INPUTS} />
          </Grid>
          {needsPayment && (
            <Grid xs={12}>
              <CRadioGroup
                label="Bank account type"
                name="bankAccountType"
                required
                aria-label="Bank account type"
                options={[
                  { label: 'Australian Bank Account', value: 'AUS' },
                  { label: 'International Bank Account', value: 'INTERNATIONAL' },
                ]}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default GraduateSchoolExaminerDetailsUpdateForm
