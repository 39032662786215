import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Button, Dialog, DialogContent, Link, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetDeanActionListQuery } from 'types/graphql'

import { Form } from '@redwoodjs/forms'

import { DeanPassSubmissionButton } from 'src/components/Thesis'
import DeanRequestsCorrectionsFromChairButton from 'src/components/Thesis/DeanActions/RequestCorrectionsFromChairButton/DeanRequestsCorrectionsFromChairButton'

type Props = {
  onOpen: boolean
  onClose: () => void
  input: GetDeanActionListQuery['getDeanActionList']['submissionToBeReviewed'][0]
  onRefetch: () => void
}

const ReviewChairDecisionChangedRequestDialog = ({ onOpen, onClose, input, onRefetch }: Props) => {
  return (
    <Dialog open={onOpen} maxWidth="md" fullWidth={true}>
      <Form /* formMethods={formMethods} onSubmit={onInitiateThesis} */>
        <DialogContent>
          <Typography id="dialogTitle" component="h2" variant="h2" color="primary" sx={{ mb: 2 }}>
            Thesis correction review
          </Typography>
          <Typography>
            The Chair ({input.chair.user.displayName}) has reviewed the corrected thesis and recommends that it pass
            examination. Please review the thesis details and endorse an outcome.
          </Typography>
          <Grid container spacing={1} sx={{ bgcolor: 'rgba(81, 36, 122, 0.075)', borderRadius: 2, p: 1, mb: 2, mt: 2 }}>
            <Grid xs={12}>
              <b>Thesis title</b> : <span dangerouslySetInnerHTML={{ __html: input.currentIteration?.title }} />
            </Grid>
            <Grid xs={12}>
              <b>Candidate</b> : {input.candidate.displayName}
              <Tooltip title={`Email: ${input.candidate.mail}`}>
                <Link href={`mailto:${input.candidate.mail}`}>
                  <EmailOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
                </Link>
              </Tooltip>
            </Grid>
            <Grid xs={12}>
              <b>Principal Advisor</b> : {input.principalAdvisor.displayName}
              <Tooltip title={`Email: ${input.principalAdvisor.mail}`}>
                <Link href={`mailto:${input.principalAdvisor.mail}`}>
                  <EmailOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
                </Link>
              </Tooltip>
            </Grid>
            <Grid xs={12}>
              <b>Chair</b> : {input.chair.user.displayName}
              <Tooltip title={`Email: ${input.chair.user.mail}`}>
                <Link href={`mailto:${input.chair.user.mail}`}>
                  <EmailOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
                </Link>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid xs />
            <Grid xs="auto">
              <Button variant="outlined" color="primary" onClick={onClose}>
                Close
              </Button>
            </Grid>
            <Grid xs="auto">
              <DeanRequestsCorrectionsFromChairButton
                key={0}
                iterationId={input.currentIteration?.id}
                onReload={onRefetch}
                onClose={onClose}
              />
            </Grid>
            <Grid xs={'auto'}>
              <DeanPassSubmissionButton
                key={1}
                iterationId={input.currentIteration?.id}
                onReload={onRefetch}
                onClose={onClose}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Form>
    </Dialog>
  )
}

export default ReviewChairDecisionChangedRequestDialog
