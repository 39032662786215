import { useState } from 'react'

import { CandidateSubmitsFinalThesis, CandidateSubmitsFinalThesisVariables, MyRecordsQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import CANDIDATE_SUBMITS_FINAL_THESIS from 'src/lib/graphql/mutations/CandidateSubmitsFinalThesis'
import useSnackbar from 'src/lib/hooks/useSnackbar'

import { ThesisSubmission } from '../../ThesisSubmissionDetailsPage'

import FinalThesisIterationForm from './components/FinalThesisIterationForm'
import { ThesisSubmissionFormData, createFinalThesisSubmissionInput } from './helpers'

type Props = {
  onReload: () => unknown
  onClose: () => void
  submission: ThesisSubmission
  userRecordsSummary: MyRecordsQuery['userRecordsSummary']
}

const FinalThesisSubmissionForm = ({ userRecordsSummary, onReload, onClose, submission }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)

  const [candidateSubmitsFinalThesis] = useMutation<CandidateSubmitsFinalThesis, CandidateSubmitsFinalThesisVariables>(
    CANDIDATE_SUBMITS_FINAL_THESIS
  )
  const onSubmit = async (data: ThesisSubmissionFormData) => {
    setSaving(true)
    try {
      await candidateSubmitsFinalThesis({
        variables: { input: createFinalThesisSubmissionInput(data), submissionId: submission.id },
      })
      showSnackbar('Congratulations! Your thesis has been submitted and the Graduate School will review.', 'success')
      onReload()
    } catch (error) {
      showSnackbar('Could not submit your thesis. Please try again later.', 'error')
      setSaving(false)
    }
  }

  return (
    <FinalThesisIterationForm
      userRecordsSummary={userRecordsSummary}
      onSubmit={onSubmit}
      submission={submission}
      saving={saving}
      disabled={false}
      onClose={onClose}
    />
  )
}

export default FinalThesisSubmissionForm
