import { useState } from 'react'

import { Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { TextField } from '../../Base/TextField'

type Props = {
  disabled: boolean
  loading: boolean
  onSearch: (searchString: string) => void
}

const SearchFilter = ({ disabled, loading, onSearch }: Props) => {
  const [fieldState, setFieldState] = useState({ value: '', error: undefined })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isError = e.target.value.length < 3
    setFieldState({ value: e.target.value, error: isError ? 'Please enter at least 3 characters' : undefined })
  }

  const onSearchClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (fieldState.error === undefined) onSearch(fieldState.value)
  }

  return (
    <form onSubmit={onSearchClick}>
      <Grid container spacing={2}>
        <Grid xs>
          <TextField
            variant="filled"
            label="Search user by name, email or username..."
            value={fieldState.value}
            helperText={fieldState.error}
            error={!!fieldState.error}
            onChange={onChange}
            disabled={disabled}
          />
        </Grid>
        <Grid sx={{ pt: 2 }}>
          <LoadingButton variant="contained" color="secondary" endIcon={<Search />} loading={loading} type="submit">
            Search
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default SearchFilter
