import { Typography } from '@mui/material'
import { ThesisSubmissionHistoryQuery } from 'types/graphql'

import ThesisHistoryIterationAccordion from './ThesisHistoryIterationAccordion'

type Props = {
  iterations: ThesisSubmissionHistoryQuery['thesisSubmissionHistory']
  openAccordion: number
  onOpenIteration: (iterationId: number) => void
  onToggleAccordion: (iterationId: number) => void
}

const ThesisHistoryList = ({ iterations, openAccordion, onToggleAccordion, onOpenIteration }: Props) => {
  if (iterations.length === 0) {
    return (
      <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
        There have been no thesis submissions in this iteration.
      </Typography>
    )
  }

  // Show iterations in reverse order
  const reversedArray = [...iterations].reverse()

  return (
    <>
      {reversedArray.map((iteration) => (
        <ThesisHistoryIterationAccordion
          hasFiles={iteration.hasFiles}
          key={iteration.id}
          expanded={iteration.id === openAccordion}
          iteration={iteration}
          onToggleExpand={() => onToggleAccordion(iteration.id)}
          onViewIteration={() => onOpenIteration(iteration.id)}
        />
      ))}
    </>
  )
}

export default ThesisHistoryList
