import { ThesisIterationDetailsQuery, ThesisIterationDetailsQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Alert from 'src/components/Alert/Alert'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import THESIS_ITERATION_DETAILS_QUERY from 'src/lib/graphql/queries/ThesisIterationDetailsQuery'

import ThesisIterationFormViewer from '../../ThesisIterationFormViewer/ThesisIterationFormViewer'

type Props = {
  iterationId: number
}

const ThesisHistoryIterationView = ({ iterationId }: Props) => {
  const { data, error, loading } = useQuery<ThesisIterationDetailsQuery, ThesisIterationDetailsQueryVariables>(
    THESIS_ITERATION_DETAILS_QUERY,
    { variables: { iterationId } }
  )

  if (loading) return <InlineLoader message="Loading iteration details..." />
  if (error) return <Alert severity="error">Could not load iteration details</Alert>

  return <ThesisIterationFormViewer iteration={data.thesisIterationDetails} />
}

export default ThesisHistoryIterationView
