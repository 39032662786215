import { useApolloClient } from '@apollo/client'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import { Stack } from '@mui/material'
import { GetThesisFileDownloadUrlQuery } from 'types/graphql'

import { ThesisSubmissionIterationType } from 'src/components/Thesis'
import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ThesisFilesViewerSection from './ThesisFilesViewerSection'

export type ThesisFileFields = Pick<
  ThesisSubmissionIterationType['files'][0],
  'id' | 'type' | 'filename' | 'submissionDate' | 'isNew' | 'isActive'
>

type Props = {
  files: ThesisFileFields[]
  showThesisFile?: boolean
  showIthenticateFile?: boolean
  showCorrectionsFile?: boolean
  showExaminerFile?: boolean
  showChairFile?: boolean
}

/**
 * Shows all uploaded thesis files for a submitted iteration
 */
const ThesisFilesViewer = ({
  files,
  showThesisFile = true,
  showIthenticateFile,
  showCorrectionsFile,
  showExaminerFile,
  showChairFile,
}: Props) => {
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()

  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const supplementary = files.filter((f) => f.type === ThesisFileType.SUPPLEMENTARY_FILE)

  return (
    <Stack spacing={2}>
      {/** Thesis document */}
      {showThesisFile && (
        <ThesisFilesViewerSection
          documentLabel="Thesis document"
          fileType={ThesisFileType.THESIS_FILE}
          files={files}
          onDownload={onDownload}
        />
      )}

      {/** Thesis iThenticate file */}
      {showIthenticateFile && (
        <ThesisFilesViewerSection
          documentLabel="Thesis iThenticate similarity report"
          fileType={ThesisFileType.ITHENTICATE_FILE}
          files={files}
          onDownload={onDownload}
        />
      )}

      {/** Thesis supplementary files */}
      {supplementary.length > 0 && (
        <ThesisFilesViewerSection
          documentLabel="Supplementary files"
          fileType={ThesisFileType.SUPPLEMENTARY_FILE}
          files={supplementary}
          onDownload={onDownload}
        />
      )}

      {/** Thesis correction files */}
      {showCorrectionsFile && (
        <ThesisFilesViewerSection
          documentLabel="Corrections file"
          fileType={ThesisFileType.CORRECTIONS_FILE}
          files={files}
          onDownload={onDownload}
        />
      )}

      {/** Thesis examiner feedback files */}
      {showExaminerFile && (
        <ThesisFilesViewerSection
          documentLabel="Examiner review file"
          fileType={ThesisFileType.EXAMINER_REVIEW_FILE}
          files={files}
          onDownload={onDownload}
        />
      )}

      {/** Thesis Chair feedback file */}
      {showChairFile && (
        <ThesisFilesViewerSection
          documentLabel="Chair review file"
          fileType={ThesisFileType.CHAIR_REVIEW_FILE}
          files={files}
          onDownload={onDownload}
        />
      )}
    </Stack>
  )
}

export default ThesisFilesViewer
