import { ThesisSubmissionIterationStatus } from 'types/graphql'

import { getThesisNotificationState } from 'src/lib/helpers/thesis'

import { getThesisSubmissionStepperInfo } from './helpers'
import Stepper from './Stepper/Stepper'

type Props = {
  iteration: {
    currentStatus: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>
  }
}

/**
 * Initial submission process stepper.
 */
const ThesisSubmissionStepperInitial = ({ iteration }: Props) => {
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration }, 'INITIAL')

  const notificationState = getThesisNotificationState(iteration?.currentStatus)

  return (
    stepperInfo && (
      <Stepper
        steps={[
          { tooltip: 'Candidate submits thesis', priority: notificationState },
          { tooltip: 'Graduate School reviews thesis', priority: notificationState },
          { tooltip: 'Principal Advisor reviews thesis', priority: notificationState },
          { tooltip: 'Graduate School initiates examination', priority: notificationState },
          { tooltip: 'Examiners review thesis', priority: notificationState },
          { tooltip: 'Graduate School schedules oral', priority: notificationState },
          { tooltip: 'Await oral examination', priority: notificationState },
          { tooltip: 'Chair reviews examination', priority: notificationState },
          { tooltip: 'Pending Dean outcome', priority: notificationState },
          { tooltip: 'Approved for thesis finalisation', priority: notificationState },
        ]}
        {...stepperInfo}
      />
    )
  )
}

export default ThesisSubmissionStepperInitial
