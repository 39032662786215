import { CheckCircle, Info } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import StyledHourglassIcon from 'src/components/Icons/HourGlassIcon'
import { isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

/**
 * Chair information viewer
 */
const ChairView = ({ submission }: Props) => {
  const { currentUser } = useAuth()

  const chair = submission?.chair
  const isAssignedPA = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  return (
    <>
      <Grid container alignContent={'flex-start'}>
        <Grid>
          {chair ? <CheckCircle sx={{ color: '#00b3a6', width: '1.1em', height: '1.1em' }} /> : <StyledHourglassIcon />}
        </Grid>
        <Grid xs sx={{ ml: 1 }}>
          <Grid direction={'column'} container>
            {chair ? (
              <>
                <Grid xs={12}>
                  <Typography variant="subtitle1" sx={{ color: 'white' }}>
                    {chair.user.displayName}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography
                    fontSize="small"
                    color={'#eeeeee'}
                    sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >{`${submission?.chair?.user.organisation?.name}`}</Typography>
                </Grid>
              </>
            ) : (
              <Grid xs={12}>
                <Typography variant="subtitle1" sx={{ color: 'white' }}>
                  {'Principal Advisor to assign'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isAssignedPA && (
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid xs={'auto'}>
            <Info sx={{ color: 'info.light', width: '1.1em', height: '1.1em' }} />
          </Grid>
          <Grid xs>
            <Typography variant="body2" sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: 'info.light' }}>
              If you would like to change the Chair please contact Graduate School.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ChairView
