import { useState } from 'react'

import { DeanDowngradeToMPhil, DeanDowngradeToMPhilVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import DEAN_DOWNGRADE_TO_MPHIL from 'src/lib/graphql/mutations/DeanDowngradeToMPhil'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import DowngradeToMPhilButtonDialog from './DowngradeToMPhilButtonDialog'

type Props = {
  iterationId: number
  onReload: () => unknown
  onClose: () => void
}

const DowngradeToMPhilButton = ({ iterationId, onReload, onClose }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [downgrade] = useMutation<DeanDowngradeToMPhil, DeanDowngradeToMPhilVariables>(DEAN_DOWNGRADE_TO_MPHIL)
  const showSnackbar = useSnackbar()

  const onConfirmRequestChanges = async () => {
    setSaving(true)
    try {
      const result = await downgrade({ variables: { iterationId } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully downgraded the submission to MPhil", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not request downgrade the submission. Please try again later.', 'error')
      setSaving(false)
    }

    onClose()
  }

  return (
    <>
      <Button sx={{ mb: 1 }} variant="contained" color="warning" onClick={() => setOpen(true)}>
        Downgrade to MPhil
      </Button>
      <DowngradeToMPhilButtonDialog
        open={open}
        saving={saving}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirmRequestChanges}
      />
    </>
  )
}

export default DowngradeToMPhilButton
