import { useState } from 'react'

import { thesisTypes } from '@common/thesis'
import { STATUSES_BEFORE_SET_ORAL_EXAM_DATE } from '@common/thesis/getThesisStatuses'
import RestoreIcon from '@mui/icons-material/Restore'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'
import { toTimezone } from 'src/lib/toTimezone'
import { isAssignedThesisCandidate, isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

import ThesisHistoryDialog from '../../ThesisHistoryButton/components/ThesisHistoryDialog'

const styles = {
  headerBackground: {
    color: 'white',
    backgroundColor: 'primary.main',
    background: 'linear-gradient(90deg, rgba(81,36,122,1) 30%, rgba(150,42,139,1) 90%)',
  },
  contentBackground: {
    backgroundColor: 'primary.main',
    background: 'linear-gradient(90deg, rgba(81,36,122,1) 30%, rgba(150,42,139,1) 90%)',
  },
}

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

/**
 * Candidature card that includes all necessary submission details for the thesis
 */
const CandidatureCard = ({ submission }: Props) => {
  const { currentUser, hasRole } = useAuth()
  const [dialogOpen, setDialogOpen] = useState(false)

  const orgName = submission?.currentIteration?.organisation?.name
  const degreeType = thesisTypes.find((type) => type.code === submission?.currentIteration?.type)?.name
  const recordName = submission?.currentIteration?.record?.name
  const intendedSubmissionDate = submission?.currentIteration?.intendedSubmissionDate
  const thesisSubmittedDate = submission?.currentIteration?.submittedDate
  const oralDateNotScheduled = STATUSES_BEFORE_SET_ORAL_EXAM_DATE.has(
    submission?.currentIteration?.currentStatus?.status
  )
  const hasOral = submission?.currentIteration?.hasOral

  const isCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const isAdvisor = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const isGS = hasRole('grad-school-user')
  const onToggleHistoryDialog = () => setDialogOpen(!dialogOpen)
  return (
    <ContentCard
      title={`Candidature`}
      headerSx={styles.headerBackground}
      sx={styles.contentBackground}
      action={
        (isCandidate || isGS || isAdvisor) && (
          <Tooltip title={'View candidature history'} placement="top">
            <IconButton onClick={onToggleHistoryDialog}>
              <RestoreIcon sx={{ color: '#FFFFFF' }} />
            </IconButton>
          </Tooltip>
        )
      }
    >
      <ThesisHistoryDialog submissionId={submission.id} open={dialogOpen} onClose={onToggleHistoryDialog} />
      <Grid container spacing={0}>
        {recordName && (
          <Grid item xs>
            <Typography variant="body2" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <b>UQRDM record of HDR project:</b> {recordName}
            </Typography>
          </Grid>
        )}
        {degreeType && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <b>Degree:</b> {degreeType}
            </Typography>
          </Grid>
        )}
        {orgName && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <b>UQ organisational unit:</b> {orgName}
            </Typography>
          </Grid>
        )}
        {thesisSubmittedDate && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <b>Thesis submitted:</b> {toTimezone(thesisSubmittedDate)}
            </Typography>
          </Grid>
        )}
        {intendedSubmissionDate && !thesisSubmittedDate && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <b>Expected submission date:</b> {toTimezone(intendedSubmissionDate)}
            </Typography>
          </Grid>
        )}
        {hasOral && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
              <b>{`${oralDateNotScheduled ? 'Tentative' : 'Scheduled'} oral examination date:`}</b>{' '}
              {submission?.currentIteration?.oralDate
                ? toTimezone(submission?.currentIteration?.oralDate)
                : 'Date not recorded'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ContentCard>
  )
}

export default CandidatureCard
