import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import CHAIR_SAVES_FEEDBACK from 'src/lib/graphql/mutations/ChairSavesFeedback'
import CHAIR_SUBMITS_FEEDBACK from 'src/lib/graphql/mutations/ChairSubmitsFeedback'
import CHAIR_SUBMITS_ORAL_FEEDBACK from 'src/lib/graphql/mutations/ChairSubmitsOralFeedback'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { isAssignedThesisChair } from 'src/lib/userAccessChecker'

import ChairFeedbackFormInputs from './components/FormInputs'
import ChairFeedbackReview from './components/FormReview'
import {
  BaseChairSummaryFormDefaultInputType,
  OralChairSummaryFormDefaultInputType,
  createChairSummaryFormSaveInput,
  createNonOralChairSummaryFormDefaultValues,
  createNonOralChairSummaryFormSaveInput,
  createNonOralChairSummaryFormSubmissionInput,
  createNonOralChairSummaryFormValidationSchema,
  createOralChairSummaryFormDefaultValues,
  createOralChairSummaryFormSubmissionInput,
  createOralChairSummaryFormValidationSchema,
} from './helpers'

type Props = {
  open: boolean
  onCancel: () => unknown
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair'] | null
  onReload: () => void
}

const ChairReviewDialog = ({ open, onCancel, submission, chair, onReload }: Props) => {
  const { currentUser } = useAuth()
  const showSnackbar = useSnackbar()

  const [isPending, setIsPending] = useState<boolean>(false)
  const [chairSubmitsOralFeedback] = useMutation(CHAIR_SUBMITS_ORAL_FEEDBACK)
  const [chairSubmitsFeedback] = useMutation(CHAIR_SUBMITS_FEEDBACK)
  const [chairSavesFeedback] = useMutation(CHAIR_SAVES_FEEDBACK)

  const isNonOralRnR = submission.currentIteration.hasOral === false && submission.reviseAndResubmitOfId !== null

  const formMethods = useForm({
    defaultValues: isNonOralRnR
      ? createNonOralChairSummaryFormDefaultValues(chair, submission)
      : createOralChairSummaryFormDefaultValues(chair, submission),
    resolver: yupResolver(
      isNonOralRnR ? createNonOralChairSummaryFormValidationSchema() : createOralChairSummaryFormValidationSchema()
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (userInput: OralChairSummaryFormDefaultInputType) => {
    setIsPending(true)

    try {
      await chairSubmitsOralFeedback({
        variables: {
          submissionId: submission.id,
          chairId: chair.id,
          input: createOralChairSummaryFormSubmissionInput(userInput),
        },
      })

      showSnackbar("You've successfully submitted the Chair summary report.", 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not submit your chair summary report. Please try again later.', 'error')
    }

    setIsPending(false)
    onReload()
  }

  const onSubmitNonOral = async (userInput: BaseChairSummaryFormDefaultInputType) => {
    setIsPending(true)
    try {
      await chairSubmitsFeedback({
        variables: {
          submissionId: submission.id,
          chairId: chair.id,
          input: createNonOralChairSummaryFormSubmissionInput(userInput),
        },
      })

      showSnackbar("You've successfully submitted the Chair summary report.", 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not submit your chair summary report. Please try again later.', 'error')
    }

    setIsPending(false)
    onReload()
  }

  const onSave = async () => {
    setIsPending(true)
    const values = formMethods.getValues()

    try {
      await chairSavesFeedback({
        variables: {
          submissionId: submission.id,
          chairId: chair.id,
          input: isNonOralRnR
            ? createNonOralChairSummaryFormSaveInput(values)
            : createChairSummaryFormSaveInput(values),
        },
      })

      showSnackbar(
        `Successfully saved your ${isNonOralRnR ? `chair summary report.` : `oral examination chair summary report.`}`,
        'success'
      )
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar(
        `Could not save your${!isNonOralRnR && ` oral examination`} chair summary report. Please try again later.`,
        'error'
      )
    }

    setIsPending(false)
    onReload()
  }

  const hasOral = submission?.currentIteration?.hasOral
  const isAssignedChair = isAssignedThesisChair(currentUser, chair?.user?.id)
  const currentStatus = submission?.currentIteration?.currentStatus.status
  const completedFeedback = chair?.summaryReportCompleted
  const readOnly =
    completedFeedback ||
    !isAssignedChair ||
    (currentStatus !== 'AWAITING_CHAIR_EXAMINATION_OUTCOME' && currentStatus !== 'AWAITING_ORAL_EXAMINATION')

  const hasValidationError = !formMethods.formState.isValid
  const hasModifiedForm = formMethods.formState.isDirty

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel()
        }
      }}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
    >
      <Form formMethods={formMethods} onSubmit={isNonOralRnR ? onSubmitNonOral : onSubmit}>
        <DialogContent id="dialogContent">
          <Typography color="primary" id="dialogTitle" component="h2" variant="h2">
            Chair summary report
          </Typography>
          <Grid container spacing={2}>
            {readOnly ? (
              <ChairFeedbackReview chair={chair} submission={submission} hasOral={hasOral} />
            ) : (
              <ChairFeedbackFormInputs chair={chair} submission={submission} hasOral={hasOral} />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onCancel} disabled={isPending}>
            Close
          </Button>
          {!readOnly && (
            <>
              <Button variant="outlined" color="secondary" onClick={() => formMethods.reset()} disabled={isPending}>
                Reset
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onSave()}
                disabled={isPending || !hasModifiedForm}
              >
                Save
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={isPending || hasValidationError}>
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ChairReviewDialog
