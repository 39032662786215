const GRADUATE_SCHOOL_UPDATES_PARTIAL_EXAMINER_DETAILS = gql`
  mutation GraduateSchoolUpdatesPartialExaminerDetails(
    $id: Int!
    $submissionId: Int!
    $examinerData: GraduateSchoolUpdatesPartialExaminerDetailsInput!
  ) {
    graduateSchoolUpdatesPartialExaminerDetails(id: $id, submissionId: $submissionId, examinerData: $examinerData)
  }
`

export default GRADUATE_SCHOOL_UPDATES_PARTIAL_EXAMINER_DETAILS
