export default {
  title: 'UQ school or institution',
  text: (
    <div>
      <p>
        Choose the school or institute that the you are associated with at UQ. This may be different than the
        organisational unit of your advisor and/or your UQRDM record. This information will be attached to you thesis
        when sent to UQ eSpace and will be used for reporting and monitoring of HDR thesis submissions.
      </p>
    </div>
  ),
}
