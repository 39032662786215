import ExternalLink from 'src/components/ExternalLink/ExternalLink'

export default {
  title: 'Field of research codes',
  text: (
    <div>
      <p>
        Type in a relevant numerical code or the name of the field of research to search.&nbsp;
        <ExternalLink href="https://www.abs.gov.au/statistics/classifications/australian-and-new-zealand-standard-research-classification-anzsrc/2020">
          See a full list of codes
        </ExternalLink>
        .
      </p>
      <p>
        To request storage or submit a record for approval, you must enter&nbsp;<strong>at least one</strong>
        &nbsp;field of research code.
      </p>
      <p>
        The 2020 ANZSRC Field of research (FOR) classification allows research and development activity to be
        categorised according to the field of research. In this respect, it is the methodology used in the research and
        development that is being considered.
      </p>
      <p>
        The categories in the classification include major fields and related sub-fields of research and emerging areas
        of study investigated by businesses, universities, tertiary institutions, national research institutions and
        other organisations.
      </p>
    </div>
  ),
}
