import thesisTypes from '@common/thesis/thesisTypes'
import { Box, InputLabel } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { TextField } from 'src/components/Form/Inputs'

import { ThesisSubmissionIterationType } from '../ThesisSubmissionType'

import ThesisFilesViewer from './components/ThesisFilesViewer'

type Props = {
  iteration: ThesisSubmissionIterationType
}

/**
 * Shows information for a single iteration of a thesis submission. This is purposefully a read-only view
 * in order to keep the logic as simple as possible. Separation of concerns is important here.
 */
const InitialThesisIterationFormViewer = ({ iteration }: Props) => {
  const hasCorrectionFile = iteration.files.some((f) => f.type === 'CORRECTIONS_FILE')

  return (
    <Grid container={true} spacing={2}>
      <Grid xs={12}>
        <TextField label="UQRDM record connected to the thesis" value={iteration.record.name} readOnly required />
      </Grid>
      <Grid xs={12}>
        <InputLabel shrink={true} id="thesislabel" required>
          Thesis title
        </InputLabel>
        <Box
          dangerouslySetInnerHTML={{ __html: iteration.title }}
          sx={{ mt: -2, pb: 0 }}
          tabIndex={0}
          aria-labelledby="thesislabel"
        />
      </Grid>
      <Grid sm={12} md={6} lg={5}>
        <TextField label="Organisation" value={iteration.organisation.name} readOnly required />
      </Grid>
      <Grid sm={12} md={6} lg={1}>
        <TextField label="Degree" value={thesisTypes.find((t) => t.code === iteration.type).name} readOnly required />
      </Grid>
      <Grid sm={12} md={6} lg={3}>
        <TextField label="Principal advisor" value={iteration.principalAdvisor.displayName} readOnly required />
      </Grid>
      <Grid sm={12} md={6} lg={3}>
        <TextField
          label="Additional supervisors"
          value={iteration.additionalSupervisors.map((s) => s.displayName).join(', ')}
          InputLabelProps={{ shrink: true }}
          readOnly
        />
      </Grid>
      <Grid xs={12}>
        <ThesisFilesViewer files={iteration.files} showIthenticateFile={true} showCorrectionsFile={hasCorrectionFile} />
      </Grid>
    </Grid>
  )
}

export default InitialThesisIterationFormViewer
