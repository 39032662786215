import { useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { CipUserLookupQuery, CipUserLookupQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Alert from 'src/components/Alert/Alert'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import { CIP_USER_LOOKUP_QUERY } from 'src/lib/graphql/queries/CipUserLookupQuery'

import ResultTable from './components/ResultTable'
import SearchFilter from './components/SearchFilter'

export type UserSelectorUser = {
  displayName: string
  eduPersonPrincipalName: string
  mail: string
}

type Props = {
  disabled: boolean
  ignoredEppns?: string[]
  onSelect: (user: UserSelectorUser) => void
}

const UserSelectorInput = ({ disabled, onSelect, ignoredEppns }: Props) => {
  const [inputValue, setInputValue] = useState('')

  const { data, error, loading } = useQuery<CipUserLookupQuery, CipUserLookupQueryVariables>(CIP_USER_LOOKUP_QUERY, {
    variables: { searchString: inputValue },
    skip: inputValue.length < 3,
  })

  const toFilter = ignoredEppns || []
  const filtered = data ? data.uqUserLookup.filter((user) => !toFilter.includes(user.eduPersonPrincipalName)) : []

  return (
    <>
      <Grid container spacing={2} justifyContent="stretch">
        <Grid xs={12}>
          <SearchFilter disabled={disabled} loading={loading} onSearch={setInputValue} />
        </Grid>
        <Grid xs={12}>
          {loading && <InlineLoader message="Loading results..." />}
          {error && <Alert severity="error">Could not load search results...</Alert>}
          {!!data && <ResultTable disabled={disabled} results={filtered} onClickRow={onSelect} />}
        </Grid>
      </Grid>
    </>
  )
}

export default UserSelectorInput
