import { ThesisFileType } from '@prisma/client'
import { ThesisType } from 'types/graphql'
const getRenamedThesisDocumentFilename = (
  filename: string,
  eduPersonPrincipalName: string,
  typeCode: string
): string => {
  const fileExtension = filename.split('.').pop()
  const username = eduPersonPrincipalName.split('@')[0]
  return `${username}_${typeCode}_thesis.${fileExtension}`
}

const getRenamedThesisIthenticateFilename = (
  filename: string,
  eduPersonPrincipalName: string,
  typeCode: string
): string => {
  const fileExtension = filename.split('.').pop()
  const username = eduPersonPrincipalName.split('@')[0]
  return `${username}_${typeCode}_ithenticate.${fileExtension}`
}

const getRenamedThesisSupplementaryFilename = (
  filename: string,
  eduPersonPrincipalName: string,
  typeCode: string
): string => {
  const username = eduPersonPrincipalName.split('@')[0]

  // These are a little tricky because the file name is used in the name.
  if (filename.includes('_supplementary_') && filename.includes(`${username}_`)) {
    filename = filename.split('_supplementary_')[1]
  }

  // Check and replace if file name contains space
  if (filename.includes(' ')) {
    filename = filename.replace(/\s/g, '')
  }

  return `${username}_${typeCode}_supplementary_${filename}`
}

const getRenamedThesisExaminerReviewFilename = (filename: string): string => {
  const fileExtension = filename.split('.').pop()
  return `examiner_review.${fileExtension}`
}

const getRenamedThesisExaminerAnnotatedThesisFilename = (filename: string): string => {
  const fileExtension = filename.split('.').pop()
  return `examiner_annotated_thesis.${fileExtension}`
}

const getRenamedThesisChairReviewFilename = (filename: string): string => {
  const fileExtension = filename.split('.').pop()
  return `chair_review.${fileExtension}`
}

const getRenamedThesisCorrectionsFilename = (
  filename: string,
  eduPersonPrincipalName: string,
  typeCode: string
): string => {
  const fileExtension = filename.split('.').pop()
  const username = eduPersonPrincipalName.split('@')[0]
  return `${username}_${typeCode}_corrections.${fileExtension}`
}

export const getThesisTypeFileCode = (thesisType: ThesisType): string => {
  switch (thesisType) {
    case 'PHD':
      return 'phd'
    case 'PROFESSIONAL_DOCTORATE':
      return 'profdoc'
    case 'MPHIL':
    case 'MPHIL_IN_LIEU_OF_PHD':
    default:
      return 'mphil'
  }
}

export const getRenamedThesisFilename = (
  filename: string,
  fileType: ThesisFileType,
  eduPersonPrincipalName: string,
  thesisType: ThesisType
): string => {
  const typeCode = getThesisTypeFileCode(thesisType)

  switch (fileType) {
    case ThesisFileType.THESIS_FILE:
      return getRenamedThesisDocumentFilename(filename, eduPersonPrincipalName, typeCode)
    case ThesisFileType.ITHENTICATE_FILE:
      return getRenamedThesisIthenticateFilename(filename, eduPersonPrincipalName, typeCode)
    case ThesisFileType.CORRECTIONS_FILE:
      return getRenamedThesisCorrectionsFilename(filename, eduPersonPrincipalName, typeCode)
    case ThesisFileType.EXAMINER_REVIEW_FILE:
      return getRenamedThesisExaminerReviewFilename(filename)
    case ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE:
      return getRenamedThesisExaminerAnnotatedThesisFilename(filename)
    case ThesisFileType.CHAIR_REVIEW_FILE:
      return getRenamedThesisChairReviewFilename(filename)
    case ThesisFileType.SUPPLEMENTARY_FILE:
    default:
      return getRenamedThesisSupplementaryFilename(filename, eduPersonPrincipalName, typeCode)
  }
}

export const getThesisFileTypeName = (fileType: ThesisFileType): string => {
  switch (fileType) {
    case ThesisFileType.THESIS_FILE:
      return 'Thesis file'
    case ThesisFileType.ITHENTICATE_FILE:
      return 'iThenticate file'
    case ThesisFileType.CORRECTIONS_FILE:
      return 'Corrections file'
    case ThesisFileType.EXAMINER_REVIEW_FILE:
      return 'Examiner review file'
    case ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE:
      return 'Examiner annotated thesis file'
    case ThesisFileType.CHAIR_REVIEW_FILE:
      return 'Chair review file'
    case ThesisFileType.SUPPLEMENTARY_FILE:
      return 'Supplementary file'
    default:
      return 'Unknown file type'
  }
}

export { ThesisFileType }
