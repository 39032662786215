import { getIterationState, getIterationStateByCode, NotificationState } from '@common/thesis/iterationState'
import { actionNotificationData } from '@common/thesis/thesisExaminers/actionNotificationData'
import { getNominatedExaminerCount } from '@common/thesis/thesisExaminers/getThesisExaminerCount'
import { GetThesisSubmissionQuery, ThesisSubmissionIterationStatus } from 'types/graphql'

import { useAuth } from 'src/auth'

import {
  isActiveThesisExaminer,
  isAssignedThesisCandidate,
  isAssignedThesisPrincipalAdvisor,
} from '../userAccessChecker'

export const replacePlaceholdersInString = (template: string, values: Record<string, string>): string => {
  return template.replace(/{{(.*?)}}/g, (_, key) => values[key] || '')
}

type Template = {
  [key: string]: any
}

type IterationStateMessage = {
  title: string
  message: string
  action: NotificationState.CANDIDATE_SUBMITTING | NotificationState.ACTION_REQUIRED | NotificationState.NO_ACTION
}

export const replacePlaceholdersInObject = (
  template: Template,
  dynamicValues: Record<string, string>
): IterationStateMessage => {
  const result: Template = {}

  Object.keys(template).forEach((key) => {
    const value = template[key]

    if (typeof value === 'string') {
      // If the value is a string, replace placeholders
      result[key] = replacePlaceholdersInString(value, dynamicValues)
    } else if (typeof value === 'object' && value !== null) {
      // If the value is an object, recurse
      result[key] = replacePlaceholdersInObject(value, dynamicValues)
    } else {
      // If the value is neither a string nor an object, copy as-is
      result[key] = value
    }
  })

  return {
    title: result.title ?? '',
    message: result.message ?? '',
    action: result.action ?? NotificationState.NO_ACTION,
  }
}

/**
 * Check and get the thesis examiner nomination action notifications for right user
 */
export const getExaminerActionNotifications = (submission: GetThesisSubmissionQuery['getThesisSubmission']) => {
  const actions = []

  const { hasRole, currentUser } = useAuth()

  const nominatedExaminersCount = getNominatedExaminerCount(submission.thesisSubmissionExaminers)
  const isAssignedPa = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor?.id)
  const isAssignedCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const isGS = hasRole('grad-school-user')
  const isThesisExaminer = submission.thesisSubmissionExaminers.find((examiner) =>
    isActiveThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)
  )

  const currentIterationState = getIterationState({
    status: submission.currentIteration.currentStatus.status,
    outcome: submission.currentIteration.currentStatus.outcome,
  })

  const chairAssigned = !!submission.chair

  // @TODO future improvement: This could be refactored down to a single reduce/filter function where it checks for the user role and the examiner status
  actionNotificationData(submission.creator.displayName).map((notification) => {
    // PA actions
    if (isAssignedPa) {
      if (chairAssigned === false && currentIterationState.thesisState !== 'FINAL') {
        if (notification.actionId === 'assign-chair') {
          actions.push(notification)
        }
      }

      if (currentIterationState.thesisState === 'INITIAL') {
        if (notification.actionId === 'nominate-examiners' && nominatedExaminersCount === 0) {
          actions.push(notification)
        }

        if (notification.actionId === 'nominate-one-examiner' && nominatedExaminersCount === 1) {
          actions.push(notification)
        }
      }

      submission.thesisSubmissionExaminers.map((examiner) => {
        if (
          notification.actionId === 'provide-additional-examiner-details' &&
          examiner.status === 'INITIAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL'
        ) {
          const updatedMessage = notification.message.replace(
            /\${examinerName}/g, // Regular expression to replace all instances
            examiner?.user?.displayName || examiner.fullName // Replace with the tester's displayName
          )

          actions.push({
            ...notification,
            message: updatedMessage,
            relatedExaminerId: examiner.id,
          })
        }
      })
    }

    // Candidate actions
    if (isAssignedCandidate) {
      submission.thesisSubmissionExaminers.map((examiner) => {
        if (notification.actionId === 'candidate-review-examiner' && examiner.status === 'CANDIDATE_COI_REVIEW') {
          let updatedMessage
          const examinerName = examiner?.user?.displayName || examiner.fullName

          // Message update if the candidate has already provided COI statements previously
          if (Object.keys(examiner.candidateCOIStatements).length !== 0) {
            updatedMessage = `The Graduate School requested changes to the details for the nomination of ${examinerName}. Your Principal Advisor has resubmitted the nomination. Please review and resubmit.`
          } else {
            updatedMessage = notification.message.replace(/\${examinerName}/g, examinerName)
          }

          actions.push({ ...notification, message: updatedMessage, relatedExaminerId: examiner.id })
        }
      })
    }

    // Examiner actions
    if (isThesisExaminer) {
      submission.thesisSubmissionExaminers.map((examiner) => {
        if (examiner?.user?.id === currentUser.id) {
          if (
            notification.actionId === 'examiner-provide-coi-disclosure' &&
            examiner.status === 'EXAMINER_COI_REVIEW'
          ) {
            actions.push({ ...notification, relatedExaminerId: examiner.id })
          }

          if (
            notification.actionId === 'examiner-update-grad-school-requested-details' &&
            examiner.status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL'
          ) {
            actions.push({ ...notification, relatedExaminerId: examiner.id })
          }
        }
      })
    }

    // GS actions
    if (isGS) {
      // @TODO check examiner status. It could be initial review and final review which both takes to the same dialog
      submission.thesisSubmissionExaminers.map((examiner) => {
        if (
          notification.actionId === 'grad-school-review-examiner' &&
          (examiner.status === 'GRADUATE_SCHOOL_INITIAL_REVIEW' || examiner.status === 'GRADUATE_SCHOOL_FINAL_REVIEW')
        ) {
          actions.push({ ...notification, relatedExaminerId: examiner.id })
        }
      })
    }

    return null
  })

  return actions
}

/**
 * Get the thesis notification state of the notification for the user
 */
export const getThesisNotificationState = (status?: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>) => {
  const { hasRole } = useAuth()

  let notificationState

  const iterationStateCode = getIterationState(status)?.code

  const iterationStateDetails = getIterationStateByCode(iterationStateCode)

  if (hasRole('user-thesis-submission')) {
    notificationState = iterationStateDetails.candidateMessage.action
  } else if (hasRole('user-thesis-principal-advisor')) {
    notificationState = iterationStateDetails.principalAdvisorMessage.action
  } else if (hasRole('user-thesis-chair')) {
    notificationState = iterationStateDetails.chairMessage.action
  } else if (hasRole('user-thesis-examiner')) {
    notificationState = iterationStateDetails.examinerMessage.action
  } else if (hasRole('provost-user')) {
    notificationState = iterationStateDetails.provostMessage.action
  } else if (hasRole('dean-user')) {
    notificationState = iterationStateDetails.deanMessage.action
  } else if (hasRole('grad-school-user')) {
    notificationState = iterationStateDetails.graduateSchoolMessage.action
  } else {
    notificationState = NotificationState.NO_ACTION
  }

  return notificationState
}
