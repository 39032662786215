import * as yup from 'yup'

export interface ThesisSubmissionExaminerBasicDetails {
  title: string
  fullName: string
  position: string
  mail: string
  institution: string
  rationaleForNomination: string
}

export interface ThesisSubmissionExaminerFormData extends ThesisSubmissionExaminerBasicDetails {
  publishedWithCandidate: boolean
  grantWithAdvisor: boolean
  personalOrProfessionalRelationship: boolean
  consultedOnThesis: boolean
  otherCOI: boolean
  advisorCOIDetails: string
}

export interface AdvisorExaminerFormDataInput extends ThesisSubmissionExaminerFormData {
  _checkboxGroup: boolean[]
}

export interface GraduateSchoolExaminerFormDataInput {
  title: string
  fullName: string
  position: string
  mail: string
  institution: string
  contactNumber: string
  contactAddress: string
  requirePayment: boolean
  bankAccountType: string
}

export const examinerBasicDetailsDefaultValues = (examiner: any): ThesisSubmissionExaminerBasicDetails => {
  return {
    title: examiner?.title,
    fullName: examiner?.fullName,
    position: examiner?.position,
    mail: examiner?.mail,
    institution: examiner?.institution,
    rationaleForNomination: examiner?.rationaleForNomination,
  }
}

// @TODO update examiner type
export const createAdvisorUpdateExaminerDetailsDefaultValues = (examiner: any): AdvisorExaminerFormDataInput => {
  return {
    title: examiner?.title,
    fullName: examiner?.fullName,
    position: examiner?.position,
    mail: examiner?.mail,
    institution: examiner?.institution,
    rationaleForNomination: examiner?.rationaleForNomination,
    publishedWithCandidate: examiner?.advisorCOIStatements?.['publishedWithCandidate'],
    grantWithAdvisor: examiner?.advisorCOIStatements?.['grantWithAdvisor'],
    personalOrProfessionalRelationship: examiner?.advisorCOIStatements?.['personalOrProfessionalRelationship'],
    consultedOnThesis: examiner?.advisorCOIStatements?.['consultedOnThesis'],
    otherCOI: examiner?.advisorCOIStatements?.['otherCOI'],
    advisorCOIDetails: examiner?.advisorCOIDetails,
    _checkboxGroup: [false],
  }
}

export const createAdvisorAddExaminerDefaultValues = (): AdvisorExaminerFormDataInput => {
  return {
    title: '',
    fullName: '',
    position: '',
    mail: '',
    institution: '',
    rationaleForNomination: '',
    publishedWithCandidate: null,
    grantWithAdvisor: null,
    personalOrProfessionalRelationship: null,
    consultedOnThesis: null,
    otherCOI: null,
    advisorCOIDetails: '',
    _checkboxGroup: [false],
  }
}

export const createGraduateSchoolAddExaminerDefaultValues = (): GraduateSchoolExaminerFormDataInput => {
  return {
    title: '',
    fullName: '',
    position: '',
    mail: '',
    institution: '',
    contactNumber: '',
    contactAddress: '',
    requirePayment: null,
    bankAccountType: '',
  }
}

// Add external examiner form schema
const commonSchema = {
  title: yup.string().required('You must provide a title.'),
  fullName: yup.string().required('You must provide a full name.'),
  position: yup.string().required('You must provide a position.'),
  mail: yup.string().email('Please enter a valid email address.').required('You must provide an email address.'),
  institution: yup.string().required('You must provide an institution.'),
  rationaleForNomination: yup
    .string()
    .required('You must provide a rationale.')
    .min(25, 'You must provide a rationale of at least 25 characters.'),
  publishedWithCandidate: yup.boolean().required('You must select Yes or No.'),
  grantWithAdvisor: yup.boolean().required('You must select Yes or No.'),
  personalOrProfessionalRelationship: yup.boolean().required('You must select Yes or No.'),
  consultedOnThesis: yup.boolean().required('You must select Yes or No.'),
  otherCOI: yup.boolean().required('You must select Yes or No.'),
  advisorCOIDetails: yup
    .string()
    .when(
      [
        'publishedWithCandidate',
        'grantWithAdvisor',
        'personalOrProfessionalRelationship',
        'consultedOnThesis',
        'otherCOI',
      ],
      {
        is: (
          publishedWithCandidate,
          grantWithAdvisor,
          personalOrProfessionalRelationship,
          consultedOnThesis,
          otherCOI
        ) =>
          publishedWithCandidate ||
          grantWithAdvisor ||
          personalOrProfessionalRelationship ||
          consultedOnThesis ||
          otherCOI,
        then: (schema) =>
          schema.required('You must provide details.').min(200, 'You must provide at least 200 characters of details.'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
}

const termsAndConditions = {
  _checkboxGroup: yup
    .array()
    .of(yup.boolean())
    .test('allConditions', 'All checkboxes must be checked.', (conditions) => {
      const checkForFalsy = conditions.some((condition) => condition === false)

      if (checkForFalsy) {
        return false
      }

      return true
    }),
}

export const advisorAddExaminerValidationSchema = yup.object().shape({ ...commonSchema, ...termsAndConditions })

export const graduateSchoolAddExaminerValidationSchema = yup.object().shape({
  title: yup.string().required('You must provide a title.'),
  fullName: yup.string().required('You must provide a full name.'),
  position: yup.string().required('You must provide a position.'),
  mail: yup.string().email('Please enter a valid email address.').required('You must provide an email address.'),
  institution: yup.string().required('You must provide an institution.'),
  contactNumber: yup
    .string()
    .required('You must provide your contact phone number.')
    .matches(/^(\+)?[0-9]*$/, 'Contact number can only contain digits and at most one "+" at the start.')
    .test(
      'plus-sign',
      'Contact phone number can only have one "+" at the start.',
      (value) => !value || value.indexOf('+') <= 0
    ),
  contactAddress: yup.string().required('You must provide your contact postal address.'),
  requirePayment: yup.boolean().required('You must select Yes or No.'),
  bankAccountType: yup.string().when('requirePayment', {
    is: true,
    then: (schema) => schema.required('You must provide your bank account type.'),

    otherwise: (schema) => schema.notRequired(),
  }),
  termsAndConditions: yup
    .boolean()
    .required('You must provide your contact postal address.')
    .test('conditions', 'You must agree to the conditions.', (condition) => {
      if (condition) {
        return true
      }

      return null
    }),
})

export type ThesisSubmissionChairFormData = {
  eduPersonPrincipalName: string
}

export const createDefaultValuesForChair = {
  eduPersonPrincipalName: '',
}

// Add external examiner form schema
export const validationSchemaForChair = yup.object().shape({
  eduPersonPrincipalName: yup.string().required('You must provide a Chair.'),
})
