export function toSanitiseString(input: string) {
  const sanitisedStr = input.replace(/\s+/g, ' ').trim()
  return sanitisedStr
}

export const minLengthString = (minLength: number) => (value: string) =>
  !value || value.trim().length < minLength ? `Field must have at least ${minLength} characters` : undefined

export const capitalizeFirstLetter = (item: string) => item.charAt(0).toUpperCase() + item.slice(1)

/**
 * Strips html tag in a string.
 */
export const stripHtmlTags = (htmlString: string) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString
  return tempDiv.textContent || tempDiv.innerText || ''
}
