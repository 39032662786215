export const THESIS_QUALITY_INPUTS = [
  { label: 'Well above expectations', value: 'exceeds' },
  { label: 'Above expectations', value: 'aboveAverage' },
  { label: 'Meets expectations', value: 'average' },
  { label: 'Below expectations', value: 'below' },
  { label: 'Well below expectations', value: 'wellBelow' },
]

export const MODE_OF_ATTENDANCE_INPUTS = [
  { label: 'In person', value: 'person' },
  { label: 'Video conference', value: 'video' },
  { label: 'Other', value: 'other' },
]

export const AMENDMENTS_INPUTS = [
  {
    label: 'All amendments required are included in the examiner reports. No list required.',
    value: 'all',
  },
  {
    label:
      'Additional amendments to those in the examiner report were identified during the oral examination and are outlined below.',
    value: 'additional',
  },
]

export const RECOMMENDATION_INPUTS = [
  { label: 'Pass - no corrections required', value: 'pass' },
  { label: 'Pass with corrections', value: 'passWithCorrections' },
  {
    label: 'Not yet passed - Repeat oral examination only',
    value: 'repeatOral',
  },
  {
    label: 'Not yet passed - Revise and resubmit (with oral)',
    value: 'reviseResubmitOral',
  },
  {
    label: 'Not yet passed - Revise and resubmit (without oral)',
    value: 'reviseResubmitWithoutOral',
  },
]

export const RNR_RECOMMENDATION_INPUTS = [
  { label: 'Pass - no corrections required', value: 'pass' },
  { label: 'Pass with corrections', value: 'passWithCorrections' },
  {
    label: 'Award an MPhil (PhD re-examination only)',
    value: 'awardOfMPhil',
  },
  {
    label: 'Fail',
    value: 'fail',
  },
]
