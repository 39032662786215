import { useRef, useState } from 'react'

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { GetDeanActionListQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import ExaminerCOIOverallDetailsViewer from 'src/components/Thesis/ThesisDetails/Committee/Examiners/components/ExaminerCOIOverallDetailsViewer/ExaminerCOIOverallDetailsViewer'
import GET_DEAN_ACTION_LIST from 'src/lib/graphql/queries/GetDeanActionListQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

const options = ['Approve', 'Reject']

type Props = {
  examinerData: GetDeanActionListQuery['getDeanActionList']['examinersToBeApproved'][0]
  onOpen: boolean
  onClose: () => void
  onRefetch: () => void
}

const DEAN_SUBMITS_EXAMINER_COI_DECISION = gql`
  mutation DeanSubmitsExaminerCOIDecision(
    $submissionId: Int!
    $examinerId: Int!
    $decision: String!
    $outcomeMessage: String
  ) {
    deanSubmitsExaminerCOIDecision(
      submissionId: $submissionId
      examinerId: $examinerId
      decision: $decision
      outcomeMessage: $outcomeMessage
    )
  }
`
/**
 * Examiner COI approval dialog
 */
const ThesisExaminerCOIApprovalDialog = ({ examinerData, onOpen, onClose, onRefetch }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [confirmReject, setConfirmReject] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')

  const { examiner, submission } = examinerData

  const [deanSubmitsExaminerCOIDecisionMutation] = useMutation(DEAN_SUBMITS_EXAMINER_COI_DECISION, {
    refetchQueries: [GET_DEAN_ACTION_LIST],
  })

  const onSubmit = async (decision: string) => {
    setSaving(true)

    try {
      const result = await deanSubmitsExaminerCOIDecisionMutation({
        variables: {
          submissionId: submission.id,
          examinerId: examiner.id,
          decision,
          outcomeMessage: rejectionReason.trim(),
        },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully submitted your decision.", 'success')
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not submit decision. Please try again later.', 'error')
    }

    onRefetch()
    onCloseDialog()
  }

  const onCloseDialog = () => {
    setSaving(false)
    onClose()
  }

  const handleClick = () => {
    if (options[selectedIndex] === 'Approve') {
      onSubmit('Approve')
    }
    if (options[selectedIndex] === 'Reject') {
      setConfirmReject(true)
    }
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <Dialog
      open={onOpen}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onCloseDialog()
        }
      }}
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="dialogTitle" variant="h2" component="h2" color="primary">
        Review conflict of interest disclosures
      </DialogTitle>
      <DialogContent id="dialogContent">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="body1">
              The Graduate School has requested your review of the following conflict of interest disclosures.
            </Typography>
          </Grid>
          <ExaminerCOIOverallDetailsViewer examiner={examiner} submission={submission} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>

        <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu">
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select decision action"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </DialogActions>
      <ConfirmDialog
        open={confirmReject}
        loading={saving}
        onClose={() => {
          setConfirmReject(false)
          setRejectionReason('')
        }}
        onConfirm={() => onSubmit('Reject')}
        title="Reject examiner"
        confirmLabel="Confirm"
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            You are about to reject this examiner. The Principal Advisor will be notified and will be asked to nominate
            a new examiner.
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              label="Reason for rejection"
              multiline
              rows={4}
              variant="outlined"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
    </Dialog>
  )
}

export default ThesisExaminerCOIApprovalDialog
