import { useState } from 'react'

import { CancelRounded, CheckCircle } from '@mui/icons-material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons'
import dayjs from 'dayjs'
import sanitizeHtml from 'sanitize-html'
import { GetDeanActionListQuery, ThesisDownloadDetailsQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import { DeanPassSubmissionButton } from 'src/components/Thesis'
import DeanPassWithCorrectionsButton from 'src/components/Thesis/DeanActions/PassWithCorrectionsButton/DeanPassWithCorrectionsButton'
import DowngradeToMPhilButton from 'src/components/Thesis/GraduateSchoolActions/DowngradeToMPhilButton/DowngradeToMPhilButton'
import FailSubmissionButton from 'src/components/Thesis/GraduateSchoolActions/FailSubmissionButton/FailSubmissionButton'
import RepeatOralButton from 'src/components/Thesis/GraduateSchoolActions/RepeatOralButton/RepeatOralButton'
import ReviseResubmitButton from 'src/components/Thesis/GraduateSchoolActions/ReviseResubmitButton/ReviseResubmitButton'
import ReviseResubmitWithoutOralButton from 'src/components/Thesis/GraduateSchoolActions/ReviseResubmitWithoutOralButton/ReviseResubmitWithoutOralButton'
import THESIS_DOWNLOAD_DETAILS_QUERY from 'src/lib/graphql/queries/ThesisDownloadDetailsQuery'

type InputData = {
  input: GetDeanActionListQuery['getDeanActionList']['submissionsToBeApproved'][0]
  submissionUuid: string
  onRefetch: () => unknown
  onClose: () => void
}

const OralExaminationChairSummaryForm = ({ input, submissionUuid, onRefetch, onClose }: InputData) => {
  const { data, loading, error } = useQuery<ThesisDownloadDetailsQuery>(THESIS_DOWNLOAD_DETAILS_QUERY, {
    variables: { uuid: submissionUuid },
  })

  const formMethods = useForm({
    defaultValues: {
      examinationSummary: input?.chair.examinationSummary || '',
      detailedKnowledgeOfThesis: input?.chair.detailedKnowledgeOfThesis || '',
      understandingResearchInBroaderContext: input?.chair.understandingResearchInBroaderContext || '',
      defendMethodologyAndConclusions: input?.chair.defendMethodologyAndConclusions || '',
      communicatedResultsAndImpact: input?.chair.communicatedResultsAndImpact || '',
      independentContributionToDiscipline: input?.chair.independentContributionToDiscipline || '',
      amendments: input?.chair.amendments || '',
      otherAmendmentDetails: input?.chair.otherAmendmentDetails || '',
      chairOutcomeRecommendation: input?.chair.chairOutcomeRecommendation || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const thesisType = input.currentIteration?.type
  const canDowngrade = thesisType === 'PHD' || thesisType === 'PROFESSIONAL_DOCTORATE'
  const [expandChair, setExpandChair] = useState(false)
  const [expandExaminers, setexpandExaminers] = useState(false)

  const cleanHTML = (html) =>
    sanitizeHtml(html, {
      allowedTags: [],
      allowedAttributes: {},
      allowedIframeHostnames: [],
    })

  const chairQuestions = {
    amendments: {
      all: 'All amendments required are included in the examiner reports. No list required.',
      additional:
        'Additional amendments to those in the examiner report were identified during the oral examination and are outlined below.',
    },
    outcome: {
      pass: 'Pass - no corrections required',
      passWithCorrections: 'Pass - with corrections',
      repeatOral: 'Not yet passed - Repeat oral examination only',
      reviseResubmitOral: 'Not yet passed - Revise and resubmit (with oral)',
      reviseResubmitWithoutOral: 'Not yet passed - Revise and resubmit (without oral)',
    },
    // The candidate demonstrated detailed knowledge of the thesis.
    detailedKnowledgeOfThesis: {
      question: 'The candidate demonstrated detailed knowledge of the thesis.',
      exceeds: (
        <span>
          The candidate demonstrated detailed knowledge of the thesis that was <b>well above expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate demonstrated detailed knowledge of the thesis that was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate demonstrated detailed knowledge of the thesis that <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate demonstrated knowledge of the thesis that was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate demonstrated knowledge of the thesis that was <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate demonstrated an understanding of their research in the broader context of their discipline.
    understandingResearchInBroaderContext: {
      question:
        'The candidate demonstrated an understanding of their research in the broader context of their discipline.',
      exceeds: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that{' '}
          <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that
          was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that{' '}
          <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that
          was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline{' '}
          <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of the limitations of the thesis.
    defendMethodologyAndConclusions: {
      question:
        'The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of the limitations of the thesis.',
      exceeds: (
        <span>
          The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate attempted to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate attempted to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that was <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate effectively communicated the results of the research in terms of impact and application of new knowledge
    communicatedResultsAndImpact: {
      question:
        'The candidate effectively communicated the results of the research in terms of impact and application of new knowledge',
      exceeds: (
        <span>
          The candidate effectively communicated the results of the research in terms of impact and application of new
          knowledge that <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate effectively communicated the results of the research in terms of impact and application of new
          knowledge that was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate effectively communicated the results of the research in terms of impact and application of new
          knowledge that <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate attempted to communicate the results of the research in terms of impact and application of new
          knowledge that was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate attmpted to communicate the results of the research in terms of impact and application of new
          knowledge that was <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate demonstrated a substantive and independent contribution to the discipline
    independentContributionToDiscipline: {
      question: 'The candidate demonstrated a substantive and independent contribution to the discipline',
      exceeds: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that{' '}
          <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that was{' '}
          <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that{' '}
          <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that was{' '}
          <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that was{' '}
          <b>well below expectations</b>.
        </span>
      ),
    },
  }

  const examinationQuestions = [
    {
      question: 'The thesis demonstrated an original contribution to the generation and/or application of knowledge',
      id: 'contributionKnowledge',
    },
    { question: 'The thesis engaged with the literature and the works of others', id: 'engagementWithLiterature' },
    {
      question:
        'The thesis showed an advanced knowledge of research principles and methods relating to the applicable discipline',
      id: 'advancedKnowledge',
    },
    {
      question: 'There is clear and discernible coherence in the presented research, its arguments, and conclusions',
      id: 'researchCoherence',
    },
    { question: 'The thesis is convincingly written', id: 'convincingWriting' },
  ]

  const styleExaminerAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1 }} />
    switch (answer) {
      case 'exceeds':
        return <span>{circle('#00B3A6')} Well above expectations</span>
        break
      case 'aboveAverage':
        return <span>{circle('#00A2C7')} Above expectations</span>
        break
      case 'average':
        return <span>{circle('#FFDD00')} Meets expectations</span>
        break
      case 'below':
        return <span>{circle('#FF7700')}Below expectations</span>
        break
      case 'wellBelow':
        return <span>{circle('#B00020')} Well below expectations</span>
        break
      default:
        return null
    }
  }

  const styles = {
    listItem: {
      '& b': {
        color: 'rgb(81, 36, 122)',
      },
    },
  }

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const styleChairAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -0.8, mr: 0.5 }} />
    switch (answer) {
      case 'exceeds':
        return circle('#00B3A6')
        break
      case 'aboveAverage':
        return circle('#00A2C7')
        break
      case 'average':
        return circle('#FFDD00')
        break
      case 'below':
        return circle('#FF7700')
        break
      case 'wellBelow':
        return circle('#B00020')
        break
      default:
        return null
    }
  }
  return (
    <Form formMethods={formMethods}>
      <Grid container spacing={1}>
        {loading ||
          (error && (
            <Grid xs={12}>
              {loading && <InlineLoader message="Loading chair oral summary outcome..." />}
              {error && <Alert severity="error">Error loading chair oral summary outcome</Alert>}
            </Grid>
          ))}
        <Grid xs={12}>
          {data && (
            <>
              <Typography
                variant="h1"
                component={'h2'}
                color={'primary'}
                sx={{ mb: 2 }}
                dangerouslySetInnerHTML={{ __html: cleanHTML(data.thesisDownloadDetails.title) }}
              />
              <Grid container spacing={1}>
                {/* @TODO currently no student number, org unit or email. Need to ask Jan */}
                <Grid xs>
                  <Typography variant="body2">
                    <b>Candidate</b> | {input.candidate.displayName}
                    <Tooltip title={`Email candidate - ${input.candidate.mail}`} arrow placement="top">
                      <Link href={`mailto:${input.candidate.mail}`} aria-label="Email candidate">
                        <EmailOutlinedIcon sx={{ height: 14 }} />
                      </Link>
                    </Tooltip>
                    <Typography variant="body2">
                      <b>Principal Advisor</b> | {input.principalAdvisor.displayName}
                      <Tooltip title={`Email principal advisor - ${input.principalAdvisor.mail}`} arrow placement="top">
                        <Link href={`mailto:${input.principalAdvisor.mail}`} aria-label="Email principal advisor">
                          <EmailOutlinedIcon sx={{ height: 14 }} />
                        </Link>
                      </Tooltip>
                    </Typography>
                    <Typography variant="body2">
                      <b>Submission date</b> | {dayjs(input.currentIteration.submittedDate).format('DD-MMM-YYYY')}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid xs>
                  <Typography variant="body2">
                    <b>Thesis submission</b> | {input.submission.isDowngradedToMPhil ? 'MPhil' : 'PhD'}
                    <br />
                    <b>Date of oral examination</b> | {dayjs(input.currentIteration.oralDate).format('DD-MMM-YYYY')}
                  </Typography>
                </Grid>

                <Grid xs={'auto'}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(
                        `${process.env.WEB_BASE_URL}/graduate-school/thesis/details/${input.submission.id}`,
                        '_blank'
                      )
                    }}
                  >
                    view thesis
                  </Button>
                </Grid>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}>
                  <Typography>
                    The Chair has reviewed the examiner feedback and completed their summary report. They have
                    recommended an outcome for the candidature. Please review and endorse the outcome, or select a new
                    outcome.
                  </Typography>
                </Grid>

                <Grid xs={12} sx={{ mt: 1, mb: 1 }}>
                  <Grid container spacing={1}>
                    <Grid xs>
                      <Typography variant="h2" color={'primary'}>
                        Written examination feedback
                      </Typography>
                    </Grid>

                    <Grid xs={'auto'}>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ mt: -1 }}
                        onClick={() => setexpandExaminers(!expandExaminers)}
                      >
                        {expandExaminers ? 'Show less' : 'Show more'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs sx={{ mb: 1 }}>
                  <Typography>The outcomes are based on the feedback of the examiners after review</Typography>
                </Grid>

                <Grid xs={12}>
                  <Table size="small" sx={{ width: '100%' }}>
                    {/* Unexpanded examiners overview */}
                    {!expandExaminers ? (
                      input.examiners.map((examiner, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell sx={index !== 0 && { borderBottom: 'none' }}>
                              <Typography variant="body2">
                                <b>Examiner {index + 1}</b> | {examiner?.user?.displayName || examiner.fullName} (
                                {examiner.institution})
                              </Typography>
                            </TableCell>
                            <TableCell sx={index !== 0 && { borderBottom: 'none' }}>
                              <Typography variant="body2">
                                <b>Outcome</b>&nbsp;|
                                {examiner.poorThesisQuality !== true ? (
                                  <CheckCircle
                                    sx={{
                                      color: '#00b3a6',
                                      width: '0.75em',
                                      height: '0.75em',
                                      mb: -0.5,
                                      mr: 1,
                                      ml: 1,
                                    }}
                                  />
                                ) : (
                                  <CancelRounded
                                    sx={{ color: 'red', width: '0.75em', height: '0.75em', mb: -0.5, mr: 1, ml: 1 }}
                                  />
                                )}
                                {typeof examiner?.poorThesisQuality === 'boolean'
                                  ? examiner?.poorThesisQuality
                                    ? 'Poor'
                                    : 'Satisfactory'
                                  : 'null'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        {input.examiners.map((examiner, index) => {
                          return (
                            <>
                              <TableCell key={index} sx={index !== 0 && { bgcolor: 'rgba(0,0,0,0.025)' }}>
                                <Typography variant="body2">
                                  <b>Examiner {index + 1}</b>&nbsp;|&nbsp;{' '}
                                  {examiner?.user?.displayName || examiner.fullName}
                                  {examiner.poorThesisQuality !== true ? (
                                    <CheckCircle
                                      sx={{
                                        color: '#00b3a6',
                                        width: '0.75em',
                                        height: '0.75em',
                                        mb: -0.5,
                                        mr: 1,
                                        ml: 1,
                                      }}
                                    />
                                  ) : (
                                    <CancelRounded
                                      sx={{ color: 'red', width: '0.75em', height: '0.75em', mb: -0.5, mr: 1, ml: 1 }}
                                    />
                                  )}
                                  {typeof examiner?.poorThesisQuality === 'boolean'
                                    ? examiner?.poorThesisQuality
                                      ? 'Poor'
                                      : 'Satisfactory'
                                    : 'null'}
                                </Typography>
                              </TableCell>
                            </>
                          )
                        })}
                      </TableRow>
                    )}
                    {/* Examiner Questions */}
                    {expandExaminers &&
                      examinationQuestions.map((question) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0 }}>
                                {question.question}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{styleExaminerAnswer(input.examiners[0][question.id])}</TableCell>
                              <TableCell sx={{ bgcolor: 'rgba(0,0,0,0.025)' }}>
                                {styleExaminerAnswer(input.examiners[1][question.id])}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      })}
                  </Table>
                </Grid>

                {/* Chair Recommendation */}
                <Grid xs={12} sx={{ backgroundColor: 'rgba(150,0,200,0.1)', p: 2, mt: 2, mb: 2 }}>
                  <Grid container spacing={1}>
                    <Grid xs>
                      <Typography variant="h2" component={'h2'} color={'primary'}>
                        Chair recommendation
                      </Typography>
                    </Grid>
                    <Grid xs={'auto'}>
                      <Button size="small" variant="outlined" onClick={() => setExpandChair(!expandChair)}>
                        {expandChair ? 'Show less' : 'Show more'}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
                    <Grid xs>The recommended outcome is based on the summary report after complete examination </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid xs={'auto'}>
                      <b>Committee Chair</b> | {input.chair.user.displayName}
                      <Tooltip title={`Email principal advisor - ${input.chair.user.mail}`} arrow placement="top">
                        <Link href={`mailto:${input.chair.user.mail}`} aria-label="Email principal advisor">
                          <EmailOutlinedIcon sx={{ height: 14 }} />
                        </Link>
                      </Tooltip>
                    </Grid>
                    <Grid xs={'auto'} sx={{ ml: 2 }}>
                      <b>Recommended outcome</b>&nbsp;|&nbsp;
                      {input.chair.chairOutcomeRecommendation === 'pass' ||
                      input.chair.chairOutcomeRecommendation === 'passWithCorrections' ? (
                        <CheckCircle
                          sx={{ color: '#00b3a6', width: '0.75em', height: '0.75em', mb: -0.5, mr: 1, ml: 1 }}
                        />
                      ) : (
                        <CancelRounded
                          sx={{ color: 'red', width: '0.75em', height: '0.75em', mb: -0.5, mr: 1, ml: 1 }}
                        />
                      )}
                      {chairQuestions.outcome[input.chair.chairOutcomeRecommendation]}
                    </Grid>
                  </Grid>

                  {/* Chair expanded info */}
                  {expandChair && (
                    <>
                      {/* Summary assessment */}
                      <Grid xs={12} sx={{ pt: 2 }}>
                        <b>Summary assessment</b>
                        <List dense disablePadding sx={styles.listItem}>
                          {/* The candidate demonstrated detailed knowledge of the thesis. */}
                          <ListItem>
                            <ListItemIcon>{styleChairAnswer(input.chair.detailedKnowledgeOfThesis)}</ListItemIcon>
                            <ListItemText>
                              <Typography variant="body1">
                                {chairQuestions.detailedKnowledgeOfThesis[input.chair.detailedKnowledgeOfThesis]}
                              </Typography>
                            </ListItemText>
                          </ListItem>

                          {/* The candidate demonstrated an understanding of their research in the broader context of their discipline. */}

                          <ListItem>
                            <ListItemIcon>
                              {styleChairAnswer(input.chair.understandingResearchInBroaderContext)}
                            </ListItemIcon>
                            <ListItemText>
                              <Typography variant="body1">
                                {
                                  chairQuestions.understandingResearchInBroaderContext[
                                    input.chair.understandingResearchInBroaderContext
                                  ]
                                }
                              </Typography>
                            </ListItemText>
                          </ListItem>

                          {/* The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of the limitations of the thesis. */}
                          <ListItem>
                            <ListItemIcon>{styleChairAnswer(input.chair.defendMethodologyAndConclusions)}</ListItemIcon>
                            <ListItemText>
                              <Typography variant="body1">
                                {
                                  chairQuestions.defendMethodologyAndConclusions[
                                    input.chair.defendMethodologyAndConclusions
                                  ]
                                }
                              </Typography>
                            </ListItemText>
                          </ListItem>

                          {/* The candidate effectively communicated the results of the research in terms of impact and application of new knowledge. */}
                          <ListItem>
                            <ListItemIcon>{styleChairAnswer(input.chair.communicatedResultsAndImpact)}</ListItemIcon>
                            <ListItemText>
                              <Typography variant="body1">
                                {chairQuestions.communicatedResultsAndImpact[input.chair.communicatedResultsAndImpact]}
                              </Typography>
                            </ListItemText>
                          </ListItem>

                          {/* The candidate demonstrated a substantive and independent contribution to the discipline. */}
                          <ListItem>
                            <ListItemIcon>
                              {styleChairAnswer(input.chair.independentContributionToDiscipline)}
                            </ListItemIcon>
                            <ListItemText>
                              <Typography variant="body1">
                                {
                                  chairQuestions.independentContributionToDiscipline[
                                    input.chair.independentContributionToDiscipline
                                  ]
                                }
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>

                      <Grid xs={12} sx={{ mt: 1, mb: 2 }}>
                        {input.chair.examinationSummary && (
                          <Typography>
                            <b>Summary feedback</b> |
                            <span>
                              &nbsp; A brief report on the oral examination, detailing the structure of the examination
                              and providing a summary of the candidate's performance in responding to the questions
                              raised by the examiners.
                            </span>
                            <Box sx={{ p: 2, pb: 2 }}>
                              <span dangerouslySetInnerHTML={{ __html: input.chair.examinationSummary }} />
                            </Box>
                          </Typography>
                        )}
                        <Typography>
                          <b>Additional amendments</b> | {chairQuestions.amendments[input.chair.amendments]}
                        </Typography>
                        {input.chair.otherAmendmentDetails && (
                          <Typography sx={{ mt: 1 }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: input.chair.otherAmendmentDetails,
                              }}
                            />
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid xs />
        {!!onClose && (
          <Grid xs={12} md={'auto'}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        )}
        <Grid xs={12} md={'auto'}>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu">
            <Button
              onClick={handleToggle}
              aria-label="Select an outcome - this will open a panel with buttons you can access using the tab key"
            >
              Select an outcome
            </Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="Select an outcome - this will open a panel with buttons you can access using the tab key"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              sx={{ zIndex: 1 }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper
                    sx={{ width: 275, pl: 1, pr: 1, mb: 1, mr: 1 }}
                    aria-expanded={open ? true : false}
                    id="split-button-menu"
                  >
                    <>
                      {input.submission.reviseAndResubmitOfId
                        ? [
                            <DeanPassSubmissionButton
                              key={3}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                            />,
                            <DeanPassWithCorrectionsButton
                              key={2}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                            />,
                            <FailSubmissionButton
                              key={0}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                            />,
                            canDowngrade ? (
                              <DowngradeToMPhilButton
                                key={1}
                                iterationId={input.currentIteration.id}
                                onReload={onRefetch}
                                onClose={onClose}
                              />
                            ) : undefined,
                          ]
                        : [
                            <DeanPassSubmissionButton
                              key={4}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                              fullWidth
                            />,
                            <DeanPassWithCorrectionsButton
                              key={3}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                              fullWidth
                            />,
                            <RepeatOralButton
                              key={0}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                              fullWidth
                            />,
                            <ReviseResubmitWithoutOralButton
                              key={2}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                              fullWidth
                            />,
                            <ReviseResubmitButton
                              key={0}
                              iterationId={input.currentIteration.id}
                              onReload={onRefetch}
                              onClose={onClose}
                              fullWidth
                            />,
                          ]}
                    </>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </Form>
  )
}

export default OralExaminationChairSummaryForm
