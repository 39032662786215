import { useState } from 'react'

import { getNominatedExaminerCount } from '@common/thesis/thesisExaminers/getThesisExaminerCount'
import { CheckCircle } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, Tooltip, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import StyledHourglassIcon from 'src/components/Icons/HourGlassIcon'
import REQUEST_ADDITIONAL_CHAIR_FROM_ADVISOR from 'src/lib/graphql/mutations/GraduateSchoolRequestsAddChairFromAdvisor'
import REQUEST_ADDITIONAL_EXAMINER_FROM_ADVISOR from 'src/lib/graphql/mutations/GraduateSchoolRequestsAdditionalExaminerFromAdvisor'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import UpdatePrincipalAdvisorDialog from './UpdatePrincipalAdvisorDialog'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

/**
 * Principal Advisor card that includes all necessary advisor details
 */
const PrincipalAdvisor = ({ submission, onReload }: Props) => {
  const { hasRole } = useAuth()
  const showSnackbar = useSnackbar()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const [openEditAdvisorDialog, setOpenEditAdvisorDialog] = useState(false)
  const [onConfirmNotifyAdvisorNominateExaminer, setOnConfirmNotifyAdvisorNominateExaminer] = useState(false)
  const [onConfirmNotifyAdvisorNominateChair, setOnConfirmNotifyAdvisorNominateChair] = useState(false)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const [requestAdditionalExaminerFromAdvisorMutation] = useMutation(REQUEST_ADDITIONAL_EXAMINER_FROM_ADVISOR)
  const [requestAdvisorAddChairMutation] = useMutation(REQUEST_ADDITIONAL_CHAIR_FROM_ADVISOR)

  const onNotifyAdvisorNominateExaminer = async () => {
    try {
      const result = await requestAdditionalExaminerFromAdvisorMutation({
        variables: { submissionId: submission.id },
      })

      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully notified Principal Advisor.", 'success')
      setOnConfirmNotifyAdvisorNominateExaminer(false)
      handleMenuClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not notify Principal Advisor. Please try again later.', 'error')
    }
  }

  const onNotifyAdvisorNominateChair = async () => {
    try {
      const result = await requestAdvisorAddChairMutation({
        variables: { submissionId: submission.id },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully notified Principal Advisor.", 'success')
      setOnConfirmNotifyAdvisorNominateChair(false)
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not notify Principal Advisor. Please try again later.', 'error')
    }
  }

  const isGS = hasRole('grad-school-user')
  const hasAdvisor = submission?.principalAdvisor

  const approvedExaminersCount = getNominatedExaminerCount(submission.thesisSubmissionExaminers)
  const hasChair = submission?.chair

  return (
    <Grid container alignItems={'flex-start'}>
      <Grid>
        {hasAdvisor ? (
          <CheckCircle sx={{ color: '#00b3a6', width: '1.1em', height: '1.1em' }} />
        ) : (
          <StyledHourglassIcon />
        )}
      </Grid>
      <Grid xs sx={{ ml: 1 }}>
        <Typography variant="subtitle1" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {hasAdvisor ? hasAdvisor.displayName : 'To be confirmed'}
        </Typography>
        <Typography
          fontSize="small"
          color={'#eeeeee'}
          sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
        >{`${submission?.principalAdvisor?.organisation?.name}`}</Typography>
      </Grid>
      {isGS && (
        <Grid xs="auto" sx={{ mr: -2 }}>
          <Tooltip title={'View actions'} arrow>
            <IconButton sx={{ color: 'rgba(255,255,255,0.75)' }} onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {isGS && (
        <UpdatePrincipalAdvisorDialog
          onCancel={() => {
            setOpenEditAdvisorDialog(false)
            handleMenuClose()
          }}
          onReload={onReload}
          open={openEditAdvisorDialog}
          submissionId={submission.id}
        />
      )}

      {isGS && (
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleMenuClose}>
          {approvedExaminersCount < 2 && (
            <MenuItem onClick={() => setOnConfirmNotifyAdvisorNominateExaminer(true)}>
              Notify Principal Advisor to nominate examiner
            </MenuItem>
          )}
          {!hasChair && (
            <MenuItem onClick={() => setOnConfirmNotifyAdvisorNominateChair(true)}>
              Notify Principal Advisor to nominate Chair
            </MenuItem>
          )}
          <MenuItem onClick={() => setOpenEditAdvisorDialog(true)}>Select new Principal Advisor</MenuItem>
        </Menu>
      )}
      <ConfirmDialog
        confirmLabel="Confirm"
        open={onConfirmNotifyAdvisorNominateExaminer}
        onClose={() => {
          setOnConfirmNotifyAdvisorNominateExaminer(false)
        }}
        onConfirm={onNotifyAdvisorNominateExaminer}
        title="Request examiner nomination"
      >
        The Principal Advisor will be notified and asked to nominate a thesis examiner.
      </ConfirmDialog>

      <ConfirmDialog
        confirmLabel="Confirm"
        open={onConfirmNotifyAdvisorNominateChair}
        onClose={() => {
          setOnConfirmNotifyAdvisorNominateChair(false)
        }}
        onConfirm={onNotifyAdvisorNominateChair}
        title="Notify Principal Advisor to assign Chair"
      >
        The Principal Advisor will be notified and asked to assign the Chair.
      </ConfirmDialog>
    </Grid>
  )
}

export default PrincipalAdvisor
