import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import UqUserLookupInput from 'src/components/Form/Inputs/UqUserLookupInput/UqUserLookupInput'
import GRADUATE_SCHOOL_ADDS_CHAIR from 'src/lib/graphql/mutations/GraduateSchoolAddsChair'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import { createDefaultValuesForChair, ThesisSubmissionChairFormData, validationSchemaForChair } from './helpers'

interface Props {
  submissionId: number
  onClose: () => void
  onOpen: boolean
}

/**
 *  Graduate School add thesis submission chair dialog
 */
const GraduateSchoolAddChairDialog = ({ onClose, onOpen, submissionId }: Props) => {
  const showSnackbar = useSnackbar()

  const [isPending, setIsPending] = useState<boolean>(false)
  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false)

  const [addChairMutation] = useMutation(GRADUATE_SCHOOL_ADDS_CHAIR, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm<ThesisSubmissionChairFormData>({
    defaultValues: createDefaultValuesForChair,
    resolver: yupResolver(validationSchemaForChair),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const hasValidationError = !formMethods.formState.isValid

  const onSubmit = async (data: ThesisSubmissionChairFormData) => {
    setIsPending(true)

    try {
      await addChairMutation({
        variables: {
          submissionId,
          chairEppn: data.eduPersonPrincipalName,
        },
      })
      showSnackbar(' You have successfully assigned the Chair of Examiners', 'success')
      onClose()
    } catch (error) {
      const errorMessage =
        error?.graphQLErrors?.[0]?.message || 'Could not assign committee Chair. Please try again later.'

      Sentry.captureException(error)

      showSnackbar(errorMessage, 'error')

      onClose()
    }
    formMethods.reset()
    onClose()
    setIsPending(false)
  }

  return (
    <Dialog
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          formMethods.reset()
          onClose()
        }
      }}
      open={onOpen}
      fullWidth
    >
      <DialogTitle id="dialogTitle" variant="h2" component="h2" color="primary">
        Assign Chair of Examiners
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit} onReset={() => formMethods.reset()}>
        <DialogContent id="dialogContent" sx={{ pt: 0 }}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <UqUserLookupInput
                name="eduPersonPrincipalName"
                setOpenAutoComplete={setOpenAutoComplete}
                disabled={isPending}
                openAutoComplete={openAutoComplete}
                label="Search for Chair of Examiners"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} direction="row" sx={{ pr: 2, pb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onClose()
                formMethods.reset()
              }}
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={openAutoComplete || isPending || hasValidationError}
            >
              Assign
            </Button>
          </Stack>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default GraduateSchoolAddChairDialog
