import Grid from '@mui/material/Unstable_Grid2/Grid2'

import p1Image from '../images/p1.jpg'
import p2Image from '../images/p2.jpg'
import p3Image from '../images/p3.jpg'
import p4Image from '../images/p4.jpg'

const createStyles = (priority: number) => {
  const backgroundImage = priority === 1 ? p1Image : priority === 2 ? p2Image : priority == 3 ? p3Image : p4Image
  const backgroundColor = (theme) =>
    priority === 1 ? theme.palette.error.dark : priority === 2 ? theme.palette.warning.dark : theme.palette.success.dark
  return {
    borderRadius: 1,
    boxShadow: '2px 2px 2px rgba(0,0,0,0.2)',
    mr: 0.2,
    ml: 0.2,
    color: '#FFF',
    '& a:link, a:hover, a:visited, a:active a:focus': {
      color: `#FFF !important`,
    },
    textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
    backgroundColor: backgroundColor,
    backgroundImage: `url('${backgroundImage}')`,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center',
    backgroundSize: 'cover !important',
    ...(priority === 1 && {
      '.MuiSvgIcon-root': {
        animation: 'shake 0.2s',
        animationIterationCount: 10,
      },
    }),
  }
}

type Props = {
  children: React.ReactNode | React.ReactNode[]
  priority: number
}

export const BackgroundContainer = ({ children, priority }: Props) => {
  return (
    <Grid container columnSpacing={2} sx={createStyles(priority)} role="alert">
      {children}
    </Grid>
  )
}
