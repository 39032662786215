import { GetThesisExaminersBySubmissionQuery, GetThesisExaminersBySubmissionQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

export const GET_THESIS_EXAMINERS_BY_SUBMISSION_QUERY = gql`
  query GetThesisExaminersBySubmissionQuery($submissionId: Int!) {
    getThesisExaminersBySubmission(submissionId: $submissionId) {
      id
      userId
    }
  }
`

export const useGetThesisExaminersBySubmissionQuery = (
  options: GraphQLQueryHookOptions<GetThesisExaminersBySubmissionQuery, GetThesisExaminersBySubmissionQueryVariables>
) => {
  return useQuery(GET_THESIS_EXAMINERS_BY_SUBMISSION_QUERY, options)
}
