import { GetThesisSubmissionQuery } from 'types/graphql'
import * as yup from 'yup'

/**
 * Candidate conflict of interest form schema
 */
export const candidateCOIFormSchema = yup.object().shape({
  publishedWithCandidate: yup.boolean().required('You must select Yes or No.'),
  grantWithAdvisor: yup.boolean().required('You must select Yes or No.'),
  personalOrProfessionalRelationship: yup.boolean().required('You must select Yes or No.'),
  consultedOnThesis: yup.boolean().required('You must select Yes or No.'),
  otherCOI: yup.boolean().required('You must select Yes or No.'),
  candidateCOIDetails: yup
    .string()
    .when(
      [
        'publishedWithCandidate',
        'grantWithAdvisor',
        'personalOrProfessionalRelationship',
        'consultedOnThesis',
        'otherCOI',
      ],
      {
        is: (
          publishedWithCandidate,
          grantWithAdvisor,
          personalOrProfessionalRelationship,
          consultedOnThesis,
          otherCOI
        ) =>
          publishedWithCandidate ||
          grantWithAdvisor ||
          personalOrProfessionalRelationship ||
          consultedOnThesis ||
          otherCOI,
        then: (schema) =>
          schema.required('You must provide details.').min(200, 'You must provide at least 200 characters of details.'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
})

export const CandidateCOIDefaultFormValues = (
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
) => {
  return {
    publishedWithCandidate: examiner?.candidateCOIStatements?.['publishedWithCandidate'],
    grantWithAdvisor: examiner?.candidateCOIStatements?.['grantWithAdvisor'],
    personalOrProfessionalRelationship: examiner?.candidateCOIStatements?.['personalOrProfessionalRelationship'],
    consultedOnThesis: examiner?.candidateCOIStatements?.['consultedOnThesis'],
    otherCOI: examiner?.candidateCOIStatements?.['otherCOI'],
    candidateCOIDetails: examiner?.candidateCOIDetails,
  }
}
