import { ThesisIterationStatus } from '@prisma/client'

type ThesisIterationStatusType = {
  code: ThesisIterationStatus
  name: string
  advancedMessage: string
  rejectedMessage: string
  isFinal: boolean
}

export default [
  // Initial
  {
    code: 'CANDIDATE_SUBMITTING',
    name: 'Candidate submitting initial thesis',
    advancedMessage: 'Candidate submitted initial thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'CANDIDATE_SUBMITTING_WITHDRAWN',
    name: 'Candidate withdrew initial thesis',
    advancedMessage: 'Candidate submitted initial thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'CANDIDATE_SUBMITTING_GS_CHANGES',
    name: 'Candidate submitting Graduate School edits',
    advancedMessage: 'Candidate submitted initial thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'CANDIDATE_SUBMITTING_PA_CHANGES',
    name: 'Candidate submitting Principal Advisor edits',
    advancedMessage: 'Candidate submitted initial thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'GRADUATE_SCHOOL_REVIEW',
    name: 'Graduate School reviewing initial thesis',
    advancedMessage: 'Approved',
    rejectedMessage: 'Edits requested by Graduate School',
    isFinal: false,
  },
  {
    code: 'PRINCIPAL_ADVISOR_REVIEW',
    name: 'Principal Advisor reviewing initial thesis',
    advancedMessage: 'Approved',
    rejectedMessage: 'Edits requested by Principal Advisor',
    isFinal: false,
  },
  {
    code: 'AWAITING_EXAMINERS_APPROVAL',
    name: 'Graduate School initiating examination',
    advancedMessage: 'Examination initiated',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'EXAMINERS_REVIEWING',
    name: 'Examiners reviewing the thesis',
    advancedMessage: 'Graduate School schedules oral examination',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION',
    name: 'Graduate School scheduling oral examination',
    advancedMessage: 'Oral examination scheduled',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'AWAITING_ORAL_EXAMINATION',
    name: 'Awaiting oral examination',
    advancedMessage: 'Oral examination finished',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'AWAITING_CHAIR_EXAMINATION_OUTCOME',
    name: 'Pending examination summary by the Chair',
    advancedMessage: 'Chair has submitted the summary',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'AWAITING_DEAN_OUTCOME',
    name: 'Pending initial outcome by the Dean',
    advancedMessage: 'Passed - ready to submit final thesis',
    rejectedMessage: 'Changes requested by the Dean',
    isFinal: false,
  },

  // Final
  {
    code: 'CANDIDATE_SUBMITTING_FINAL_THESIS',
    name: 'Candidate submitting final thesis',
    advancedMessage: 'Final thesis submitted',
    rejectedMessage: '',
    isFinal: true,
  },
  {
    code: 'CANDIDATE_SUBMITTING_FINAL_GS_CHANGES',
    name: 'Candidate submitting final Graduate School edits',
    advancedMessage: 'Final thesis submitted',
    rejectedMessage: '',
    isFinal: true,
  },
  {
    code: 'CANDIDATE_SUBMITTING_FINAL_PA_CHANGES',
    name: 'Candidate submitting final Principal Advisor edits',
    advancedMessage: 'Final thesis submitted',
    rejectedMessage: '',
    isFinal: true,
  },
  {
    code: 'GRADUATE_SCHOOL_FINAL_REVIEW',
    name: 'Graduate School reviewing final thesis',
    advancedMessage: 'Approved',
    rejectedMessage: 'Changes requested by Graduate School',
    isFinal: true,
  },
  {
    code: 'PRINCIPAL_ADVISOR_FINAL_REVIEW',
    name: 'Principal Advisor reviewing final thesis',
    advancedMessage: 'Approved',
    rejectedMessage: 'Changes requested by Principal Advisor',
    isFinal: true,
  },
  {
    code: 'DEAN_FINAL_REVIEW',
    name: 'Dean reviewing final thesis',
    advancedMessage: 'Approved',
    rejectedMessage: '',
    isFinal: true,
  },
  {
    code: 'AWAITING_PROVOST_CONFERRAL',
    name: 'Provost conferring the thesis',
    advancedMessage: 'Thesis conferred by Provost',
    rejectedMessage: '',
    isFinal: true,
  },
  {
    code: 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE',
    name: 'Graduate School completing the candidature',
    advancedMessage: 'Thesis completed',
    rejectedMessage: '',
    isFinal: true,
  },

  // Corrections
  {
    code: 'CANDIDATE_SUBMITTING_CHANGES',
    name: 'Candidate submitting corrected thesis',
    advancedMessage: 'Candidate submitted corrected thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'CANDIDATE_SUBMITTING_CHANGES_PA_ADDITIONAL',
    name: 'Candidate editing corrected thesis',
    advancedMessage: 'Candidate submitted corrected thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'CANDIDATE_SUBMITTING_CHANGES_CHAIR_ADDITIONAL',
    name: 'Candidate submitting additional corrections',
    advancedMessage: 'Candidate submitted corrected thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'PRINCIPAL_ADVISOR_REVIEWING_CHANGES',
    name: 'Principal Advisor reviewing corrected thesis',
    advancedMessage: 'Approved',
    rejectedMessage: 'Additional corrections requested by the Principal Advisor',
    isFinal: false,
  },
  {
    code: 'CHAIR_REVIEWING_CHANGES',
    name: 'Chair reviewing corrected thesis',
    advancedMessage: 'Approved',
    rejectedMessage: 'Additional corrections requested by the Chair',
    isFinal: false,
  },
  {
    code: 'CHAIR_SUBMITTING_CHANGES_DEAN_ADDITIONAL',
    name: 'Chair reviewing additional corrections',
    advancedMessage: 'Chair submitted corrected thesis',
    rejectedMessage: '',
    isFinal: false,
  },
  {
    code: 'DEAN_REVIEWING_CHANGES',
    name: 'Dean reviewing the corrected thesis',
    advancedMessage: 'Approved for final submission',
    rejectedMessage: 'Corrections requested by the Dean',
    isFinal: false,
  },

  // RnR
  {
    code: 'CANDIDATE_REVISING_AND_RESUBMITTING',
    name: 'Candidate revising and resubmitting',
    advancedMessage: 'Candidate revising and resubmitting',
    rejectedMessage: '', // Does not happen
    isFinal: false,
  },
] as ThesisIterationStatusType[]

export { ThesisIterationStatus }
