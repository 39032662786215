import { useState } from 'react'

import { ChairApprovesCorrections, ChairApprovesCorrectionsVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import CHAIR_APPROVES_CORRECTIONS from 'src/lib/graphql/mutations/ChairApprovesCorrections'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ChairApprovesCorrectionsDialog from './ChairApprovesCorrectionsDialog'

type Props = {
  iterationId: number
  onReload: () => unknown
}

/**
 * Chair action to approve a thesis submission
 */
const ChairApprovesCorrectionsButton = ({ iterationId, onReload }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [approval] = useMutation<ChairApprovesCorrections, ChairApprovesCorrectionsVariables>(
    CHAIR_APPROVES_CORRECTIONS
  )
  const showSnackbar = useSnackbar()

  const onConfirmApproval = async () => {
    setSaving(true)
    try {
      const result = await approval({ variables: { iterationId } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully approved this submission.", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not approve thesis submission. Please try again later.', 'error')
      setSaving(false)
    }
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} fullWidth>
        Approve submission
      </Button>
      <ChairApprovesCorrectionsDialog
        open={open}
        saving={saving}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirmApproval}
      />
    </>
  )
}

export default ChairApprovesCorrectionsButton
