import { ThesisSubmissionIterationStatus } from 'types/graphql'

type THESIS_STEPPER_STATE = 'INITIAL' | 'NO_ORAL_INITIAL' | 'REQUEST_CHANGES' | 'FINAL'

type Props = {
  iteration: {
    currentStatus: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>
  }
}

type StepInfo = {
  activeStep: number
  maxStep: number
}

export const getInitialType = (hasOral: boolean): THESIS_STEPPER_STATE => {
  if (hasOral) {
    return 'INITIAL'
  } else {
    return 'NO_ORAL_INITIAL'
  }
}

/**
 * Get the stepper information for the current thesis submission state.
 */
export const getThesisSubmissionStepperInfo = (iteration: Props, thesisState: THESIS_STEPPER_STATE): StepInfo => {
  if (thesisState === 'INITIAL') {
    return getInitialStepInfo(iteration)
  } else if (thesisState === 'NO_ORAL_INITIAL') {
    return getNoOralInitialStepInfo(iteration)
  } else if (thesisState === 'REQUEST_CHANGES') {
    return getCorrectionStepInfo(iteration)
  } else if (thesisState === 'FINAL') {
    return getFinalStepInfo(iteration)
  }
}

/**
 * Initial submission process stepper information.
 */
const getInitialStepInfo = ({ iteration }: Props): StepInfo => {
  let activeStep = 1
  const maxStep = 10

  if (!iteration) activeStep = 1
  else if (iteration?.currentStatus?.outcome === 'FAILED') return null
  else if (iteration?.currentStatus?.outcome !== null) {
    // Current iteration has a status with an outcome, so the graduate should submit
    activeStep = iteration.currentStatus.outcome === 'REVISE_AND_RESUBMIT' ? 11 : 1
  } else {
    if (iteration.currentStatus.status === 'GRADUATE_SCHOOL_REVIEW') activeStep = 2
    if (iteration.currentStatus.status === 'PRINCIPAL_ADVISOR_REVIEW') activeStep = 3
    if (iteration.currentStatus.status === 'AWAITING_EXAMINERS_APPROVAL') activeStep = 4
    if (iteration.currentStatus.status === 'EXAMINERS_REVIEWING') activeStep = 5
    if (iteration.currentStatus.status === 'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION') activeStep = 6
    if (iteration.currentStatus.status === 'AWAITING_ORAL_EXAMINATION') activeStep = 7
    if (iteration.currentStatus.status === 'AWAITING_CHAIR_EXAMINATION_OUTCOME') activeStep = 8
    if (iteration.currentStatus.status === 'AWAITING_DEAN_OUTCOME') activeStep = 9
  }

  return { activeStep, maxStep }
}

/**
 * Initial submission (without oral) process stepper information.
 */
const getNoOralInitialStepInfo = ({ iteration }: Props): StepInfo => {
  let activeStep = 1
  const maxStep = 8

  if (!iteration) activeStep = 1
  else if (iteration?.currentStatus?.outcome === 'FAILED') return null
  else if (iteration?.currentStatus?.outcome !== null) {
    // Current iteration has a status with an outcome, so the graduate should submit
    activeStep = iteration.currentStatus.outcome === 'REVISE_AND_RESUBMIT' ? maxStep : 1
  } else {
    if (iteration.currentStatus.status === 'GRADUATE_SCHOOL_REVIEW') activeStep = 2
    if (iteration.currentStatus.status === 'PRINCIPAL_ADVISOR_REVIEW') activeStep = 3
    if (iteration.currentStatus.status === 'AWAITING_EXAMINERS_APPROVAL') activeStep = 4
    if (iteration.currentStatus.status === 'EXAMINERS_REVIEWING') activeStep = 5
    if (iteration.currentStatus.status === 'AWAITING_CHAIR_EXAMINATION_OUTCOME') activeStep = 6
    if (iteration.currentStatus.status === 'AWAITING_DEAN_OUTCOME') activeStep = 7
  }

  return { activeStep, maxStep }
}

/**
 * Corrections process stepper information.
 */
const getCorrectionStepInfo = ({ iteration }: Props): StepInfo => {
  let activeStep = 1
  const maxStep = 5

  if (iteration.currentStatus.status === 'CANDIDATE_SUBMITTING_CHANGES') activeStep = 1
  if (iteration.currentStatus.status === 'PRINCIPAL_ADVISOR_REVIEWING_CHANGES') activeStep = 2
  if (
    iteration.currentStatus.status === 'CHAIR_REVIEWING_CHANGES' ||
    iteration.currentStatus.status === 'CHAIR_SUBMITTING_CHANGES_DEAN_ADDITIONAL'
  )
    activeStep = 3
  if (iteration.currentStatus.status === 'DEAN_REVIEWING_CHANGES') activeStep = 4

  return { activeStep, maxStep }
}

/**
 * Final submission process stepper information.
 */
const getFinalStepInfo = ({ iteration }: Props): StepInfo => {
  let activeStep = 1
  const maxStep = 7

  if (iteration.currentStatus.outcome !== null) {
    if (iteration.currentStatus.status === 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE') activeStep = 8
    else activeStep = 1
  } else {
    if (iteration.currentStatus.status === 'GRADUATE_SCHOOL_FINAL_REVIEW') activeStep = 2
    if (iteration.currentStatus.status === 'PRINCIPAL_ADVISOR_FINAL_REVIEW') activeStep = 3
    if (iteration.currentStatus.status === 'DEAN_FINAL_REVIEW') activeStep = 4
    if (iteration.currentStatus.status === 'AWAITING_PROVOST_CONFERRAL') activeStep = 5
    if (iteration.currentStatus.status === 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE') activeStep = 6
  }

  return { activeStep, maxStep }
}
