import { InMemoryCache } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import theme from 'src/lib/muiTheme'
import Sentry from 'src/lib/sentry'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import BrowserChecker from './components/BrowserChecker/BrowserChecker'
import { MimickAlert } from './components/MimickAlert'
import './index.css'
import UiContextProvider from './lib/context/Ui/Provider'
import { ProductionErrorPage } from './pages/FatalErrorPage/FatalErrorPage'

dayjs.extend(localizedFormat)

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={({ eventId }) => <ProductionErrorPage eventId={eventId} />}>
      <BrowserChecker />
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <UiContextProvider>
              <RedwoodApolloProvider useAuth={useAuth} graphQLClientConfig={{ cache: new InMemoryCache() }}>
                <MimickAlert />
                <Routes />
              </RedwoodApolloProvider>
            </UiContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
