export default {
  title: 'UQ school or institution',
  text: (
    <div>
      <p>
        Choose the school or institution that the lead investigator is associated with (i.e. UQ lead
        investigator/Principal Advisor/supervisor).
      </p>
      <p>
        Please note that this information is used to establish the custodianship of research data in the event the lead
        investigator leaves UQ and the head of school or director of institute must make data management decisions.
      </p>
    </div>
  ),
}
