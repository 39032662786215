import { useState } from 'react'

import { getIterationState } from '@common/thesis/iterationState'
import { Button, ButtonGroup, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { GetDeanActionListQuery } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { DataGrid, renderTooltipCell } from 'src/components/DataGrid'
import { toFullTimezone } from 'src/lib/toTimezone'

import ReviewChairDecisionChangedRequestDialog from './ReviewChairDecisionChangedRequestDialog'

type Props = {
  data: GetDeanActionListQuery['getDeanActionList']
  onRefetch: () => void
}

/**
 * Pending review of changed request submission by Dean
 */
const PendingChangedRequestSubmissionReviewCard = ({ data, onRefetch }: Props) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 })

  const [onOpenChairRecommendationDialog, setOnOpenChairRecommendationDialog] = useState(false)
  const [thesisSubmissionData, setThesisSubmissionData] =
    useState<GetDeanActionListQuery['getDeanActionList']['submissionToBeReviewed'][0]>(undefined)

  const renderSubmissionActionCell = (params: GridRenderCellParams) => {
    const input = params.value
    const iterationState = getIterationState(params.value.currentStatus)

    return (
      <>
        {iterationState.code === 'DEAN_REVIEWING_CHANGES' && (
          <ButtonGroup size="small" variant="contained" aria-label="Basic button group">
            <Button
              color="secondary"
              onClick={(e) => {
                e.stopPropagation()
                window.open(routes.graduateSchoolThesisSubmissionDetailsPage({ id: params.row.submissionId }), '_blank')
              }}
            >
              View thesis
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                setOnOpenChairRecommendationDialog(true)
                setThesisSubmissionData(input)
              }}
              variant="contained"
              color="primary"
            >
              Review outcome
            </Button>
          </ButtonGroup>
        )}
      </>
    )
  }

  const onCloseDialog = () => {
    setOnOpenChairRecommendationDialog(false)
    setThesisSubmissionData(undefined)
    // @TODO reset form fields
  }

  const submissionColumns: GridColDef[] = [
    {
      field: 'lastStatusUpdate',
      headerName: 'Last status update',
      sortable: true,
      flex: 1,
      renderCell: renderTooltipCell,
    },
    { field: 'candidate', headerName: 'Candidate', sortable: true, flex: 1, renderCell: renderTooltipCell },
    { field: 'pa', headerName: 'Principal Advisor', sortable: true, flex: 1, renderCell: renderTooltipCell },
    { field: 'chair', headerName: 'Chair', sortable: true, flex: 1, renderCell: renderTooltipCell },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: renderSubmissionActionCell,
      align: 'right',
      headerAlign: 'right',
      width: 250,
    },
  ]
  return (
    <>
      <Typography component="h2" variant="h2" color="primary" sx={{ m: 2 }}>
        Pending review of corrected theses
      </Typography>
      <DataGrid
        pageSizeOptions={[10, 20, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastStatusUpdate', sort: 'asc' }],
          },
        }}
        onRowClick={(params) => {
          navigate(routes.graduateSchoolThesisSubmissionDetailsPage({ id: params.row.submissionId }))
        }}
        columns={submissionColumns}
        rowHeight={80}
        rows={data.submissionToBeReviewed.map((action, index) => {
          return {
            id: index,
            submissionId: action?.submission.id,
            candidate: action?.candidate.displayName,
            pa: action?.principalAdvisor.displayName,
            chair: action?.chair.user.displayName,
            lastStatusUpdate: toFullTimezone(action?.currentStatus.createdAt),
            action: action,
          }
        })}
        sx={{ gridRow: { cursor: 'pointer' } }}
      />

      {onOpenChairRecommendationDialog && thesisSubmissionData && (
        <ReviewChairDecisionChangedRequestDialog
          onOpen={onOpenChairRecommendationDialog}
          onClose={onCloseDialog}
          input={thesisSubmissionData}
          onRefetch={onRefetch}
        />
      )}
    </>
  )
}

export default PendingChangedRequestSubmissionReviewCard
