import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, Link, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { MyRecordsQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import { useAuth } from 'src/auth'
import {
  CCheckbox,
  ControlledThesisEmbargoType,
  CTextField,
  CWysiwyg,
  FieldOfResearchCodes,
  Organisation,
  RecordSelectorInput,
  ThesisFiles,
  ThesisKeywords,
} from 'src/components/Form/Inputs'
import ThesisIntellectualProperty from 'src/components/Form/Inputs/Thesis/ThesisIntellectualProperty'
import { ThesisSubmissionDetails } from 'src/components/Thesis'
import organisationThesisGuidance from 'src/lib/guidance/organisationThesisGuidance'

import { createDefaultValues, finalValidationSchema, ThesisSubmissionFormData } from '../helpers'

import AdditionalSupervisorsInput from './AdditionalSupervisorsInput'

type Props = {
  disabled: boolean
  saving?: boolean
  userRecordsSummary: MyRecordsQuery['userRecordsSummary']
  submission: ThesisSubmissionDetails
  onSubmit?: (data: ThesisSubmissionFormData) => unknown
  onClose: () => void
}

const FinalThesisIterationForm = ({ disabled, saving, submission, userRecordsSummary, onSubmit, onClose }: Props) => {
  const { currentUser } = useAuth()
  const formMethods = useForm({
    defaultValues: createDefaultValues(submission, currentUser),
    resolver: yupResolver(finalValidationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  // Trigger initial validation on load (some of the fields)
  useEffect(() => {
    formMethods.trigger()
  }, [])

  const userRecordsWithAssignedPaAsLead = userRecordsSummary.filter((r) =>
    r.collaborators.find(
      (c) => c.isLead && c.user.eduPersonPrincipalName === submission.principalAdvisor.eduPersonPrincipalName
    )
  )

  const record = formMethods.watch('record')
  const isValid = formMethods.formState.isValid
  const initialFinalSubmission =
    submission.currentIteration?.currentStatus.status === 'CANDIDATE_SUBMITTING_FINAL_THESIS'

  return (
    <Form formMethods={formMethods} encType="form" onSubmit={onSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography component="h2" variant="h2" color="primary" sx={{ mb: 2 }}>
            Thesis submission details
          </Typography>
          <Typography component={'p'}>
            {`Please include the following information to help make your thesis easier to find for the research community:
            your thesis abstract, the number of pages in your thesis, field of research codes, and relevant keywords.
            ${!initialFinalSubmission ? 'To replace the current thesis file with the final version, click the remove icon and upload the new file and only supplemental files required for publication. All previous iterations have been saved and can be viewed at any time on the thesis page.' : ''}`}
            <br />
            <br />
            Additionally, please discuss with your Principal Advisor how to assign intellectual property and whether you
            wish to impose an embargo period before the thesis is made open access. (Note: The default intellectual
            property ownership is "Yes" unless you have a preexisting contract with UQ that indicates otherwise.)
          </Typography>
          <Divider sx={{ mt: 2, mb: 1 }} />
        </Grid>
        <Grid xs={12}>
          <RecordSelectorInput
            userRecordsSummary={userRecordsWithAssignedPaAsLead}
            onlyOwnedRecords={true}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <CWysiwyg
            height={100}
            name="title"
            label="Thesis title"
            readOnly={disabled}
            required={true}
            helperText={!disabled && 'Type your thesis title here'}
          />
        </Grid>
        <Grid xs={12}>
          <CWysiwyg
            height={450}
            name="abstract"
            label="Thesis abstract"
            readOnly={disabled}
            required={true}
            helperText={!disabled && 'Type your thesis abstract here'}
          />
        </Grid>
        <Grid xs={12} md={7}>
          <Organisation
            required={true}
            inactiveMessageDisabled={true}
            readOnly={disabled}
            guidance={organisationThesisGuidance}
          />
        </Grid>
        {record && (
          <Grid xs={12} sm>
            <AdditionalSupervisorsInput readOnly={disabled} />
          </Grid>
        )}
        <Grid xs={12} sm={12}>
          <FieldOfResearchCodes name="forCodes" readOnly={disabled} />
        </Grid>
        <Grid xs={12} sm={7}>
          <ThesisKeywords name="keywords" readOnly={disabled} />
        </Grid>
        <Grid xs={12} sm sx={{ pr: 3 }}>
          <CTextField name="totalPages" label="Total pages" required />
        </Grid>
        <Grid xs={12} sm={7}>
          <ThesisIntellectualProperty name="isUqIntellectualProperty" readOnly={disabled} />
        </Grid>
        <Grid xs={12} sm>
          <ControlledThesisEmbargoType name="embargoType" readOnly={disabled} />
        </Grid>
        <Grid xs={12}>
          <ThesisFiles readOnly={disabled} isFinal={true} isRequestChanges={false} submission={submission} />
        </Grid>
        <Grid xs={12}>
          {!disabled && (
            <>
              <Grid xs={12} sx={{ mt: 2 }}>
                <Typography component="h2" variant="h2" color="primary">
                  Statements
                </Typography>
                <ul>
                  <li>
                    I certify that the thesis I have submitted is composed of my original work and that the assertions I
                    have made in the thesis are truthful and complete.
                  </li>
                  <li>
                    I understand that work submitted may be subjected to a plagiarism detection process. I have reviewed
                    UQ's{' '}
                    <Link href="https://policies.uq.edu.au/document/view-current.php?id=118" target="_blank">
                      open access policy
                    </Link>{' '}
                    and have discussed access options with my advisors.
                  </li>
                </ul>
              </Grid>
              <Grid xs={12}>
                <CCheckbox name="certify" label="I agree to the statements as listed above" />
              </Grid>
            </>
          )}
          <Grid container spacing={1}>
            <Grid xs />
            <Grid xs={'auto'}>
              <Button color="secondary" variant="contained" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid xs={'auto'}>
              <LoadingButton disabled={!isValid} variant="contained" color="primary" loading={saving} type="submit">
                Submit your thesis
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default FinalThesisIterationForm
