import { Stack } from '@mui/material'

import PageTitle from 'src/components/Page/PageTitle'
import PageWrapper from 'src/components/Page/PageWrapper'

import DeansPageCell from './components/DeansPageCell'

// import AssignedThesisSubmissionsCard from './components/AssignedThesisSubmissionsCard'

const DeansPage = () => {
  return (
    <PageWrapper>
      <Stack direction="column" spacing={2}>
        <PageTitle>Pending Dean actions</PageTitle>
        <DeansPageCell />
      </Stack>
    </PageWrapper>
  )
}

export default DeansPage
