import { getIterationState } from '@common/thesis/iterationState'
import { ThesisSubmissionIterationStatus } from 'types/graphql'

import ThesisSubmissionStepperChangesRequested from './ThesisSubmissionStepperChangesRequested'
import ThesisSubmissionStepperFinal from './ThesisSubmissionStepperFinal'
import ThesisSubmissionStepperInitial from './ThesisSubmissionStepperInitial'
import ThesisSubmissionStepperInitialNoOral from './ThesisSubmissionStepperInitialNoOral'

type Props = {
  iteration: {
    currentStatus: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>
  }
  hasOral: boolean
}

/**
 * Gets the appropriate stepper for the thesis submission process.
 */
const ThesisSubmissionStepper = ({ iteration, hasOral }: Props) => {
  if (!iteration) return <ThesisSubmissionStepperInitial iteration={iteration} />

  const iterationState = getIterationState(iteration?.currentStatus)

  if (iterationState.thesisState === 'INITIAL' && hasOral)
    return <ThesisSubmissionStepperInitial iteration={iteration} />
  if (iterationState.thesisState === 'INITIAL') return <ThesisSubmissionStepperInitialNoOral iteration={iteration} />
  if (iterationState.thesisState === 'REQUEST_CHANGES')
    return <ThesisSubmissionStepperChangesRequested iteration={iteration} />
  if (iterationState.thesisState === 'FINAL') return <ThesisSubmissionStepperFinal iteration={iteration} />
}

export default ThesisSubmissionStepper
