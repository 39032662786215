const CANDIDATE_SUBMITS_EXAMINER_COI = gql`
  mutation CandidateSubmitsExaminerCOI(
    $submissionId: Int!
    $examinerId: Int!
    $candidateCOIInputs: CandidateCOIInput!
  ) {
    candidateSubmitsExaminerCOI(
      submissionId: $submissionId
      examinerId: $examinerId
      candidateCOIInputs: $candidateCOIInputs
    )
  }
`

export default CANDIDATE_SUBMITS_EXAMINER_COI
