import { useState } from 'react'

import { GetThesisSubmissionQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import RESEND_GRADUATE_SCHOOL_REQUESTS_FURTHER_INFO_FROM_EXAMINER_MAIL from 'src/lib/graphql/mutations/ResendGraduateSchoolRequestsFurtherInfoFromExaminerMail'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  onOpen: boolean
  onClose: () => void
}

/**
 * Resend thesis examiner request further info mail dialog component
 *
 * This component can be accessed GS role
 */
const ResendThesisExaminerRequestFurtherInfoMailDialog = ({ examinerData, onOpen, onClose }: Props) => {
  const { hasRole } = useAuth()
  const showSnackbar = useSnackbar()

  const [saving, setSaving] = useState<boolean>(false)

  const [resendGraduateSchoolRequestsFurtherInfoMailFromExaminer] = useMutation(
    RESEND_GRADUATE_SCHOOL_REQUESTS_FURTHER_INFO_FROM_EXAMINER_MAIL,
    {
      refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
    }
  )

  const onConfirmResend = async () => {
    setSaving(true)

    try {
      let result = undefined

      if (hasRole(['grad-school-user'])) {
        result = await resendGraduateSchoolRequestsFurtherInfoMailFromExaminer({
          variables: { examinerId: examinerData.id },
        })

        if (!result) throw new Error('Received invalid result')

        showSnackbar('You have successfully resent the email to the examiner.', 'success')
      } else {
        throw new Error('Could not resend email.')
      }
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not resend email.', 'error')
    }

    setSaving(false)
    onClose()
  }

  return (
    <ConfirmDialog
      open={onOpen}
      onClose={onClose}
      onConfirm={onConfirmResend}
      title="Resend notification email to examiner"
      confirmLabel="Send"
      loading={saving}
    >
      {`Would you like to resend the notification email to the examiner requesting additional information?`}
    </ConfirmDialog>
  )
}

export default ResendThesisExaminerRequestFurtherInfoMailDialog
