import ExternalLink from 'src/components/ExternalLink/ExternalLink'

export default {
  title: 'Thesis files',
  text: (
    <>
      <p>
        Upload your thesis and any supporting material to be published to UQ eSpace. Your written thesis must be in PDF
        format and not protected by a password. It is important to ensure that you have uploaded the correct file(s) and
        version(s). Uploaded files can be removed at any point until you click “submit”. If you leave this site before
        submitting, your files will not be saved. If you need help converting your thesis to a PDF read{' '}
        <ExternalLink href="https://my.uq.edu.au/files/31647/guide-pdf-conversion.pdf">this guide</ExternalLink>. The
        maximum file size that can be uploaded is 5GB.
      </p>
    </>
  ),
}
