import { FormControl, MenuItem } from '@mui/material'

import { useController } from '@redwoodjs/forms'

import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'
import intellectualPropertyGuidance from 'src/lib/guidance/thesis/intellectualPropertyGuidance'

import { CSelect } from '../Base/Select'

interface Props {
  readOnly: boolean
  name: string
}
const ThesisIntellectualProperty = ({ readOnly, name }: Props) => {
  const { field } = useController({ name })
  return (
    <HelpWrapper helpProps={intellectualPropertyGuidance} sx={{ alignItems: 'start' }}>
      <FormControl component="fieldset" required variant="standard" sx={{ width: '100%' }}>
        <CSelect
          {...field}
          readOnly={readOnly}
          variant="standard"
          fullWidth
          label="Have you assigned your intellectual property to UQ?"
          placeholder="Please select an option"
        >
          <MenuItem value="1">Yes</MenuItem>
          <MenuItem value="0">No</MenuItem>
        </CSelect>
      </FormControl>
    </HelpWrapper>
  )
}

export default ThesisIntellectualProperty
