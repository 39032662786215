import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Submission failed message for revised and resubmit & revised and resubmit without oral state
 *
 * Chair view
 */
const ThesisSubmissionFailed = ({ submission, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).chairMessage
  const stepperState = getInitialType(submission.currentIteration.hasOral) // Need to check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      outcomeMessage={submission?.previousIteration?.lastStatus?.outcomeMessage}
    />
  )
}

export default ThesisSubmissionFailed
