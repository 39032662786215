import { useState } from 'react'

import { GetThesisSubmissionQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import GRADUATE_SCHOOL_DELETES_EXAMINER from 'src/lib/graphql/mutations/GraduateSchoolDeletesExaminer'
import PRINCIPAL_ADVISOR_DELETES_EXAMINER from 'src/lib/graphql/mutations/PrincipalAdvisorDeletesExaminer'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submissionId: number
  submissionPrincipalAdvisorId: number
  onOpen: boolean
  onClose: () => void
}

/**
 * Thesis examiner removal dialog component
 *
 * This component is shared by role GS and PA
 */
const ThesisExaminerRemovalDialog = ({
  examinerData,
  submissionId,
  submissionPrincipalAdvisorId,
  onOpen,
  onClose,
}: Props) => {
  const { hasRole, currentUser } = useAuth()
  const showSnackbar = useSnackbar()

  const [saving, setSaving] = useState<boolean>(false)

  const [graduateSchoolDeletesExaminerMutation] = useMutation(GRADUATE_SCHOOL_DELETES_EXAMINER, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })
  const [principalAdvisorDeletesExaminerMutation] = useMutation(PRINCIPAL_ADVISOR_DELETES_EXAMINER, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const onConfirmDelete = async () => {
    setSaving(true)

    // @TODO check for role

    try {
      let result = undefined

      if (hasRole('grad-school-user')) {
        result = await graduateSchoolDeletesExaminerMutation({
          variables: { id: examinerData.id, submissionId: submissionId },
        })
      } else if (isAssignedThesisPrincipalAdvisor(currentUser, submissionPrincipalAdvisorId)) {
        result = await principalAdvisorDeletesExaminerMutation({
          variables: { id: examinerData.id, submissionId: submissionId },
        })
      }

      if (!result) throw new Error('Received invalid result')

      showSnackbar('You have successfully removed the examiner.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not delete examiner', 'error')
    }

    setSaving(false)
    onClose()
  }

  return (
    <ConfirmDialog open={onOpen} onClose={onClose} onConfirm={onConfirmDelete} title="Remove examiner">
      {`By removing ${examinerData.fullName}, they will no longer be involved in the thesis process. The Principal Advisor will be notified and asked to nominate a new examiner.`}
    </ConfirmDialog>
  )
}

export default ThesisExaminerRemovalDialog
