import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { toBrisbaneTime } from '../dateTimeHelpers'

dayjs.extend(isSameOrBefore)

export const hasOralExamCompleted = (oralDate: Date | string) => {
  const newOralDate = toBrisbaneTime(oralDate)
  const now = toBrisbaneTime(dayjs().toDate())

  return oralDate ? dayjs(newOralDate).isSameOrBefore(now, 'day') : false
}

// ====================== Important dates and durations ======================
/**
 * Thesis examiner feedback duration is 6 weeks from when the examination is initiated
 */
export const EXAMINER_FEEDBACK_DURATION = () => {
  return dayjs().add(6, 'weeks')
}

/**
 * Thesis examiner invitation token expiry duration is 90 days
 */
export const EXAMINER_INVITATION_TOKEN_EXPIRY = () => {
  return dayjs().add(90, 'days')
}

/**
 * Intended submission date is 3 months from when the Graduate School initiates the submission
 */
export const INTENDED_SUBMISSION_DATE = () => {
  return dayjs().add(3, 'months')
}

/**
 * Revise and resubmit intended submission date is 12 months from the date of Dean makes the decision
 */
export const REVISE_AND_RESUBMIT_INTENDED_SUBMISSION_DATE = () => {
  return dayjs().add(12, 'months')
}

/**
 * Intended submission date for corrections is 12 weeks from the date of Dean makes the decision
 */
export const CORRECTIONS_INTENDED_SUBMISSION_DATE = () => {
  return dayjs().add(12, 'weeks')
}

/**
 * Intended oral examination date is 3 months from the intended submission date
 */
export const INTENDED_ORAL_EXAMINATION_DATE = (intendedSubmissionDate: Date) => {
  return dayjs(intendedSubmissionDate).add(12, 'week')
}

/**
 * Chair summary due date is 1 week from the oral examination date
 */
export const CHAIR_SUMMARY_REPORT_DUE_DATE = (oralDate?: Date) => {
  return oralDate ? dayjs(oralDate).add(1, 'week') : dayjs().add(1, 'week')
}

/**
 * Chair summary due date for submissions without oral examination is 7 weeks from the intended submission date
 */
export const CHAIR_SUMMARY_REPORT_DUE_DATE_WITHOUT_ORAL = (intendedSubmissionDate: Date) => {
  return dayjs(intendedSubmissionDate).add(7, 'week')
}
