import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { CipUserLookupQuery } from 'types/graphql'

type Props = {
  disabled: boolean
  results: CipUserLookupQuery['uqStaffLookup']
  onClickRow: (user: CipUserLookupQuery['uqStaffLookup'][0]) => void
}

const ResultTable = ({ disabled, results, onClickRow }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Username</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.length === 0 && (
          <TableRow>
            <TableCell colSpan={2}>No results found</TableCell>
          </TableRow>
        )}
        {results.map((user) => (
          <TableRow
            hover
            key={user.eduPersonPrincipalName}
            onClick={() => !disabled && onClickRow(user)}
            sx={{ cursor: disabled ? 'auto' : 'pointer' }}
          >
            <TableCell>{user.displayName}</TableCell>
            <TableCell>{user.eduPersonPrincipalName.replace('@uq.edu.au', '')}</TableCell>
            <TableCell>{user.mail}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ResultTable
