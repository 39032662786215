import { useState } from 'react'

import { thesisTypes } from '@common/thesis'
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import sanitizeHtml from 'sanitize-html'
import { GetDeanActionListQuery } from 'types/graphql'

import { toFullTimezone } from 'src/lib/toTimezone'

import ApproveFinalSubmissionDialog from './ApproveFinalSubmissionDialog'

type Props = {
  data: GetDeanActionListQuery['getDeanActionList']
  onRefetch: () => void
}
const cleanHTML = (html) =>
  sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {},
    allowedIframeHostnames: [],
  })
/**
 * Final submission approval by Dean.
 */
const FinalSubmissionApprovalCard = ({ data, onRefetch }: Props) => {
  const [onOpenFinalSubmissionApprovalDialog, setOnOpenFinalSubmissionApprovalDialog] = useState(false)
  const [thesisSubmissionData, setThesisSubmissionData] =
    useState<GetDeanActionListQuery['getDeanActionList']['pendingFinalSubmissionApproval'][0]>(undefined)

  const onCloseDialog = () => {
    setOnOpenFinalSubmissionApprovalDialog(false)
    setThesisSubmissionData(undefined)
  }

  return (
    <>
      <Typography component="h2" variant="h2" color="primary" sx={{ m: 2 }}>
        Pending requests to confer final theses
      </Typography>

      <Table
        aria-label="Theses conferral table. PLease note that each thesis record is made of 2 rows in the table, the first being the title and the second row being the extranous data for that thesis"
        size="small"
        sx={{ minWidth: 100, maxWidth: '100%', width: '100%', tableLayout: 'fixed' }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <i>Title</i>/Candidate
            </TableCell>
            <TableCell>Principal Advisor</TableCell>
            <TableCell width={70}>Degree</TableCell>
            <TableCell>UQ organisational unit</TableCell>
            <TableCell width={180}>Reviewed by date</TableCell>
            <TableCell align="right" width={250}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pendingFinalSubmissionApproval.length == 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No theses available to confer
              </TableCell>
            </TableRow>
          ) : (
            data.pendingFinalSubmissionApproval.map((action, index) => {
              const styles = {
                dataCell: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }
              return (
                <>
                  <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }} colSpan={6}>
                      <Typography
                        variant="body1"
                        sx={{ pb: 0, pt: 1, mb: -1, fontStyle: 'italic' }}
                        dangerouslySetInnerHTML={{ __html: cleanHTML(action.currentIteration.title) }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip title={action.candidate.displayName} arrow>
                        <Typography variant="body2" sx={styles.dataCell}>
                          {action.candidate.displayName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip title={action.principalAdvisor.displayName} arrow>
                        <Typography variant="body2" sx={styles.dataCell}>
                          {action.principalAdvisor.displayName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip
                        title={thesisTypes.find((type) => type.code === action?.currentIteration?.type).name}
                        arrow
                      >
                        <span>{thesisTypes.find((type) => type.code === action?.currentIteration?.type).name}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>
                      <Tooltip title={action.currentIteration.organisation.name} arrow>
                        <Typography variant="body2" sx={styles.dataCell}>
                          {action.currentIteration.organisation.name}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.dataCell}>{toFullTimezone(action?.currentStatus.createdAt)}</TableCell>
                    <TableCell align="right" sx={styles.dataCell}>
                      <ButtonGroup
                        size="small"
                        variant="contained"
                        color="secondary"
                        aria-label="View or confer this thesis"
                        sx={{ mr: -1, mb: 1 }}
                      >
                        <Button
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(
                              `${process.env.WEB_BASE_URL}/graduate-school/thesis/details/${action.submission.id}`,
                              '_blank'
                            )
                          }}
                        >
                          View
                        </Button>
                        {action.currentStatus.status === 'DEAN_FINAL_REVIEW' && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              setOnOpenFinalSubmissionApprovalDialog(true)
                              setThesisSubmissionData(action)
                            }}
                            variant="contained"
                            color="primary"
                          >
                            {'Request Conferral'}
                          </Button>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                </>
              )
            })
          )}
        </TableBody>
      </Table>

      {onOpenFinalSubmissionApprovalDialog && thesisSubmissionData && (
        <ApproveFinalSubmissionDialog
          onOpen={onOpenFinalSubmissionApprovalDialog}
          onClose={onCloseDialog}
          input={thesisSubmissionData}
          onRefetch={onRefetch}
        />
      )}
    </>
  )
}

export default FinalSubmissionApprovalCard
