import { useState } from 'react'

import { GetThesisSubmissionQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import RESEND_EXAMINER_INVITATION_MAIL from 'src/lib/graphql/mutations/ResendExaminerInvitationMail'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  onOpen: boolean
  onClose: () => void
}

/**
 * Resend thesis examiner invitation mail dialog component
 *
 * This component can be accessed GS and PA role
 */
const ResendThesisExaminerInvitationMailDialog = ({ examinerData, onOpen, onClose }: Props) => {
  const { hasRole } = useAuth()
  const showSnackbar = useSnackbar()

  const [saving, setSaving] = useState<boolean>(false)

  const [resendExaminerInvitationMail] = useMutation(RESEND_EXAMINER_INVITATION_MAIL, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const onConfirmResend = async () => {
    setSaving(true)

    try {
      let result = undefined

      if (hasRole(['grad-school-user', 'user-thesis-principal-advisor'])) {
        result = await resendExaminerInvitationMail({
          variables: { examinerId: examinerData.id },
        })

        if (!result) throw new Error('Received invalid result')

        showSnackbar('You have successfully resent the invitation to the examiner.', 'success')
      } else {
        throw new Error('Could not resend examiner invitation email.')
      }
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not resend examiner invitation email.', 'error')
    }

    setSaving(false)
    onClose()
  }

  return (
    <ConfirmDialog
      open={onOpen}
      onClose={onClose}
      onConfirm={onConfirmResend}
      confirmLabel="Send"
      title="Resend invitation email to examiner"
      loading={saving}
    >
      {`Would you like to resend invitation email to ${examinerData.fullName} (${examinerData.mail})?`}
    </ConfirmDialog>
  )
}

export default ResendThesisExaminerInvitationMailDialog
