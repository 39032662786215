import { Fragment, useState } from 'react'

import {
  Alert,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Grant, UserGrantsQuery } from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'

export const QUERY = gql`
  query UserGrantsQuery {
    grants {
      title
      scheme
      statusTitle
      externalId
    }
  }
`

export const Loading = () => (
  <DialogContent id="dialogcontent">
    <InlineLoader message="Loading your grants from central UQ systems..." />
  </DialogContent>
)

type FailureProps = {
  onClose: () => unknown
}
export const Failure = ({ onClose }: FailureProps) => (
  <>
    <DialogContent id="dialogcontent">
      <Alert severity="error">UQRDM could not load your grants from central UQ systems. Please try again.</Alert>
    </DialogContent>
    <DialogActions>
      <Button color="inherit" onClick={onClose} variant="outlined">
        Close
      </Button>
    </DialogActions>
  </>
)

type SelectGrantsDialogProps = CellSuccessProps<UserGrantsQuery> & {
  open: boolean
  selectedGrants: Grant[]
  onClose: () => void
  onAdd: (grants: Grant[]) => void
}

export const Success = (props: SelectGrantsDialogProps) => {
  const [selected, setSelected] = useState<Grant[]>(props.selectedGrants)

  const onSubmit = () => {
    props.onAdd(selected)
  }

  const isSelected = (grant: Grant) => {
    return !!selected.find((g) => g.externalId === grant.externalId)
  }

  const toggleGrant = (grant: Grant) => {
    if (isSelected(grant)) {
      setSelected(selected.filter((g) => g.externalId !== grant.externalId))
    } else {
      setSelected([...selected, grant])
    }
  }

  // Only show those applications that are not already on the record
  const newGrants = props.grants.filter((e) => {
    return props.selectedGrants.find((se) => se.externalId === e.externalId) === undefined
  })

  return (
    <>
      <DialogContent id="dialogcontent">
        <List dense>
          {newGrants.map((grant) => (
            <Fragment key={grant.externalId}>
              <ListItem button onClick={() => toggleGrant(grant)}>
                <ListItemIcon>
                  <Checkbox edge="start" tabIndex={-1} checked={isSelected(grant)} />
                </ListItemIcon>
                <ListItemText primary={grant.externalId} secondary={grant.title} />
              </ListItem>
              <Divider />
            </Fragment>
          ))}

          {newGrants.length === 0 ? (
            <ListItem>
              <ListItemText primary="UQRDM could not find any current grants associated with your credentials in central UQ systems." />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemText primary="UQRDM found these current grants associated with your credentials in central UQ systems." />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {newGrants.length === 0 ? (
          <Button color="inherit" onClick={props.onClose} variant="outlined">
            Close
          </Button>
        ) : (
          <>
            <Button color="inherit" onClick={props.onClose} variant="outlined">
              Cancel
            </Button>
            <Button color="primary" onClick={onSubmit}>
              Add
            </Button>
          </>
        )}
      </DialogActions>
    </>
  )
}
