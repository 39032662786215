import { useState } from 'react'

import { SHOW_EXAMINER_REPORTS_STATUSES, STATUSES_BEFORE_EXAMINER_REVIEWING } from '@common/thesis/getThesisStatuses'
import { getIterationState } from '@common/thesis/iterationState'
import { isExaminerAddedByGraduateSchool } from '@common/thesis/thesisExaminers/helper'
import { Alert, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { toTimezone } from 'src/lib/toTimezone'
import {
  isAssignedThesisCandidate,
  isAssignedThesisChair,
  isAssignedThesisPrincipalAdvisor,
} from 'src/lib/userAccessChecker'

import ExaminersReviewDialog from './components/ExaminersReviewDialog'
import UserAccordion from './UserAccordion'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

const GRADUATE_SCHOOL_EXAMINER_FEEDBACK_REPORTS = gql`
  mutation GraduateSchoolShowExaminerFeedbackReports($submissionId: Int!, $iterationId: Int!, $decision: Boolean!) {
    graduateSchoolShowExaminerReports(submissionId: $submissionId, iterationId: $iterationId, decision: $decision)
  }
`

/**
 * Thesis written examination card
 */
const WrittenExaminationCard = ({ submission, onReload }: Props) => {
  const showSnackbar = useSnackbar()
  const { currentUser, hasRole } = useAuth()

  const [openDialog, setOpenDialog] = useState(false)
  const [openExaminersReviewDialog, setOpenExaminersReviewDialog] = useState(false)
  const [saving, setSaving] = useState(false)

  const [feedbackReportsMutation] = useMutation(GRADUATE_SCHOOL_EXAMINER_FEEDBACK_REPORTS)

  const onConfirmRelease = async () => {
    setSaving(true)
    try {
      const result = await feedbackReportsMutation({
        variables: {
          submissionId: submission.id,
          iterationId: submission.currentIteration?.id,
          decision: true,
        },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You have successfully released the examiners' feedback reports.", 'success')
      setOpenDialog(false)
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar(
        "An error occurred while releasing the examiners' feedback reports. Please try again later.",
        'error'
      )
    }

    setSaving(false)
    onReload()
  }

  const approvedExaminers = submission?.thesisSubmissionExaminers.filter(
    (examiner) => examiner.status === 'APPROVED' || isExaminerAddedByGraduateSchool(examiner)
  )
  const isCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const isAdvisor = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const isExaminer = hasRole('user-thesis-examiner')
  const showExaminerReportsReleaseButton =
    hasRole('grad-school-user') &&
    SHOW_EXAMINER_REPORTS_STATUSES.has(submission.currentIteration?.currentStatus.status) &&
    submission.showExaminerReports === false

  const awaitExaminerReviewing = STATUSES_BEFORE_EXAMINER_REVIEWING.has(
    submission.currentIteration?.currentStatus.status
  )
  const examinerReviewing = submission.currentIteration?.currentStatus.status === 'EXAMINERS_REVIEWING'
  const graduateSchoolSchedulingOral =
    submission.currentIteration?.currentStatus.status === 'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION'

  const hasAtLeastOneFeedbackCompleted = approvedExaminers.some((examiner) => examiner.examinationFeedbackCompleted)
  const chairAccess = isAssignedThesisChair(currentUser, submission?.chair?.user.id)
  const gsAccess = hasRole(['grad-school-user'])
  const hasAccessToViewForm = (chairAccess || gsAccess) && hasAtLeastOneFeedbackCompleted // only chair and GS can view the form

  const iterationState = getIterationState({
    status: submission.currentIteration?.currentStatus.status,
    outcome: submission.currentIteration?.currentStatus.outcome,
  })
  const isInFinalState = iterationState.thesisState === 'FINAL'

  return (
    <ContentCard
      title="Examination of thesis documents"
      action={
        <>
          {showExaminerReportsReleaseButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenDialog(true)
              }}
              sx={{ mr: 1 }}
              size={'medium'}
            >
              Release Feedback Reports
            </Button>
          )}
        </>
      }
    >
      <Grid container spacing={2} direction={'column'}>
        {submission?.currentIteration?.currentStatus?.status === 'EXAMINERS_REVIEWING' &&
          submission?.currentIteration?.examinerFeedbackDueDate && (
            <Grid xs={12}>
              <Typography variant="body2">
                {`Examinations due date: ${toTimezone(submission?.currentIteration?.examinerFeedbackDueDate)}`}
              </Typography>
            </Grid>
          )}
        {/* BEFORE GS initiates examination */}
        {/* STATUS */}
        <Grid xs={12}>
          {awaitExaminerReviewing ? (
            <Alert severity="info">
              {`Once the Graduate School approves ${isCandidate ? 'your' : 'the'} thesis for examination, ${
                isExaminer ? 'you will provide your' : 'the examiners will provide their'
              } feedback
              here.`}
            </Alert>
          ) : examinerReviewing ? (
            // After GS initiates examination
            <>
              {!isExaminer ? (
                <Typography variant="body2">
                  {isCandidate ? 'Your' : 'The'} thesis is currently being examined. Once both examiners have completed
                  their feedback, the Graduate School will review and release the feedback forms.
                </Typography>
              ) : (
                <Typography variant="body2">Please review the thesis and submit your feedback below.</Typography>
              )}
            </>
          ) : graduateSchoolSchedulingOral ? (
            // Both examiners have completed their feedback, but oral not set.
            <>
              {(isExaminer || isCandidate || isAdvisor) && (
                <Typography variant="body2">
                  The Graduate School is reviewing examiner feedback and setting the oral exam date. You will be
                  notified when the feedback is available.
                </Typography>
              )}
            </>
          ) : null}
        </Grid>

        {!awaitExaminerReviewing &&
          Array.from({ length: 2 }).map((_, index) => {
            const examiner = approvedExaminers[index] || null

            return (
              <Grid key={index} xs={12}>
                <UserAccordion key={index} examiner={examiner} submission={submission} onReload={onReload} />
              </Grid>
            )
          })}
        {hasAccessToViewForm && (
          <Grid xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpenExaminersReviewDialog(true)
                  }}
                  size={'medium'}
                >
                  {isInFinalState ? 'View feedback' : 'Review feedback'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        confirmLabel="Release reports"
        open={openDialog}
        loading={saving}
        onClose={() => setOpenDialog(false)}
        onConfirm={onConfirmRelease}
        title="Release feedback reports"
      >
        Are you sure you want to make examiners feedback reports visible to the candidate, Principal Advisor, and
        examiners?
        <br />
        <br />
        Note: Examiner feedback is automatically released when the oral examination is scheduled and does not have to be
        done manually here if the examination date is known.
      </ConfirmDialog>
      <ExaminersReviewDialog
        open={openExaminersReviewDialog}
        onCancel={() => setOpenExaminersReviewDialog(false)}
        submission={submission}
      />
    </ContentCard>
  )
}

export default WrittenExaminationCard
