const GET_PROVOST_ACTION_LIST = gql`
  query GetProvostActionListQuery {
    getProvostActionList {
      pendingConferralSubmissions {
        candidate {
          id
          displayName
        }
        principalAdvisor {
          displayName
        }
        submission {
          id
          uuid
          reviseAndResubmitOfId

          isDowngradedToMPhil
          updatedAt
        }
        currentIteration {
          id
          title
          type
          submittedDate
          oralDate
          organisation {
            name
            id
          }
        }
        currentStatus {
          id
          previousStatusId
          status
          outcome
          outcomeMessage
          createdAt
          updatedAt
          outcomeByUser {
            displayName
          }
          nextStatus {
            id
          }
        }
      }
    }
  }
`

export default GET_PROVOST_ACTION_LIST
