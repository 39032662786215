import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import HelpButton from 'src/components/HelpButton/HelpButton'
import { ThesisSubmissionDetails } from 'src/components/Thesis'
import finalThesisFilesGuidance from 'src/lib/guidance/thesis/finalThesisFilesGuidance'
import initialThesisFilesGuidance from 'src/lib/guidance/thesis/initialThesisFilesGuidance'

import ThesisCorrectionsFile from './components/ThesisCorrectionsFile'
import ThesisDocument from './components/ThesisDocument'
import ThesisIthenticateFile from './components/ThesisIthenticateFile'
import ThesisSupplementaryFiles from './components/ThesisSupplementaryFiles'

type Props = {
  isRequestChanges: boolean
  isFinal: boolean
  readOnly: boolean
  submission: ThesisSubmissionDetails
}

const ThesisFiles = ({ isFinal, readOnly, isRequestChanges, submission }: Props) => {
  const hasFiles = submission?.currentIteration?.files.length > 0
  return (
    <Grid container={true} spacing={2} justifyContent="stretch">
      <Grid xs={12}>
        <Grid container spacing={2}>
          <Grid xs>
            <Typography variant="h4" component="h3" color="primary">
              Thesis files
            </Typography>
          </Grid>
          {!readOnly && (
            <Grid xs="auto">
              <HelpButton
                title={!isFinal ? initialThesisFilesGuidance.title : finalThesisFilesGuidance.title}
                text={!isFinal ? initialThesisFilesGuidance.text : finalThesisFilesGuidance.text}
                buttonSx={{ mt: -1 }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {hasFiles && (
        <Grid xs={12}>
          <Typography variant="body2" sx={{ mt: -2 }}>
            To submit an edited thesis document, remove the files you edited first, by clicking the trash icon and
            upload the newest versions of those files (all previously submitted file versions are saved and can be
            accessed in the your candidature history - the clock icon within the candidature pane).
            {!isFinal && (
              <span>
                If you are submitting a new thesis document, your iThenticate report needs to be generated from the new
                thesis document.
              </span>
            )}
          </Typography>
        </Grid>
      )}
      <Grid xs={12}>
        <ThesisDocument readOnly={readOnly} />
      </Grid>
      {!isFinal && (
        <Grid xs={12}>
          <ThesisIthenticateFile readOnly={readOnly} />
        </Grid>
      )}
      <Grid xs={12}>
        <ThesisSupplementaryFiles readOnly={readOnly} />
      </Grid>
      {isRequestChanges && (
        <Grid xs={12}>
          <ThesisCorrectionsFile readOnly={readOnly} />
        </Grid>
      )}
    </Grid>
  )
}
export default ThesisFiles
