import { useApolloClient } from '@apollo/client'
import { isExaminerAddedByGraduateSchool } from '@common/thesis/thesisExaminers/helper'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { List, ListItem, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisFileDownloadUrlQuery, GetThesisSubmissionQuery } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'

import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import theme from 'src/lib/muiTheme'
import Sentry from 'src/lib/sentry'

import ThesisFilesViewerSection from '../../../ThesisIterationFormViewer/components/ThesisFilesViewerSection'

type Props = {
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair']
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  hasOral: boolean
  readOnly?: boolean
}

const ChairFeedbackReview = ({ chair, submission, hasOral }: Props) => {
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()
  const formMethods = useFormContext()
  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const completedFeedback = chair?.summaryReportCompleted
  const hasAdditionalAmendments = formMethods.watch('amendments') === 'additional'
  const chairFile = submission.currentIteration.files.filter(
    (f) => f.id === chair?.fileId && f.type === ThesisFileType.CHAIR_REVIEW_FILE && f.isActive
  )

  const chairQuestions = {
    amendments: {
      all: 'All amendments required are included in the examiner reports. No list required.',
      additional:
        'Additional amendments to those in the examiner report were identified during the oral examination and are outlined below.',
    },
    outcome: {
      pass: 'Pass - no corrections required',
      passWithCorrections: 'Pass - with corrections',
      repeatOral: 'Not yet passed - Repeat oral examination only',
      reviseResubmitOral: 'Not yet passed - Revise and resubmit (with oral)',
      reviseResubmitWithoutOral: 'Not yet passed - Revise and resubmit (without oral)',
    },
    // The candidate demonstrated detailed knowledge of the thesis.
    detailedKnowledgeOfThesis: {
      question: 'The candidate demonstrated detailed knowledge of the thesis.',
      exceeds: (
        <span>
          The candidate demonstrated detailed knowledge of the thesis that was <b>well above expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate demonstrated detailed knowledge of the thesis that was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate demonstrated detailed knowledge of the thesis that <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate demonstrated knowledge of the thesis that was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate demonstrated knowledge of the thesis that was <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate demonstrated an understanding of their research in the broader context of their discipline.
    understandingResearchInBroaderContext: {
      question:
        'The candidate demonstrated an understanding of their research in the broader context of their discipline.',
      exceeds: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that{' '}
          <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that
          was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that{' '}
          <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline that
          was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate demonstrated an understanding of their research in the broader context of their discipline{' '}
          <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of the limitations of the thesis.
    defendMethodologyAndConclusions: {
      question:
        'The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of the limitations of the thesis.',
      exceeds: (
        <span>
          The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate was able to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate attempted to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate attempted to defend the methodology and conclusions of the thesis and displayed an awareness of
          the limitations of the thesis that was <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate effectively communicated the results of the research in terms of impact and application of new knowledge
    communicatedResultsAndImpact: {
      question:
        'The candidate effectively communicated the results of the research in terms of impact and application of new knowledge',
      exceeds: (
        <span>
          The candidate effectively communicated the results of the research in terms of impact and application of new
          knowledge that <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate effectively communicated the results of the research in terms of impact and application of new
          knowledge that was <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate effectively communicated the results of the research in terms of impact and application of new
          knowledge that <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate attempted to communicate the results of the research in terms of impact and application of new
          knowledge that was <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate attmpted to communicate the results of the research in terms of impact and application of new
          knowledge that was <b>well below expectations</b>.
        </span>
      ),
    },
    // The candidate demonstrated a substantive and independent contribution to the discipline
    independentContributionToDiscipline: {
      question: 'The candidate demonstrated a substantive and independent contribution to the discipline',
      exceeds: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that{' '}
          <b>exceeded expectations</b>.
        </span>
      ),
      aboveAverage: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that was{' '}
          <b>above expectations</b>.
        </span>
      ),
      average: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that{' '}
          <b>met expectations</b>.
        </span>
      ),
      below: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that was{' '}
          <b>below expectations</b>.
        </span>
      ),
      wellBelow: (
        <span>
          The candidate demonstrated a substantive and independent contribution to the discipline that was{' '}
          <b>well below expectations</b>.
        </span>
      ),
    },
  }

  const styleExaminerAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -0.8, mr: 0.5 }} />
    switch (answer) {
      case 'exceeds':
        return circle('#00B3A6')
        break
      case 'aboveAverage':
        return circle('#00A2C7')
        break
      case 'average':
        return circle('#FFDD00')
        break
      case 'below':
        return circle('#FF7700')
        break
      case 'wellBelow':
        return circle('#B00020')
        break
      default:
        return null
    }
  }
  return (
    <>
      {hasOral && (
        <>
          <Grid xs={12} marginTop={2}>
            <Typography component="h4" variant="h4" color="primary">
              Examiner attendance
            </Typography>
            <List sx={{ bgcolor: 'rgba(81, 36, 122, 0.05)', borderRadius: 2, mt: 2 }}>
              {submission.thesisSubmissionExaminers
                .filter((e) => e.status === 'APPROVED' || isExaminerAddedByGraduateSchool(e))
                .map((examiner, index) => {
                  const attendanceParser = (attendance) => {
                    switch (attendance) {
                      case 'person':
                        return "'s attendance was in person."
                      case 'video':
                        return "'s attendance was via video conference."
                      case 'other':
                        return "'s attendance was by other means."
                    }
                  }
                  return (
                    <ListItem key={index}>
                      <Grid container spacing={2}>
                        <Grid xs={12}>
                          <Typography>
                            <b>{examiner?.user?.displayName || examiner?.fullName}</b>
                            {`${attendanceParser(formMethods.watch(`examiner${index}AttendanceType.type`))}`}

                            {examiner.otherOralAttendanceArrangements && (
                              <Tooltip arrow placement="right" title={examiner.otherOralAttendanceArrangements}>
                                <InfoOutlinedIcon sx={{ height: 18, width: 18, fill: 'rgba(0,0,0,0.6)' }} />
                              </Tooltip>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )
                })}
            </List>
          </Grid>
        </>
      )}

      <Grid xs={12} marginTop={1}>
        <Typography component="h4" variant="h4" color="primary" sx={{ mb: 2 }}>
          Summary assessment
        </Typography>
        {hasOral
          ? `The candidate's presentation and their responses to questions raised by the examination committee during the oral examination:`
          : `The candidate's knowledge and understanding demonstrated in their examination:`}
      </Grid>
      <Grid xs={12}>
        <List sx={{ bgcolor: 'rgba(81, 36, 122, 0.05)', borderRadius: 2 }}>
          <ListItem sx={{ display: 'inline-block' }}>
            <Grid container spacing={0}>
              <Grid xs="auto">{styleExaminerAnswer(formMethods.watch('detailedKnowledgeOfThesis'))}</Grid>
              <Grid xs>{chairQuestions.detailedKnowledgeOfThesis[formMethods.watch('detailedKnowledgeOfThesis')]}</Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: 'inline-block' }}>
            <Grid container spacing={0}>
              <Grid xs="auto">{styleExaminerAnswer(formMethods.watch('understandingResearchInBroaderContext'))}</Grid>
              <Grid xs>
                {
                  chairQuestions.understandingResearchInBroaderContext[
                    formMethods.watch('understandingResearchInBroaderContext')
                  ]
                }
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: 'inline-block' }}>
            <Grid container spacing={0}>
              <Grid xs="auto">{styleExaminerAnswer(formMethods.watch('defendMethodologyAndConclusions'))}</Grid>
              <Grid xs>
                {chairQuestions.defendMethodologyAndConclusions[formMethods.watch('defendMethodologyAndConclusions')]}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: 'inline-block' }}>
            <Grid container spacing={0}>
              <Grid xs="auto">{styleExaminerAnswer(formMethods.watch('communicatedResultsAndImpact'))}</Grid>
              <Grid xs>
                {chairQuestions.communicatedResultsAndImpact[formMethods.watch('communicatedResultsAndImpact')]}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: 'inline-block' }}>
            <Grid container spacing={0}>
              <Grid xs="auto">{styleExaminerAnswer(formMethods.watch('independentContributionToDiscipline'))}</Grid>
              <Grid xs>
                {
                  chairQuestions.independentContributionToDiscipline[
                    formMethods.watch('independentContributionToDiscipline')
                  ]
                }
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>

      <Grid xs={12} marginTop={1}>
        <Typography component="h4" variant="h4" color="primary">
          Summary feedback
        </Typography>
      </Grid>

      <Grid xs={12} sx={{ mb: 1, mt: 1 }}>
        <Typography>
          {`A brief report on the ${hasOral ? 'oral' : ''} examination, detailing the structure of the examination and providing a
          summary of the candidate's performance in responding to the questions raised by the examiners:`}
        </Typography>
      </Grid>

      <Grid xs={12} sx={{ bgcolor: 'rgba(81, 36, 122, 0.05)', borderRadius: 2, p: 2, ml: 1, mr: 1 }}>
        <Typography sx={{ fontStyle: 'italic', ml: 1, overflowWrap: 'break-word' }}>
          <div dangerouslySetInnerHTML={{ __html: formMethods.watch('examinationSummary') }} />
        </Typography>
      </Grid>
      <Grid xs={12} sx={{ pt: 3 }}>
        {formMethods.watch('amendments') === 'all'
          ? 'All amendments required are included in the examiner reports. No list required.'
          : 'Additional amendments to those in the examiner report were identified during the oral examination and are outlined below:'}
      </Grid>
      {hasAdditionalAmendments && (
        <Grid xs={12}>
          <List sx={{ bgcolor: 'rgba(81, 36, 122, 0.05)', borderRadius: 2 }}>
            <ListItem>
              <Typography sx={{ fontStyle: 'italic', ml: 1 }}>
                <span dangerouslySetInnerHTML={{ __html: formMethods.watch('otherAmendmentDetails') }} />
              </Typography>
            </ListItem>
          </List>
        </Grid>
      )}

      <Grid xs={12} marginTop={1}>
        <Typography component="h3" variant="h4" color="primary">
          Summary recommendation
        </Typography>
      </Grid>
      <Grid xs={12}>
        <List sx={{ bgcolor: 'rgba(81, 36, 122, 0.05)', borderRadius: 2, mt: 0 }}>
          <ListItem>
            <FiberManualRecordIcon
              sx={{
                color:
                  formMethods.watch('chairOutcomeRecommendation') === 'pass'
                    ? theme.palette.success.main
                    : formMethods.watch('chairOutcomeRecommendation') === 'passWithCorrections'
                      ? theme.palette.warning.main
                      : theme.palette.error.main,
                mr: 0.5,
              }}
            />
            <strong>{chairQuestions.outcome[formMethods.watch('chairOutcomeRecommendation')]}</strong>
          </ListItem>
        </List>
      </Grid>

      {completedFeedback && chairFile.length !== 0 && (
        <>
          <Grid xs={12}>
            <Typography component="h4" variant="h4" color="primary">
              Review document
            </Typography>
          </Grid>
          <Grid xs={12}>
            <ThesisFilesViewerSection
              documentLabel=""
              fileType={ThesisFileType.CHAIR_REVIEW_FILE}
              files={chairFile}
              onDownload={onDownload}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default ChairFeedbackReview
