import { useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import { isAssignedThesisPrincipalAdvisor } from 'src/lib/userAccessChecker'

import ChairEdit from './ChairEdit'
import ChairView from './ChairView'
import GraduateSchoolAddChairDialog from './GraduateSchoolAddChairDialog'
import PrincipalAdvisorAddChairDialog from './PrincipalAdvisorAddChairDialog'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

/**
 * Chair section
 */
const ChairSection = ({ submission }: Props) => {
  const { hasRole, currentUser } = useAuth()

  const isGS = hasRole('grad-school-user')
  const isAssignedPA = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)

  const [openPAAddChairDialog, setOpenPAAddChairDialog] = useState<boolean>(false)
  const [openGradSchoolAddChairDialog, setOpenGradSchoolAddChairDialog] = useState<boolean>(false)

  // If a user has both grad and pa roles, pa role should come precedent.
  const onAddChair = () => {
    if (isAssignedPA) {
      setOpenPAAddChairDialog(true)
      return true
    }

    if (isGS) {
      setOpenGradSchoolAddChairDialog(true)
      return true
    }
  }

  const hasChair = submission?.chair

  const canAdvisorEdit = isAssignedPA && !hasChair
  const canEditChair = isGS || canAdvisorEdit

  return (
    <>
      <Grid container>
        <Grid xs={6} display={'flex'} alignSelf={'center'}>
          <Typography variant="h4" sx={{ color: 'white', mb: 1 }}>
            Chair of Examiners:
          </Typography>
        </Grid>
        {canEditChair && !hasChair && (
          <Grid xs={6} display={'flex'} justifyContent={'end'}>
            <Tooltip title={'Assign Chair of Examiners'} placement="left">
              <IconButton onClick={onAddChair} sx={{ mr: -1 }}>
                <AddCircle sx={{ color: '#00a2c7' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {canEditChair ? <ChairEdit submission={submission} /> : <ChairView submission={submission} />}
      {/* Dialogs */}
      {!hasChair && openGradSchoolAddChairDialog && isGS && (
        <GraduateSchoolAddChairDialog
          onClose={() => setOpenGradSchoolAddChairDialog(false)}
          onOpen={openGradSchoolAddChairDialog}
          submissionId={submission.id}
        />
      )}
      {!hasChair && openPAAddChairDialog && isAssignedPA && (
        <PrincipalAdvisorAddChairDialog
          onClose={() => setOpenPAAddChairDialog(false)}
          onOpen={openPAAddChairDialog}
          submissionId={submission.id}
        />
      )}
    </>
  )
}

export default ChairSection
