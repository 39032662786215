import Grid from '@mui/material/Unstable_Grid2'

import ThesisFilesViewer from 'src/components/Thesis/ThesisIterationFormViewer/components/ThesisFilesViewer'
import { ThesisSubmissionIterationType } from 'src/components/Thesis/ThesisSubmissionType'


type Props = {
  iteration: ThesisSubmissionIterationType
}

const InitialThesisIterationFilesViewer = ({ iteration }: Props) => {
  const hasCorrectionFile = iteration.files.some((f) => f.type === 'CORRECTIONS_FILE')

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <ThesisFilesViewer files={iteration.files} showIthenticateFile={true} showCorrectionsFile={hasCorrectionFile} />
      </Grid>
    </Grid>
  )
}

export default InitialThesisIterationFilesViewer
