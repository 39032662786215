import Uppy from '@uppy/core'
import DashboardModal from '@uppy/react/lib/DashboardModal'

type Props = {
  uppy: Uppy
  open: boolean
  onClose: () => void
}

const ThesisFilesUploadModal = ({ open, onClose, uppy }: Props) => {
  return (
    <DashboardModal
      uppy={uppy}
      open={open}
      onRequestClose={onClose}
      proudlyDisplayPoweredByUppy={false}
      showProgressDetails={true}
      thumbnailHeight={10}
      locale={{
        strings: {
          dropPasteFiles: 'Drag and drop your files here or %{browse}',
          browseFiles: 'click here to browse your local files',
        },
      }}
    />
  )
}
export default ThesisFilesUploadModal
