import { useState } from 'react'

import { Alert, Box, Paper, Tab, Tabs, Typography } from '@mui/material'
import { GetDeanActionListQuery } from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import GET_DEAN_ACTION_LIST from 'src/lib/graphql/queries/GetDeanActionListQuery'

import FinalSubmissionApprovalCard from '../FinalSubmissionApprovalCard'
import PendingChangedRequestSubmissionReviewCard from '../PendingChangedRequestSubmissionReviewCard'
import PendingCOIApprovalCard from '../PendingCOIApprovalCard'
import PendingReviewChairOralFeedbackCard from '../PendingReviewChairOralFeedbackCard'

export const QUERY = GET_DEAN_ACTION_LIST

export const Loading = () => <InlineLoader message="Loading Dean action page..." />

export const Failure = () => (
  <Alert severity="error">Could not load Dean action page. Please refresh the page and try again.</Alert>
)

export const Success = ({ getDeanActionList, queryResult: { refetch } }: CellSuccessProps<GetDeanActionListQuery>) => {
  const labels = [
    'examinersToBeApproved',
    'submissionsToBeApproved',
    'submissionToBeReviewed',
    'pendingFinalSubmissionApproval',
  ]

  const getNonEmptyLabelIndex = (labels, dataObj) => {
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i]
      if (Array.isArray(dataObj[label]) && dataObj[label].length > 0) {
        return i
      }
    }
    return 0
  }

  const [tab, setTab] = useState(getNonEmptyLabelIndex(labels, getDeanActionList))
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const renderValue = (value, thisTab) => {
    const ringColor = () => {
      if (thisTab) {
        return 'rgb(81, 36, 122)'
      } else if (value === 0) {
        return 'rgba(0,0,0,0.3)'
      } else if (value < 10) {
        return 'rgba(0,0,0,0.5)'
      } else if (value < 20) {
        return 'rgba(255,140,0,1)'
      } else {
        return 'rgba(255,0,0,1)'
      }
    }
    const ringWidth = () => {
      if (thisTab) {
        return 3
      } else if (value === 0) {
        return 1
      } else if (value < 10) {
        return 2
      } else if (value < 20) {
        return 3
      } else {
        return 3
      }
    }
    return (
      <Box
        sx={{
          display: 'inline-block',
          width: 24,
          height: 24,
          borderRadius: 10,
          border: `${ringWidth()}px solid ${ringColor()}`,
          color: ringColor(),
          fontSize: 13,
          fontWeight: 'bold',
          pt: thisTab ? 0.3 : value < 20 ? 0.5 : 0.3,
          mt: -0.1,
          ml: 1,
          mr: -1,
        }}
      >
        {value}
      </Box>
    )
  }
  const nothingToDo =
    getDeanActionList.examinersToBeApproved?.length +
      getDeanActionList.submissionsToBeApproved?.length +
      getDeanActionList.submissionToBeReviewed?.length +
      getDeanActionList.pendingFinalSubmissionApproval?.length ===
    0
  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          disabled={getDeanActionList.examinersToBeApproved.length === 0}
          label={
            <span>
              Conflict of interest review {renderValue(getDeanActionList.examinersToBeApproved?.length || 0, tab === 0)}
            </span>
          }
          {...a11yProps(0)}
        />
        <Tab
          disabled={getDeanActionList.submissionsToBeApproved.length === 0}
          label={
            <span>
              Initial thesis outcome {renderValue(getDeanActionList.submissionsToBeApproved?.length || 0, tab === 1)}
            </span>
          }
          {...a11yProps(1)}
        />
        <Tab
          disabled={getDeanActionList.submissionToBeReviewed.length === 0}
          label={
            <span>
              Thesis correction review {renderValue(getDeanActionList.submissionToBeReviewed?.length || 0, tab === 2)}
            </span>
          }
          {...a11yProps(2)}
        />
        <Tab
          disabled={getDeanActionList.pendingFinalSubmissionApproval.length === 0}
          label={
            <span>
              Requests to confer {renderValue(getDeanActionList.pendingFinalSubmissionApproval?.length || 0, tab === 3)}
            </span>
          }
          {...a11yProps(3)}
        />
      </Tabs>
      <Paper sx={{ marginTop: '0px !important', padding: 0 }}>
        {!nothingToDo && tab === 0 && <PendingCOIApprovalCard data={getDeanActionList} onRefetch={refetch} />}
        {!nothingToDo && tab === 1 && (
          <PendingReviewChairOralFeedbackCard data={getDeanActionList} onRefetch={refetch} />
        )}
        {!nothingToDo && tab === 2 && (
          <PendingChangedRequestSubmissionReviewCard data={getDeanActionList} onRefetch={refetch} />
        )}
        {!nothingToDo && tab === 3 && <FinalSubmissionApprovalCard data={getDeanActionList} onRefetch={refetch} />}
        {nothingToDo && (
          <Box sx={{ p: 4, pt: 3 }}>
            <Typography component="h2" variant="h2" color="primary" sx={{ mb: 2 }}>
              Nothing outstanding
            </Typography>
            There are no outstanding tasks pending Dean action.
            <br />
            <br />
            To view a specific thesis submission or candidature, please use the ‘Search thesis submission’ tab in the
            menu.
          </Box>
        )}
      </Paper>
    </>
  )
}
