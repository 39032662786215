export type ThesisExaminerActionNotificationDataType = {
  actionDescription: string
  actionId: string
  notificationDisplayStatuses: string
  userRole: string
  message: string
  buttonText: string
  relatedExaminerId: number | null
  dialog: string
}

/**
 * Thesis examiner nomination action notification data
 *
 * @TODO future improvement: This could be refactored down to a single reduce/filter function where it checks for the user role and the examiner status. Potentially a few variables can be eliminated.
 */
export const actionNotificationData = (candidateName?: string) => [
  {
    actionDescription: 'Assign chair',
    actionId: 'assign-chair',
    notificationDisplayStatuses: '',
    userRole: 'user-thesis-principal-advisor',
    message: `You are required to assign the Chair of Examiners for the HDR candidature of ${candidateName}.`,
    buttonText: 'Assign',
    relatedExaminerId: null,
    dialog: 'paAddChairDialog',
  },
  {
    actionDescription: 'Nominate examiners dfgsdfg',
    actionId: 'nominate-examiners',
    notificationDisplayStatuses: '',
    userRole: 'user-thesis-principal-advisor',
    message: `You must nominate examiners for the HDR thesis of ${candidateName}.`,
    buttonText: 'Nominate',
    relatedExaminerId: null,
    dialog: 'addExaminerDialog',
  },
  {
    actionDescription: 'Nominate one examiner',
    actionId: 'nominate-one-examiner',
    notificationDisplayStatuses: '',
    userRole: 'user-thesis-principal-advisor',
    message: `You must nominate an additional examiner for the HDR thesis of ${candidateName}.`,
    buttonText: 'Nominate',
    relatedExaminerId: null,
    dialog: 'addExaminerDialog',
  },
  {
    actionDescription: 'Provide additional examiner details',
    actionId: 'provide-additional-examiner-details',
    notificationDisplayStatuses: ['INITIAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL'],
    userRole: 'user-thesis-principal-advisor',
    message:
      'The Graduate School has requested additional details for your nominated thesis examiner: ${examinerName}.',
    buttonText: 'Update',
    relatedExaminerId: null,
    dialog: 'paCOIFormDialog',
  },
  {
    actionDescription: 'Examiner provide COI disclosure',
    actionId: 'examiner-provide-coi-disclosure',
    notificationDisplayStatuses: ['EXAMINER_COI_REVIEW'],
    userRole: 'user-thesis-examiner',
    message: `As a nominated examiner for ${candidateName} you must provide a conflict of interest disclosure. Please complete and submit the form.`,
    buttonText: 'Submit',
    relatedExaminerId: null,
    dialog: 'examinerCOIFormDialog',
  },
  {
    actionDescription: 'Examiner update Graduate School requested details',
    actionId: 'examiner-update-grad-school-requested-details',
    notificationDisplayStatuses: ['FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL'],
    userRole: 'user-thesis-examiner',
    message: `The Graduate School has requested that you update your details for consideration as an examiner.`,
    buttonText: 'Update',
    relatedExaminerId: null,
    dialog: 'examinerCOIFormDialog',
  },
  {
    actionDescription: 'Candidate review examiner',
    actionId: 'candidate-review-examiner',
    notificationDisplayStatuses: ['CANDIDATE_COI_REVIEW'],
    userRole: 'user-thesis-submission',
    message:
      'Your Principal Advisor has nominated ${examinerName} to your examination committee. Please review and provide your conflict of interest disclosure.',
    buttonText: 'Review',
    relatedExaminerId: null,
    dialog: 'candidateCOIFormDialog',
  },
  {
    actionDescription: 'Graduate School review examiner',
    actionId: 'grad-school-review-examiner',
    notificationDisplayStatuses: ['GRADUATE_SCHOOL_INITIAL_REVIEW', 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL'],
    userRole: 'grad-school-user',
    message: 'Examiner nomination and conflict of interest disclosures have been submitted for your review.',
    buttonText: 'Review',
    relatedExaminerId: null,
    dialog: 'gsApprovalDialog',
  },
]
