/**
 * Thesis examiner statuses
 */
export const STATUSES_TO_SHOW_INITIAL_GS_CHANGE_REQUEST_MESSAGE = new Set([
  'GRADUATE_SCHOOL_INITIAL_REVIEW',
  'INITIAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL',
  'CANDIDATE_COI_REVIEW',
])

export const STATUSES_TO_SHOW_FINAL_GS_CHANGE_REQUEST_MESSAGE = new Set([
  'GRADUATE_SCHOOL_FINAL_REVIEW',
  'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL',
])

export const STATUSES_TO_ALLOW_EXAMINER_ACCESS = new Set([
  'EXAMINER_COI_REVIEW',
  'GRADUATE_SCHOOL_FINAL_REVIEW',
  'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL',
  'APPROVED',
])

export const STATUSES_TO_SHOW_GS_REJECTION_MESSAGE = new Set(['REJECTED'])

// Stages when GS requires to action review and approve the thesis examiner
export const PENDING_GS_APPROVAL_STATUSES = new Set(['GRADUATE_SCHOOL_INITIAL_REVIEW', 'GRADUATE_SCHOOL_FINAL_REVIEW'])

// Statuses of examiners
export const EXAMINER_REMOVED_STATUSES = new Set(['REJECTED', 'REMOVED'])

// Statuses of examiner that has been assigned to the thesis
export const EXAMINER_ASSIGNED_STATUSES = new Set(['APPROVED'])

export const PA_CAN_DELETE_EXAMINER_STATUSES = new Set(['INITIAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL'])
