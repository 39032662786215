import { useEffect } from 'react'

import { YES_NO_INPUTS } from '@common/thesis/thesisExaminers/examinationFormData'
import { THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE } from '@common/thesis/thesisExaminers/examinerNominationData'
import { Link, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ThesisSubmission, ThesisSubmissionExaminer } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'

import { CRadioGroup, CTextField } from 'src/components/Form/Inputs'

import CandidateConflictOfInterestDisclosureExaminerDetails from './CandidateUpdateExaminerCOIDetails'

type Props = {
  readOnly?: boolean
  examiner: Pick<
    ThesisSubmissionExaminer,
    | 'status'
    | 'fullName'
    | 'mail'
    | 'position'
    | 'institution'
    | 'rationaleForNomination'
    | 'examinerCOIStatements'
    | 'examinerCOIDetails'
    | 'advisorCOIStatements'
    | 'advisorCOIDetails'
    | 'candidateCOIStatements'
    | 'candidateCOIDetails'
  > & {
    user?: {
      displayName: string
      mail: string
    }
  }
  submission: Pick<ThesisSubmission, 'id'> & {
    principalAdvisor?: {
      displayName: string
      mail: string
    }
    creator?: {
      displayName: string
      mail: string
    }
  }
}

/**
 * Candidate COI form for examiner.
 */
const CandidateUpdateExaminerCOIForm = ({ examiner, submission, readOnly }: Props) => {
  const { watch, setValue } = useFormContext()

  const publishedWithCandidate = watch('publishedWithCandidate')
  const grantWithAdvisor = watch('grantWithAdvisor')
  const personalOrProfessionalRelationship = watch('personalOrProfessionalRelationship')
  const consultedOnThesis = watch('consultedOnThesis')
  const otherCOI = watch('otherCOI')
  const showExaminerCOIDetailsInput =
    publishedWithCandidate || grantWithAdvisor || personalOrProfessionalRelationship || consultedOnThesis || otherCOI

  useEffect(() => {
    if (!showExaminerCOIDetailsInput) {
      setValue('candidateCOIDetails', null)
    } else {
      setValue('candidateCOIDetails', examiner.candidateCOIDetails)
    }
  }, [showExaminerCOIDetailsInput])
  return (
    <Grid container spacing={2}>
      {!readOnly && (
        <>
          <Grid xs={12}>
            <Typography component={'h2'} variant="h2" color={'primary'}>
              Conflict of interest disclosure
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography>
              Our <abbr>COI</abbr> (conflict of interest) guidelines ensure that all theses are examined independently
              and free from any perception of bias or preferential treatment. Some of the more common <abbr>COI</abbr>s
              are listed below. Selecting any of the boxes below may not necessarily preclude you from examining the
              thesis however should be declared so that an assessment can be made. Please note that the conflicts listed
              below are not exhaustive and further information on UQ's <abbr>COI</abbr> guidelines is{' '}
              <Link href="https://policies.uq.edu.au/document/view-current.php?id=452#section4"> available here</Link>.
              If you select any of these categories, please provide further details below.
            </Typography>
          </Grid>
        </>
      )}
      {!readOnly ? (
        <>
          <Grid xs={12}>
            <CRadioGroup
              label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('publishedWithCandidate')}
              name="publishedWithCandidate"
              required
              aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('publishedWithCandidate')}
              readOnly={readOnly}
              options={YES_NO_INPUTS}
            />
          </Grid>
          <Grid xs={12}>
            <CRadioGroup
              label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('grantWithAdvisor')}
              name="grantWithAdvisor"
              required
              aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('grantWithAdvisor')}
              readOnly={readOnly}
              options={YES_NO_INPUTS}
            />
          </Grid>
          <Grid xs={12}>
            <CRadioGroup
              label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('personalOrProfessionalRelationship')}
              name="personalOrProfessionalRelationship"
              required
              aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('personalOrProfessionalRelationship')}
              readOnly={readOnly}
              options={YES_NO_INPUTS}
            />
          </Grid>
          <Grid xs={12}>
            <CRadioGroup
              label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('consultedOnThesis')}
              name="consultedOnThesis"
              required
              aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('consultedOnThesis')}
              readOnly={readOnly}
              options={YES_NO_INPUTS}
            />
          </Grid>
          <Grid xs={12}>
            <CRadioGroup
              label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('otherCOI')}
              name="otherCOI"
              required
              aria-label={THESIS_EXAMINER_NOMINATION_COI_QUESTIONS_FOR_CANDIDATE('otherCOI')}
              readOnly={readOnly}
              options={YES_NO_INPUTS}
            />
          </Grid>
          {showExaminerCOIDetailsInput && (
            <Grid xs={12}>
              <CTextField
                name="candidateCOIDetails"
                label="Please provide specific details about the conflict and your relationship with the examiner"
                placeholder="If you have published together, include the year and citation for the publication. If you have collaborated in other ways (e.g. thesis consultation), specify when it occurred and describe the nature of the collaboration. Providing clear details will help us assess the potential conflict appropriately."
                multiline
                minRows={3}
                required
                minLength={200}
                fullWidth
                readOnly={readOnly}
              />
            </Grid>
          )}
        </>
      ) : (
        Object.keys(examiner.candidateCOIStatements).length > 0 && (
          <>
            <Grid xs={12}>
              <Typography component={'h2'} variant="h2" color={'primary'}>
                Your conflict of interest disclosure with this examiner
              </Typography>
            </Grid>
            <CandidateConflictOfInterestDisclosureExaminerDetails examiner={examiner} submission={submission} />
          </>
        )
      )}
    </Grid>
  )
}

export default CandidateUpdateExaminerCOIForm
