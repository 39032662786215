import { useContext } from 'react'

import UiContext from '../context/Ui/Context'

const useSnackbar = () => {
  const { showSnackbar } = useContext(UiContext)

  return showSnackbar
}

export default useSnackbar
