import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import ChairApprovesCorrectionsButton from '../../ChairActions/ApproveCorrectionsButton/ChairApprovesCorrectionsButton'
import ChairRequestsAdditionalCorrectionsButton from '../../ChairActions/RequestAdditionalCorrectionsButton/ChairRequestsAdditionalCorrectionsButton'
import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'
type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
  onReload: () => unknown
}

/**
 * Thesis submission correction - Chair review corrections state.
 *
 * Chair view.
 */
const ChairReviewsChanges = ({ submission, onReload, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).chairMessage
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, 'REQUEST_CHANGES')

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      actions={[
        <ChairRequestsAdditionalCorrectionsButton
          key={0}
          iterationId={submission.currentIteration?.id}
          onReload={onReload}
        />,
        <ChairApprovesCorrectionsButton key={1} iterationId={submission.currentIteration?.id} onReload={onReload} />,
      ]}
    />
  )
}

export default ChairReviewsChanges
