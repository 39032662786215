import { Stack } from '@mui/material'

import PageTitle from 'src/components/Page/PageTitle'
import PageWrapper from 'src/components/Page/PageWrapper'

import DashboardPageCell from './components/DashboardPageCell'

const ProvostDashboardPage = () => {
  return (
    <PageWrapper>
      <Stack direction="column" spacing={2}>
        <PageTitle>Provost Dashboard</PageTitle>
        <DashboardPageCell />
      </Stack>
    </PageWrapper>
  )
}

export default ProvostDashboardPage
