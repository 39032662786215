import { thesisIterationStatusTypes } from '@common/thesis'
import { GetThesisSubmissionQuery } from 'types/graphql'

import FinalThesisIterationFormViewer from '../../ThesisIterationFormViewer/FinalThesisIterationFormViewer'

import InitialThesisIterationFilesViewer from './components/InitialThesisIterationFilesViewer'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

/**
 * Thesis submission form viewer
 */
const SubmissionFormViewer = ({ submission }: Props) => {
  const status = thesisIterationStatusTypes.find((s) => s.code === submission.currentIteration.currentStatus.status)

  return status.isFinal ? (
    <FinalThesisIterationFormViewer iteration={submission.currentIteration} />
  ) : (
    <InitialThesisIterationFilesViewer iteration={submission.currentIteration} />
  )
}

export default SubmissionFormViewer
