import { ThesisSubmissionIterationStatus } from 'types/graphql'

import { getThesisNotificationState } from 'src/lib/helpers/thesis'

import { getThesisSubmissionStepperInfo } from './helpers'
import Stepper from './Stepper/Stepper'

type Props = {
  iteration: {
    currentStatus: Pick<ThesisSubmissionIterationStatus, 'status' | 'outcome'>
  }
}

/**
 * Thesis submission final process stepper
 */
const ThesisSubmissionStepperFinal = ({ iteration }: Props) => {
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration }, 'FINAL')
  const notificationState = getThesisNotificationState(iteration?.currentStatus)

  return (
    <Stepper
      steps={[
        { tooltip: 'Candidate finalises thesis', priority: notificationState },
        { tooltip: 'Graduate School reviews final thesis', priority: notificationState },
        { tooltip: 'Principal Advisor reviews final thesis', priority: notificationState },
        { tooltip: 'Dean reviews final thesis', priority: notificationState },
        { tooltip: 'Provost confers final thesis', priority: notificationState },
        { tooltip: 'Graduate School completes candidature', priority: notificationState },
        { tooltip: 'Candidature completed', priority: notificationState },
      ]}
      {...stepperInfo}
    />
  )
}

export default ThesisSubmissionStepperFinal
