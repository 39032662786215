import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'

import { CTextField } from 'src/components/Form/Inputs'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: (outcomeMessage: string) => unknown
}

/**
 * Dialog for Dean requests corrections from the candidate.
 */
const DeanPassWithCorrectionsDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  const formMethods = useForm({ defaultValues: { message: '' } })

  const onSubmit = (data) => {
    onConfirm(data.message)
    formMethods.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
    >
      <DialogTitle color="primary" variant="h2" component="h2" id="dialogTitle">
        Pass with corrections
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogContent">
          <Typography variant="body1" sx={{ mb: 1 }}>
            If you require additional corrections to the thesis in addition to those requested by the Chair and
            examiners, please list them below. The candidate will review all feedback and your message before submitting
            their corrected thesis.
          </Typography>
          <CTextField name="message" label="Message" multiline={true} rows={10} maxLength={2000} />
        </DialogContent>
        <DialogActions>
          {!saving && (
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}

          <LoadingButton variant="contained" color="primary" loading={saving} type="submit">
            Request corrections
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default DeanPassWithCorrectionsDialog
