import { getIterationStateByCode, NotificationState, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import { useAuth } from 'src/auth'

import ExaminerFeedbackSummaryButton from '../../ExaminerActions/ExaminerFeedbackSummaryButton/ExaminerFeedbackSummaryButton'
import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
  onReload: () => void
}

/**
 * Initial submission - examiners reviewing thesis submission state.
 *
 * Examiner view.
 */
const ExaminersReviewing = ({ submission, iterationStateCode, onReload }: Props) => {
  const { currentUser } = useAuth()

  const examiner = submission.thesisSubmissionExaminers.find(
    (e) => e?.user?.id === currentUser.id && e?.status === 'APPROVED'
  )

  const cm = examiner?.examinationFeedbackCompleted
    ? {
        title: 'Thesis submission being reviewed',
        message: 'Thank you for submitting your feedback. The second examiner is still reviewing the thesis.',
        action: NotificationState.ACTION_REQUIRED,
      }
    : getIterationStateByCode(iterationStateCode).examinerMessage
  const stepperState = getInitialType(submission.currentIteration.hasOral) // Need to check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return (
    <ThesisSubmissionMessage
      {...cm}
      {...stepperInfo}
      actions={
        examiner?.examinationFeedbackCompleted
          ? null
          : [
              <ExaminerFeedbackSummaryButton
                key={0}
                examiner={examiner}
                submission={submission}
                onReload={onReload}
                hasAccess={true}
                isForThesisNotification={true}
              />,
            ]
      }
    />
  )
}

export default ExaminersReviewing
