import { useEffect } from 'react'

import { titles } from '@common/inputFormData'
import { YES_NO_INPUTS } from '@common/thesis/thesisExaminers/examinationFormData'
import { MenuItem, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFormContext } from '@redwoodjs/forms'

import { CCheckbox, CRadioGroup, CSelect, CTextField } from 'src/components/Form/Inputs'
import Link from 'src/components/Link/Link'

/**
 * Principal Advisor add examiner nomination form.
 */
const AdvisorAddExaminerForm = () => {
  const { watch, setValue } = useFormContext()

  const publishedWithCandidate = watch('publishedWithCandidate')
  const grantWithAdvisor = watch('grantWithAdvisor')
  const personalOrProfessionalRelationship = watch('personalOrProfessionalRelationship')
  const consultedOnThesis = watch('consultedOnThesis')
  const otherCOI = watch('otherCOI')
  const showAdvisorCOIDetailsInput =
    publishedWithCandidate || grantWithAdvisor || personalOrProfessionalRelationship || consultedOnThesis || otherCOI

  useEffect(() => {
    if (!showAdvisorCOIDetailsInput) {
      setValue('advisorCOIDetails', null)
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="body2">
          Nominating appropriate examiners is an important step in the examination process. Examiners must be research
          active and have sufficient expertise relating to the thesis or a substantial part of the thesis. When
          selecting examiners it is required for the Principal Advisor to make informal contact with the examiner to
          determine their interest and availability to perform the examination.{' '}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="h2" component="h2" color="primary">
          Examiner details
        </Typography>
      </Grid>
      <Grid xs={2}>
        <CSelect required label={'Title'} name="title">
          {titles.map((o) => (
            <MenuItem key={o.label} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </CSelect>
      </Grid>
      <Grid xs={4}>
        <CTextField name="fullName" label="Full name" required fullWidth />
      </Grid>
      <Grid xs={6}>
        <CTextField name="mail" label="Email address" type="email" required fullWidth autoComplete="off" />
      </Grid>
      <Grid xs={6}>
        <CTextField name="position" label="Position" required fullWidth />
      </Grid>
      <Grid xs={6}>
        <CTextField name="institution" label="Institution" required fullWidth />
      </Grid>
      <Grid xs={12}>
        <CTextField
          name="rationaleForNomination"
          label="Nomination rationale"
          required
          minLength={25}
          fullWidth
          multiline
          minRows={3}
        />
      </Grid>
      <Grid xs={12}>
        <Typography variant="h2" component="h2" color="primary">
          Conflict of interest disclosure
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="body2">
          Our <abbr>COI</abbr> (conflict of interest) guidelines ensure that all theses are examined independently and
          free from any perception of bias or preferential treatment. Some of the more common <abbr>COI</abbr>s are
          listed below. Selecting any of the boxes below may not necessarily preclude you from examining the thesis
          however should be declared so that an assessment can be made. Please note that the conflicts listed below are
          not exhaustive and further information on UQ's <abbr>COI</abbr> guidelines is{' '}
          <Link href="https://policies.uq.edu.au/document/view-current.php?id=452#section4"> available here</Link>. If
          you select any of these categories, please provide further details below.
        </Typography>
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          size="small"
          label="The examiner has published with the candidate or a member of the candidate's advisory team in the last 5 years."
          name="publishedWithCandidate"
          required
          aria-label="The examiner has published with the candidate or a member of the candidate's advisory team in the last 5 years."
          options={YES_NO_INPUTS}
        />
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          size="small"
          label="The examiner holds a current grant or has applied for a grant with the candidate or a member of the candidate's advisory team."
          name="grantWithAdvisor"
          required
          aria-label="The examiner holds a current grant or has applied for a grant with the candidate or a member of the candidate's advisory team."
          options={YES_NO_INPUTS}
        />
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          size="small"
          label="The examiner has or has had a close personal or professional relationship with the candidate or a member of the candidate's advisory team. "
          name="personalOrProfessionalRelationship"
          required
          aria-label="The examiner has or has had a close personal or professional relationship with the candidate or a member of the candidate's advisory team. "
          options={YES_NO_INPUTS}
        />
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          size="small"
          label="The examiner has consulted with the candidate or a member of the candidate`s advisory team regarding the thesis."
          name="consultedOnThesis"
          required
          aria-label="The examiner has consulted with the candidate or a member of the candidate`s advisory team regarding the thesis."
          options={YES_NO_INPUTS}
        />
      </Grid>
      <Grid xs={12}>
        <CRadioGroup
          size="small"
          label="Other conflict not listed above."
          name="otherCOI"
          required
          aria-label="Other conflict not listed above."
          options={YES_NO_INPUTS}
        />
      </Grid>
      {showAdvisorCOIDetailsInput && (
        <Grid xs={12}>
          <CTextField
            name="advisorCOIDetails"
            label="Please provide specific details about the conflict and your relationship with the examiner"
            placeholder="If you have published together, include the year and citation for the publication. If you have collaborated in other ways (e.g. thesis consultation), specify when it occurred and describe the nature of the collaboration. Providing clear details will help us assess the potential conflict appropriately."
            multiline
            minRows={3}
            required
            minLength={200}
            fullWidth
          />
        </Grid>
      )}

      <Grid xs={12}>
        <CCheckbox
          color={'secondary'}
          name="_checkboxGroup[0]"
          label="I have contacted the examiner and they are willing and able to examine thesis."
        />
      </Grid>
    </Grid>
  )
}

export default AdvisorAddExaminerForm
