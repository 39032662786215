import thesisTypes from '@common/thesis/thesisTypes'
import { ThesisFileType } from '@prisma/client'
import {
  ExaminationSummaryInput,
  ExaminationSummaryRnRInput,
  GetThesisSubmissionQuery,
  ThesisType,
} from 'types/graphql'
import * as yup from 'yup'

import { UploadedFile } from 'src/components/Form/Inputs'

type BaseExaminationFormType = {
  contributionKnowledge: string
  engagementWithLiterature: string
  advancedKnowledge: string
  researchCoherence: string
  convincingWriting: string
  thesesAssessed: string
  candidatesSupervised: string
  thesisRating: string
  examinerReviewFile: UploadedFile
  examinerAnnotatedThesisFile: UploadedFile | null
  type: ThesisType
}

// Initial submission
export type ExaminationFormInputType = BaseExaminationFormType & {}

// RnR
export type ReviseAndResubmitExaminationFormInputType = BaseExaminationFormType & {
  examinerRecommendation: string
}

// RnR create Default Values type
export type ReviseAndResubmitExaminationFormDefaultInputType = BaseExaminationFormType & {
  examinerRecommendation: string
}

// Initial submission create Default Values type
export type ExaminationFormDefaultInputType = BaseExaminationFormType & {}

export const createDefaultValues = (
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0],
  submission: GetThesisSubmissionQuery['getThesisSubmission']
): ExaminationFormDefaultInputType => {
  const iteration = submission.currentIteration

  return {
    contributionKnowledge: examiner?.contributionKnowledge || '',
    engagementWithLiterature: examiner?.engagementWithLiterature || '',
    advancedKnowledge: examiner?.advancedKnowledge || '',
    researchCoherence: examiner?.researchCoherence || '',
    convincingWriting: examiner?.convincingWriting || '',
    thesesAssessed: examiner?.thesesAssessed || '',
    candidatesSupervised: examiner?.candidatesSupervised || '',
    thesisRating: examiner?.thesisRating || '',
    examinerReviewFile: iteration
      ? iteration.files.find(
          (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_REVIEW_FILE && f.isActive
        )
      : null,
    examinerAnnotatedThesisFile: iteration
      ? iteration.files.find(
          (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE && f.isActive
        )
      : null,
    type: submission.isDowngradedToMPhil ? 'MPHIL_IN_LIEU_OF_PHD' : iteration ? iteration.type : 'PHD',
  }
}

export const createRnRDefaultValues = (
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0],
  submission: GetThesisSubmissionQuery['getThesisSubmission']
): ReviseAndResubmitExaminationFormDefaultInputType => {
  const iteration = submission.currentIteration

  return {
    contributionKnowledge: examiner?.contributionKnowledge || '',
    engagementWithLiterature: examiner?.engagementWithLiterature || '',
    advancedKnowledge: examiner?.advancedKnowledge || '',
    researchCoherence: examiner?.researchCoherence || '',
    convincingWriting: examiner?.convincingWriting || '',
    thesesAssessed: examiner?.thesesAssessed || '',
    candidatesSupervised: examiner?.candidatesSupervised || '',
    thesisRating: examiner?.thesisRating || '',
    examinerRecommendation: examiner?.examinerRecommendation || '',
    examinerReviewFile: iteration
      ? iteration.files.find(
          (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_REVIEW_FILE && f.isActive
        )
      : null,
    examinerAnnotatedThesisFile: iteration
      ? iteration.files.find(
          (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE && f.isActive
        )
      : null,
    type: submission.isDowngradedToMPhil ? 'MPHIL_IN_LIEU_OF_PHD' : iteration ? iteration.type : 'PHD',
  }
}

const baseSchema = {
  contributionKnowledge: yup.string().required('This field is required.'),
  engagementWithLiterature: yup.string().required('This field is required.'),
  advancedKnowledge: yup.string().required('This field is required.'),
  researchCoherence: yup.string().required('This field is required.'),
  convincingWriting: yup.string().required('This field is required.'),
  thesesAssessed: yup.string().required('This field is required.'),
  candidatesSupervised: yup.string().required('This field is required.'),
  thesisRating: yup.string().required('This field is required.'),
  examinerReviewFile: yup
    .object({
      key: yup.string().required('This file is required.'),
      filename: yup.string().required('This file is required.'),
    })
    .required('This file is required.'),
  examinerAnnotatedThesisFile: yup.object({ key: yup.string(), filename: yup.string() }).nullable(),
  type: yup
    .string()
    .required()
    .oneOf(thesisTypes.map((t) => t.code)),
}

// Initial submission
export const formValidationSchema = () => {
  return yup.object({ ...baseSchema })
}

// RnR
export const reviseAndResubmitFormValidationSchema = () => {
  return yup.object({ ...baseSchema, examinerRecommendation: yup.string().required('This field is required.') })
}

const baseExaminationFormInput = (
  formData: ExaminationFormInputType | ReviseAndResubmitExaminationFormDefaultInputType
) => {
  const examinerReviewFile = formData?.examinerReviewFile
    ? [
        {
          id: typeof formData?.examinerReviewFile.id === 'number' ? formData?.examinerReviewFile.id : undefined,
          key: formData?.examinerReviewFile.key,
          filename: formData?.examinerReviewFile.filename,
          fileType: ThesisFileType.EXAMINER_REVIEW_FILE,
        },
      ]
    : []

  const examinerAnnotatedThesisFile =
    formData?.examinerAnnotatedThesisFile && Object.keys(formData?.examinerAnnotatedThesisFile).length !== 0
      ? [
          {
            id:
              typeof formData?.examinerAnnotatedThesisFile.id === 'number'
                ? formData?.examinerAnnotatedThesisFile.id
                : undefined,
            key: formData?.examinerAnnotatedThesisFile.key,
            filename: formData?.examinerAnnotatedThesisFile.filename,
            fileType: ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE,
          },
        ]
      : []

  return {
    contributionKnowledge: formData.contributionKnowledge,
    engagementWithLiterature: formData.engagementWithLiterature,
    advancedKnowledge: formData.advancedKnowledge,
    researchCoherence: formData.researchCoherence,
    convincingWriting: formData.convincingWriting,

    thesesAssessed: formData.thesesAssessed,
    candidatesSupervised: formData.candidatesSupervised,
    thesisRating: formData.thesisRating,
    files: [...examinerReviewFile, ...examinerAnnotatedThesisFile],
  }
}

// Initial submission
export const createSubmissionInput = (formData: ExaminationFormInputType): ExaminationSummaryInput => {
  return {
    ...baseExaminationFormInput(formData),
  }
}

// RnR
export const createThesisExaminerSummaryInput = (
  formData: ReviseAndResubmitExaminationFormDefaultInputType
): ExaminationSummaryRnRInput => {
  return {
    ...baseExaminationFormInput(formData),
    examinerRecommendation: formData.examinerRecommendation,
  }
}
