import { getIterationState } from '@common/thesis/iterationState'

import { ThesisSubmissionDetails } from '../ThesisSubmissionType'

import CandidateSubmittingChanges from './ChangesRequested/CandidateSubmittingChanges'
import CandidateSubmittingChangesAdditionalChair from './ChangesRequested/CandidateSubmittingChangesAdditionalChair'
import CandidateSubmittingChangesAdditionalPa from './ChangesRequested/CandidateSubmittingChangesAdditionalPa'
import ChairReviewsChanges from './ChangesRequested/ChairReviewsChanges'
import ChairSubmittingChangesAdditionalDean from './ChangesRequested/ChairSubmittingChangesAdditionalDean'
import DeanReviewsChanges from './ChangesRequested/DeanReviewsChanges'
import PrincipalAdvisorReviewsChanges from './ChangesRequested/PrincipalAdvisorReviewsChanges'
import AwaitingDeanConferral from './FinalSubmission/AwaitingDeanConferral'
import AwaitingProvostConferral from './FinalSubmission/AwaitingProvostConferral'
import CandidateSubmittingFinal from './FinalSubmission/CandidateSubmittingFinal'
import CandidateSubmittingFinalGsChanges from './FinalSubmission/CandidateSubmittingFinalGsChanges'
import CandidateSubmittingFinalPaChanges from './FinalSubmission/CandidateSubmittingFinalPaChanges'
import GraduateSchoolCompleteCandidature from './FinalSubmission/GraduateSchoolCompleteCandidature'
import GraduateSchoolReviewFinal from './FinalSubmission/GraduateSchoolReviewFinal'
import PrincipalAdvisorReviewFinal from './FinalSubmission/PrincipalAdvisorReviewFinal'
import ThesisComplete from './FinalSubmission/ThesisComplete'
import AwaitingChairSummaryOutcome from './InitialSubmission/AwaitingChairSummaryOutcome'
import AwaitingDeanExaminationOutcome from './InitialSubmission/AwaitingDeanExaminationOutcome'
import CandidateAwaitingOralExamination from './InitialSubmission/CandidateAwaitingOralExamination'
import CandidateSubmitting from './InitialSubmission/CandidateSubmitting'
import CandidateSubmittingGsChanges from './InitialSubmission/CandidateSubmittingGsChanges'
import CandidateSubmittingPaChanges from './InitialSubmission/CandidateSubmittingPaChanges'
import CandidateSubmittingReviseAndResubmit from './InitialSubmission/CandidateSubmittingReviseAndResubmit'
import CandidateSubmittingWithdrawn from './InitialSubmission/CandidateSubmittingWithdrawn'
import ExaminersReviewing from './InitialSubmission/ExaminersReviewing'
import GraduateSchoolFinalisingExaminers from './InitialSubmission/GraduateSchoolFinalisingExaminers'
import GraduateSchoolReview from './InitialSubmission/GraduateSchoolReview'
import GraduateSchoolSchedulingOralExamination from './InitialSubmission/GraduateSchoolSchedulingOralExamination'
import MarkedAsReviseAndResubmit from './InitialSubmission/MarkedAsReviseAndResubmit'
import PrincipalAdvisorReview from './InitialSubmission/PrincipalAdvisorReview'
import ThesisSubmissionFailed from './InitialSubmission/ThesisSubmissionFailed'

type Props = {
  submission: ThesisSubmissionDetails
  onReload: () => void
}

/**
 * This controls the message at the top of the Thesis Form for examiners. Done through an if clause as each component might have
 * custom actions that need to be taken
 */
const ThesisSubmissionMessageExaminer = ({ submission, onReload }: Props) => {
  const iterationStateCode = getIterationState(submission.currentIteration?.currentStatus)?.code

  switch (iterationStateCode) {
    // Initial submission / Revise and resubmit with oral / Revise and resubmit without oral (no oral components)
    case 'CANDIDATE_SUBMITTING':
      return <CandidateSubmitting submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_PA_CHANGES':
      return <CandidateSubmittingPaChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_GS_CHANGES':
      return <CandidateSubmittingGsChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_WITHDRAWN':
      return <CandidateSubmittingWithdrawn submission={submission} iterationStateCode={iterationStateCode} />
    case 'PRINCIPAL_ADVISOR_REVIEW':
      return <PrincipalAdvisorReview submission={submission} iterationStateCode={iterationStateCode} />
    case 'GRADUATE_SCHOOL_REVIEW':
      return <GraduateSchoolReview submission={submission} iterationStateCode={iterationStateCode} />
    case 'AWAITING_EXAMINERS_APPROVAL':
      return <GraduateSchoolFinalisingExaminers submission={submission} iterationStateCode={iterationStateCode} />
    case 'EXAMINERS_REVIEWING':
      return <ExaminersReviewing submission={submission} iterationStateCode={iterationStateCode} onReload={onReload} />
    case 'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION':
      return <GraduateSchoolSchedulingOralExamination submission={submission} iterationStateCode={iterationStateCode} />
    case 'AWAITING_ORAL_EXAMINATION':
      return <CandidateAwaitingOralExamination submission={submission} iterationStateCode={iterationStateCode} />
    case 'AWAITING_CHAIR_EXAMINATION_OUTCOME':
      return <AwaitingChairSummaryOutcome submission={submission} iterationStateCode={iterationStateCode} />
    case 'AWAITING_DEAN_OUTCOME':
      return <AwaitingDeanExaminationOutcome submission={submission} iterationStateCode={iterationStateCode} />

    // Final submission
    case 'DOWNGRADED_TO_MPHIL':
    case 'CANDIDATE_SUBMITTING_FINAL_THESIS':
      return <CandidateSubmittingFinal submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_FINAL_PA_CHANGES':
      return <CandidateSubmittingFinalPaChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_FINAL_GS_CHANGES':
      return <CandidateSubmittingFinalGsChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'GRADUATE_SCHOOL_FINAL_REVIEW':
      return <GraduateSchoolReviewFinal submission={submission} iterationStateCode={iterationStateCode} />
    case 'PRINCIPAL_ADVISOR_FINAL_REVIEW':
      return <PrincipalAdvisorReviewFinal submission={submission} iterationStateCode={iterationStateCode} />
    case 'DEAN_FINAL_REVIEW':
      return <AwaitingDeanConferral submission={submission} iterationStateCode={iterationStateCode} />
    case 'AWAITING_PROVOST_CONFERRAL':
      return <AwaitingProvostConferral submission={submission} iterationStateCode={iterationStateCode} />
    case 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE':
      return <GraduateSchoolCompleteCandidature submission={submission} iterationStateCode={iterationStateCode} />
    case 'THESIS_COMPLETE':
      return <ThesisComplete submission={submission} iterationStateCode={iterationStateCode} />

    // Request changes
    case 'CANDIDATE_SUBMITTING_CHANGES':
      return <CandidateSubmittingChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'PRINCIPAL_ADVISOR_REVIEWING_CHANGES':
      return <PrincipalAdvisorReviewsChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_CHANGES_PA_ADDITIONAL':
      return <CandidateSubmittingChangesAdditionalPa submission={submission} iterationStateCode={iterationStateCode} />
    case 'CHAIR_REVIEWING_CHANGES':
      return <ChairReviewsChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'CANDIDATE_SUBMITTING_CHANGES_CHAIR_ADDITIONAL':
      return (
        <CandidateSubmittingChangesAdditionalChair submission={submission} iterationStateCode={iterationStateCode} />
      )
    case 'DEAN_REVIEWING_CHANGES':
      return <DeanReviewsChanges submission={submission} iterationStateCode={iterationStateCode} />
    case 'CHAIR_SUBMITTING_CHANGES_DEAN_ADDITIONAL':
      return <ChairSubmittingChangesAdditionalDean submission={submission} iterationStateCode={iterationStateCode} />

    // Other
    case 'CANDIDATE_REVISING_AND_RESUBMITTING':
      return <CandidateSubmittingReviseAndResubmit submission={submission} iterationStateCode={iterationStateCode} />
    case 'DEAN_MARKED_AS_REVISE_AND_RESUBMIT':
      return <MarkedAsReviseAndResubmit submission={submission} iterationStateCode={iterationStateCode} />
    case 'THESIS_SUBMISSION_FAILED':
      return <ThesisSubmissionFailed submission={submission} iterationStateCode={iterationStateCode} />
    default:
      return <></>
  }
}

export default ThesisSubmissionMessageExaminer
