import { useWatch } from '@redwoodjs/forms'

import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import { CAutocomplete } from 'src/components/Form/Inputs'
import HelpWrapper from 'src/components/HelpWrapper/HelpWrapper'
import { isStudent } from 'src/lib/helpers/collaborators'

type ValidSupervisor = {
  id: number
  displayName: string
}

type Props = {
  readOnly: boolean
}

const AdditionalSupervisorsInput = ({ readOnly }: Props) => {
  const record = useWatch({ name: 'record' })

  const validSupervisors: ValidSupervisor[] = record.collaborators
    .filter((c) => !!c.user && !c.isLead && !c.isOwner && !isStudent(c.user.eduPersonPrincipalName))
    .map((c) => ({ id: c.user.id, displayName: c.user.displayName }))

  return (
    <HelpWrapper
      sx={{ pl: 0, pr: 0 }}
      disabled={readOnly}
      helpProps={{
        buttonSx: { mt: 2 },
        title: 'Additional supervisors',
        text: (
          <>
            Additional supervisors must be UQ staff and can only be selected from existing collaborators of the selected
            UQRDM record. If you cannot find a supervisor in this list, ensure they are added as a collaborator to the
            UQRDM record via their UQ staff email. To add a collaborator to the selected record,{` `}
            <ExternalLink href={record ? `/record/${record.uuid}` : `/`}>click here</ExternalLink>. Please note that
            additional supervisors cannot access your thesis submission.
          </>
        ),
      }}
    >
      <CAutocomplete<ValidSupervisor>
        name="additionalSupervisors"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.displayName)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={validSupervisors}
        multiple={true}
        freeSolo={false}
        filterSelectedOptions={true}
        readOnly={readOnly}
        textFieldProps={{
          label: 'Additional supervisors',
          required: false,
        }}
      />
    </HelpWrapper>
  )
}

export default AdditionalSupervisorsInput
