import { HourglassEmpty } from '@mui/icons-material'
import { Box } from '@mui/material'

const StyledHourglassIcon = () => (
  <Box
    sx={{
      backgroundColor: (theme) => theme.palette.grey[500], // Warning color
      color: 'white', // White icon
      borderRadius: '50%', // Optional: to make it circular
      padding: 1.1, // Optional: adjust padding to create space
      margin: 0.15,
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '1.5em',
      height: '1.5em',
    }}
  >
    <HourglassEmpty fontSize="small" /> {/* Adjust icon size as needed */}
  </Box>
)

export default StyledHourglassIcon
