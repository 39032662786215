import { useCallback, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Alert, Box } from '@mui/material'
import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'

import { useController, useFieldArray, useWatch } from '@redwoodjs/forms'

import { useAuth } from 'src/auth'
import GET_PRESIGNED_URL_THESIS_UPLOAD from 'src/lib/graphql/queries/GetPresignedUrlThesisUpload'
import { ThesisSubmissionFormData } from 'src/pages/ThesisSubmissionDetailsPage/components/ThesisSubmissionForm/helpers'

import { getThesisFileTableValues } from '../helpers'

import ThesisFilesTable from './ThesisFilesTable'
import ThesisFilesUploadButton from './ThesisFilesUploadButton'
import ThesisFilesUploadModal from './ThesisFilesUploadModal'

const uppy = new Uppy({
  id: 'uppy-upload-thesis-supplementary-files',
  restrictions: {
    maxFileSize: 5000000000,
    maxNumberOfFiles: 10,
  },
  autoProceed: true,
  allowMultipleUploadBatches: false,
}).use(AwsS3)

type Props = {
  readOnly: boolean
  allowUpload?: boolean
}

const ThesisSupplementaryFiles = ({ readOnly, allowUpload = true }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { currentUser } = useAuth()
  const { fieldState } = useController({ name: 'supplementaryFiles' })
  const { fields, append, remove } = useFieldArray<ThesisSubmissionFormData, 'supplementaryFiles'>({
    name: 'supplementaryFiles',
    keyName: 'uuid' as 'id',
  })

  const apolloClient = useApolloClient()
  const thesisType = useWatch({ name: 'type' })

  const onUploadFile = useCallback(
    (file) => {
      if (fields.find((f) => f.key === file.meta.key) === undefined) {
        append({
          id: file.meta.key,
          key: file.meta.key as string,
          filename: file.name,
        })
      }
    },
    [fields, append]
  )

  uppy.setOptions({ restrictions: { maxNumberOfFiles: 10 - fields.length } })

  useEffect(() => {
    uppy.on('upload-success', onUploadFile)

    uppy.getPlugin('AwsS3').setOptions({
      getUploadParameters(file) {
        // Fetch presigned URL from the API for this file
        return apolloClient
          .query({ query: GET_PRESIGNED_URL_THESIS_UPLOAD, variables: { filename: file.name, contentType: file.type } })
          .then((response) => {
            return {
              method: 'put',
              url: response.data.getPresignedUrlThesisUpload.presignedUrl,
              fields: { key: response.data.getPresignedUrlThesisUpload.key },
              headers: { 'Content-Type': file.type },
            }
          })
      },
    })

    return () => {
      uppy.off('upload-success', onUploadFile)
    }
  }, [apolloClient, onUploadFile])

  const onRemoveUploadedFile = (key: string) => {
    remove(fields.findIndex((file) => file.key === key))
  }

  const fieldValue = getThesisFileTableValues(
    fields,
    'SUPPLEMENTARY_FILE',
    currentUser.eduPersonPrincipalName,
    thesisType
  )

  return (
    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)', br: 5 }}>
      <Box sx={{ p: 1 }}>
        <ThesisFilesTable
          uploadedFiles={fieldValue}
          onRemove={onRemoveUploadedFile}
          fileTitle="Supplementary files"
          noFilesMessage="No supplementary thesis documents uploaded"
          readOnly={readOnly}
        />
        {fieldState.error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {fieldState.error.message}
          </Alert>
        )}
        {allowUpload && fields.length < 10 && !readOnly && (
          <ThesisFilesUploadButton label="Upload supplementary files" onClick={() => setDialogOpen(true)} />
        )}
        <ThesisFilesUploadModal
          uppy={uppy}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false)
            uppy.cancelAll()
          }}
        />
      </Box>
    </Box>
  )
}
export default ThesisSupplementaryFiles
