import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {
  AcceptThesisSubmissionExaminerInvitation,
  AcceptThesisSubmissionExaminerInvitationVariables,
  ThesisSubmissionExaminerInvitationResult,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import ACCEPT_THESIS_SUBMISSION_EXAMINER_INVITATION from 'src/lib/graphql/mutations/AcceptThesisSubmissionExaminerInvitation'

import TokenError from './TokenError'

type Props = {
  joinToken: string
  thesisSubmissionExaminerInvitation: ThesisSubmissionExaminerInvitationResult
}

const TokenAcceptance = ({ joinToken, thesisSubmissionExaminerInvitation }: Props) => {
  const { currentUser } = useAuth()
  const [saving, setSaving] = useState<boolean | undefined>(false)

  const [acceptInvitation] = useMutation<
    AcceptThesisSubmissionExaminerInvitation,
    AcceptThesisSubmissionExaminerInvitationVariables
  >(ACCEPT_THESIS_SUBMISSION_EXAMINER_INVITATION)
  const [acceptResult, setAcceptResult] = useState(undefined)

  const onAccept = async () => {
    setSaving(true)

    const result = await acceptInvitation({ variables: { joinToken } })

    setAcceptResult(result.data.acceptThesisSubmissionExaminerInvitation)
    setSaving(false)
  }

  if (acceptResult === true) {
    window.location.href = `${window.location.origin}${routes.thesisExaminerDetails({
      id: thesisSubmissionExaminerInvitation.thesisSubmissionId,
      showDialog: 'true',
    })}`

    return null
  }

  if (acceptResult === false) {
    return <TokenError statusCode="INVITATION_INVALID" />
  }

  return (
    <div>
      <Typography variant="body1">
        Principal Advisor <strong>{thesisSubmissionExaminerInvitation.invitedBy.displayName}</strong> has invited you to
        be an examiner for the thesis of <strong>{thesisSubmissionExaminerInvitation.candidateName}</strong>. The thesis
        review and feedback process will take place in the UQ Research Data Manager (UQRDM) system.
        <br />
        <br />
        You are currently logged in as <strong>{currentUser.displayName}</strong> with{' '}
        <strong>{currentUser.mail}</strong>. If you wish to accept this nomination using a different account, please{' '}
        <ExternalLink target="_self" href={routes.logout()}>
          log out
        </ExternalLink>{' '}
        and follow the link in the email to sign in with the correct account.{' '}
      </Typography>
      {currentUser.eduPersonPrincipalName.indexOf('uq.edu.au') === -1 && (
        <Typography variant="body1" style={{ fontWeight: 'bold' }} sx={{ mt: 1, mb: 1 }}>
          By joining this system, you agree to comply with the{' '}
          <ExternalLink href="https://ppl.app.uq.edu.au/content/6.20.01-information-and-communication-technology-policy">
            UQ Information and Communication Technology Policy
          </ExternalLink>
          .{' '}
        </Typography>
      )}
      <Typography sx={{ mb: 3 }}>
        If you are no longer able, or do not wish to examine this thesis, please{' '}
        <ExternalLink href={`mailto:${thesisSubmissionExaminerInvitation.invitedBy.mail}`}>contact</ExternalLink> the
        Principal Advisor.
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={12} md={6}>
          <TextField
            label="Principal Advisor"
            value={thesisSubmissionExaminerInvitation.invitedBy.displayName}
            fullWidth
            variant="filled"
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            label="Thesis Candidate"
            value={thesisSubmissionExaminerInvitation.candidateName}
            fullWidth
            variant="filled"
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid xs>
          <LoadingButton variant="contained" color="secondary" onClick={onAccept} loading={saving} fullWidth>
            Accept Nomination to Examine Thesis
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default TokenAcceptance
