import { useState } from 'react'

import { DeanFinalApproval, DeanFinalApprovalVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import DEAN_FINAL_APPROVAL from 'src/lib/graphql/mutations/DeanApprovesConferral'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

type Props = {
  iterationId: number
  disabled?: boolean
  onReload: () => unknown
  fullWidth?: boolean
  onClose: () => unknown
}

const ApproveFinalSubmissionButton = ({ iterationId, onReload, disabled, fullWidth, onClose }: Props) => {
  const [saving, setSaving] = useState(false)
  const [finalApproval] = useMutation<DeanFinalApproval, DeanFinalApprovalVariables>(DEAN_FINAL_APPROVAL)
  const showSnackbar = useSnackbar()

  const onConfirm = async () => {
    setSaving(true)
    try {
      const result = await finalApproval({ variables: { iterationId } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully approved this final submission", 'success')
      onReload()
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not approve this final submission. Please try again later.', 'error')
      setSaving(false)
    }
  }

  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        onClick={() => onConfirm()}
        disabled={disabled || saving}
      >
        Request conferral
      </Button>
    </>
  )
}

export default ApproveFinalSubmissionButton
