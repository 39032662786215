import { AdminPanelSettings, Article } from '@mui/icons-material'

import { routes } from '@redwoodjs/router'

import MenuItem from 'src/types/MenuItem'

const menuItems = (hasRole: (rolesToCheck: string | string[]) => boolean) => {
  const items: MenuItem[] = []

  items.push({
    primaryText: 'Dashboard',
    secondaryText: '',
    linkTo: routes.provostDashboard(),
  })

  items.push({
    primaryText: 'Logout',
    secondaryText: 'Sign out of UQRDM',
    linkTo: routes.logout(),
  })

  if (hasRole('grad-school-dashboard')) {
    items.push({
      primaryText: 'Graduate School Dashboard',
      secondaryText: 'View and manage thesis submissions',
      linkTo: routes.graduateSchoolDashboard(),
      icon: <Article fontSize="small" color="disabled" />,
    })
  }

  if (hasRole('admin-dashboard')) {
    items.push({
      primaryText: 'Admin Dashboard',
      secondaryText: '',
      linkTo: routes.adminDashboard(),
      icon: <AdminPanelSettings fontSize="small" color="disabled" />,
    })
  }

  items.push({
    primaryText: 'Back to UQRDM',
    linkTo: routes.dashboard(),
  })

  return items
}

export default menuItems
