import { useState } from 'react'

import { Autocomplete, FormHelperText, TextField } from '@mui/material'
import { CipUserLookupQuery, UqPerson } from 'types/graphql'

import { Controller, useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import { CIP_USER_LOOKUP_QUERY } from 'src/lib/graphql/queries/CipUserLookupQuery'
import useDebounce from 'src/lib/hooks/useDebounce'

type Option = {
  displayName: string
  mail: string
  eduPersonPrincipalName: string
}

type Props = {
  disabled: boolean
  label: string
  name: string
  setOpenAutoComplete: (value: boolean) => void
  openAutoComplete: boolean
  helperText?: string
  excludeUserEppn?: string

}

const UqUserLookupInput = ({ disabled, setOpenAutoComplete, openAutoComplete, label, name, helperText, excludeUserEppn }: Props) => {
  const { control } = useFormContext()
  const [autocompleteInput, setAutocompleteInput] = useState<string>(``)

  const debouncedInputValue = useDebounce(autocompleteInput, 200)

  const {
    data,
    loading,
    error: queryError,
  } = useQuery<CipUserLookupQuery>(CIP_USER_LOOKUP_QUERY, {
    skip: debouncedInputValue.length < 3,
    variables: { searchString: debouncedInputValue },
  })

  const options: Option[] = data ? [...data.uqUserLookup.filter(user => user.eduPersonPrincipalName !== excludeUserEppn)] : []

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field }, fieldState: { error }, formState: { isSubmitting } }) => (
        <>
          <Autocomplete<UqPerson>
            {...field}
            onOpen={() => setOpenAutoComplete(true)}
            onClose={() => setOpenAutoComplete(false)}
            onChange={(event, options) => onChange(options?.eduPersonPrincipalName || '')}
            clearOnEscape
            clearOnBlur
            open={openAutoComplete}
            options={options}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : `${option.displayName} - ${option.eduPersonPrincipalName}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ marginTop: '5px' }}
                label={label}
                required
                variant="outlined"
                fullWidth
                // error={isError}
              />
            )}
            isOptionEqualToValue={(o: UqPerson, v: UqPerson) => o.eduPersonPrincipalName === v.eduPersonPrincipalName}
            onInputChange={(_event, newInputValue: string) => {
              if (newInputValue) {
                setAutocompleteInput(newInputValue)
              } else {
                setAutocompleteInput('')
              }
            }}
            filterOptions={(x: UqPerson[]) => x}
            loading={loading}
            disabled={disabled || !!queryError || isSubmitting}
          />
          <FormHelperText>{helperText}</FormHelperText>
          {!!error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
        </>
      )}
    />
  )
}

export default UqUserLookupInput
