import { Fragment, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { HumanEthics, ValidRecordHumanEthicsQuery, ValidRecordHumanEthicsQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Alert from 'src/components/Alert/Alert'
import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'

const QUERY = gql`
  query ValidRecordHumanEthicsQuery($recordId: Int!) {
    validRecordHumanEthics(recordId: $recordId) {
      isApproved
      externalId
      statusTitle
      title
    }
  }
`

type Props = {
  loading?: boolean
  open: boolean
  selectedHumanEthics: HumanEthics[]
  recordId: number
  onClose: () => void
  onAdd: (humanEthics: HumanEthics[]) => void
}

export const AddHumanEthicsRecordDialog = (props: Props) => {
  const [selected, setSelected] = useState<HumanEthics[]>(props.selectedHumanEthics)
  const { data, error, loading } = useQuery<ValidRecordHumanEthicsQuery, ValidRecordHumanEthicsQueryVariables>(QUERY, {
    skip: !props.open,
    variables: { recordId: props.recordId },
  })

  const onSubmit = () => {
    props.onAdd(selected)
  }

  const isSelected = (humanEthic: HumanEthics) => {
    return !!selected.find((e) => e.externalId === humanEthic.externalId)
  }

  const toggleHumanEthic = (humanEthic: HumanEthics) => {
    if (isSelected(humanEthic)) {
      setSelected(selected.filter((e) => e.externalId !== humanEthic.externalId))
    } else {
      setSelected([...selected, humanEthic])
    }
  }

  // Only show those applications that are not already on the record
  const newHumanEthics = data
    ? data.validRecordHumanEthics.filter(
        (e) => props.selectedHumanEthics.find((se) => se.externalId === e.externalId) === undefined
      )
    : []

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      <DialogTitle color="primary" component="h2" variant="h2" id="dialogtitle">
        Add human ethics application
      </DialogTitle>
      {props.open && (
        <>
          {loading && (
            <DialogContent>
              <InlineLoader message="Loading your human ethics applications from central UQ systems..." />
            </DialogContent>
          )}
          {error && (
            <>
              <DialogContent id="dialogcontent">
                <Alert severity="error">
                  UQRDM could not load your ethics applications from central UQ systems. Please try again.
                </Alert>
              </DialogContent>
              <DialogActions>
                <Button color="inherit" onClick={props.onClose} variant="outlined">
                  Close
                </Button>
              </DialogActions>
            </>
          )}
          {!loading && !!data && (
            <>
              <DialogContent>
                <List dense>
                  {newHumanEthics.map((e) => (
                    <Fragment key={e.externalId}>
                      <ListItemButton onClick={() => toggleHumanEthic(e)} divider>
                        <ListItemIcon>
                          <Checkbox edge="start" tabIndex={-1} checked={isSelected(e)} disabled={props.loading} />
                        </ListItemIcon>
                        <ListItemText primary={e.externalId} secondary={e.title} />
                      </ListItemButton>
                    </Fragment>
                  ))}

                  {newHumanEthics.length === 0 ? (
                    <ListItem>
                      <ListItemText
                        primary={
                          <span>
                            UQRDM could not find any current ethics applications associated with your credentials in
                            central UQ systems. Please contact{' '}
                            <ExternalLink href="mailto:my-research-help@uq.edu.au">MyResearch</ExternalLink> if you
                            believe this to be an error.
                          </span>
                        }
                      />
                    </ListItem>
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={
                          <span>
                            UQRDM found these ethics applications associated with your credentials in central UQ
                            systems. Please contact{' '}
                            <ExternalLink href="mailto:my-research-help@uq.edu.au">MyResearch</ExternalLink> if the
                            intended application was not found.
                          </span>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </DialogContent>
              <DialogActions>
                {newHumanEthics.length === 0 ? (
                  <Button color="inherit" onClick={props.onClose} variant="outlined">
                    Close
                  </Button>
                ) : (
                  <>
                    <Button color="inherit" onClick={props.onClose} variant="outlined" disabled={props.loading}>
                      Cancel
                    </Button>
                    <LoadingButton color="primary" variant="contained" onClick={onSubmit} loading={props.loading}>
                      Add
                    </LoadingButton>
                  </>
                )}
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  )
}

export default AddHumanEthicsRecordDialog
