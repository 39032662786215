const MY_RECORDS_QUERY = gql`
  query MyRecordsQuery {
    userRecordsSummary {
      id
      uuid
      name
      status
      collaborators {
        id
        isLead
        isOwner
        joinedRecordAt
        user {
          id
          displayName
          eduPersonPrincipalName
        }
      }
      storageLabels
    }
  }
`

export default MY_RECORDS_QUERY
