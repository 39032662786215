import { useState } from 'react'

import { getIterationState } from '@common/thesis/iterationState'
import {
  CandidateSubmitsThesis,
  CandidateSubmitsThesisCorrections,
  CandidateSubmitsThesisCorrectionsVariables,
  CandidateSubmitsThesisVariables,
  MyRecordsQuery,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { ThesisSubmissionDetails } from 'src/components/Thesis'
import CANDIDATE_SUBMITS_THESIS from 'src/lib/graphql/mutations/CandidateSubmitsThesis'
import CANDIDATE_SUBMITS_THESIS_CORRECTIONS from 'src/lib/graphql/mutations/CandidateSubmitsThesisCorrections'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ThesisIterationForm from './components/ThesisIterationForm'
import { ThesisSubmissionFormData, createThesisSubmissionInput } from './helpers'

type Props = {
  onClose: () => void
  onReload: () => unknown
  submission: ThesisSubmissionDetails
  userRecordsSummary: MyRecordsQuery['userRecordsSummary']
}

const ThesisSubmissionForm = ({ onReload, onClose, submission, userRecordsSummary }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)

  const [candidateSubmitsThesis] = useMutation<CandidateSubmitsThesis, CandidateSubmitsThesisVariables>(
    CANDIDATE_SUBMITS_THESIS
  )
  const [candidateSubmitsThesisCorrections] = useMutation<
    CandidateSubmitsThesisCorrections,
    CandidateSubmitsThesisCorrectionsVariables
  >(CANDIDATE_SUBMITS_THESIS_CORRECTIONS)

  const onSubmit = async (data: ThesisSubmissionFormData) => {
    const currentIterationState = getIterationState(submission?.currentIteration?.currentStatus)

    setSaving(true)

    try {
      const iterationState = getIterationState(submission?.currentIteration?.currentStatus)

      if (iterationState.thesisState === 'INITIAL') {
        await candidateSubmitsThesis({
          variables: { input: createThesisSubmissionInput(data), submissionId: submission.id },
        })
      } else if (iterationState.thesisState === 'REQUEST_CHANGES') {
        await candidateSubmitsThesisCorrections({
          variables: { input: createThesisSubmissionInput(data), submissionId: submission.id },
        })
      } else {
        throw new Error('Invalid thesis state.')
      }

      const successMessageInitialSubmit =
        'Congratulations! Your thesis has been submitted and the Graduate School will review.'
      const successMessageCorrectionsSubmit =
        'Congratulations! Your corrections have been submitted and the Principal Advisor will review.'

      const successMessage =
        currentIterationState.thesisState === 'INITIAL' ? successMessageInitialSubmit : successMessageCorrectionsSubmit

      showSnackbar(successMessage, 'success')
      onReload()
    } catch (error) {
      Sentry.captureException(error)

      const errorMessage = `Could not submit your ${currentIterationState.thesisState === 'INITIAL' ? 'thesis' : 'corrections'}. Please try again later.`

      showSnackbar(errorMessage, 'error')
      setSaving(false)
    }
  }

  return (
    <ThesisIterationForm
      userRecordsSummary={userRecordsSummary}
      onSubmit={onSubmit}
      submission={submission}
      saving={saving}
      disabled={false}
      onClose={onClose}
    />
  )
}

export default ThesisSubmissionForm
