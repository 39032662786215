import { Fragment, useState } from 'react'

import {
  Alert,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { HumanEthics, UserHumanEthicsQuery } from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'

export const QUERY = gql`
  query UserHumanEthicsQuery {
    humanEthics {
      isApproved
      externalId
      statusTitle
      title
    }
  }
`

export const Loading = () => (
  <DialogContent id="dialogcontent">
    <InlineLoader message="Loading your human ethics applications from central UQ systems..." />
  </DialogContent>
)

type FailureProps = {
  onClose: () => unknown
}
export const Failure = ({ onClose }: FailureProps) => (
  <>
    <DialogContent id="dialogcontent">
      <Alert severity="error">
        UQRDM could not load your ethics applications from central UQ systems. Please try again.
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button color="inherit" onClick={onClose} variant="outlined">
        Close
      </Button>
    </DialogActions>
  </>
)

type SelectHumanEthicsDialogCellProps = CellSuccessProps<UserHumanEthicsQuery> & {
  open: boolean
  selectedHumanEthics: HumanEthics[]
  onClose: () => void
  onAdd: (grants: HumanEthics[]) => void
}

export const Success = (props: SelectHumanEthicsDialogCellProps) => {
  const [selected, setSelected] = useState<HumanEthics[]>(props.selectedHumanEthics)

  const onSubmit = () => {
    props.onAdd(selected)
  }

  const isSelected = (humanEthic: HumanEthics) => {
    return !!selected.find((e) => e.externalId === humanEthic.externalId)
  }

  const toggleHumanEthic = (humanEthic: HumanEthics) => {
    if (isSelected(humanEthic)) {
      setSelected(selected.filter((e) => e.externalId !== humanEthic.externalId))
    } else {
      setSelected([...selected, humanEthic])
    }
  }

  // Only show those applications that are not already on the record
  const newHumanEthics = props.humanEthics.filter((e) => {
    return props.selectedHumanEthics.find((se) => se.externalId === e.externalId) === undefined
  })

  return (
    <>
      <DialogContent id="dialogcontent">
        <List dense>
          {newHumanEthics.map((e) => (
            <Fragment key={e.externalId}>
              <ListItem button onClick={() => toggleHumanEthic(e)}>
                <ListItemIcon>
                  <Checkbox edge="start" tabIndex={-1} checked={isSelected(e)} />
                </ListItemIcon>
                <ListItemText primary={e.externalId} secondary={e.title} />
              </ListItem>
              <Divider />
            </Fragment>
          ))}

          {newHumanEthics.length === 0 ? (
            <ListItem>
              <ListItemText
                primary={
                  <span>
                    UQRDM could not find any current ethics applications associated with your credentials in central UQ
                    systems. Please contact{' '}
                    <ExternalLink href="mailto:my-research-help@uq.edu.au">MyResearch</ExternalLink> if you believe this
                    to be an error.
                  </span>
                }
              />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemText
                primary={
                  <span>
                    UQRDM found these ethics applications associated with your credentials in central UQ systems. Please
                    contact <ExternalLink href="mailto:my-research-help@uq.edu.au">MyResearch</ExternalLink> if the
                    intended application was not found.
                  </span>
                }
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {newHumanEthics.length === 0 ? (
          <Button color="inherit" onClick={props.onClose} variant="outlined">
            Close
          </Button>
        ) : (
          <>
            <Button color="inherit" onClick={props.onClose} variant="outlined">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              Add
            </Button>
          </>
        )}
      </DialogActions>
    </>
  )
}
