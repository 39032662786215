import { Divider } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'
import { isAssignedThesisCandidate } from 'src/lib/userAccessChecker'

import ChairSection from './Chair/ChairSection'
import ExaminersSection from './Examiners/ExaminersSection'
import PrincipalAdvisorSection from './PrincipalAdvisor/PrincipalAdvisorSection'

const styles = {
  headerBackground: {
    color: '#FFFFFF !important',
    backgroundColor: 'primary.main',
    background: 'linear-gradient(90deg, rgba(81,36,122,1) 5%, rgba(150,42,139,1) 100%)',
  },
  contentBackground: {
    backgroundColor: 'primary.main',
    background: 'linear-gradient(90deg, rgba(81,36,122,1) 5%, rgba(150,42,139,1) 100%)',
  },
}

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
  query?: URLSearchParams
}

/**
 * Submission Committee information card
 */
const CommitteeCard = ({ submission, query, onReload }: Props) => {
  const { currentUser } = useAuth()

  const isCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)

  return (
    <ContentCard
      title={`${isCandidate ? 'Your' : ''} Committee`}
      headerSx={styles.headerBackground}
      sx={styles.contentBackground}
    >
      <PrincipalAdvisorSection submission={submission} onReload={onReload} />
      <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.25)', my: 2 }} />
      <ChairSection submission={submission} />
      <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.25)', my: 2 }} />
      <ExaminersSection submission={submission} query={query} />
    </ContentCard>
  )
}

export default CommitteeCard
