import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'

import { CTextField } from 'src/components/Form/Inputs'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: (outcomeMessage: string) => unknown
}

const ReviseResubmitButtonDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  const formMethods = useForm({ defaultValues: { message: '' } })

  const onSubmit = (data) => {
    onConfirm(data.message)
    formMethods.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      <DialogTitle component="h2" variant="h2" color="primary" id="dialogtitle">
        Request revision and resubmission from candidate
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogcontent">
          <Typography variant="body1" sx={{ mb: 1 }}>
            The candidate will be asked to revise and resubmit their thesis. Please provide a brief explanation below.
          </Typography>
          <CTextField name="message" label="Message" multiline={true} rows={10} maxLength={2000} />
        </DialogContent>
        <DialogActions>
          {!saving && (
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}

          <LoadingButton variant="contained" color="primary" loading={saving} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ReviseResubmitButtonDialog
