type BareCollaborator = {
  user?: { displayName: string; mail: string }
  invitation?: { name: string; mail: string }
}

export const getCollaboratorName = (collaborator: BareCollaborator) => {
  return collaborator.user ? collaborator.user.displayName : collaborator.invitation.name
}

export const getCollaboratorMail = (collaborator: BareCollaborator) => {
  return collaborator.user ? collaborator.user.mail : collaborator.invitation.mail
}

export const isUq = (eduPersonPrincipalName: string) => {
  return eduPersonPrincipalName.endsWith('@uq.edu.au')
}

export const isStudent = (eduPersonPrincipalName: string) => {
  if (isUq(eduPersonPrincipalName)) {
    const username = eduPersonPrincipalName.substring(0, eduPersonPrincipalName.indexOf('@'))
    return /^[s][0-9]{7}$/.test(username)
  }
  return false
}

// Check if the user is the owner or lead of the record.
export const isOwnerOrLead = (userId: number, record: any) => {
  const collaborator = record.collaborators.find((collaborator) => collaborator.userId === userId)

  return collaborator?.isLead || collaborator?.isOwner
}

// Check if the user has dataset access.
export const hasDatasetAccess = (userId: number, dataset: any) => {
  return dataset.datasetUsers.some((datasetUser) => datasetUser.userId === userId)
}

// Check if the user is a dataset owner.
export const isDatasetOwner = (userId: number, dataset: any) => {
  const isDatasetUser = dataset.datasetUsers.find((user) => user.userId === userId)

  return isDatasetUser?.isOwner
}

// Sort collaborators by dataset creator, record owner, record lead and rest of collaborators.
export const sortOwnerLeadOrder = (collaborators = [], currentUserId) => {
  return collaborators.sort((a, b) => {
    if (a.userId === currentUserId) {
      return -1
    }
    if (b.userId === currentUserId) {
      return 1
    }
    if (a.isOwner) {
      return -1
    }
    if (b.isOwner) {
      return 1
    }
    if (a.isLead) {
      return -1
    }
    if (b.isLead) {
      return 1
    }
    return 0
  })
}

export const sortOwnerOrder = (collaborators = []) => {
  return collaborators.sort((a, b) => {
    if (a.isOwner) {
      return -1
    }
    if (b.isOwner) {
      return 1
    }
    return 0
  })
}
