import { Circle, Error } from '@mui/icons-material'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ThesisSubmission, ThesisSubmissionExaminer } from 'types/graphql'

interface Props {
  examiner: Pick<
    ThesisSubmissionExaminer,
    | 'status'
    | 'fullName'
    | 'mail'
    | 'position'
    | 'institution'
    | 'rationaleForNomination'
    | 'examinerCOIStatements'
    | 'examinerCOIDetails'
    | 'advisorCOIStatements'
    | 'advisorCOIDetails'
    | 'candidateCOIStatements'
    | 'candidateCOIDetails'
  > & {
    user?: {
      displayName: string
      mail: string
    }
  }
  submission: Pick<ThesisSubmission, 'id'> & {
    principalAdvisor?: {
      displayName: string
      mail: string
    }
    creator?: {
      displayName: string
      mail: string
    }
  }
}
const COIDisclosureDetails = ({ examiner, submission }: Props) => {
  const showExaminerCOIResponse =
    examiner?.status === 'GRADUATE_SCHOOL_FINAL_REVIEW' ||
    examiner?.status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL' ||
    examiner?.status === 'APPROVED'

  const hasCandidateCOIStatements =
    !!examiner?.candidateCOIStatements && Object.keys(examiner?.candidateCOIStatements).length > 0

  const styles = {
    table: {
      width: '100%',
      mb: 2,
    },
    tableCell: {
      verticalAlign: 'top !important',
      width: showExaminerCOIResponse ? '33%' : '50%',
      borderBottomWidth: 0,
      position: 'relative',
    },
    tableBorderBottom: {
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
    tableShadedCell: {
      bgcolor: 'rgba(0,0,0,0.02)',
    },
    tableQuestionCell: {
      display: 'inline',
      position: 'absolute',
      fontWeight: 'bold',
      overflowX: 'visible',
      textOverflow: 'visible',
      whiteSpace: 'nowrap',
    },
    wordWrap: {
      whiteSpace: '-moz-pre-wrap !important',
      whiteSpace: '-webkit-pre-wrap',
      whiteSpace: '-pre-wrap',
      whiteSpace: '-o-pre-wrap',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      whiteSpace: 'normal',
    },
  }
  return (
    <Grid container spacing={1} sx={{ width: '100%' }}>
      <Grid xs={12}>
        <Table
          sx={styles.table}
          size="small"
          aria-label="Conflict of interest disclosure table. Please note this table is non-standard. Each question is 2 rows, wth the first row being the question spanning the columns, and the second row being the answers."
        >
          {/* Header */}
          <TableHead>
            <TableRow sx={styles.tableBorderBottom}>
              {/* Principal advisor */}
              <TableCell sx={styles.tableCell}>
                <Grid container direction={'column'} spacing={0}>
                  <Grid xs={12}>
                    <Typography component={'h3'} color={'primary'}>
                      Principal Advisor
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography fontSize={'small'}>
                      {`${submission.principalAdvisor.displayName} (${submission.principalAdvisor.mail})`}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* Candidate */}
              {hasCandidateCOIStatements && (
                <TableCell sx={{ ...styles.tableCell, pl: 2 }}>
                  <Grid container direction={'column'} spacing={0}>
                    <Grid xs={12}>
                      <Typography component={'h3'} color={'primary'}>
                        Candidate
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography fontSize={'small'}>
                        {`${submission.creator.displayName} (${submission.creator.mail})`}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
              {/* Examiner */}
              {showExaminerCOIResponse && (
                <TableCell sx={styles.tableCell}>
                  <Grid container spacing={0}>
                    <Grid xs={12}>
                      <Typography component={'h3'} color={'primary'}>
                        Nominated examiner
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography fontSize={'small'}>
                        {`${examiner?.user?.displayName} (${examiner?.user?.mail})`}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {/* Body */}
          <TableBody>
            {/* Row 1 */}
            <TableRow aria-describedby="question1">
              {/* Question 1 */}
              <TableCell sx={styles.tableCell} id="question1">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      The examiner has published with the candidate or a member of the candidate's advisory team in the
                      last 5 years.
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
              {showExaminerCOIResponse && <TableCell sx={styles.tableCell}>&nbsp;</TableCell>}
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* PA Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.advisorCOIStatements?.['publishedWithCandidate'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.advisorCOIStatements?.['publishedWithCandidate'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* Candidate Answer */}
              {hasCandidateCOIStatements && (
                <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.candidateCOIStatements?.['publishedWithCandidate'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.candidateCOIStatements?.['publishedWithCandidate'] === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
              {/* Examiner Answer */}
              {showExaminerCOIResponse && (
                <TableCell sx={styles.tableCell}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.examinerCOIStatements?.['publishedWithCandidate'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.examinerCOIStatements?.['publishedWithCandidate'] === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>

            {/* Row 2 */}
            <TableRow aria-describedby="question2">
              {/* Question 2 */}
              <TableCell sx={styles.tableCell} id="question2">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      The examiner holds a current grant or has applied for a grant with the candidate or a member of
                      the candidate's advisory team.
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
              {showExaminerCOIResponse && <TableCell sx={styles.tableCell}>&nbsp;</TableCell>}{' '}
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* PA Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.advisorCOIStatements?.['grantWithAdvisor'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.advisorCOIStatements?.['grantWithAdvisor'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* Candidate Answer */}
              {hasCandidateCOIStatements && (
                <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.candidateCOIStatements?.['grantWithAdvisor'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.candidateCOIStatements?.['grantWithAdvisor'] === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
              {/* Examiner Answer */}
              {showExaminerCOIResponse && (
                <TableCell sx={styles.tableCell}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.examinerCOIStatements?.['grantWithAdvisor'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.examinerCOIStatements?.['grantWithAdvisor'] === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>

            {/* Row 3 */}
            <TableRow aria-describedby="question3">
              {/* Question 3 */}
              <TableCell sx={styles.tableCell} id="question3">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      The examiner has or has had a close personal or professional relationship with the candidate or a
                      member of the candidate's advisory team.
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
              {showExaminerCOIResponse && <TableCell sx={styles.tableCell}>&nbsp;</TableCell>}{' '}
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* PA Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.advisorCOIStatements?.['personalOrProfessionalRelationship'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.advisorCOIStatements?.['personalOrProfessionalRelationship'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* Candidate Answer */}
              {hasCandidateCOIStatements && (
                <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.candidateCOIStatements?.['personalOrProfessionalRelationship'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.candidateCOIStatements?.['personalOrProfessionalRelationship'] === true
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
              {/* Examiner Answer */}
              {showExaminerCOIResponse && (
                <TableCell sx={styles.tableCell}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.examinerCOIStatements?.['personalOrProfessionalRelationship'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.examinerCOIStatements?.['personalOrProfessionalRelationship'] === true
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>

            {/* Row 4 */}
            <TableRow aria-describedby="question4">
              {/* Question 4 */}
              <TableCell sx={styles.tableCell} id="question4">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>
                      The examiner has consulted with the candidate or a member of the candidate`s advisory team
                      regarding the thesis.
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
              {showExaminerCOIResponse && <TableCell sx={styles.tableCell}>&nbsp;</TableCell>}{' '}
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* PA Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.advisorCOIStatements?.['consultedOnThesis'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>
                      {examiner?.advisorCOIStatements?.['consultedOnThesis'] === true ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* Candidate Answer */}
              {hasCandidateCOIStatements && (
                <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.candidateCOIStatements?.['consultedOnThesis'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.candidateCOIStatements?.['consultedOnThesis'] === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
              {/* Examiner Answer */}
              {showExaminerCOIResponse && (
                <TableCell sx={styles.tableCell}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.examinerCOIStatements?.['consultedOnThesis'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>
                        {examiner?.examinerCOIStatements?.['consultedOnThesis'] === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>

            {/* Row 5 */}
            <TableRow aria-describedby="question5">
              {/* Question 5 */}
              <TableCell sx={styles.tableCell} id="question5">
                <Grid container spacing={2}>
                  <Grid>
                    &nbsp;
                    <Box sx={styles.tableQuestionCell}>Other conflict not listed above.</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>&nbsp;</TableCell>
              {showExaminerCOIResponse && <TableCell sx={styles.tableCell}>&nbsp;</TableCell>}{' '}
            </TableRow>
            <TableRow sx={styles.tableBorderBottom}>
              {/* PA Answer */}
              <TableCell sx={styles.tableCell}>
                <Grid container spacing={2}>
                  <Grid>
                    {examiner?.advisorCOIStatements?.['otherCOI'] === true ? (
                      <Error sx={{ color: '#FF7700' }} />
                    ) : (
                      <Circle sx={{ color: '#00b3a6' }} />
                    )}
                  </Grid>
                  <Grid alignSelf={'center'}>
                    <Typography>{examiner?.advisorCOIStatements?.['otherCOI'] === true ? 'Yes' : 'No'}</Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* Candidate Answer */}
              {hasCandidateCOIStatements && (
                <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.candidateCOIStatements?.['otherCOI'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>{examiner?.candidateCOIStatements?.['otherCOI'] === true ? 'Yes' : 'No'}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
              {/* Examiner Answer */}
              {showExaminerCOIResponse && (
                <TableCell sx={styles.tableCell}>
                  <Grid container spacing={2}>
                    <Grid>
                      {examiner?.examinerCOIStatements?.['otherCOI'] === true ? (
                        <Error sx={{ color: '#FF7700' }} />
                      ) : (
                        <Circle sx={{ color: '#00b3a6' }} />
                      )}
                    </Grid>
                    <Grid alignSelf={'center'}>
                      <Typography>{examiner?.examinerCOIStatements?.['otherCOI'] === true ? 'Yes' : 'No'}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>

            {/* Row 6 */}
            {(!!examiner.advisorCOIDetails || !!examiner.candidateCOIDetails || !!examiner.examinerCOIDetails) && (
              <>
                <TableRow sx={styles.tableBorderBottom}>
                  {/* PA Answer */}
                  <TableCell sx={styles.tableCell}>
                    <Box sx={styles.tableQuestionCell}>Details of the conflict:</Box>
                    <Typography fontStyle={'italic'} sx={{ ...styles.wordWrap, mt: 4 }}>
                      {`${examiner?.advisorCOIDetails ? '"' + examiner?.advisorCOIDetails + '"' : ''}`}
                    </Typography>
                  </TableCell>
                  {/* Candidate Answer */}
                  {hasCandidateCOIStatements && (
                    <TableCell sx={{ ...styles.tableCell, ...styles.tableShadedCell }}>
                      <Box sx={{ ...styles.tableQuestionCell }}>Details of the conflict:</Box>
                      <Typography fontStyle={'italic'} sx={{ ...styles.wordWrap, mt: 4 }}>
                        {`${examiner?.candidateCOIDetails ? '"' + examiner?.candidateCOIDetails + '"' : ''}`}
                      </Typography>
                    </TableCell>
                  )}
                  {/* Examiner Answer */}
                  {showExaminerCOIResponse && (
                    <TableCell sx={styles.tableCell}>
                      <Box sx={{ ...styles.tableQuestionCell }}>Details of the conflict:</Box>
                      <Typography fontStyle={'italic'} sx={{ ...styles.wordWrap, mt: 4 }}>
                        {`${examiner?.examinerCOIDetails ? '"' + examiner?.examinerCOIDetails + '"' : ''}`}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default COIDisclosureDetails
