import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetDeanActionListQuery } from 'types/graphql'

import { Form } from '@redwoodjs/forms'

import Button from 'src/components/Button/Button'
import { ApproveFinalSubmissionButton } from 'src/components/Thesis'

type Props = {
  onOpen: boolean
  onClose: () => void
  input: GetDeanActionListQuery['getDeanActionList']['pendingFinalSubmissionApproval'][0]
  onRefetch: () => void
}

const ApproveFinalSubmissionDialog = ({ onOpen, onClose, input, onRefetch }: Props) => {
  return (
    <Dialog open={onOpen} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
      <Form /* formMethods={formMethods} onSubmit={onInitiateThesis} */>
        <DialogTitle variant="h2" component="h2" color="primary">
          Request to confer
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid xs={12}>
              <Typography>
                By approving this thesis you are submitting a request to the Provost to confer the candidate's degree.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <ApproveFinalSubmissionButton
            key={0}
            iterationId={input.currentIteration?.id}
            onReload={onRefetch}
            onClose={onClose}
          />
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ApproveFinalSubmissionDialog
