import { externalHelpLink, staffHelpLink, studentHelpLink } from '@common/variables'

import { CurrentUser } from '@redwoodjs/auth'

export const getItsHelpLink = (currentUser: CurrentUser) => {
  if (currentUser.roles.includes('uq-staff')) {
    return staffHelpLink
  }
  if (currentUser.roles.includes('uq-student')) {
    return studentHelpLink
  }
  return externalHelpLink
}
