import { useApolloClient } from '@apollo/client'
import {
  NUMBER_OF_THESES_ASSESSED_INPUTS,
  RECOMMENDATION_INPUTS,
  THESIS_QUALITY_INPUTS,
  THESIS_RATING_INPUTS,
} from '@common/thesis/thesisExaminers/examinationFormData'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import { CancelRounded, CheckCircle } from '@mui/icons-material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisFileDownloadUrlQuery, GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import { CRadioGroup } from 'src/components/Form/Inputs'
import ThesisExaminerAnnotatedThesisFile from 'src/components/Form/Inputs/Thesis/ThesisFiles/components/ThesisExaminerAnnotatedThesisFile'
import ThesisExaminerReviewFile from 'src/components/Form/Inputs/Thesis/ThesisFiles/components/ThesisExaminerReviewFile'
import HelpButton from 'src/components/HelpButton/HelpButton'
import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import thesisExaminationCriteriaGuidance from 'src/lib/guidance/thesis/thesisExaminationCriteriaGuidance'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import {
  isAssignedThesisCandidate,
  isAssignedThesisExaminer,
  isAssignedThesisPrincipalAdvisor,
} from 'src/lib/userAccessChecker'

import ThesisFilesViewerSection from '../../../ThesisIterationFormViewer/components/ThesisFilesViewerSection'

export type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  readOnly?: boolean
}

const ExaminerWrittenFeedbackFormInputs = ({ examiner, submission, readOnly }: Props) => {
  const { currentUser, hasRole } = useAuth()
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()
  const isRnR = submission?.reviseAndResubmitOfId !== null

  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const isApprovedExaminer = isAssignedThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)
  const isAssignedPa = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const isAssignedCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)

  const hasAccess = !(isAssignedPa || isAssignedCandidate)

  const feedbackCompleted = examiner?.examinationFeedbackCompleted
  const userCanUploadFiles =
    (isApprovedExaminer ||
      (hasRole('grad-school-user') && submission.currentIteration.currentStatus.status === 'EXAMINERS_REVIEWING')) &&
    !feedbackCompleted

  const examinerReviewFile = submission.currentIteration.files.filter(
    (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_REVIEW_FILE && f.isActive
  )
  const examinerAnnotatedThesisFile = submission.currentIteration.files.filter(
    (f) => f.examinerId === examiner?.id && f.type === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE && f.isActive
  )

  const examinationQuestions = [
    {
      question: 'The thesis demonstrated an original contribution to the generation and/or application of knowledge',
      id: 'contributionKnowledge',
    },
    { question: 'The thesis engaged with the literature and the works of others', id: 'engagementWithLiterature' },
    {
      question:
        'The thesis showed an advanced knowledge of research principles and methods relating to the applicable discipline',
      id: 'advancedKnowledge',
    },
    {
      question: 'There is clear and discernible coherence in the presented research, its arguments, and conclusions',
      id: 'researchCoherence',
    },
    { question: 'The thesis is convincingly written', id: 'convincingWriting' },
  ]

  const styleExaminerAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1 }} />
    switch (answer) {
      case 'exceeds':
        return <span>{circle('#00B3A6')} Well above expectations</span>
        break
      case 'aboveAverage':
        return <span>{circle('#00A2C7')} Above expectations</span>
        break
      case 'average':
        return <span>{circle('#FFDD00')} Meets expectations</span>
        break
      case 'below':
        return <span>{circle('#FF7700')}Below expectations</span>
        break
      case 'wellBelow':
        return <span>{circle('#B00020')} Well below expectations</span>
        break
      default:
        return null
    }
  }

  const styleRatingAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1 }} />
    switch (answer) {
      case 'One of the best (in the top 10%)':
        return (
          <span>
            {circle('#00B3A6')} {answer}
          </span>
        )
        break
      case 'Better than average (in the 10+ to 30% range)':
        return (
          <span>
            {circle('#00A2C7')} {answer}
          </span>
        )
        break
      case 'About average (in the 30+ to 70% range)':
        return (
          <span>
            {circle('#FFDD00')} {answer}
          </span>
        )
        break
      case 'Worse than average (in the 70+ to 90% range)':
        return (
          <span>
            {circle('#FF7700')} {answer}
          </span>
        )
        break
      case 'One of the worst (in the bottom 10%)':
        return (
          <span>
            {circle('#B00020')} {answer}
          </span>
        )
        break
      default:
        return <>{answer}</>
    }
  }

  const styleRecommendedOutcome = (outcome) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1, mr: 0.5 }} />
    switch (outcome) {
      case 'pass':
        return circle('#00B3A6')
        break
      case 'awardOfMPhil':
        return circle('#FFDD00')
        break
      case 'fail':
        return circle('#B00020')
        break
      default:
        return null
    }
  }

  return (
    <Grid container spacing={2} sx={{ pt: 0 }}>
      {hasAccess && (
        <>
          {isApprovedExaminer && (
            <Grid xs={12}>
              {feedbackCompleted ? (
                <Typography variant="body2">
                  You have already submitted your feedback. Below is your response.
                </Typography>
              ) : (
                <Typography variant="body2">
                  Please review the thesis (download from the Thesis documents pane) and fill in the examination summary
                  below. Please also upload any relevant documents (the Thesis examiner review document or, optionally,
                  an annotated copy of the HDR thesis).
                </Typography>
              )}
            </Grid>
          )}

          {!readOnly ? (
            <>
              <Grid xs>
                <Typography color="primary" variant="h4" component="h3">
                  Evaluation of thesis quality
                </Typography>
              </Grid>
              <Grid xs="auto">
                <HelpButton {...thesisExaminationCriteriaGuidance} />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="The thesis demonstrated an original contribution to the generation and/or application of knowledge"
                  name="contributionKnowledge"
                  aria-label="contributionKnowledge"
                  required
                  readOnly={readOnly}
                  options={THESIS_QUALITY_INPUTS}
                />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="The thesis engaged with the literature and the works of others"
                  name="engagementWithLiterature"
                  aria-label="engagementWithLiterature"
                  required
                  readOnly={readOnly}
                  options={THESIS_QUALITY_INPUTS}
                />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="The thesis showed an advanced knowledge of research principles and methods relating to the applicable discipline"
                  name="advancedKnowledge"
                  aria-label="advancedKnowledge"
                  readOnly={readOnly}
                  options={THESIS_QUALITY_INPUTS}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="There is clear and discernible coherence in the presented research, its arguments, and conclusions"
                  name="researchCoherence"
                  aria-label="researchCoherence"
                  readOnly={readOnly}
                  options={THESIS_QUALITY_INPUTS}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="The thesis is convincingly written"
                  name="convincingWriting"
                  aria-label="convincingWriting"
                  readOnly={readOnly}
                  options={THESIS_QUALITY_INPUTS}
                  required
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid xs={12}>
                <Table size="small" sx={{ width: '100%' }}>
                  <TableHead className="sr-only">
                    <TableRow>
                      <TableCell>Examiner 1's responses</TableCell>
                      <TableCell>Examiner 2's responses</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        The responses below are spread over 2 rows - the first row being the question answered, and the
                        second row will contain the answers
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Examiners overview */}
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">
                          <b>Examiner</b>&nbsp;|&nbsp; {examiner?.user?.displayName || examiner?.fullName}
                        </Typography>
                        {!isApprovedExaminer && (
                          <Typography variant="body2">
                            {examiner.poorThesisQuality !== true ? (
                              <CheckCircle
                                sx={{
                                  color: '#00b3a6',
                                  width: '0.75em',
                                  height: '0.75em',
                                  mb: -0.5,
                                  mr: 1,
                                  ml: 1,
                                }}
                              />
                            ) : (
                              <CancelRounded
                                sx={{ color: 'red', width: '0.75em', height: '0.75em', mb: -0.5, mr: 1, ml: 1 }}
                              />
                            )}
                            {typeof examiner?.poorThesisQuality === 'boolean'
                              ? examiner?.poorThesisQuality
                                ? 'Poor'
                                : 'Satisfactory'
                              : 'null'}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2} sx={{ pb: 2, pt: 2, pl: 0, ml: -3 }}>
                        <Typography variant="h4" component="h3" color="primary">
                          Thesis quality
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {/* Questions */}
                    {examinationQuestions.map((question) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0 }}>
                              <i>{question.question}</i>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{styleExaminerAnswer(examiner[question.id])}</TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
          {/* Recommendation */}

          {isRnR && !readOnly && (
            <>
              <Grid xs={12}>
                <Typography color="primary" variant="h4" component="h3">
                  Recommendation
                </Typography>
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  label=""
                  name="examinerRecommendation"
                  aria-label="examinerRecommendation"
                  readOnly={readOnly}
                  options={RECOMMENDATION_INPUTS}
                  row={false}
                />
              </Grid>
            </>
          )}
          {isRnR && readOnly && (
            <Table size="small">
              <TableRow>
                <TableCell colSpan={2} sx={{ pb: 2, pt: 2 }}>
                  <Typography variant="h4" component="h3" color="primary">
                    Recommended outcome
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Grid container spacing={0}>
                    <Grid xs="auto">{styleRecommendedOutcome(examiner.examinerRecommendation)}</Grid>
                    <Grid xs>
                      {RECOMMENDATION_INPUTS.find((i) => i.value === examiner.examinerRecommendation)?.label}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </Table>
          )}

          <Grid xs={12} sx={{ ml: 1, mr: 1 }}>
            <Typography id="dialogTitle" color="primary" variant="h4" component="h3" sx={{ pb: 0.75, ml: -1 }}>
              Other information
            </Typography>
          </Grid>

          {!readOnly ? (
            <>
              <Grid xs={12}>
                <Typography variant="body2">
                  Please indicate the number of theses you have assessed (not including this thesis) and the number of
                  candidates you have supervised to completion at or above the level of this program (doctoral only for
                  the PhD, doctoral and masters for the MPhil).
                </Typography>
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="Number of theses assessed"
                  name="thesesAssessed"
                  aria-label="thesesAssessed"
                  readOnly={readOnly}
                  options={NUMBER_OF_THESES_ASSESSED_INPUTS}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="Number of candidates supervised to completion"
                  name="candidatesSupervised"
                  aria-label="candidatesSupervised"
                  readOnly={readOnly}
                  options={NUMBER_OF_THESES_ASSESSED_INPUTS}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <CRadioGroup
                  size="small"
                  label="Of all the theses I have read at this level, the rating for this thesis is"
                  name="thesisRating"
                  aria-label="thesisRating"
                  readOnly={readOnly}
                  options={THESIS_RATING_INPUTS}
                  row={false}
                  required
                />
              </Grid>
            </>
          ) : (
            <Grid xs={12}>
              <Table size="small" sx={{ width: '100%', ml: 0 }}>
                <TableRow>
                  <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0, pl: 3 }}>
                    <i>Number of theses assessed</i>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ pl: 3 }}>
                    {NUMBER_OF_THESES_ASSESSED_INPUTS.find((i) => i.value === examiner.thesesAssessed).label}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0, pl: 3 }}>
                    <i>Number of candidates supervised to completion</i>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ pl: 3 }}>
                    {NUMBER_OF_THESES_ASSESSED_INPUTS.find((i) => i.value === examiner.candidatesSupervised).label}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0, pl: 3 }}>
                    <i>Of all the theses I have read at this level, the rating for this thesis is</i>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ pl: 3 }}>
                    {styleRatingAnswer(THESIS_RATING_INPUTS.find((i) => i.value === examiner.thesisRating).label)}
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>
          )}
        </>
      )}
      {/* Examiner review doc uploader */}
      <Grid xs={12}>
        <Typography id="dialogTitle" color="primary" variant="h4" component="h3">
          Review documents
        </Typography>
      </Grid>
      {userCanUploadFiles && (
        <>
          <Grid xs={12}>
            <Typography variant="body2">
              Upload your complete review and comments as a single pdf here (required)
            </Typography>
          </Grid>
          <Grid xs={12}>
            <ThesisExaminerReviewFile readOnly={feedbackCompleted} examinerId={examiner?.id} />
          </Grid>
        </>
      )}
      {feedbackCompleted && (
        <Grid xs={12}>
          <ThesisFilesViewerSection
            documentLabel=""
            fileType={ThesisFileType.EXAMINER_REVIEW_FILE}
            files={examinerReviewFile}
            onDownload={onDownload}
          />
        </Grid>
      )}
      {/* Examiner annotated thesis doc uploader */}
      {userCanUploadFiles && (
        <>
          <Grid xs={12}>
            <Typography variant="body2">
              If you annotated the thesis file and would like to provide it to the candidate, upload a pdf here
              (optional)
            </Typography>
          </Grid>
          <Grid xs={12}>
            <ThesisExaminerAnnotatedThesisFile readOnly={feedbackCompleted} examinerId={examiner?.id} />
          </Grid>
        </>
      )}
      {feedbackCompleted && examinerAnnotatedThesisFile.length !== 0 && (
        <>
          <Grid xs={12}>
            <ThesisFilesViewerSection
              documentLabel=""
              fileType={ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE}
              files={examinerAnnotatedThesisFile}
              onDownload={onDownload}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ExaminerWrittenFeedbackFormInputs
