import DoneIcon from '@mui/icons-material/Done'
import { Box, Grid, Tooltip } from '@mui/material'

import StepperPointer from './components/StepperPointer'
import startBG from './Images/ThesisStepperBG_01.png'
import betweenBG from './Images/ThesisStepperBG_02.png'
import lastBetweenBG from './Images/ThesisStepperBG_03.png'
import endBG from './Images/ThesisStepperBG_05.png'
import twoStart from './Images/ThesisStepperBG_06.png'
import twoEnd from './Images/ThesisStepperBG_08.png'
import oneStart from './Images/ThesisStepperBG_09.png'

const styles = {
  wrapper: {
    overflow: 'hidden',
    height: 30,
    width: '100%',
    borderRadius: 15,
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  step: {
    marginTop: 5,
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: 'rgba(0,0,0,0.2)',
    outlineStyle: 'solid',
    group1: { backgroundColor: '#01A299' },
    group2: { backgroundColor: '#1E72C6' },
    group3: { backgroundColor: '#FF74D5' },
    group4: { backgroundColor: '#FF7700' },
    group5: { backgroundColor: '#FF9100' },
    groupError: { backgroundColor: 'red' },
  },
  cells: {
    '&:last-child': {
      width: '40px !important',
      display: 'block !important',
      minWidth: 30,
      maxWidth: 30,
    },
  },
  activeWrapper: {
    one: {
      paddingLeft: 1,
      backgroundImage: `url(${oneStart})`,
      backgroundRepeat: 'no-repeat',
      height: 30,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
    },
    twoStart: {
      paddingLeft: 1,
      backgroundImage: `url(${twoStart})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#912a8a',
      height: 30,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
    },
    twoEnd: {
      backgroundImage: `url(${twoEnd})`,
      backgroundRepeat: 'no-repeat',
      height: 30,
    },
    first: {
      paddingLeft: 1,
      backgroundImage: `url(${startBG})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#51247a',
      height: 30,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
    },
    last: {
      backgroundImage: `url(${endBG})`,
      backgroundRepeat: 'no-repeat',
      height: 30,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
    },
    lastBetween: {
      height: 30,
      backgroundImage: `url(${lastBetweenBG})`,
      backgroundSize: '101%',
    },
    between: {
      height: 30,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${betweenBG})`,
      backgroundSize: '300%',
    },
    other: {
      height: 30,
      backgroundColor: 'transparent',
    },
  },
}

interface StepperData {
  activeStep?: number
  maxStep?: number
  steps: { tooltip: string; group?: number; priority?: number; error?: boolean }[]
}

/**
 * Reusable thesis submission stepper component
 */
function Stepper({ steps, activeStep, maxStep }: StepperData) {
  const activeWrapperStyle = (step) => {
    if (step == 1 && activeStep == 1) {
      return styles.activeWrapper.one
    } else if (step == 1 && activeStep == 2) {
      return styles.activeWrapper.twoStart
    } else if (step == 2 && activeStep == 2) {
      return styles.activeWrapper.twoEnd
    } else if (step == activeStep) {
      return styles.activeWrapper.last
    } else if (step == 1) {
      return styles.activeWrapper.first
    } else if (step + 1 == activeStep) {
      return styles.activeWrapper.lastBetween
    } else if (step > activeStep) {
      return styles.activeWrapper.other
    } else {
      return styles.activeWrapper.between
    }
  }

  const buttonStyle = (group = 1, stepIndex, error) => {
    if (stepIndex <= activeStep) {
      if (error) {
        return styles.step.groupError
      }
      switch (group) {
        case 1:
          return styles.step.group1
        case 2:
          return styles.step.group2
        case 3:
          return styles.step.group3
        case 4:
          return styles.step.group4
        case 5:
          return styles.step.group5
      }
    }
    return null
  }

  return (
    // @TODO negative margin needs to be updated
    <Box marginBottom={-2}>
      <Grid container justifyContent={'space-between'} spacing={0} alignItems={'center'} sx={styles.wrapper}>
        {steps.map((step, index) => {
          return (
            <Grid xs item key={index} sx={[activeWrapperStyle(index + 1), styles.cells]} role="banner">
              <Tooltip title={`Step ${index + 1} of ${steps.length} - ${step.tooltip}`} placement="top" arrow>
                <Box
                  aria-current={index + 1 == activeStep ? 'step' : false}
                  aria-label={`Step ${index + 1} of ${steps.length} - ${step.tooltip}`}
                  role="button"
                  tabIndex={0}
                  style={{
                    ...styles.step,
                    ...buttonStyle(step.group, index + 1, step.error),
                    outlineWidth: index + 1 == activeStep ? '3px' : '0px',
                    outlineColor: index + 1 == activeStep ? '#51247a' : '',
                  }}
                >
                  {index + 1 < activeStep && <DoneIcon fontSize="small" sx={{ color: 'white' }} />}
                </Box>
              </Tooltip>
            </Grid>
          )
        })}
      </Grid>

      <StepperPointer steps={steps} activeStep={activeStep} maxStep={maxStep} />
    </Box>
  )
}

export default Stepper
