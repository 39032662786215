import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import ExaminersReviewFormInputs from './ExaminersReviewFormInputs'

type Props = {
  open: boolean
  onCancel: () => unknown
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

const ExaminersReviewDialog = ({ open, onCancel, submission }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
    >
      <DialogTitle id="dialogTitle" component="h2" variant="h2" color="primary" sx={{ mb: 1 }}>
        Written examination feedback
      </DialogTitle>
      <DialogContent id="dialogContent">
        <Grid container justifyContent="flex-end">
          <Grid xs={12}>
            <ExaminersReviewFormInputs submission={submission} />
          </Grid>
          <Grid xs />
          <Grid xs="auto">
            <Button variant="contained" color="secondary" onClick={onCancel} sx={{ mt: 2 }}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ExaminersReviewDialog
