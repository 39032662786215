import { useState } from 'react'

import { getIterationState } from '@common/thesis/iterationState'
import { Button, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { GetDeanActionListQuery } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { DataGrid, renderTooltipCell } from 'src/components/DataGrid'
import { toFullTimezone } from 'src/lib/toTimezone'

import OralExaminationChairSummaryDialog from './OralExaminationChairSummaryDialog'

type Props = {
  data: GetDeanActionListQuery['getDeanActionList']
  onRefetch: () => void
}

/**
 * Pending review of chair's feedback on candidate's oral examination
 */
const PendingReviewChairOralFeedbackCard = ({ data, onRefetch }: Props) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 })

  const [onOpenChairSummaryDialog, setOnOpenChairSummaryDialog] = useState(false)
  const [thesisSubmissionData, setThesisSubmissionData] =
    useState<GetDeanActionListQuery['getDeanActionList']['submissionsToBeApproved'][0]>(undefined)

  const renderSubmissionActionCell = (params: GridRenderCellParams) => {
    const input = params.value
    const iterationState = getIterationState(params.value.currentStatus)

    return (
      <>
        {iterationState.code === 'AWAITING_DEAN_OUTCOME' && (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              // e.preventDefault()
              setOnOpenChairSummaryDialog(true)
              setThesisSubmissionData(input)
            }}
            variant="contained"
            color="primary"
          >
            {'View Feedback'}
          </Button>
        )}
      </>
    )
  }

  const onCloseDialog = () => {
    setOnOpenChairSummaryDialog(false)
    setThesisSubmissionData(undefined)
    // @TODO reset form fields
  }

  const submissionColumns: GridColDef[] = [
    {
      field: 'lastStatusUpdate',
      headerName: 'Last status update',
      sortable: true,
      flex: 1,
      renderCell: renderTooltipCell,
    },
    { field: 'candidateDisplayName', headerName: 'Candidate', sortable: true, flex: 1, renderCell: renderTooltipCell },
    {
      field: 'advisorDisplayName',
      headerName: 'Principal advisor',
      sortable: true,
      flex: 1,
      renderCell: renderTooltipCell,
    },
    { field: 'chairDisplayName', headerName: 'Chair', sortable: true, flex: 1, renderCell: renderTooltipCell },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: renderSubmissionActionCell,
      align: 'right',
      headerAlign: 'right',
    },
  ]

  return (
    <>
      <Typography component="h2" variant="h2" color="primary" sx={{ m: 2 }}>
        Pending endorsement of initial thesis examination outcomes
      </Typography>
      <DataGrid
        pageSizeOptions={[10, 20, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastStatusUpdate', sort: 'asc' }],
          },
        }}
        onRowClick={(params) => {
          navigate(routes.graduateSchoolThesisSubmissionDetailsPage({ id: params.row.submissionId }))
        }}
        columns={submissionColumns}
        rowHeight={80}
        rows={data.submissionsToBeApproved.map((action, index) => {
          return {
            id: index,
            submissionId: action.submission.id,
            candidateDisplayName: action.candidate.displayName,
            advisorDisplayName: action.principalAdvisor.displayName,
            chairDisplayName: action?.chair?.user?.displayName || 'No chair',
            chair: action.chair,
            lastStatusUpdate: toFullTimezone(action?.currentStatus.createdAt),
            action: action,
          }
        })}
        sx={{ gridRow: { cursor: 'pointer' } }}
      />

      {onOpenChairSummaryDialog && thesisSubmissionData && (
        <OralExaminationChairSummaryDialog
          onOpen={onOpenChairSummaryDialog}
          onClose={onCloseDialog}
          input={thesisSubmissionData}
          onRefetch={onRefetch}
        />
      )}
    </>
  )
}

export default PendingReviewChairOralFeedbackCard
