import { ChevronLeft } from '@mui/icons-material'
import { Typography, TypographyProps } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { back } from '@redwoodjs/router'

import Button from '../Button/Button'

type PageTitleProps = TypographyProps & {
  noMarginBottom?: boolean
  subTitle?: string
  actions?: React.ReactNode[]
  showBackButton?: boolean
}
const PageTitle = ({
  variant,
  children,
  noMarginBottom,
  subTitle,
  showBackButton,
  actions,
  ...props
}: PageTitleProps) => {
  const marginBottom = noMarginBottom ? 0 : 2
  return (
    <Grid container justifyContent="space-between" sx={{ mb: marginBottom }}>
      <Grid>
        <Typography variant={variant || 'h1'} {...props}>
          {children}
        </Typography>
        {subTitle && <Typography variant="h2">{subTitle}</Typography>}
      </Grid>
      <Grid
        container
        sx={{ position: 'relative', pl: 3, pb: 2 }}
        alignContent={'flex-end'}
        justifyContent={'flex-end'}
        spacing={2}
      >
        {actions &&
          actions.length > 0 &&
          actions?.map((action, index) => (action === undefined ? null : <Grid key={index}>{action}</Grid>))}
        {showBackButton && (
          <Grid>
            <Button startIcon={<ChevronLeft />} variant="outlined" color="secondary" onClick={back}>
              Back
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PageTitle
