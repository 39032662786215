import MuiAlert, { AlertProps } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

const Alert = (props: AlertProps) => {
  const sx = props.sx ? props.sx : {}
  sx['& .MuiAlert-message'] = { flex: 1 }
  return <MuiAlert {...props} sx={sx} />
}

export { AlertTitle }
export default Alert
