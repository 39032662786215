import { useState } from 'react'

import { DeanRequestsCorrections, DeanRequestsCorrectionsVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import DEAN_REQUESTS_CORRECTIONS from 'src/lib/graphql/mutations/DeanRequestsCorrections'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import DeanPassWithCorrectionsDialog from './DeanPassWithCorrectionsDialog'

type Props = {
  iterationId: number
  disabled?: boolean
  onReload: () => unknown
  onClose: () => void
  fullWidth?: boolean
}

/**
 * Button for Dean request corrections from the candidate.
 */
const DeanPassWithCorrectionsButton = ({ iterationId, onReload, disabled, fullWidth, onClose }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [requestCorrections] = useMutation<DeanRequestsCorrections, DeanRequestsCorrectionsVariables>(
    DEAN_REQUESTS_CORRECTIONS
  )
  const showSnackbar = useSnackbar()

  const onConfirmRequestCorrections = async (outcomeMessage: string) => {
    setSaving(true)
    try {
      const result = await requestCorrections({ variables: { iterationId, outcomeMessage } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully requested corrections", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not request corrections from the candidate. Please try again later.', 'error')
      setSaving(false)
    }

    onClose()
  }

  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
        disabled={disabled}
        sx={{ mb: 1 }}
      >
        Request corrections
      </Button>
      <DeanPassWithCorrectionsDialog
        open={open}
        saving={saving}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirmRequestCorrections}
      />
    </>
  )
}

export default DeanPassWithCorrectionsButton
