import { getIterationStateByCode, THESIS_ITERATION_STATE_CODE } from '@common/thesis/iterationState'

import ThesisSubmissionMessage from '../../ThesisMessage/ThesisMessage'
import { getInitialType, getThesisSubmissionStepperInfo } from '../../ThesisSubmissionStepper/helpers'
import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

type Props = {
  submission: ThesisSubmissionDetails
  iterationStateCode: THESIS_ITERATION_STATE_CODE
}

/**
 * Initial submission Principal Advisor review state.
 *
 * Examiner view.
 */
const PrincipalAdvisorReview = ({ submission, iterationStateCode }: Props) => {
  const cm = getIterationStateByCode(iterationStateCode).examinerMessage
  const stepperState = getInitialType(submission.currentIteration.hasOral) // Need to check if 'INITIAL' | 'NO_ORAL_INITIAL'
  const stepperInfo = getThesisSubmissionStepperInfo({ iteration: submission.currentIteration }, stepperState)

  return <ThesisSubmissionMessage {...cm} {...stepperInfo} />
}

export default PrincipalAdvisorReview
