import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { CTextField } from 'src/components/Form/Inputs'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

const schema = yup.object().shape({
  thesisExaminerNote: yup.string(),
})

type FormValues = {
  thesisExaminerNote: string
}

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  onOpen: boolean
  onClose: () => void
  readOnly?: boolean
}

const GRADUATE_SCHOOL_UPDATES_EXAMINER_USER_NOTE = gql`
  mutation GraduateSchoolUpdatesExaminerUserNote($userId: Int!, $submissionId: Int!, $note: String) {
    graduateSchoolUpdatesExaminerUserNote(userId: $userId, submissionId: $submissionId, note: $note)
  }
`

/**
 * Examiner user note dialog
 */
const ThesisExaminerUserNoteDialog = ({ examinerData, onOpen, onClose, readOnly }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)

  const [updateExaminerUserNoteMutation] = useMutation(GRADUATE_SCHOOL_UPDATES_EXAMINER_USER_NOTE, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm({
    defaultValues: {
      thesisExaminerNote: examinerData.user.graduateSchoolNote || '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  const onConfirmUpdate = async (data: FormValues) => {
    setSaving(true)

    try {
      const result = await updateExaminerUserNoteMutation({
        variables: {
          userId: examinerData.user.id,
          submissionId: examinerData.submissionId,
          note: data.thesisExaminerNote,
        },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar(`You've successfully updated the user note for ${examinerData.user.displayName}.`, 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar(`Could not update user note for ${examinerData.user.displayName}. Please try again later.`, 'error')
    }

    setSaving(false)
    onClose()
  }

  return (
    <Dialog
      open={onOpen}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' || readOnly) {
          onClose()
        }
      }}
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
      fullWidth
    >
      <DialogTitle id="dialogTitle" color="primary" variant="h2" component="h2">
        Examiner Note (User level)
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onConfirmUpdate}>
        <DialogContent id="dialogContent">
          <Grid container>
            <Grid xs={12}>
              <CTextField name="thesisExaminerNote" minRows={5} multiline={true} fullWidth readOnly={readOnly} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => onClose()} disabled={saving}>
            Cancel
          </Button>
          {!readOnly && (
            <Button variant="contained" color="primary" type="submit" disabled={saving}>
              Submit
            </Button>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ThesisExaminerUserNoteDialog
