export const THESIS_QUALITY_INPUTS = [
  { label: 'Exceeds expectations', value: 'exceeds' },
  { label: 'Meets expectations', value: 'average' },
  { label: 'Below expectations', value: 'below' },
]

export const RECOMMENDATION_INPUTS = [
  { label: 'Pass - The thesis is of an appropriate standard.', value: 'pass' },
  {
    label:
      'Award of MPhil (PhD re-examination only) - The thesis is not of an appropriate standard for a PhD but fulfills the criteria for an MPhil.',
    value: 'awardOfMPhil',
  },
  {
    label: 'Fail - The thesis is not of the appropriate standard for the award of any research higher degree.',
    value: 'fail',
  },
]

export const NUMBER_OF_THESES_ASSESSED_INPUTS = [
  { label: '0 to 1', value: '0_1' },
  { label: '2 to 5', value: '2_5' },
  { label: '6 to 10', value: '6_10' },
  { label: '11 or more', value: '11_more' },
]

export const THESIS_RATING_INPUTS = [
  { label: 'One of the best (in the top 10%)', value: 'Best' },
  { label: 'Better than average (in the 10+ to 30% range)', value: 'betterThanAverage' },
  { label: 'About average (in the 30+ to 70% range)', value: 'average' },
  { label: 'Worse than average (in the 70+ to 90% range)', value: 'worstThanAverage' },
  { label: 'One of the worst (in the bottom 10%)', value: 'worst' },
]

export const YES_NO_INPUTS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
