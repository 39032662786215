import { useState } from 'react'

import { hasOralExamCompleted } from '@common/thesis/utility'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import dayjs from 'dayjs'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import { Date as DateInput } from 'src/components/Form/Inputs'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

const GRADUATE_SCHOOL_SCHEDULES_ORAL_EXAMINATION = gql`
  mutation GraduateSchoolConfirmOralDate($iterationId: Int!, $oralDate: DateTime!) {
    graduateSchoolConfirmOralDate(iterationId: $iterationId, oralDate: $oralDate)
  }
`

type Props = {
  submission: ThesisSubmissionDetails
  iterationId: number
  onReload: () => unknown
}

const FinalizeOralExaminationDateButton = ({ submission, iterationId, onReload }: Props) => {
  const showSnackbar = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [saving, setSaving] = useState(false)
  const [oralDate, setOralDate] = useState<Date>(dayjs(submission?.currentIteration?.oralDate).toDate())

  const [confirmOralDate] = useMutation(GRADUATE_SCHOOL_SCHEDULES_ORAL_EXAMINATION)

  const onConfirm = async () => {
    setSaving(true)

    try {
      const result = await confirmOralDate({
        variables: { iterationId, oralDate: dayjs(oralDate) },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully confirmed the oral examination date.", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not confirm oral examination date. Please try again later.', 'error')
    }

    setSaving(false)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Confirm oral examination date
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} aria-describedby="dialogContent" aria-labelledby="dialogTitle">
        <DialogTitle id="dialogTitle" variant="h2" component="h3" color="primary">
          Confirm oral examination date
        </DialogTitle>
        <DialogContent id="dialogContent">
          Confirm or update the oral examination date below. By confirming, the candidate, Principal Advisor, Chair, and
          examiners will be notified.
          <br />
          <br />
          Confirmation of the oral examination date will release the examiner feedback reports to all parties and the
          examiner feedback forms to the Chair of Examiners, if this has not been previously done.
          <br />
          <br />
          <DateInput
            value={oralDate}
            onChange={setOralDate}
            label="Oral examination date"
            required
            helperText="Please select the oral examination date."
          />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setOpen(false)} disabled={!!saving}>
            {'Cancel'}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={hasOralExamCompleted(oralDate) ? () => setOpenWarningDialog(true) : onConfirm}
            loading={!!saving}
          >
            {'Submit'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
        onConfirm={onConfirm}
        loading={saving}
        title="Warning"
        confirmLabel="Confirm"
      >
        You have selected a date that indicates the oral examination has already happened or will happen today. This
        will advance the candidature so that the Chair can complete their summary report.
      </ConfirmDialog>
    </>
  )
}

export default FinalizeOralExaminationDateButton
