import { ThesisFileFields } from './ThesisFilesViewer'
import ThesisFilesViewerTile from './ThesisFilesViewerTile'

type Props = {
  documentLabel?: string
  fileType?: string
  files: ThesisFileFields[]
  onDownload: (fileId: number) => void
}

const ThesisFilesViewerSection = ({ fileType, files, onDownload }: Props) => {
  files = fileType ? files.filter((f) => f.type === fileType && f.isActive) : files

  return (
    <>
      {files.map((file) => {
        return <ThesisFilesViewerTile key={file.id} file={file} onDownload={onDownload} />
      })}
    </>
  )
}

export default ThesisFilesViewerSection
