import { useEffect, useState } from 'react'

import { YES_NO_INPUTS } from '@common/thesis/thesisExaminers/examinationFormData'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { CRadioGroup, CTextField } from 'src/components/Form/Inputs'
import HelpButton from 'src/components/HelpButton/HelpButton'
import Link from 'src/components/Link/Link'
import EXAMINER_UPDATES_SELF_DETAILS from 'src/lib/graphql/mutations/ExaminerUpdatesSelfDetails'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ExaminerUpdateExaminerCOIDetails from './ExaminerUpdateExaminerCOIDetails'
import {
  createExaminerUpdateSelfDetailsDefaultValues,
  examinerDetailsTransformer,
  examinerUpdateSelfDetailsSchema,
  ExaminerUpdateSelfDetailsType,
} from './schema'

export interface ExaminerFormData {
  publishedWithCandidate: boolean
  grantWithAdvisor: boolean
  personalOrProfessionalRelationship: boolean
  consultedOnThesis: boolean
  otherCOI: boolean
  examinerCOIDetails: string
}

type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onOpen: boolean
  onClose: () => void
}

/**
 * Examiner updates self details form dialog
 */
const ExaminerUpdateSelfDetailsDialog = ({ examinerData, submission, onOpen, onClose }: Props) => {
  const showSnackbar = useSnackbar()
  const [isPending, setIsPending] = useState<boolean>(false)

  const [updateSelfDetails] = useMutation(EXAMINER_UPDATES_SELF_DETAILS, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm({
    defaultValues: {
      ...createExaminerUpdateSelfDetailsDefaultValues(examinerData),
    },
    resolver: yupResolver(examinerUpdateSelfDetailsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const needsPayment = formMethods.watch('requirePayment')
  const publishedWithCandidate = formMethods.watch('publishedWithCandidate')
  const grantWithAdvisor = formMethods.watch('grantWithAdvisor')
  const personalOrProfessionalRelationship = formMethods.watch('personalOrProfessionalRelationship')
  const consultedOnThesis = formMethods.watch('consultedOnThesis')
  const otherCOI = formMethods.watch('otherCOI')
  const showExaminerCOIDetails =
    !!publishedWithCandidate ||
    !!grantWithAdvisor ||
    !!personalOrProfessionalRelationship ||
    !!consultedOnThesis ||
    !!otherCOI

  useEffect(() => {
    if (!needsPayment) {
      formMethods.setValue('bankAccountType', null)
    }

    if (!showExaminerCOIDetails) {
      formMethods.setValue('examinerCOIDetails', null)
    }
  }, [needsPayment])

  const onSubmit = async ({ ...inputData }: ExaminerUpdateSelfDetailsType) => {
    setIsPending(true)

    try {
      await updateSelfDetails({
        variables: { id: examinerData.id, submissionId: submission.id, input: examinerDetailsTransformer(inputData) },
      })
      showSnackbar('Successfully updated your information.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update your information. Please try again later.', 'error')
    }

    setIsPending(false)
    onCloseDialog()
    navigate(routes.thesisExaminerDetails({ id: submission.id }))
  }

  const onCloseDialog = () => {
    formMethods.reset()
    onClose()
  }

  const hasValidationError = !formMethods.formState.isValid

  return (
    <>
      <Dialog
        open={onOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog()
          }
        }}
        aria-describedby="dialogContent"
        aria-labelledby="dialogTitle"
        maxWidth="lg"
      >
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <DialogContent id="dialogContent">
            <Typography variant="h1" color="primary" id="dialogTitle" sx={{ mb: 2 }}>
              Contact details and COI disclosure
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography variant="h2" component="h2" color="primary">
                  Your details
                </Typography>
              </Grid>
              <Grid xs={4}>
                <CTextField name="institution" label="Institution" required fullWidth />
              </Grid>
              <Grid xs={4}>
                <CTextField name="position" label="Position" required fullWidth />
              </Grid>
              <Grid xs={4}>
                <CTextField name="contactNumber" label="Contact phone number" required fullWidth />
              </Grid>
              <Grid xs={12}>
                <CTextField name="contactAddress" label="Contact postal address" required fullWidth multiline />
              </Grid>
              <Grid xs={'auto'}>
                <CRadioGroup
                  label="Do you require payment for your examination?"
                  name="requirePayment"
                  required
                  aria-label="Do you require payment for your examination?"
                  options={YES_NO_INPUTS}
                  readOnly={true}
                />
              </Grid>
              <Grid xs>
                <HelpButton
                  buttonSx={{ mt: -1, ml: -2 }}
                  isInfo
                  title="Payment of examiners"
                  text={
                    <div>
                      We’re grateful for the significant contribution you make to the quality of our research and offer
                      the payment of an honorarium.
                      <br />
                      <br />
                      Honorarium amounts are recommended by{' '}
                      <Link
                        target="_blank"
                        href="https://www.universitiesaustralia.edu.au/policy-submissions/research-innovations/fees-for-external-examiners/#.XA8JE7EzZhG"
                      >
                        Universities Australia
                      </Link>
                      . You’ll be paid into the bank account you nominate when accepting our invitation.
                    </div>
                  }
                />
              </Grid>
              {needsPayment && (
                <Grid xs={12}>
                  <CRadioGroup
                    label="Please indicate your bank account type"
                    name="bankAccountType"
                    required
                    aria-label="Please indicate your bank account type"
                    options={[
                      { label: 'Australian Bank Account', value: 'AUS' },
                      { label: 'International Bank Account', value: 'INTERNATIONAL' },
                    ]}
                    readOnly={true}
                  />
                </Grid>
              )}
              {Object.keys(examinerData.examinerCOIStatements).length > 0 && (
                <>
                  <Grid xs={12}>
                    <Typography component={'h2'} variant="h2" color={'primary'}>
                      Conflict of interest disclosure
                    </Typography>
                  </Grid>
                  <ExaminerUpdateExaminerCOIDetails
                    examiner={examinerData}
                    showExaminerCOIDetails={showExaminerCOIDetails}
                  />
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => onCloseDialog()} disabled={isPending}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={isPending || hasValidationError}>
              Update
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default ExaminerUpdateSelfDetailsDialog
