import { useState } from 'react'

import { Button } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import ExaminerReviewDialog from '../../ThesisDetails/WrittenExamination/ExaminerReviewDialog'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0] | null
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
  hasAccess: boolean
  isForThesisNotification?: boolean
}

/**
 * Dialog for examiner to provide feedback on the thesis
 */
const ExaminerFeedbackSummaryButton = ({
  examiner,
  submission,
  onReload,
  hasAccess,
  isForThesisNotification,
}: Props) => {
  const [openReviewForm, setOpenReviewForm] = useState(false)

  return (
    <>
      <Button
        variant={isForThesisNotification ? 'contained' : 'outlined'}
        color={isForThesisNotification ? 'primary' : 'secondary'}
        onClick={() => setOpenReviewForm(true)}
        disabled={!hasAccess}
        size={isForThesisNotification ? undefined : 'small'}
      >
        {isForThesisNotification ? 'Submit review' : 'Review'}
      </Button>
      <ExaminerReviewDialog
        open={openReviewForm}
        onCancel={() => setOpenReviewForm(false)}
        examiner={examiner}
        submission={submission}
        onReload={onReload}
      />
    </>
  )
}

export default ExaminerFeedbackSummaryButton
