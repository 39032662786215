import { useApolloClient } from '@apollo/client'
import {
  NUMBER_OF_THESES_ASSESSED_INPUTS,
  RECOMMENDATION_INPUTS,
  THESIS_RATING_INPUTS,
} from '@common/thesis/thesisExaminers/examinationFormData'
import { isExaminerAddedByGraduateSchool } from '@common/thesis/thesisExaminers/helper'
import { ThesisFileType } from '@common/thesis/thesisFiles'
import { CancelRounded, CheckCircle } from '@mui/icons-material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisFileDownloadUrlQuery, GetThesisSubmissionQuery } from 'types/graphql'

import GET_THESIS_FILE_DOWNLOAD_URL_QUERY from 'src/lib/graphql/queries/GetThesisFileDownloadUrlQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import ThesisFilesViewerSection from '../../../ThesisIterationFormViewer/components/ThesisFilesViewerSection'

export type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
}

const ExaminersReviewFormInputs = ({ submission }: Props) => {
  const showSnackbar = useSnackbar()
  const apolloClient = useApolloClient()

  const onDownload = async (fileId: number) => {
    try {
      const response = await apolloClient.query<GetThesisFileDownloadUrlQuery>({
        query: GET_THESIS_FILE_DOWNLOAD_URL_QUERY,
        variables: { fileId },
      })

      window.open(response.data.getPresignedUrlThesisFileDownload, '_blank')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not download file. Please try again later.', 'error')
    }
  }

  const examiners = submission?.thesisSubmissionExaminers?.filter(
    (examiner) => examiner.status === 'APPROVED' || isExaminerAddedByGraduateSchool(examiner)
  )
  const examiner1 = examiners[0]
  const examiner2 = examiners[1]

  const isRnR = submission?.reviseAndResubmitOfId !== null

  const examiner1ReviewFile = submission.currentIteration.files.filter(
    (f) => f.examinerId === examiner1?.id && f.type === ThesisFileType.EXAMINER_REVIEW_FILE && f.isActive
  )
  const examiner1AnnotatedThesisFile = submission.currentIteration.files.filter(
    (f) => f.examinerId === examiner1?.id && f.type === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE && f.isActive
  )
  const examiner2ReviewFile = submission.currentIteration.files.filter(
    (f) => f.examinerId === examiner2?.id && f.type === ThesisFileType.EXAMINER_REVIEW_FILE && f.isActive
  )
  const examiner2AnnotatedThesisFile = submission.currentIteration.files.filter(
    (f) => f.examinerId === examiner2?.id && f.type === ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE && f.isActive
  )

  const examinationQuestions = [
    {
      question: 'The thesis demonstrated an original contribution to the generation and/or application of knowledge',
      id: 'contributionKnowledge',
    },
    { question: 'The thesis engaged with the literature and the works of others', id: 'engagementWithLiterature' },
    {
      question:
        'The thesis showed an advanced knowledge of research principles and methods relating to the applicable discipline',
      id: 'advancedKnowledge',
    },
    {
      question: 'There is clear and discernible coherence in the presented research, its arguments, and conclusions',
      id: 'researchCoherence',
    },
    { question: 'The thesis is convincingly written', id: 'convincingWriting' },
  ]

  const styleExaminerAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1 }} />
    switch (answer) {
      case 'exceeds':
        return <span>{circle('#00B3A6')} Well above expectations</span>
        break
      case 'aboveAverage':
        return <span>{circle('#00A2C7')} Above expectations</span>
        break
      case 'average':
        return <span>{circle('#FFDD00')} Meets expectations</span>
        break
      case 'below':
        return <span>{circle('#FF7700')}Below expectations</span>
        break
      case 'wellBelow':
        return <span>{circle('#B00020')} Well below expectations</span>
        break
      default:
        return null
    }
  }

  const styleRatingAnswer = (answer) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1 }} />
    switch (answer) {
      case 'One of the best (in the top 10%)':
        return (
          <span>
            {circle('#00B3A6')} {answer}
          </span>
        )
        break
      case 'Better than average (in the 10+ to 30% range)':
        return (
          <span>
            {circle('#00A2C7')} {answer}
          </span>
        )
        break
      case 'About average (in the 30+ to 70% range)':
        return (
          <span>
            {circle('#FFDD00')} {answer}
          </span>
        )
        break
      case 'Worse than average (in the 70+ to 90% range)':
        return (
          <span>
            {circle('#FF7700')} {answer}
          </span>
        )
        break
      case 'One of the worst (in the bottom 10%)':
        return (
          <span>
            {circle('#B00020')} {answer}
          </span>
        )
        break
      default:
        return <>{answer}</>
    }
  }

  const styleRecommendedOutcome = (outcome) => {
    const circle = (color) => <FiberManualRecordIcon sx={{ color: color, mb: -1, mr: 0.5 }} />
    switch (outcome) {
      case 'pass':
        return circle('#00B3A6')
        break
      case 'awardOfMPhil':
        return circle('#FFDD00')
        break
      case 'fail':
        return circle('#B00020')
        break
      default:
        return null
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sx={{ p: 0, pt: 2, mb: 2 }}>
        <Table size="small" sx={{ width: '100%' }}>
          <TableHead className="sr-only">
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Examiner 1's responses</TableCell>
              <TableCell sx={{ width: '50%' }}>Examiner 2's responses</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                The responses below are spread over 2 rows - the first row being the question answered, and the second
                row will contain the answers
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Examiners overview */}
            <TableRow>
              {examiners.map((examiner, index) => {
                return (
                  <TableCell key={index} sx={index !== 0 ? { bgcolor: 'rgba(0,0,0,0.025)' } : {}}>
                    <Typography variant="body2">
                      <b>Examiner {index + 1}</b>&nbsp;|&nbsp; {examiner?.user?.displayName || examiner?.fullName}
                      {examiner.poorThesisQuality !== true ? (
                        <CheckCircle
                          sx={{
                            color: '#00b3a6',
                            width: '0.75em',
                            height: '0.75em',
                            mb: -0.5,
                            mr: 1,
                            ml: 1,
                          }}
                        />
                      ) : (
                        <CancelRounded
                          sx={{ color: 'red', width: '0.75em', height: '0.75em', mb: -0.5, mr: 1, ml: 1 }}
                        />
                      )}
                      {typeof examiner?.poorThesisQuality === 'boolean'
                        ? examiner?.poorThesisQuality
                          ? 'Poor'
                          : 'Satisfactory'
                        : 'null'}
                    </Typography>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ pb: 2, pt: 2 }}>
                <Typography variant="h4" component="h3" color="primary">
                  Thesis quality
                </Typography>
              </TableCell>
            </TableRow>

            {/* Examiner Questions */}
            {examinationQuestions.map((question) => {
              return (
                <>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0 }}>
                      <i>{question.question}</i>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {examiners[0] && <TableCell>{styleExaminerAnswer(examiners[0][question.id])}</TableCell>}
                    {examiners[1] && (
                      <TableCell sx={{ bgcolor: 'rgba(0,0,0,0.025)' }}>
                        {styleExaminerAnswer(examiners[1][question.id])}
                      </TableCell>
                    )}
                  </TableRow>
                </>
              )
            })}

            {isRnR && (
              <>
                <TableRow>
                  <TableCell colSpan={2} sx={{ pb: 2, pt: 2 }}>
                    <Typography variant="h4" component="h3" color="primary">
                      Recommended outcome
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: '50%' }}>
                    <Grid container spacing={0}>
                      <Grid xs="auto">
                        {examiners[0] ? styleRecommendedOutcome(examiners[0]?.examinerRecommendation) : undefined}
                      </Grid>
                      <Grid xs>
                        {RECOMMENDATION_INPUTS.find((i) => i.value === examiners[0]?.examinerRecommendation)?.label}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ width: '50%' }}>
                    <Grid container spacing={0}>
                      <Grid xs="auto">
                        {examiners[1] ? styleRecommendedOutcome(examiners[1]?.examinerRecommendation) : undefined}
                      </Grid>
                      <Grid xs>
                        {RECOMMENDATION_INPUTS.find((i) => i.value === examiners[1]?.examinerRecommendation)?.label}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </>
            )}

            {/* Other Information */}
            <TableRow>
              <TableCell colSpan={2} sx={{ pb: 2, pt: 2 }}>
                <Typography variant="h4" component="h3" color="primary">
                  Other information
                </Typography>
              </TableCell>
            </TableRow>
            {/* Number of theses assessed */}
            <TableRow>
              <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0 }}>
                <Typography variant="body2">
                  <i>Number of theses assessed</i>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {examiners[0] && (
                <TableCell>
                  {NUMBER_OF_THESES_ASSESSED_INPUTS.find((i) => i.value === examiners[0]?.thesesAssessed)?.label}
                </TableCell>
              )}
              {examiners[1] && (
                <TableCell sx={{ bgcolor: 'rgba(0,0,0,0.025)' }}>
                  {NUMBER_OF_THESES_ASSESSED_INPUTS.find((i) => i.value === examiners[1]?.thesesAssessed)?.label}
                </TableCell>
              )}
            </TableRow>

            {/* Number od candidates supervised to completion */}
            <TableRow>
              <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0 }}>
                <Typography variant="body2">
                  <i>Number of candidates supervised to completion</i>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {examiner1 && (
                <TableCell>
                  {NUMBER_OF_THESES_ASSESSED_INPUTS.find((i) => i.value === examiner1?.candidatesSupervised)?.label}
                </TableCell>
              )}
              {examiner2 && (
                <TableCell sx={{ bgcolor: 'rgba(0,0,0,0.025)' }}>
                  {NUMBER_OF_THESES_ASSESSED_INPUTS.find((i) => i.value === examiner2?.candidatesSupervised)?.label}
                </TableCell>
              )}
            </TableRow>

            {/* Of all the theses I have read at this level, the rating for this thesis is */}
            <TableRow>
              <TableCell colSpan={2} sx={{ borderBottom: 'none', pb: 0 }}>
                <Typography variant="body2">
                  <i>Of all the theses I have read at this level, the rating for this thesis is:</i>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {examiner1 && (
                <TableCell>
                  {styleRatingAnswer(THESIS_RATING_INPUTS.find((i) => i.value === examiner1?.thesisRating)?.label)}
                </TableCell>
              )}
              {examiner2 && (
                <TableCell sx={{ bgcolor: 'rgba(0,0,0,0.025)' }}>
                  {styleRatingAnswer(THESIS_RATING_INPUTS.find((i) => i.value === examiner2?.thesisRating)?.label)}
                </TableCell>
              )}
            </TableRow>

            {/* Review documents */}
            <TableRow>
              <TableCell colSpan={2} sx={{ pb: 2, pt: 2 }}>
                <Typography variant="h4" component="h3" color="primary">
                  Review documents
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ p: 0, pb: 2, pt: 2, borderBottom: 'none' }}>
                <ThesisFilesViewerSection
                  documentLabel=""
                  fileType={ThesisFileType.EXAMINER_REVIEW_FILE}
                  files={examiner1ReviewFile}
                  onDownload={onDownload}
                />
              </TableCell>
              <TableCell sx={{ p: 0, pb: 2, pt: 2, borderBottom: 'none' }}>
                <ThesisFilesViewerSection
                  documentLabel=""
                  fileType={ThesisFileType.EXAMINER_REVIEW_FILE}
                  files={examiner2ReviewFile}
                  onDownload={onDownload}
                />
              </TableCell>
            </TableRow>

            {/* Annotated thesis documents */}
            {(examiner1AnnotatedThesisFile.length > 0 || examiner2AnnotatedThesisFile.length > 0) && (
              <>
                <TableRow>
                  <TableCell sx={{ p: 0, borderBottom: 'none' }}>
                    <ThesisFilesViewerSection
                      documentLabel=""
                      fileType={ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE}
                      files={examiner1AnnotatedThesisFile}
                      onDownload={onDownload}
                    />
                  </TableCell>
                  <TableCell sx={{ p: 0, borderBottom: 'none' }}>
                    <ThesisFilesViewerSection
                      documentLabel=""
                      fileType={ThesisFileType.EXAMINER_ANNOTATED_THESIS_FILE}
                      files={examiner2AnnotatedThesisFile}
                      onDownload={onDownload}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default ExaminersReviewFormInputs
