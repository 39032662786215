import { Alert, Stack } from '@mui/material'
import { GetProvostActionListQuery } from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import GET_PROVOST_ACTION_LIST from 'src/lib/graphql/queries/GetProvostActionListQuery'

import PendingConferralCard from '../PendingConferralCard'

export const QUERY = GET_PROVOST_ACTION_LIST

export const Loading = () => <InlineLoader message="Loading Provost action page..." />

export const Failure = () => (
  <Alert severity="error">Could not load Provost action page. Please refresh the page and try again.</Alert>
)

export const Success = ({
  getProvostActionList,
  queryResult: { refetch },
}: CellSuccessProps<GetProvostActionListQuery>) => {
  return (
    <Stack direction="column" spacing={2}>
      <PendingConferralCard data={getProvostActionList} onRefetch={refetch} />
    </Stack>
  )
}
