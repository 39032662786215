export const titles = [
  { label: 'Mr.', value: 'Mr' },
  { label: 'Ms.', value: 'Ms' },
  { label: 'Mrs.', value: 'Mrs' },
  { label: 'Dr.', value: 'Dr' },
  { label: 'Professor', value: 'Prof' },
  { label: 'Associate Professor', value: 'Associate Prof' },
  { label: 'Assistant Professor', value: 'Assistant Prof' },
  { label: 'Emeritus Professor', value: 'Emeritus Prof' },
  { label: 'Honorary Professor', value: 'Honorary Prof' },
  { label: 'Adjunct Professor', value: 'Adjunct Prof' },
  { label: 'N/A', value: 'N/A' },
]
