import { useState } from 'react'

import getThesisExaminerOutcomeLabel from '@common/thesis/thesisExaminers/getThesisExaminerOutcomeLabel'
import { Button, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { GetDeanActionListQuery } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { DataGrid, renderTooltipCell } from 'src/components/DataGrid'
import { toFullTimezone } from 'src/lib/toTimezone'

import ThesisExaminerCOIApprovalDialog from './ThesisExaminerCOIApprovalDialog'

type Props = {
  data: GetDeanActionListQuery['getDeanActionList']
  onRefetch: () => void
}

const PendingCOIApprovalCard = ({ data, onRefetch }: Props) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 })

  const [onOpenExaminerCOIReviewDialog, setOnOpenExaminerCOIReviewDialog] = useState(false)
  const [thesisExaminerData, setThesisExaminerData] =
    useState<GetDeanActionListQuery['getDeanActionList']['examinersToBeApproved'][0]>(undefined)

  const renderExaminerActionCell = (params: GridRenderCellParams) => {
    const input = params.value

    return (
      <Button
        onClick={(e) => {
          e.stopPropagation()
          setOnOpenExaminerCOIReviewDialog(true)
          setThesisExaminerData(input)
        }}
        variant="contained"
        color="primary"
      >
        {'Review COI'}
      </Button>
    )
  }

  const onCloseDialog = () => {
    setOnOpenExaminerCOIReviewDialog(false)
  }

  const examinerColumns: GridColDef[] = [
    { field: 'candidate', headerName: 'Candidate', sortable: true, flex: 1, renderCell: renderTooltipCell },
    {
      field: 'principalAdvisor',
      headerName: 'Principal Advisor',
      sortable: true,
      flex: 1,
      renderCell: renderTooltipCell,
    },
    { field: 'examiner', headerName: 'Examiner', sortable: true, flex: 1, renderCell: renderTooltipCell },
    {
      field: 'examinerStatus',
      headerName: 'Status',
      sortable: true,
      width: 225,
      renderCell: renderTooltipCell,
    },
    {
      field: 'lastStatusUpdate',
      headerName: 'Last status update',
      sortable: true,
      width: 170,
      renderCell: renderTooltipCell,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 135,
      renderCell: renderExaminerActionCell,
      align: 'right',
      headerAlign: 'right',
    },
  ]

  return (
    <>
      <Typography component="h2" variant="h2" color="primary" sx={{ m: 2 }}>
        Pending conflict of interest disclosures
      </Typography>
      <DataGrid
        pageSizeOptions={[10, 20, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastStatusUpdate', sort: 'asc' }],
          },
        }}
        onRowClick={(params) => {
          navigate(routes.graduateSchoolThesisSubmissionDetailsPage({ id: params.row.submissionId }))
        }}
        columns={examinerColumns}
        rowHeight={80}
        rows={data.examinersToBeApproved.map((action, index) => {
          return {
            id: index,
            submissionId: action.submission.id,
            candidate: action.submission.creator.displayName,
            principalAdvisor: action.submission.principalAdvisor.displayName,
            examiner: action?.examiner?.fullName,
            examinerStatus: getThesisExaminerOutcomeLabel(action?.examiner?.status),
            lastStatusUpdate: toFullTimezone(action?.examiner.updatedAt),
            action: action,
          }
        })}
        sx={{ gridRow: { cursor: 'pointer' } }}
      />

      {onOpenExaminerCOIReviewDialog && (
        <ThesisExaminerCOIApprovalDialog
          onOpen={onOpenExaminerCOIReviewDialog}
          onClose={onCloseDialog}
          examinerData={thesisExaminerData}
          onRefetch={onRefetch}
        />
      )}
    </>
  )
}

export default PendingCOIApprovalCard
