const PRINCIPAL_ADVISOR_UPDATES_EXAMINER_DETAILS = gql`
  mutation PrincipalAdvisorUpdatesExaminerDetails(
    $id: Int!
    $submissionId: Int!
    $examinerData: UpdateExaminerDetailsInput!
  ) {
    principalAdvisorUpdatesExaminerDetails(id: $id, submissionId: $submissionId, examinerData: $examinerData)
  }
`

export default PRINCIPAL_ADVISOR_UPDATES_EXAMINER_DETAILS
