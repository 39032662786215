import { getThesisFileTypeName, ThesisFileType } from '@common/thesis/thesisFiles'
import { ArticleOutlined, NewReleases, SaveAlt } from '@mui/icons-material'
import { Badge, Card, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Unstable_Grid2'

import { useAuth } from 'src/auth'
import { toFullTimezone } from 'src/lib/toTimezone'

import { ThesisFileFields } from './ThesisFilesViewer'

type Props = {
  file: ThesisFileFields
  onDownload: (fileId: number) => void
}

const ThesisFilesViewerTile = ({ file, onDownload }: Props) => {
  const { hasRole } = useAuth()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xl'))

  // @TODO put this in helper function
  function truncateWithEllipsis(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '…' // Use a single ellipsis character
    }
    return str // No truncation needed
  }

  const filesTypesToNotShowNewBadge = !(
    file.type == 'EXAMINER_ANNOTATED_THESIS_FILE' ||
    file.type == 'EXAMINER_REVIEW_FILE' ||
    file.type == 'CHAIR_REVIEW_FILE'
  )

  const rolesThatCanSeeNewBadge = hasRole('user-thesis-principal-advisor') || hasRole('user-thesis-submission')

  const canSeeNewBadge = file.isNew && filesTypesToNotShowNewBadge && rolesThatCanSeeNewBadge

  return (
    <Card sx={{ ml: 1, mr: 1, backgroundColor: 'rgba(0,0,0,0.05)' }}>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid alignItems="center" display="flex" xs="auto">
          <Badge
            invisible={
              !file.isNew || file.type == 'EXAMINER_ANNOTATED_THESIS_FILE' || file.type == 'EXAMINER_REVIEW_FILE'
            }
            badgeContent={
              canSeeNewBadge && (
                <Tooltip title="This file was uploaded in this iteration">
                  <NewReleases color="warning" fontSize="small" />
                </Tooltip>
              )
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <ArticleOutlined sx={{ color: '#666' }} />
          </Badge>
        </Grid>
        <Grid alignContent={'center'}>
          <Grid container spacing={1}>
            <Grid xs={'auto'}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} alignSelf={'center'}>
                {getThesisFileTypeName(file.type)}
              </Typography>
            </Grid>
            {file.type === ThesisFileType.SUPPLEMENTARY_FILE && (
              <>
                {' '}
                <Grid xs={'auto'}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid xs={'auto'} alignSelf={'center'}>
                  <Tooltip title={file.filename}>
                    <Typography variant="body2" fontSize={'small'}>
                      {smallScreen ? truncateWithEllipsis(file.filename, 5) : truncateWithEllipsis(file.filename, 20)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </>
            )}
            <Grid xs={'auto'}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid xs={'auto'} alignSelf={'center'}>
              <Typography variant="body2" fontSize={'small'}>
                {toFullTimezone(file.submissionDate)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs display="flex" justifyContent="flex-end" alignItems="center">
          <Tooltip title="Download file">
            <IconButton onClick={() => onDownload(file.id)} size="small">
              <SaveAlt />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ThesisFilesViewerTile
