import { Box, Drawer } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import RdmLogoColor from 'src/images/rdm_logo_color'
import MenuItem from 'src/types/MenuItem'

import MenuDrawerLogoBar from './MenuDrawerLogoBar'
import MenuItems from './MenuItems'
import SkipMenu from './SkipMenu'

const MenuDrawerWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const MenuContentWrapper = styled(Box)({
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const MenuItemsWrapper = styled(Box)({
  flex: 1,
})

const MenuDrawerFooter = styled(Box)({
  marginLeft: 8,
})

const drawerWidth = 320

type MenuDrawerProps = {
  handleMenuClose: () => void
  menuItems: MenuItem[]
  open: boolean
}

const MenuDrawer = ({ handleMenuClose, menuItems, open }: MenuDrawerProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? open : true}
        onClose={isMobile ? handleMenuClose : undefined}
        sx={{
          display: isMobile
            ? { md: 'block', lg: 'none', xl: 'none' }
            : { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderRight: 0,
          },
        }}
      >
        <MenuDrawerWrapper sx={{ mt: '-1px' }}>
          <MenuDrawerLogoBar handleMenuClose={handleMenuClose} height={64} showCloseButton={isMobile} />
          <MenuContentWrapper>
            <MenuItemsWrapper>
              <SkipMenu />
              <MenuItems menuItems={menuItems} />
            </MenuItemsWrapper>
            <MenuDrawerFooter>
              <RdmLogoColor width={150} height={96} />
            </MenuDrawerFooter>
          </MenuContentWrapper>
        </MenuDrawerWrapper>
      </Drawer>
    </Box>
  )
}

export default MenuDrawer
