import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'

import { CTextField } from 'src/components/Form/Inputs'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: (outcomeMessage: string) => unknown
}

/**
 * Char request additional corrections from candidate dialog
 */
const ChairRequestsAdditionalCorrectionsDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  const [message, setMessage] = useState('')

  const formMethods = useForm({ defaultValues: { message: '' } })

  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }

  const onSubmit = (e) => {
    onConfirm(e.message)
    formMethods.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      <DialogTitle variant="h2" component="h2" color="primary" id="dialogtitle">
        Request additional corrections from candidate
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogcontent">
          <Typography variant="body1" sx={{ mb: 1 }}>
            By taking the action below, you are asking the candidate to edit their thesis submission. Please leave a
            detailed message for the candidate explaining what needs to be changed. After they submit the corrected
            submission and the Principal Advisor approves it, you will be asked to review and either request further
            corrections or approve the submission.
          </Typography>
          <CTextField
            name="message"
            label="Details of your request"
            placeholder="Please add the details of your request"
            multiline={true}
            rows={10}
            value={message}
            onChange={onMessageChange}
            maxLength={2000}
          />
        </DialogContent>
        <DialogActions>
          {!saving && (
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}

          <LoadingButton variant="contained" color="primary" loading={saving} type="submit">
            Request additional corrections
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ChairRequestsAdditionalCorrectionsDialog
