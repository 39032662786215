import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { CTextField } from 'src/components/Form/Inputs'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

const schema = yup.object().shape({
  thesisExaminerNote: yup.string(),
})

type FormValues = {
  thesisExaminerNote: string
}

const GRADUATE_SCHOOL_UPDATES_EXAMINER_SUBMISSION_NOTE = gql`
  mutation GraduateSchoolUpdatesExaminerSubmissionNote($id: Int!, $submissionId: Int!, $note: String) {
    graduateSchoolUpdatesExaminerSubmissionNote(id: $id, submissionId: $submissionId, note: $note)
  }
`
type Props = {
  examinerData: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  onOpen: boolean
  onClose: () => void
  readOnly?: boolean
}

/**
 * Examiner submission note dialog
 */
const ThesisExaminerSubmissionNoteDialog = ({ examinerData, onOpen, onClose, readOnly }: Props) => {
  const showSnackbar = useSnackbar()
  const [saving, setSaving] = useState(false)

  const [updateSubmissionNoteMutation] = useMutation(GRADUATE_SCHOOL_UPDATES_EXAMINER_SUBMISSION_NOTE, {
    refetchQueries: [GET_THESIS_SUBMISSION_QUERY],
  })

  const formMethods = useForm({
    defaultValues: {
      thesisExaminerNote: examinerData?.graduateSchoolNote || '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  const onConfirmUpdate = async (data: FormValues) => {
    setSaving(true)

    try {
      const result = await updateSubmissionNoteMutation({
        variables: {
          id: examinerData.id,
          submissionId: examinerData.submissionId,
          note: data.thesisExaminerNote,
        },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully updated the note for this examiner.", 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update note for this examiner. Please try again later.', 'error')
    }

    setSaving(false)
    onClose()
  }

  return (
    <>
      <Dialog
        open={onOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick' || readOnly) {
            formMethods.reset()
            onClose()
          }
        }}
        aria-describedby="dialogContent"
        aria-labelledby="dialogTitle"
        fullWidth
      >
        <DialogTitle color="primary" id="dialogTitle" variant="h2" component="h2">
          Examiner Note (Submission level)
        </DialogTitle>
        <Form formMethods={formMethods} onSubmit={onConfirmUpdate}>
          <DialogContent id="dialogContent">
            <Grid container>
              <Grid xs={12}>
                <CTextField name="thesisExaminerNote" minRows={5} multiline={true} fullWidth readOnly={readOnly} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => onClose()} disabled={saving}>
              Cancel
            </Button>
            {!readOnly && (
              <Button variant="contained" color="primary" type="submit" disabled={saving}>
                Submit
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default ThesisExaminerSubmissionNoteDialog
