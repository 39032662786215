import thesisTypes from '@common/thesis/thesisTypes'
import { MenuItem } from '@mui/material'

import { useWatch } from '@redwoodjs/forms'

import { CSelect, CSelectProps } from '../Base/Select'

type Props = Exclude<CSelectProps, 'children'>

const ThesisTypeSelectorInput = (props: Props) => {
  const currentValue = useWatch({ name: props.name })
  const allowedThesisTypes = thesisTypes.filter((type) => type.code !== 'MPHIL_IN_LIEU_OF_PHD')

  if (currentValue === 'MPHIL_IN_LIEU_OF_PHD')
    allowedThesisTypes.push(thesisTypes.find((type) => type.code === 'MPHIL_IN_LIEU_OF_PHD'))

  return (
    <CSelect {...props} disableUnderline={false}>
      {allowedThesisTypes.map((type) => (
        <MenuItem key={type.code} value={type.code}>
          {type.name}
        </MenuItem>
      ))}
    </CSelect>
  )
}

export default ThesisTypeSelectorInput
