import { useState } from 'react'

import { ProvostConfer, ProvostConferVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import PROVOST_CONFER from 'src/lib/graphql/mutations/ProvostConfer'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

type Props = {
  iterationId: number
  onReload: () => unknown
  disabled?: boolean
  fullWidth?: boolean
  closePage?: boolean
}

/**
 * Provost confer thesis button
 */
const ConferThesisButton = ({ iterationId, onReload, disabled, fullWidth, closePage = false }: Props) => {
  const [saving, setSaving] = useState(false)
  const [confer] = useMutation<ProvostConfer, ProvostConferVariables>(PROVOST_CONFER)
  const showSnackbar = useSnackbar()

  const onConfirm = async () => {
    setSaving(true)
    try {
      const result = await confer({ variables: { iterationId } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully conferred this thesis.", 'success')

      if (closePage) {
        window.onbeforeunload = function () {
          if (window.opener) {
            window.opener.location.reload() // Reload the parent window before closing the child window
          }
        }

        window.close()
      }

      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not confer this thesis. Please try again later.', 'error')
      setSaving(false)
    }
  }

  return (
    <>
      <Button
      size='small'
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        onClick={onConfirm}
        disabled={disabled || saving}
      >
        Confer
      </Button>
    </>
  )
}

export default ConferThesisButton
