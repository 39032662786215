import { useController, useFormContext } from '@redwoodjs/forms'

import { TextField, TextFieldProps } from './TextField'

export type SelectProps = TextFieldProps
export const Select = (props: SelectProps) => <TextField {...props} select={true} />

export type CSelectProps = SelectProps & {
  name: string
  multiple?: boolean
}
export const CSelect = (props: CSelectProps) => {
  const { trigger } = useFormContext()

  const { name, multiple = false, ...selectProps } = props
  const { field, fieldState } = useController({ name })
  const { ref, ...fieldProps } = field
  return (
    <Select
      SelectProps={{ multiple: multiple }}
      {...selectProps}
      {...fieldProps}
      inputRef={ref}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : selectProps.helperText}
      onBlur={() => trigger(name)}
    />
  )
}
