import { Fragment, useState } from 'react'

import {
  Alert,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { AnimalEthics, UserAnimalEthicsQuery } from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import ExternalLink from 'src/components/ExternalLink/ExternalLink'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'

export const QUERY = gql`
  query UserAnimalEthicsQuery {
    animalEthics {
      isApproved
      externalId
      statusTitle
      title
    }
  }
`

export const Loading = () => (
  <DialogContent id="dialogcontent">
    <InlineLoader message="Loading your animal ethics applications from central UQ systems..." />
  </DialogContent>
)

type FailureProps = {
  onClose: () => unknown
}
export const Failure = ({ onClose }: FailureProps) => (
  <>
    <DialogContent id="dialogcontent">
      <Alert severity="error">
        UQRDM could not load your ethics applications from central UQ systems. Please try again.
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button color="inherit" onClick={onClose} variant="outlined">
        Close
      </Button>
    </DialogActions>
  </>
)

type SelectAnimalEthicsDialogCellProps = CellSuccessProps<UserAnimalEthicsQuery> & {
  open: boolean
  selectedAnimalEthics: AnimalEthics[]
  onClose: () => void
  onAdd: (grants: AnimalEthics[]) => void
}

export const Success = (props: SelectAnimalEthicsDialogCellProps) => {
  const [selected, setSelected] = useState<AnimalEthics[]>(props.selectedAnimalEthics)

  const onSubmit = () => {
    props.onAdd(selected)
  }

  const isSelected = (animalEthic: AnimalEthics) => {
    return !!selected.find((e) => e.externalId === animalEthic.externalId)
  }

  const toggleAnimalEthic = (animalEthic: AnimalEthics) => {
    if (isSelected(animalEthic)) {
      setSelected(selected.filter((e) => e.externalId !== animalEthic.externalId))
    } else {
      setSelected([...selected, animalEthic])
    }
  }

  // Only show those applications that are not already on the record
  const newAnimalEthics = props.animalEthics.filter((e) => {
    return props.selectedAnimalEthics.find((se) => se.externalId === e.externalId) === undefined
  })

  return (
    <>
      <DialogContent id="dialogcontent">
        <List dense>
          {newAnimalEthics.map((e) => (
            <Fragment key={e.externalId}>
              <ListItemButton onClick={() => toggleAnimalEthic(e)}>
                <ListItemIcon>
                  <Checkbox edge="start" tabIndex={-1} checked={isSelected(e)} />
                </ListItemIcon>
                <ListItemText primary={e.externalId} secondary={e.title} />
              </ListItemButton>
              <Divider />
            </Fragment>
          ))}

          {newAnimalEthics.length === 0 ? (
            <ListItem>
              <ListItemText
                primary={
                  <span>
                    UQRDM could not find any current ethics applications associated with your credentials in central UQ
                    systems. Please contact{' '}
                    <ExternalLink href="mailto:my-research-help@uq.edu.au">MyResearch</ExternalLink> if you believe this
                    to be an error.
                  </span>
                }
              />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemText
                primary={
                  <span>
                    UQRDM found these ethics applications associated with your credentials in central UQ systems. Please
                    contact <ExternalLink href="mailto:my-research-help@uq.edu.au">MyResearch</ExternalLink> if the
                    intended application was not found.
                  </span>
                }
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {newAnimalEthics.length === 0 ? (
          <Button color="inherit" onClick={props.onClose} variant="outlined">
            Close
          </Button>
        ) : (
          <>
            <Button color="inherit" onClick={props.onClose} variant="outlined">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              Add
            </Button>
          </>
        )}
      </DialogActions>
    </>
  )
}
