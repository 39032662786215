const GET_PRESIGNED_URL_EXAMINER_FILE_UPLOAD = gql`
  query GetPresignedUrlExaminerFileUpload($filename: String!, $contentType: String!, $examinerId: Int!) {
    getPresignedUrlExaminerFileUpload(filename: $filename, contentType: $contentType, examinerId: $examinerId) {
      key
      presignedUrl
    }
  }
`

export default GET_PRESIGNED_URL_EXAMINER_FILE_UPLOAD
