import { useState } from 'react'

import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ThesisSubmissionHistoryQuery, ThesisSubmissionHistoryQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import QUERY from 'src/lib/graphql/queries/ThesisSubmissionHistoryQuery'

import ThesisHistoryIterationView from './ThesisHistoryIterationView'
import ThesisHistoryList from './ThesisHistoryList'

export type ThesisSubmissionHistoryEntry = ThesisSubmissionHistoryQuery['thesisSubmissionHistory'][0]

type Props = {
  submissionId: number
  open: boolean
  onClose: () => void
}

const ThesisHistoryDialog = ({ submissionId, open, onClose }: Props) => {
  const { data, error, loading } = useQuery<ThesisSubmissionHistoryQuery, ThesisSubmissionHistoryQueryVariables>(
    QUERY,
    { variables: { id: submissionId } }
  )
  const [openAccordion, setOpenAccordion] = useState(-1)
  const [openIteration, setOpenIteration] = useState(null)

  const onToggleAccordion = (iterationId: number) => {
    setOpenAccordion(iterationId === openAccordion ? -1 : iterationId)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      <DialogTitle id="dialogtitle" variant="h2" component="h2" color="primary">
        Candidature history
      </DialogTitle>
      <DialogContent id="dialogcontent">
        {loading && <InlineLoader message="Loading thesis submission history..." />}
        {error && <Alert severity="error">Could not load your thesis submission history</Alert>}
        {openIteration && <ThesisHistoryIterationView iterationId={openIteration} />}
        {data && !openIteration && (
          <ThesisHistoryList
            iterations={data.thesisSubmissionHistory}
            openAccordion={openAccordion}
            onOpenIteration={setOpenIteration}
            onToggleAccordion={onToggleAccordion}
          />
        )}
      </DialogContent>
      <DialogActions>
        {openIteration && (
          <Button variant="outlined" color="secondary" onClick={() => setOpenIteration(null)}>
            Back
          </Button>
        )}
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ThesisHistoryDialog
