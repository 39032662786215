import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import BasicExaminerDetails from '../BasicExaminerDetails'

type Props = {
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0]
  onOpen: boolean
  onClose: () => void
}

/**
 * Examiner details viewer dialog for Chair
 */
const ChairExaminerCOIViewerDialog = ({ examiner, onOpen, onClose }: Props) => {
  return (
    <Dialog
      open={onOpen}
      onClose={() => {
        onClose()
      }}
      aria-describedby="dialogContent"
      fullWidth
      maxWidth="lg"
    >
      <DialogContent id="dialogContent">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <BasicExaminerDetails examiner={examiner} isChair={true} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12}>
          <Grid container spacing={1} justifyContent={'flex-end'} flexDirection={'row'}>
            <Grid>
              <Button variant="contained" color="secondary" onClick={() => onClose()}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ChairExaminerCOIViewerDialog
