const GET_THESIS_SUBMISSION_EXAMINER_INVITATION_QUERY = gql`
  query GetThesisSubmissionExaminerInvitationQuery($joinToken: String!) {
    thesisSubmissionExaminerInvitation(joinToken: $joinToken) {
      candidateName
      invitedBy {
        displayName
        mail
        createdAt
        eduPersonPrincipalName
        id
        updatedAt
      }
      statusCode
      thesisSubmissionId
    }
  }
`

export default GET_THESIS_SUBMISSION_EXAMINER_INVITATION_QUERY
