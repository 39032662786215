import * as yup from 'yup'

export type ThesisSubmissionChairFormData = {
  eduPersonPrincipalName: string
}

export const createDefaultValuesForChair = {
  eduPersonPrincipalName: '',
}

// Add external examiner form schema
export const validationSchemaForChair = yup.object().shape({
  eduPersonPrincipalName: yup.string().required('You must provide a Chair.'),
})
