import { useState } from 'react'

import { hasOralExamCompleted } from '@common/thesis/utility'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import dayjs from 'dayjs'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import { Date as DateInput } from 'src/components/Form/Inputs'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import { ThesisSubmissionDetails } from '../../ThesisSubmissionType'

const GRADUATE_SCHOOL_RESCHEDULES_ORAL_EXAMINATION = gql`
  mutation GraduateSchoolUpdateOralDate($iterationId: Int!, $oralDate: DateTime!) {
    graduateSchoolUpdateOralDate(iterationId: $iterationId, oralDate: $oralDate)
  }
`

type Props = {
  submission: ThesisSubmissionDetails
  iterationId: number
  onReload: () => unknown
}

const UpdateOralExaminationDateButton = ({ submission, iterationId, onReload }: Props) => {
  const showSnackbar = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [saving, setSaving] = useState(false)
  const [oralDate, setOralDate] = useState<Date>(dayjs(submission?.currentIteration?.oralDate).toDate())

  const [updateOralDate] = useMutation(GRADUATE_SCHOOL_RESCHEDULES_ORAL_EXAMINATION)

  const onConfirm = async () => {
    setSaving(true)

    try {
      const result = await updateOralDate({
        variables: { iterationId, oralDate: dayjs(oralDate) },
      })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully updated the oral date", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not update oral date. Please try again later.', 'error')
    }

    setSaving(false)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Update oral examination date
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} aria-describedby="dialogContent" aria-labelledby="dialogTitle">
        <DialogTitle id="dialogTitle" variant="h2" component="h3" color="primary">
          Update oral examination date
        </DialogTitle>
        <DialogContent id="dialogContent">
          Select a date below to update the scheduled date of the oral examination. All parties will be notified of the
          change and new scheduled date.
          <br />
          <br />
          <DateInput
            value={oralDate}
            onChange={setOralDate}
            label="Oral examination date"
            required
            helperText="Please select the oral examination date."
          />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setOpen(false)} disabled={!!saving}>
            {'Cancel'}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={hasOralExamCompleted(oralDate) ? () => setOpenWarningDialog(true) : onConfirm}
            loading={!!saving}
          >
            {'Update'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
        onConfirm={onConfirm}
        loading={saving}
        title="Warning"
        confirmLabel="Confirm"
      >
        You have selected a date that indicates the oral examination has already happened or will happen today. This
        will advance the candidature so that the Chair can complete their summary report.
      </ConfirmDialog>
    </>
  )
}

export default UpdateOralExaminationDateButton
