import { Typography, Box, Divider } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

const Group = ({ group, key, ...props }: any) => {
  const resultNumber = group.children.length
  const numberMoreThanMax = resultNumber - 10
  return (
    <Box key={key}>
      <Typography component="h4" variant="h4">
        {group.title}
      </Typography>
      <Box component="ul" {...props} id={`grp-${group.title}`} tabIndex={0} sx={{ padding: 0, listStyleType: 'none' }}>
        {group.children.slice(0, 10)}
      </Box>
      {numberMoreThanMax > 0 && (
        <Grid container>
          <Grid xs sx={{ textAlign: 'left', color: '#878787', fontSize: '.8rem', pl: 2, pr: 2 }}>
            TIP: Type a more specific query to narrow the search
          </Grid>
          <Grid xs="auto" sx={{ textAlign: 'right', color: '#878787', fontSize: '.8rem' }}>
            +{numberMoreThanMax} more
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
type Group<T> = {
  title: string
  selector: (c: string) => boolean
  children: T[]
}

type ListboxComponentProps<T> = {
  children: T[]
  [key: string]: unknown
}
function ListboxComponent<T>(
  { children = [], ...props }: ListboxComponentProps<T>,
  ref: React.RefObject<HTMLDivElement>
) {
  const groups = children
    .reduce(
      (accum: Group<T>[], element: unknown) => {
        const code = element.key.split(' ')[0]
        return accum.map((g: unknown) => (g.selector(code) ? { ...g, children: [...g.children, element] } : g))
      },
      [
        { title: 'Division', selector: (c: string) => c.length === 2, children: [] },
        { title: 'Group', selector: (c: string) => c.length === 4, children: [] },
        { title: 'Code', selector: (c: string) => c.length === 6, children: [] },
      ]
    )
    .map((group: Group<T>) => ({
      ...group,
      // add no results
      children: !group.children.length ? [<Typography key={0}>Nothing matches your query</Typography>] : group.children,
    }))
  return (
    <Grid container spacing={0} sx={{ padding: 2 }} ref={ref}>
      <Grid xs>
        <Group group={groups[0]} {...props} />
        <Divider flexItem sx={{ m: 1 }} />
        <Group group={groups[1]} {...props} />
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ m: 1 }} />
      <Grid xs>
        <Group group={groups[2]} {...props} />
      </Grid>
    </Grid>
  )
}

export default ListboxComponent as any
