import { thesisIterationStatusTypes } from '@common/thesis'
import { getIterationState } from '@common/thesis/iterationState'
import { EXAMINER_REMOVED_STATUSES } from '@common/thesis/thesisExaminers/getThesisExaminerStatuses'
import { Alert, Grid, Typography } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'
import { toTimezone } from 'src/lib/toTimezone'
import {
  isActiveThesisExaminer,
  isAssignedThesisCandidate,
  isAssignedThesisChair,
  isAssignedThesisPrincipalAdvisor,
} from 'src/lib/userAccessChecker'

import ThesisSubmissionButton from '../../CandidateActions/ThesisSubmissionButton/ThesisSubmissionButton'

import SubmissionFormViewer from './SubmissionFormViewer'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
}

/**
 * Thesis submission card section
 */
const ThesisSubmissionCard = ({ submission, onReload }: Props) => {
  const { currentUser, hasRole } = useAuth()

  const isCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const isChair = isAssignedThesisChair(currentUser, submission?.chair?.user.id)
  const isAdvisor = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const isGS = hasRole('grad-school-user')
  const examiner = submission.thesisSubmissionExaminers.find(
    (examiner) => examiner?.user?.id === currentUser.id && !EXAMINER_REMOVED_STATUSES.has(examiner?.status)
  )
  const isExaminer = isActiveThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)

  const chairCannotViewSubmission = isChair && !submission.chair.canViewSubmission && submission.currentIteration
  const examinerCannotViewSubmission = isExaminer && !examiner?.canViewSubmission && submission.currentIteration

  const canViewSubmission = !chairCannotViewSubmission || !examinerCannotViewSubmission || isAdvisor || isGS

  const iterationState = getIterationState(submission?.currentIteration?.currentStatus)
  const canCreateIteration = iterationState.canSubmitIteration

  const candidateSubmitting =
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_REVISING_AND_RESUBMITTING' ||
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING'

  const candidateSubmittingFinals =
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_FINAL_THESIS'

  const candidateSubmittingEdits =
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_FINAL_GS_CHANGES' ||
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_FINAL_PA_CHANGES' ||
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_GS_CHANGES' ||
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_PA_CHANGES' ||
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_CHANGES_PA_ADDITIONAL' ||
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_CHANGES_CHAIR_ADDITIONAL'

  const candidateSubmittingCorrections =
    submission?.currentIteration?.currentStatus?.status === 'CANDIDATE_SUBMITTING_CHANGES'

  const isFinal = thesisIterationStatusTypes.find(
    (s) => s.code === submission.currentIteration.currentStatus.status
  )?.isFinal ?? false

  return (
    <>
      <ContentCard title={isFinal ? 'Final thesis submission' : 'Thesis documents'}>
        {(candidateSubmitting || candidateSubmittingFinals) && !isCandidate ? (
          <Alert severity="info">
            When the thesis is submitted and requires your evaluation, it will display here. You will be notified when
            action is required.
          </Alert>
        ) : chairCannotViewSubmission || examinerCannotViewSubmission ? (
          // After thesis has been submitted - View by Chair or Examiner
          <Alert severity="info">
            Candidate has submitted the thesis, which is being reviewed by the Graduate School and the Principal
            Advisor. {!isGS && 'You will be notified via email when your action is required.'}
          </Alert>
        ) : (
          <>
            {/* Candidate section */}
            {isCandidate && (
              <>
                {canCreateIteration === false ? (
                  <SubmissionFormViewer submission={submission} />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {candidateSubmitting && (
                        <Typography variant="subtitle1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {'Submit your thesis for review and examination.'}
                        </Typography>
                      )}

                      {candidateSubmittingFinals && (
                        <Typography variant="subtitle1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {'Submit your thesis for review and conferral.'}
                        </Typography>
                      )}

                      {candidateSubmittingEdits && (
                        <Typography variant="subtitle1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {'Please update your thesis as requested.'}
                        </Typography>
                      )}

                      {candidateSubmittingCorrections && (
                        <>
                          <Typography variant="subtitle1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {`Corrections due date: ${toTimezone(submission?.currentIteration?.intendedSubmissionDate)}`}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {'Submit your corrected thesis and corrections document for review.'}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <ThesisSubmissionButton submission={submission} onReload={onReload} />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {/* Everyone else section */}
            {!isCandidate && canViewSubmission && <SubmissionFormViewer submission={submission} />}
          </>
        )}
      </ContentCard>
    </>
  )
}

export default ThesisSubmissionCard
