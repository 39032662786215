export default {
  title: 'Embargo status',
  text: (
    <>
      A thesis embargo restricts access to the thesis in UQ eSpace for three years from the date of conferral. Embargo
      restriction can be placed on only the thesis document or the thesis title and abstract, as well.
      <br />
      <br />
      Candidates should discuss their thesis embargo status with their Principal Advisor before final submission.
    </>
  ),
}
