import { Alert } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import ContentLoader from 'src/components/ContentLoader/ContentLoader'
import GET_THESIS_SUBMISSION_QUERY from 'src/lib/graphql/queries/GetThesisSubmissionQuery'

import Content from './components/Content'

type Props = {
  query?: URLSearchParams
  id: number
}

const ThesisExaminersDetailsPage = ({ id }: Props) => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const { data, error, loading, refetch } = useQuery<GetThesisSubmissionQuery>(GET_THESIS_SUBMISSION_QUERY, {
    variables: { id },
  })

  return (
    <>
      {error && <Alert severity="error">Error loading thesis submission details</Alert>}
      {loading && <ContentLoader message="Loading thesis submission details..." />}
      {!loading && data && <Content submission={data.getThesisSubmission} onReload={refetch} query={query} />}
    </>
  )
}

export default ThesisExaminersDetailsPage
