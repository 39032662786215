const GET_PRESIGNED_URL_THESIS_UPLOAD = gql`
  query GetPresignedUrlThesisUpload($filename: String!, $contentType: String!, $fileCreatorUserId: Int) {
    getPresignedUrlThesisUpload(filename: $filename, contentType: $contentType, fileCreatorUserId: $fileCreatorUserId) {
      key
      presignedUrl
    }
  }
`

export default GET_PRESIGNED_URL_THESIS_UPLOAD
