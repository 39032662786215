import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'

type Props = {
  open: boolean
  saving: boolean
  onCancel: () => unknown
  onConfirm: () => unknown
}

const DowngradeToMPhilButtonDialog = ({ open, saving, onCancel, onConfirm }: Props) => {
  const formMethods = useForm({ defaultValues: { message: '' } })

  const onSubmit = () => {
    onConfirm()
    formMethods.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      aria-describedby="dialogcontent"
      aria-labelledby="dialogtitle"
    >
      <DialogTitle id="dialogtitle" color="primary" variant="h2" component="h2">
        Approve for final submission of downgraded degree
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <DialogContent id="dialogcontent">
          <Typography variant="body1" sx={{ mb: 1 }}>
            By downgrading the thesis, the candidate can submit their final thesis but will be awarded a Master's of
            Philosophy.
          </Typography>
        </DialogContent>
        <DialogActions>
          {!saving && (
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}

          <LoadingButton variant="contained" color="primary" loading={saving} type="submit">
            Downgrade to MPhil
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default DowngradeToMPhilButtonDialog
