import { ExaminerPaymentBankType } from 'types/graphql'
import * as yup from 'yup'

export interface GSExaminerBasicDetailUpdateType {
  title?: string
  fullName?: string
  position: string
  mail?: string
  institution: string
  rationaleForNomination?: string

  contactNumber?: string
  contactAddress?: string
  requirePayment?: boolean
  bankAccountType?: ExaminerPaymentBankType
}

// @TODO examiner type
export const createGSExaminerBasicDetailsUpdateDefaultValues = (
  examiner: any,
  examinerHasUQAccount
): GSExaminerBasicDetailUpdateType => {
  const title = examinerHasUQAccount
    ? {}
    : {
        title: examiner?.title,
      }

  return {
    ...title,
    fullName: examinerHasUQAccount ? examiner?.user?.displayName : examiner?.fullName,
    position: examiner?.position,
    mail: examinerHasUQAccount ? examiner?.user?.mail : examiner?.mail,
    institution: examiner?.institution,
    rationaleForNomination: examiner?.rationaleForNomination,
    contactNumber: examiner?.contactNumber,
    contactAddress: examiner?.contactAddress,
    requirePayment: examiner?.requirePayment,
    bankAccountType: examiner?.bankAccountType,
  }
}

const commonSchema = {
  title: yup.string().required('You must provide a title.'),
  fullName: yup.string().required('You must provide a full name.'),
  mail: yup.string().email('Please enter a valid email address.').required('You must provide an email address.'),
}

const gsUpdateExaminerDetailsSchema = {
  position: yup.string().required('You must provide a position.'),
  institution: yup.string().required('You must provide an institution.'),
  rationaleForNomination: yup.string().nullable(),
  contactNumber: yup
    .string()
    .matches(/^(\+)?[0-9]*$/, 'Contact number can only contain digits and at most one "+" at the start.')
    .test(
      'plus-sign',
      'Contact phone number can only have one "+" at the start.',
      (value) => !value || value.indexOf('+') <= 0
    )
    .nullable(),
  contactAddress: yup.string().nullable(),
  requirePayment: yup.boolean().nullable(),
  bankAccountType: yup.string().when('requirePayment', {
    is: true,
    then: (schema) => schema.required('You must provide your bank account type.'),

    otherwise: (schema) => schema.notRequired(),
  }),
}

export const graduateSchoolUpdatePartialExaminerDetailsValidation = yup.object().shape(gsUpdateExaminerDetailsSchema)
export const graduateSchoolUpdateAllExaminerDetailsValidation = yup
  .object()
  .shape({ ...commonSchema, ...gsUpdateExaminerDetailsSchema })

export const gsUpdatesPartialExaminerDetailsTransformer = (data: GSExaminerBasicDetailUpdateType) => {
  return {
    fullName: data.fullName,
    position: data.position,
    institution: data.institution,
    rationaleForNomination: data.rationaleForNomination,
    contactNumber: data?.contactNumber,
    contactAddress: data?.contactAddress,
    requirePayment: data?.requirePayment,
    bankAccountType: data?.bankAccountType,
  }
}

export const gsUpdatesExaminerDetailsTransformer = (data: GSExaminerBasicDetailUpdateType) => {
  return {
    title: data.title,
    fullName: data.fullName,
    mail: data.mail,
    ...gsUpdatesPartialExaminerDetailsTransformer(data),
  }
}
