import {
  ApartmentOutlined,
  AttachMoney,
  MailOutline,
  PaymentOutlined,
  PersonOutline,
  WorkOutline,
} from '@mui/icons-material'
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ThesisSubmissionExaminer } from 'types/graphql'

import { useAuth } from 'src/auth'

interface Props {
  readOnly?: boolean
  examiner: Pick<
    ThesisSubmissionExaminer,
    | 'status'
    | 'fullName'
    | 'mail'
    | 'position'
    | 'institution'
    | 'rationaleForNomination'
    | 'examinerCOIStatements'
    | 'examinerCOIDetails'
    | 'advisorCOIStatements'
    | 'advisorCOIDetails'
    | 'candidateCOIStatements'
    | 'candidateCOIDetails'
  > &
    Partial<
      Pick<ThesisSubmissionExaminer, 'contactNumber' | 'contactAddress' | 'requirePayment' | 'bankAccountType'>
    > & {
      user?: {
        displayName: string
        mail: string
      }
    }
}

const BasicExaminerDetails = ({ examiner, readOnly }: Props) => {
  const isFinalGSApproval = examiner?.status === 'GRADUATE_SCHOOL_FINAL_REVIEW'
  const styles = {
    table: {
      width: '100%',
      mb: 2,
    },
    tableCell: {
      pl: 2,
      verticalAlign: 'top !important',
      borderBottomWidth: 0,
      width: isFinalGSApproval ? '33%' : '50%',
    },
    tableBorderBottom: {
      borderBottom: '1px solid rgba(0,0,0,0.05',
    },
  }
  const { currentUser } = useAuth()
  const isChair = currentUser.roles.includes('user-thesis-chair')
  const isDean = currentUser.roles.includes('dean-user')

  return (
    <Grid container spacing={1} sx={{ width: '100%' }}>
      {/* Dialog title*/}
      <Grid xs={12}>
        <Typography variant="h2" component="h2" color={'primary'} aria-hidden sx={{ ml: 1 }}>
          Examiner details
        </Typography>
      </Grid>
      {!readOnly && !isChair && !isDean && (
        <Grid xs={12}>
          <Typography sx={{ ml: 1 }}>
            Nominating appropriate examiners is an important step in the examination process. Examiners must be research
            active and have sufficient expertise relating to the thesis or a substantial part of the thesis. When
            selecting examiners it is required for the Principal Advisor to make informal contact with the examiner to
            determine their interest and availability to perform the examination.
          </Typography>
        </Grid>
      )}
      {/* Examiner Details */}

      <Grid xs={12}>
        <Table aria-label="Examiner details table" size="small" sx={styles.table}>
          <TableHead>
            {/* Headers */}
            <TableRow>
              <TableCell align="left" sx={styles.tableCell}>
                <Typography variant="body1" component="h3" color={'primary'} fontWeight={'bold'}>
                  Nominated examiner
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ ...styles.tableCell, pl: 2 }}>
                <Typography variant="body1" component="h3" color={'primary'} fontWeight={'bold'}>
                  Rationale
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* Nominated examiner cell */}
              <TableCell sx={styles.tableCell}>
                {/* Name */}
                <Grid container spacing={0}>
                  <Grid xs={'auto'}>
                    <Tooltip title="Name" placement="left" arrow>
                      <PersonOutline aria-label="Name" sx={{ mr: 1 }} />
                    </Tooltip>
                  </Grid>
                  <Grid xs>
                    <Typography variant="body1">{examiner?.user?.displayName || examiner?.fullName}</Typography>
                  </Grid>
                </Grid>
                {/* Email */}
                <Grid container spacing={0}>
                  <Grid xs={'auto'}>
                    <Tooltip title="Email address" placement="left" arrow>
                      <MailOutline aria-label="Email address" sx={{ mr: 1 }} />
                    </Tooltip>
                  </Grid>
                  <Grid xs>
                    <Typography variant="body1">{examiner?.user?.mail || examiner?.mail}</Typography>
                  </Grid>
                </Grid>
                {/* Position */}
                <Grid container spacing={0}>
                  <Grid xs={'auto'}>
                    <Tooltip title="Position" placement="left" arrow>
                      <WorkOutline aria-label="Position" sx={{ mr: 1 }} />
                    </Tooltip>
                  </Grid>
                  <Grid xs>
                    <Typography variant="body1">{examiner?.position}</Typography>
                  </Grid>
                </Grid>
                {/* Institution */}
                <Grid container spacing={0}>
                  <Grid xs={'auto'}>
                    <Tooltip title="Institution" placement="left" arrow>
                      <ApartmentOutlined aria-label="Institution" sx={{ mr: 1 }} />
                    </Tooltip>
                  </Grid>
                  <Grid xs>
                    <Typography variant="body1">{examiner?.institution}</Typography>
                  </Grid>
                </Grid>
                {/* Payment required */}
                {isFinalGSApproval && (
                  <Grid container spacing={0}>
                    <Grid xs={'auto'}>
                      <Tooltip title="Examiner requires payment?" placement="left" arrow>
                        <AttachMoney aria-label="Is payment required" sx={{ mr: 1 }} />
                      </Tooltip>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1">
                        {examiner?.requirePayment === true ? 'Payment is required' : 'Payment is not required'}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {/* Bank details */}
                {isFinalGSApproval && examiner?.requirePayment === true && (
                  <Grid container spacing={0}>
                    <Grid xs={'auto'}>
                      <Tooltip title="Bank account type" placement="left" arrow>
                        <PaymentOutlined aria-label="Bank details" sx={{ mr: 1 }} />
                      </Tooltip>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1">
                        {examiner?.bankAccountType === 'AUS' ? 'Australian bank account' : 'International bank account'}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </TableCell>
              {/* Rationale cell */}
              <TableCell sx={{ ...styles.tableCell, pl: 2 }}>
                <Grid container spacing={0}>
                  <Grid xs>
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                      {examiner.rationaleForNomination}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default BasicExaminerDetails
