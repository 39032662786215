import { useEffect, useState } from 'react'

import { EXAMINERS_CAN_BE_REMOVED_THESIS_STATUSES } from '@common/thesis/getThesisStatuses'
import getThesisExaminerOutcomeLabel from '@common/thesis/thesisExaminers/getThesisExaminerOutcomeLabel'
import {
  EXAMINER_REMOVED_STATUSES,
  PA_CAN_DELETE_EXAMINER_STATUSES,
} from '@common/thesis/thesisExaminers/getThesisExaminerStatuses'
import { CheckCircle } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import WarningAmberIcon from 'src/components/Icons/WarningAmberIcon'
import {
  isActiveThesisExaminer,
  isAssignedThesisCandidate,
  isAssignedThesisChair,
  isAssignedThesisPrincipalAdvisor,
  isPendingThesisExaminer,
} from 'src/lib/userAccessChecker'

import ResendThesisExaminerInvitationMailDialog from '../ResendThesisExaminerInvitationMailDialog'
import ResendThesisExaminerRequestFurtherInfoMailDialog from '../ResendThesisExaminerRequestFurtherInfoMailDialog'
import ThesisExaminerApprovalDialog from '../ThesisExaminerApprovalDialog'
import ThesisExaminerRemovalDialog from '../ThesisExaminerRemovalDialog'

import AdvisorUpdateExaminerCOIFormDialog from './AdvisorUpdateExaminerCOI/AdvisorUpdateExaminerCOIFormDialog'
import CandidateCOIFormDialog from './CandidateUpdateExaminerCOI/CandidateUpdateExaminerCOIDialog'
import ChairExaminerCOIViewerDialog from './ChairExaminerCOIViewer/ChairExaminerCOIViewerDialog'
import ThesisExaminerDetailsFormDialog from './ExaminerUpdateExaminerCOI/ThesisExaminerDetailsFormDialog'
import ExaminerUpdateSelfDetailsDialog from './ExaminerUpdateSelfDetails/ExaminerUpdateSelfDetailsDialog'
import GraduateSchoolExaminerDetailsUpdateDialog from './GraduateSchoolExaminerDetailsUpdate/GraduateSchoolExaminerDetailsUpdateDialog'
import ThesisExaminerSubmissionNoteDialog from './ThesisExaminerSubmissionNoteDialog'
import ThesisExaminerUserNoteDialog from './ThesisExaminerUserNoteDialog'

type Props = {
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0] | null
  query?: URLSearchParams
}
/**
 * Editable examiner view for Graduate School user and Principal Advisor
 *
 * The Graduate School user can add/edit the examiner
 * The Principal Advisor can only add the examiner
 */
const ExaminerActionMenu = ({ submission, examiner, query }: Props) => {
  const { hasRole, currentUser } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // Principal Advisor & Graduate School
  const [openViewExaminerDetailsDialog, setOpenViewExaminerDetailsDialog] = useState<boolean>(false)
  const [onOpenResendExaminerInvitationDialog, setOnOpenResendExaminerInvitationDialog] = useState<boolean>(false)
  // Principal Advisor
  const [onOpenAdvisorExaminerCOIResponseDialog, setOnOpenAdvisorExaminerCOIResponseDialog] = useState<boolean>(false)
  const [openDeleteExaminerDialog, setOpenDeleteExaminerDialog] = useState<boolean>(false)
  const [onOpenAdvisorCOIChangeRequestDialog, setOnOpenAdvisorCOIChangeRequestDialog] = useState<boolean>(false)
  // Graduate School
  const [onOpenExaminerApprovalDialog, setOnOpenExaminerApprovalDialog] = useState<boolean>(false)
  const [onOpenExaminerSubmissionNoteDialog, setOnOpenExaminerSubmissionNoteDialog] = useState<boolean>(false)
  const [onOpenExaminerUserNoteDialog, setOnOpenExaminerUserNoteDialog] = useState<boolean>(false)
  const [onOpenResendExaminerRequestFurtherInfoDialog, setOnOpenResendExaminerRequestFurtherInfoDialog] =
    useState<boolean>(false)
  // Thesis examiner
  const [onOpenExaminerCOIDialog, setOnOpenExaminerCOIDialog] = useState<boolean>(false)
  const [onOpenExaminerCOIResponseDialog, setOnOpenExaminerCOIResponseDialog] = useState<boolean>(false)
  // Candidate
  const [onOpenCandidateCOIDialog, setOnOpenCandidateCOIDialog] = useState<boolean>(false)
  const [onOpenCandidateExaminerCOIResponseDialog, setOnOpenCandidateExaminerCOIResponseDialog] =
    useState<boolean>(false)
  // Chair
  const [onOpenExaminerBasicDetailsDialog, setOnOpenExaminerBasicDetailsDialog] = useState<boolean>(false)

  const isGS = hasRole('grad-school-user')
  const isAdvisor = isAssignedThesisPrincipalAdvisor(currentUser, submission.principalAdvisor.id)
  const isPendingExaminer = isPendingThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)
  const isActiveExaminer = isActiveThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)
  const isCandidate = isAssignedThesisCandidate(currentUser, submission.creator.id)
  const isChair = isAssignedThesisChair(currentUser, submission.chair?.user?.id)

  // Open dialog if query params are present; Used for redirecting from token acceptance page
  useEffect(() => {
    if (isPendingExaminer && query?.get('showDialog') === 'true') {
      setOnOpenExaminerCOIDialog(true)
    }
  }, [query])

  const onCloseDialog = () => {
    handleMenuClose() // @TODO palce handleMenuClose in each dialog onClose for better UX

    setOnOpenExaminerApprovalDialog(false)
    setOnOpenExaminerSubmissionNoteDialog(false)
    setOnOpenExaminerUserNoteDialog(false)
    setOnOpenExaminerCOIDialog(false)
    setOpenDeleteExaminerDialog(false)
    setOpenViewExaminerDetailsDialog(false)
    setOnOpenAdvisorCOIChangeRequestDialog(false)
    setOnOpenAdvisorExaminerCOIResponseDialog(false)
    setOnOpenResendExaminerInvitationDialog(false)
    setOnOpenResendExaminerRequestFurtherInfoDialog(false)

    // Examiner
    setOnOpenExaminerCOIResponseDialog(false)
    if (isPendingExaminer && query) {
      navigate(routes.thesisExaminerDetails({ id: submission.id, tab: 'committee' }), { replace: true })
    }

    // Candidate
    setOnOpenCandidateCOIDialog(false)
    setOnOpenCandidateExaminerCOIResponseDialog(false)

    // Chair
    setOnOpenExaminerBasicDetailsDialog(false)
  }

  // Only GS and PA can remove examiner in the following cases
  const canRemoveExaminer =
    ((PA_CAN_DELETE_EXAMINER_STATUSES.has(examiner?.status) && isAdvisor) ||
      (!EXAMINER_REMOVED_STATUSES.has(examiner?.status) && isGS)) &&
    EXAMINERS_CAN_BE_REMOVED_THESIS_STATUSES.has(submission.currentIteration.currentStatus.status)

  const isExaminerApproved = examiner?.status === 'APPROVED'

  // @TODO Menu items that needs to be refactored
  const menuItems = [
    // Graduate School
    isGS &&
      (examiner.status === 'GRADUATE_SCHOOL_INITIAL_REVIEW' || examiner.status === 'GRADUATE_SCHOOL_FINAL_REVIEW') && (
        <MenuItem
          key="examiner-approval-button"
          onClick={() => {
            handleMenuClose()
            setOnOpenExaminerApprovalDialog(true)
          }}
        >
          {examiner.status === 'GRADUATE_SCHOOL_INITIAL_REVIEW' ? 'Initial approval' : 'Final approval'}
        </MenuItem>
      ),
    isGS && (
      <MenuItem
        key="gs-view-details-button"
        onClick={() => {
          handleMenuClose()
          setOpenViewExaminerDetailsDialog(true)
        }}
      >
        View details
      </MenuItem>
    ),
    isGS && (
      <MenuItem
        key="submission-note-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenExaminerSubmissionNoteDialog(true)
        }}
      >
        {examiner?.graduateSchoolNote ? 'View submission note' : 'Add submission note'}
      </MenuItem>
    ),
    isGS && examiner?.user && (
      <MenuItem
        key="user-note-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenExaminerUserNoteDialog(true)
        }}
      >
        {examiner?.user?.graduateSchoolNote ? 'View user note' : 'Add user note'}
      </MenuItem>
    ),
    isGS && examiner.status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL' && !!examiner?.user && (
      <MenuItem
        key="resend-request-further-info-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenResendExaminerRequestFurtherInfoDialog(true)
        }}
      >
        {'Resend notification email'}
      </MenuItem>
    ),

    // Other
    (isGS || isAdvisor) && examiner.status === 'PENDING_INVITATION_ACCEPTANCE' && !examiner?.user && (
      <MenuItem
        key="resend-invitation-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenResendExaminerInvitationDialog(true)
        }}
      >
        {'Resend invitation'}
      </MenuItem>
    ),

    // GS
    isGS && canRemoveExaminer && (
      <MenuItem
        key="remove-examiner-button"
        onClick={() => {
          handleMenuClose()
          setOpenDeleteExaminerDialog(true)
        }}
      >
        Remove examiner
      </MenuItem>
    ),

    // Other
    isActiveExaminer && (examiner.status === 'APPROVED' || examiner.status === 'GRADUATE_SCHOOL_FINAL_REVIEW') && (
      <Box>
        <MenuItem
          key="examiner-view-details-button"
          onClick={() => {
            handleMenuClose()
            setOnOpenExaminerCOIResponseDialog(true)
          }}
        >
          {'View details'}
        </MenuItem>
      </Box>
    ),
    isActiveExaminer &&
      isPendingExaminer &&
      (examiner.status === 'EXAMINER_COI_REVIEW' || examiner.status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL') && (
        <Box>
          <MenuItem
            key="examiner-details-form-button"
            onClick={() => {
              handleMenuClose()
              setOnOpenExaminerCOIDialog(true)
            }}
          >
            {examiner.status === 'FINAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL' ? 'Update details' : 'COI form'}
          </MenuItem>
        </Box>
      ),

    // Candidate
    isCandidate && (
      <MenuItem
        key="candidate-view-details-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenCandidateExaminerCOIResponseDialog(true)
        }}
      >
        {'View details'}
      </MenuItem>
    ),
    isCandidate && examiner.status === 'CANDIDATE_COI_REVIEW' && (
      <MenuItem
        key="candidate-details-form-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenCandidateCOIDialog(true)
        }}
      >
        Provide COI disclosure
      </MenuItem>
    ),

    // Advisor
    isAdvisor && (
      <MenuItem
        key="advisor-view-details-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenAdvisorExaminerCOIResponseDialog(true)
        }}
      >
        {'View details'}
      </MenuItem>
    ),
    isAdvisor && examiner.status === 'INITIAL_CHANGE_REQUEST_BY_GRADUATE_SCHOOL' && (
      <MenuItem key="advisor-change-request-form-button" onClick={() => setOnOpenAdvisorCOIChangeRequestDialog(true)}>
        Update details
      </MenuItem>
    ),

    // Chair
    isChair && (
      <MenuItem
        key="chair-view-details-button"
        onClick={() => {
          handleMenuClose()
          setOnOpenExaminerBasicDetailsDialog(true)
        }}
      >
        {'View examiner details'}
      </MenuItem>
    ),
  ]

  const visibleMenuItems = menuItems.filter(Boolean)

  return (
    <>
      <Grid
        role="banner"
        container
        alignContent={'flex-start'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        sx={{ mb: 1 }}
      >
        <Tooltip title={getThesisExaminerOutcomeLabel(examiner?.status)}>
          <Grid xs="auto">
            {isExaminerApproved ? (
              <CheckCircle sx={{ color: '#00b3a6', height: '1.1em', width: '1.1em' }} />
            ) : (
              <WarningAmberIcon />
            )}
          </Grid>
        </Tooltip>
        <Grid xs sx={{ ml: 1 }}>
          <Grid direction={'column'} container>
            <Grid xs={12}>
              <Typography variant="subtitle1" sx={{ color: 'white', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {examiner?.user ? examiner?.user?.displayName : examiner?.fullName || 'To be confirmed'}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography
                fontSize="small"
                color={'#eeeeee'}
                sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
              >{`${examiner?.institution || ''}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* If the user is an examiner, the user can only access to their own action */}
        {currentUser.roles.includes('user-thesis-examiner') && currentUser.id !== examiner?.user?.id
          ? undefined
          : visibleMenuItems.length > 0 && (
              <Grid xs={'auto'}>
                <Tooltip
                  title={`View actions for ${examiner?.user ? examiner?.user?.displayName : examiner?.fullName || 'this user'}`}
                >
                  <IconButton sx={{ color: 'rgba(255,255,255,0.75)', mr: -2 }} onClick={handleMenuOpen}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
      </Grid>

      {/* Dialogs */}
      {/* Examiner action only */}
      {onOpenExaminerCOIDialog && isPendingExaminer && (
        <ThesisExaminerDetailsFormDialog
          onOpen={onOpenExaminerCOIDialog}
          onClose={onCloseDialog}
          submission={submission}
          examinerData={examiner}
        />
      )}
      {onOpenExaminerCOIResponseDialog && (
        <ExaminerUpdateSelfDetailsDialog
          onOpen={onOpenExaminerCOIResponseDialog}
          onClose={onCloseDialog}
          submission={submission}
          examinerData={examiner}
        />
      )}

      {/* GS action only */}
      {onOpenExaminerSubmissionNoteDialog && isGS && (
        <ThesisExaminerSubmissionNoteDialog
          onOpen={onOpenExaminerSubmissionNoteDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
        />
      )}

      {/* Only available once user confirms their email */}
      {onOpenExaminerUserNoteDialog && examiner?.user && isGS && (
        <ThesisExaminerUserNoteDialog
          onOpen={onOpenExaminerUserNoteDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
        />
      )}

      {onOpenExaminerApprovalDialog && isGS && (
        <ThesisExaminerApprovalDialog
          onOpen={onOpenExaminerApprovalDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
          submission={submission}
        />
      )}

      {/* Candidate action only */}
      {(onOpenCandidateCOIDialog || onOpenCandidateExaminerCOIResponseDialog) && (
        <CandidateCOIFormDialog
          onOpen={onOpenCandidateCOIDialog || onOpenCandidateExaminerCOIResponseDialog}
          onClose={onCloseDialog}
          submission={submission}
          examiner={examiner}
          readOnly={onOpenCandidateExaminerCOIResponseDialog ? true : false}
        />
      )}

      {/* PA action only */}
      {(onOpenAdvisorCOIChangeRequestDialog || onOpenAdvisorExaminerCOIResponseDialog) && (
        <AdvisorUpdateExaminerCOIFormDialog
          onOpen={onOpenAdvisorCOIChangeRequestDialog || onOpenAdvisorExaminerCOIResponseDialog}
          onClose={onCloseDialog}
          submission={submission}
          examiner={examiner}
          readOnly={onOpenAdvisorExaminerCOIResponseDialog ? true : false}
        />
      )}

      {/* PA & GS action only */}
      {openDeleteExaminerDialog && canRemoveExaminer && (
        <ThesisExaminerRemovalDialog
          onOpen={openDeleteExaminerDialog}
          onClose={onCloseDialog}
          submissionId={submission.id}
          submissionPrincipalAdvisorId={submission.principalAdvisor.id}
          examinerData={examiner}
        />
      )}

      {openViewExaminerDetailsDialog && (
        <GraduateSchoolExaminerDetailsUpdateDialog
          onOpen={openViewExaminerDetailsDialog}
          onClose={onCloseDialog}
          submission={submission}
          examiner={examiner}
        />
      )}

      {onOpenExaminerBasicDetailsDialog && (
        <ChairExaminerCOIViewerDialog
          onOpen={onOpenExaminerBasicDetailsDialog}
          onClose={onCloseDialog}
          examiner={examiner}
        />
      )}

      {onOpenResendExaminerInvitationDialog && (
        <ResendThesisExaminerInvitationMailDialog
          onOpen={onOpenResendExaminerInvitationDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
        />
      )}

      {onOpenResendExaminerRequestFurtherInfoDialog && (
        <ResendThesisExaminerRequestFurtherInfoMailDialog
          onOpen={onOpenResendExaminerRequestFurtherInfoDialog}
          onClose={onCloseDialog}
          examinerData={examiner}
        />
      )}

      {/* Menu */}
      {visibleMenuItems.length > 0 && (
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleMenuClose}>
          {visibleMenuItems}
        </Menu>
      )}
    </>
  )
}

export default ExaminerActionMenu
