import { LoadingButton } from '@mui/lab'
import { Alert, Snackbar } from '@mui/material'
import { StopMimickingUser } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { STOP_MIMICKING_USER_MUTATION } from 'src/lib/graphql/mutations/StopMimickingUser'
import useSnackbar from 'src/lib/hooks/useSnackbar'

export const MimickAlert = () => {
  const { currentUser } = useAuth()
  const [stopMimick, { loading }] = useMutation<StopMimickingUser>(STOP_MIMICKING_USER_MUTATION)
  const showSnackbar = useSnackbar()

  const onStop = async () => {
    try {
      await stopMimick()
      showSnackbar('Stopped mimicking user', 'success')
      setTimeout(() => navigate(routes.adminUserOverview()), 500)
    } catch (error) {
      console.error('Failed to stop mimicking user', error)
      showSnackbar('Failed to stop mimicking user', 'error')
    }
  }

  if (currentUser?.mimickedBy === undefined) return undefined

  return (
    <Snackbar open={true} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} color="warning">
      <Alert severity="error">
        <b>You are currently mimicking {currentUser.displayName}</b>&nbsp;&nbsp;&nbsp;
        <LoadingButton color="error" variant="contained" size="small" loading={loading} onClick={onStop}>
          Stop
        </LoadingButton>
      </Alert>
    </Snackbar>
  )
}
