import { useState } from 'react'

import { DeanFailSubmission, DeanFailSubmissionVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Button/Button'
import DEAN_FAIL_SUBMISSION from 'src/lib/graphql/mutations/DeanFailSubmission'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'

import FailSubmissionButtonDialog from './FailSubmissionButtonDialog'

type Props = {
  iterationId: number
  onReload: () => unknown
  onClose: () => void
}

const FailSubmissionButton = ({ iterationId, onReload, onClose }: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [requestChanges] = useMutation<DeanFailSubmission, DeanFailSubmissionVariables>(DEAN_FAIL_SUBMISSION)
  const showSnackbar = useSnackbar()

  const onConfirmRequestChanges = async (outcomeMessage: string) => {
    setSaving(true)
    try {
      const result = await requestChanges({ variables: { iterationId, outcomeMessage } })
      if (!result) throw new Error('Received invalid result')

      showSnackbar("You've successfully failed this submission", 'success')
      setOpen(false)
      onReload()
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not fail submission. Please try again later.', 'error')
      setSaving(false)
    }

    onClose()
  }

  return (
    <>
      <Button variant="contained" color="error" onClick={() => setOpen(true)} sx={{ mb: 1 }}>
        Fail submission
      </Button>
      <FailSubmissionButtonDialog
        open={open}
        saving={saving}
        onCancel={() => setOpen(false)}
        onConfirm={onConfirmRequestChanges}
      />
    </>
  )
}

export default FailSubmissionButton
